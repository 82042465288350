import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslationService } from './translation.service';
import { PtnAppConfig } from './../shared/ptn-app-config';
import { LoaderService } from './loader.service';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from './../services/user.service';


@Injectable()
export class BubbleMapService {

    private url = PtnAppConfig.url_v2;

    constructor(private http: HttpClient, private translationService: TranslationService, private loaderService: LoaderService,
            private userService: UserService) { }
    

    getBubbleMap(companyType: string, industryID: number, limit: number, min_founded_year: number): Observable<any> {

        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        let paramStr = '?limit=' + limit.toString() + '&min_founded_year=' + min_founded_year.toString() + '&type_firm=' + companyType;
        if (industryID)
            paramStr = paramStr + '&code_industry=' + industryID.toString();
    
        this.loaderService.startLoading();
        return this.http.get(this.url + '/analysis/bubble-map-firm' + paramStr, 
                { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading));
    }

}    
