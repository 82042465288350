import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PtnAppConfig } from './../shared/ptn-app-config';
import { map, finalize, catchError } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { TranslationService } from './translation.service';
import { UserService } from './user.service';
import { ListData } from '../models/user';
import { PresentationPresignInfo } from '../models/file';
import { ErrorService } from './error.service';

@Injectable()
export class ListService {

    private url = PtnAppConfig.api_url;
    private url_v2 = PtnAppConfig.url_v2;

    constructor(private http: HttpClient, private userService: UserService, private loaderService: LoaderService, 
        private translationService: TranslationService, private errorService: ErrorService) {}

    getS3Url(filename: string): Observable<PresentationPresignInfo> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/pdf",
            "Content-Disposition": `attachment; filename="${filename}"`,
            "x-vms-session": this.userService.x_vms_session,
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.get<PresentationPresignInfo>(`${this.url_v2}/user/firm/presentation-presigned-post?file_name=${filename}`, { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading)).pipe(map((data: any) => {
            console.log(data);
            return {s3PresignedUrl: data.s3PresignedUrl, generatedFileName: data.generatedFileName};
        }), catchError((error) => throwError(`getS3Url error: ${this.errorService.getErrorPTNMessage(error)}`)));
    }

    saveListData(listData: ListData): Observable<any> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-session": this.userService.x_vms_session,
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.post(`${this.url_v2}/user/firm/save`, listData, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }
}