import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router"
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { CompanyDealsTranslation, CompanyTranslation } from "./../shared/translation.const";
import { TranslationService } from "./../services/translation.service";
import { langPAGE_HOME } from "./../shared/constant.module";
import { CompanyService } from "./../services/company.service";
import { CompanyAdvanced, CompanyRounds } from './../models/company.data';
import { UserService } from "./../services/user.service";
import { PlatformService } from './../services/platform.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';


@Component({
    selector: 'deals',
    templateUrl: './company.deals.component.html',
    styleUrls: ['./company.deals.component.css']
})
  
export class CompanyDealsComponent implements OnInit{

    companyDealsTranslation = CompanyDealsTranslation;
    companyTranslation = CompanyTranslation;

    company: CompanyAdvanced = {    
        code_firm: null,
        also_known_as: "",
        code_city: null,
        code_country: null,
        code_number_of_emploees: "",
        code_state: null,
        deals_size: "",
        email: "",
        exit_status: "",
        external_number_firm: null,
        focus: "",
        founded_date: "",
        founded_year: null,
        innovation_index: null,
        legal_name: "",
        name_city: "",
        name_country: "",
        name_deals_size: "",
        name_exit_status: "",
        name_firm: "",
        name_state: "",
        name_type_firm: "",
        number_of_emploees: "",
        phone: "",
        product_list: "",
        top_deals: "",
        total_capital: null,
        total_funding_amount: null,
        type_firm: "",
        valuation: null,
        website: "",
        websiteToShow: "",
        category: [],
        presentation_orig_file: null,
        competitors: [],
        founders: [],
        industry: [],
        investmentStage: [],
        investors: [],
        keyPeople: [],
        nameRegion: [],
        portfolioCompanies: [],
        principalAt: [],
        socialNetworks: [],
        image_url: "",
        is_exists_rounds: null,
        presentation_url: "",
        is_send_message: 0,
        principals: [],
        current_user_company_relations: []
      };

    companyRounds: Array<CompanyRounds>;

    private companyID: number = undefined;

    public barChartOptions: ChartOptions = {
        responsive: true,
        // We use these empty structures as placeholders for dynamic theming.
        scales: {
            xAxes: [
            {
                display: true,
                scaleLabel: {
                    display: false,
                    fontColor: "#364D7B"
                },
                ticks: {
                    stepSize: 1,
                    min: 0,
                    maxRotation: 90,
                    minRotation: 90,
                    autoSkip: false,
                    fontColor: "#364D7B",
                    fontStyle: "bold"
                }
            }
            ],
            yAxes: [
            {
                display: true,
                scaleLabel: {
                    display: false,
                    labelString: '',
                    fontColor: "#364D7B"
                },
                ticks: {
                    fontColor: '#364D7B',
//                    fontStyle: "bold"
                }
            }
            ]
        },

        plugins: {
                datalabels: {
                    rotation: 270,
                    color: '#364d7b',
                    align: 'top',
                    font: {
                        size: 12
            //              weight: "bolder"
                    },

                    formatter: (value, ctx) => {

                        const label = value  + ' M';
                        return label;
                    }
                }
        }
    };

    public barChartLabels: Label[] = [];
    public barChartType: ChartType = "bar";
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];

    public barChartColors: Color[] = [
        { backgroundColor: "#189AD3" },
        { backgroundColor: "#00C2C7" }
    ];

    public barChartData: ChartDataSets[] = [
        { data: [], label: '', barThickness: 12 },
        { data: [], label: '', barThickness: 12 }
    ];

    // Mobile
    showRounds: boolean = true;


    constructor(private translationService: TranslationService, private activatedRoute: ActivatedRoute,
            private companyService: CompanyService, private userService: UserService,
            public platformService: PlatformService) { 

        this.activatedRoute.params.forEach((params: Params) => {
        
            this.companyID = +params["dealsId"];

            if (this.companyID)
                this.getCompany(this.companyID);
        });

        // Переклади
        
        this.translationService.getTranslate(langPAGE_HOME, this.companyDealsTranslation);
        this.translationService.getTranslate(langPAGE_HOME, this.companyTranslation);

        this.barChartData[0].label = this.companyDealsTranslation.company.deals.amount;
        this.barChartData[1].label = this.companyDealsTranslation.company.deals.valuation;

        // Раунди
        if (this.companyID)
            this.getRounds(this.companyID);
    }


    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate(langPAGE_HOME, this.companyDealsTranslation);
            this.translationService.getTranslate(langPAGE_HOME, this.companyTranslation);

            this.barChartData[0].label = this.companyDealsTranslation.company.deals.amount;
            this.barChartData[1].label = this.companyDealsTranslation.company.deals.valuation;
        });

        this.barChartOptions.scales.yAxes[0].scaleLabel.labelString = this.companyDealsTranslation.company.deals.axis_y.title;
        this.barChartOptions.scales.xAxes[0].scaleLabel.labelString = this.companyDealsTranslation.company.deals.axis_x.title;
    }


    private getCompany(codeCompany: number) {

        this.companyService.getCompany(codeCompany).subscribe(
            response => {
                let json = response;

                if (json) {
                  this.companyService.company = json;
                  this.company = this.companyService.company;
                }
            },
            error => console.log(error));
    }


    private getRounds(codeCompany: number) {

        this.companyService.getRounds(codeCompany).subscribe(
            response => {
                let json = response;

                if (json) {
                    this.companyRounds = json;
                    this.buildData();
                }
            },
            error => console.log(error));
    }


    private buildData() {

        if (!this.companyRounds || this.companyRounds.length <= 0)
            return;

        this.barChartLabels = [];
        this.barChartData[0].data = [];
        this.barChartData[1].data = [];
        for (let i = 0; i < this.companyRounds.length; i++) {

            this.barChartLabels.push(this.companyRounds[i].type_round);
            this.companyRounds[i].date_round = this.companyRounds[i].date_round * 1000;

            this.companyRounds[i].value_round = this.companyRounds[i].value_round / 1000000;
            this.barChartData[0].data.push(this.companyRounds[i].value_round);

            if (this.companyRounds[i].post_valuation)
                this.companyRounds[i].post_valuation = this.companyRounds[i].post_valuation / 1000000
            else
                this.companyRounds[i].post_valuation = 0;

            this.barChartData[1].data.push(this.companyRounds[i].post_valuation);
        }
    }

    neadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;

        return (subscribeLevel > userSubsribeLevel);
    }


    isVisorVisible(): boolean {

        let res = this.userService != undefined && this.userService.user != undefined;

        if (res) {

            if (this.companyRounds) {
                for (let i = 0; i < this.companyRounds.length; i++) {
                    if (this.userService.user.subscribe_level < this.companyRounds[i].subscribe_level) {
                        return true;
                    }
                }
            } else
                return false;
        } else
            return true;
    }

    
    getBodyStyle() {

        if (this.isVisorVisible()) {

            let styles = {
                "overflow-y": "hidden"
            };

            return styles;
        }
    }


    onShowRoundsClick() {

        this.showRounds = true;
    }


    onShowDiagramClick() {

        this.showRounds = false;
    }

}
