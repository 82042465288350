import { Component, OnInit } from '@angular/core';
import { VisorTranslation } from "./../../shared/translation.const";
import { TranslationService } from "./../../services/translation.service";
import { Router } from '@angular/router';
import { PlatformService } from './../../services/platform.service';
import { UserService } from './../../services/user.service';
import { SendEmail, SendMessageResult } from './../../models/user';
import { DeviceInfoService } from './../../services/device-info.service';
import { LocalStorageService } from './../../services/local-storage-service';
import { ErrorService } from './../../services/error.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'ptn-visor',
    templateUrl: './visor.component.html',
    styleUrls: ['./visor.component.scss']
})
  
export class VisorComponent implements OnInit {

    private visorTranslation = VisorTranslation;
    private verifyMessage: SendMessageResult = {
        msg_after_sending: ''
    };

    captionTitle: string = "";
    captionText: string = "";
    captionButton: string = "";

    
    constructor(private translationService: TranslationService, private router: Router, public platformService: PlatformService,
                private userService: UserService, private deviceInfoService: DeviceInfoService, 
                private localStorage: LocalStorageService, private errorService: ErrorService, private matSnackBar: MatSnackBar) {

        this.translationService.getTranslate('CURTAIN', this.visorTranslation);
        this.prepareText();
    }
    
    
    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('CURTAIN', this.visorTranslation);
            this.prepareText();
        });
    }


    private prepareText() {

        if (!this.userService.isAuthenticated) {

            this.captionTitle = this.visorTranslation.subscribe_level_0.no_account.title;
            this.captionText = this.visorTranslation.subscribe_level_0.no_account.text;
            this.captionButton = this.visorTranslation.subscribe_level_0.no_account.button;
        } else {
            if (this.userService.user.account_identifier_is_verified == "0" && this.userService.user.subscribe_level == 0) {

                this.captionTitle = this.visorTranslation.subscribe_level_0.no_email.title;
                this.captionText = this.visorTranslation.subscribe_level_0.no_email.text;
                this.captionButton = this.visorTranslation.subscribe_level_0.no_email.button;
            } else {

                if (this.userService.user.account_identifier_is_verified == "1" && this.userService.user.subscribe_level == 0) {

                    this.captionTitle = this.visorTranslation.subscribe_level_0.normal.title;
                    this.captionText = this.visorTranslation.subscribe_level_0.normal.text;
                    this.captionButton = this.visorTranslation.subscribe_level_0.normal.button;
                }
            }
        }
    }


    onSubscribeClick() {

        if (!this.userService.isAuthenticated) {

            this.router.navigate(['login/register']);
        } else {

            if (this.userService.user.account_identifier_is_verified == "0" && this.userService.user.subscribe_level == 0) {
                this.sendLetter();
            } else {

                if (this.userService.user.account_identifier_is_verified == "1" && this.userService.user.subscribe_level == 0) {

                    this.router.navigate(['/subscribes']);
                }
            }
        }
    }


    private sendLetter() {

        let verifymEmail: SendEmail = {
            email: this.userService.user.email, 
            type_operation: 'VM', 
            device_data: this.deviceInfoService.deviceInfo,
            language_id: this.translationService.currentLanguage
        }
  
        this.userService.sendEmail(verifymEmail).subscribe(
            result => {
                this.verifyMessage = result;

                let snackBarRef = this.matSnackBar.open(this.verifyMessage.msg_after_sending, 'Ok', {
                    duration: 10000,
                    panelClass: ['snack-trial']
                });

                this.userService.user.account_verify_send_letter = '1';
                this.localStorage.user = this.userService.user;
            },
            (error) => {
                console.log(error);
                this.verifyMessage.msg_after_sending = this.errorService.getErrorPTNMessage(error);

                let snackBarRef = this.matSnackBar.open(this.verifyMessage.msg_after_sending, 'Ok', {
                    duration: 10000,
                    panelClass: ['snack-bar']
                });
    
                this.userService.actionOnSignInError();
            }
        );
    }
  
}