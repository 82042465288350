export const ListTranslation = {
    list: 'List',
    first_name: {
        field: 'First Name',
        error: {
            required: 'First name must be entered'
        }
    },
    last_name: {
        field: 'Last Name',
        error: {
            required: 'Last name must be entered'
        }
    },
    email: {
        field: 'Email',
        error: {
            required: 'E-Mail must be entered'
        }
    },
    title: {
        field: 'Title',
        error: {
            required: 'Title must be entered'
        }
    },
    company: {
        field: 'Company',
        error: {
            required: 'Company must be entered'
        }
    },
    website: {
        field: 'Website',
        error: {
            required: 'Website must be entered'
        }
    },
    presentation: {
        field: 'Presentation',
        error: {
            mustmatch: 'Presentation must be a PDF-file'
        }
    },    
    description: {
        title: 'Add your company to the PTN list',
        text: `Coinbase is a digital currency exchange headquartered in San Francisco,
            California. They broker exchanges of Bitcoin, Bitcoin Cash, Ethereum, Ethereum Classic
            Litecoin, Tezos, and many others, with fiat currencies in approximately 32 countries,
            and bitcoin transactions and storage in 190 countries worldwide.`
    },
    consent: {
        title: 'Consent for processing personal data',
        text: `Coinbase is a digital currency exchange headquartered in San Francisco,
            California. They broker exchanges of Bitcoin, Bitcoin Cash, Ethereum, Ethereum Classic
            Litecoin, Tezos, and many others, with fiat currencies in approximately 32 countries,
            and bitcoin transactions and storage in 190 countries worldwide.`
    },
    add: {
        button: 'ADD',
        message: 'The data has been uploaded and will be processed by the moderator'
    }
}


export const HeaderTranslation = {
    analysis: 'ANALYSIS',
    companies: 'COMPANIES',
    deals: 'DEALS',
    home: 'HOME',
    list: 'LIST',
    privat_investors: 'PRIVATE INVESTORS',
    ptn_index: 'PTN INDEX',
    search: 'SEARCH',
    trends: 'TRENDS',
    vc_funds: 'VC FUNDS',
    settings: 'SETTINGS'
}


export const CompanyDealsTranslation = {
    company: {
        deals: {
            amount: "Amount, $",
            axis_x: {
                title: "Funding type"
            },
            axis_y: {
                title: "Funding amount"
            },
            diagram: "INVESTMENTS DIAGRAM",
            funding_year: "Funding year",
            funding_type: "Funding type",
            funding_amount: "Funding amount",
            investors: "Investors",
            limit_of_lines_0: "Limit the number of lines",
            rounds_table: "COMPANY FUNDING ROUNDS",
            subscribe_level_0: "Required subscription level",
            valuation: "Valuation, $",
            button: {
                rounds: "ROUNDS",
                diagram: "DIAGRAM"
            }
        }
    }
}


export const CompaniesTranslation = {
    company: {
        card: {
            button: {
                open_card: "OPEN CARD"
            },
            field: {
                product: "Product",
                focus: "Focus",
                founder: "Founder",
                invested: "Invested",
                investors: "Investor",
                valuation: "Valuation"
            }
        },
        open_card: "OPEN CARD",
        send_message: "SEND MESSAGE",
        show_more: "Show more"
    }
}


export const CompanyTranslation = {
    firm: {
        card: {
            field: {
                name_firm: "Name",
                also_known_as: "Also known as",
                valuation: "Valuation",
                investors: "Investors",
                founders: "Founders",
                product_list: "Product list",
                legal_name: "Legal name",
                name_type_firm: "Type",
                name_exit_status: "Exit status",
                founded_year: "Founded year",
                founded_date: "Founded date",
                number_of_emploees: "Number of emploees",
                industry: "Industry",
                category: "Category",
                focus: "Focus",
                innovation_index: "Innovation index",
                name_region: "Region",
                name_country: "Country",
                name_state: "State",
                name_city: "City",
                phone: "Phone",
                email: "E-mail",
                website: "Web site",
                social_networks: "Social networks",
                key_people: "Key people",
                principal_at: "Principal at",
                competitors: "Competitors",
                total_funding_amount: "Total funding amount",
                total_capital: "Total capital",
                portfolio_companies: "Portfolio companies",
                investment_stage: "Investment stage",
                deal_size: "Deal size",
                top_deal: "Top deal",
                principals: "Principals"
            },
            button: {
                deals: "Deals",
                view_presentation: "view presentation",
                next: "NEXT",
                back: "BACK",
                message: "SEND A MESSAGE"
            },
            anchor: {
                general: "General",
                financials: "Financials",
                investments: "Investments",
                locations: "Locations",
                social_networks: "Social Networks",
                key_people: "Key People",
                competitors: "Competitors",
                sectors: "Sectors"
            }
        }
    }
}


export const HomeTranslation = {
    slogan: "look at the future...",
    industry: {
        sorting_options: {
            sort_by_rating: "Sort by PTN Rate",
            sort_by_name: "Sort by Name",
            sort_by_number_of_companies: "Sort by Number of companies",
            sort_by_company_value: "Sort by Company Value"
        }
    },
    company: {
        sorting_options: {
            sort_by_rating: "Sort by PTN Rate",
            sort_by_name: "Sort by Name"
        },
        card: {
            field:{
                focus: "Focus",
                founder: "Founder",
                invested: "Invested",
                investors: "Investor",
                valuation: "Valuation"
            } 
        }
    },
    category: {
        sorting_options: {
            sort_by_rating: "Sort by PTN Rate",
            sort_by_name: "Sort by Name",
            sort_by_number_of_companies: "Sort by Number of companies",
            sort_by_company_value: "Sort by Company Value"
        }
    },
    partnership: "in partnership with",
    partner_slogan: "tomorrow’s ipos today",
    search: "Search..",
}


export const SearchTranslation = {
    type_object: {
        private_company: "Private company",
        firm_investor: "Firm investor",
        private_investor: "Private investor",
        person: "Person"
    },
    type_person: {
        founder: "Founder",
        principal: "Principal"
    },
    text: "Search...",
    show_more: "Show more",
    not_found: "No results found"
}


export const RegistrationTranslation = {
    email: {
        field: "E-Mail address *",
        placeholder: "Enter E-Mail address",
        error: {
            required: "E-Mail must be entered",
            pattern: "E-Mail does not match format"
        }
    },
    password: {
        field: "Password *",
        placeholder: "Enter password",
        error: {
            required: "Password must be entered",
            minlength: "minlength",
            passwordStrengthValidator: "The password should be more complex"
        }
    },
    confirm_password: {
        field: "Confirm password *",
        placeholder: "Confirm your Password",
        error: {
            mustmatch: "The password must match"
        }
    },
    first_name: {
        field: "First Name *",
        placeholder: "Enter first name",
        error: {
            required: "First name must be entered"
        }
    },
    last_name: {
        field: "Last Name *",
        placeholder: "Enter last name",
        error: {
                required: "Last name must be entered"
        }
    },
    company: {
        field: "Company *",
        placeholder: "Enter your Company",
        error: {
                required: "Company must be entered"
        }
    },
    signup: {
        button: "Register"
    },
    privacy_policy: {
        text: "By clicking on Register, you agree to <<Terms of Service|https://policies.google.com/terms?hl=ru-UA&fg=1>> and <<Privacy Policy|https://policies.google.com/privacy?hl=ru-UA&fg=1>>",
    },
    register_using_sa: {
        text: "Register using social network authentication"
    },
    title: {
        field: "title",
        placeholder: "Enter your position",
        error: {
            required: "Title must be entered"
        }
    },
    form_title: {
        text: "Registration Form"
    },
    password_policy: {
        text: "text",
        hint: "hint"
    },
    free_trial: {
        text: "After registering and confirming E-mail, you will have 14 days of full access to view, filtering and communicating with companies and investors."
    }
}


export const IndustryDealsTranslation = {
    company: "Company",
    funding_amount: "Funding amount",
    funding_type: "Funding type",
    funding_year: "Funding year",
    industry_list: "INDUSTRY NAME",
    investors: "Investors",
    rank: "Rank",
    rounds_table: "INDUSTRY FUNDING ROUNDS",
    text: "Funding rounds for the selected industry, sorted by amount within a year",
    title: "DEALS",
    button: {
        rounds: "ROUNDS",
        industries: "INDUSTRIES"
    }
}


export const SignInTranslation = {
    signin_using_sa: {
        text: "Log in using social networt authentication",
        message_error: "Error of authentification through a social network"
    },
    signin_using_em: {
        text: "Log in using your E-mail address"
    },
    email: {
        field: "E-Mail address *",
        placeholder: "Enter E-Mail address",
        error: {
            required: "E-Mail must be entered",
            pattern: "E-Mail does not match format"
        }
    },
    password: {
        field: "Password *",
        placeholder: "Enter password",
        error: {
            required: "Password must be entered"
        }
    },
    forgot_password: {
        button: "Forgot password?"
    },
    signin: {
        button: "Log In"
    },
    signup: {
        button: "Register"
    },
    form_title: {
        signin: {
            text: "Account Login"
        },
        forgot_pass: {
            text: "Forgot Password"
        }
    },
    session_time_out: {
        message_error: "The session has expired. Go to the login page"
    },
    trial_active: {
        message: "Your trial period is active until <<subscription_end_date>> (<<days_left>> days left)"
    },
    trial_ended: {
        message: "Your trial period ended. Please, buy subscription"
    }
}


export const FilterCompaniesTranslation = {
    text: {
        filters: 'Filters'
    },
    button: {
        clear_all: 'Clear all',
        show: 'Show',
        filters: 'Filters'
    },
    sorting_options: {
        sort_by_name_az: 'Name A-Z',
        sort_by_name_za: 'Name Z-A',
        founded_year_19: 'Founded Year 1-9',
        founded_year_91: 'Founded Year 9-1',
        sort_by_company_size_19: 'Company Size 1-9',
        sort_by_company_size_91: 'Company Size 9-1',
        sort_by_company_value_19: 'Company Valuation 1-9',
        sort_by_company_value_91: 'Company Valuation 9-1',
        total_funding_amount_19: 'Total Funding Amount 1-9',
        total_funding_amount_91: 'Total Funding Amount 9-1',
        sort_by_inn_index: 'Innovation Index'
    },
    filters: {
        filter: {
            title: {
                industry: undefined,
                category: undefined,
                region: undefined,
                country: undefined,
                founded_year: undefined,
                number_of_emploees: undefined,
                valuation: undefined,
                total_funding_amount: undefined,
                innovation_index: undefined,
                is_send_message: undefined
            }
        }
    },
    data: {
        field: {
            name_firm: 'Company',
            founded_year: 'Founded',
            name_exit_status: 'Exit',
            industry: 'Industry',
            category: 'Category',
            name_region: 'Region',
            name_country: 'Country',
            number_of_emploees: 'Size',
            total_funding_amount: 'Funding, $',
            valuation: 'Valuation, $',
            focus: 'Focus',
            product_list: 'Product list',
            innovation_index: 'Inn. Index',
            is_send_message: 'Message'
        }
    }
}


export const FilterFundsTranslation = {
    text: {
        filters: 'Filters'
    },
    button: {
        clear_all: 'Clear all',
        show: 'Show',
        filters: 'Filters'
    },
    sorting_options: {
        sort_by_name_az: 'Name A-Z',
        sort_by_name_za: 'Name Z-A',
        founded_year_19: 'Founded Year 1-9',
        founded_year_91: 'Founded Year 9-1',
        sort_by_fund_size_19: 'Company Size 1-9',
        sort_by_fund_size_91: 'Company Size 9-1',
        sort_by_total_capital_19: 'Total Capital 1-9',
        sort_by_total_capital_91: 'Total Capital 9-1'
    },
    filters: {
        filter: {
            title: {
                founded_year: undefined,
                region: undefined,
                country: undefined,
                number_of_emploees: undefined,
                industry: undefined,
                category: undefined,
                total_capital: undefined,
                investment_stage: undefined,
                deal_size: undefined,
                is_send_message: undefined
            }
        }
    },
    data: {
        field: {
            name_firm: 'VC Fund',
            founded_year: 'Founded',
            industry: 'Industry',
            category: 'Category',
            name_region: 'Region',
            name_country: 'Country',
            number_of_emploees: 'Size',
            total_capital: 'Total capital',
            investment_stage: 'Investment stage',
            deal_size: 'Deal size',
            focus: 'Focus',
            is_send_message: 'Message'
        }
    }
}


export const FilterInvestorsTranslation = {
    text: {
        filters: 'Filters'
    },
    button: {
        clear_all: 'Clear all',
        show: 'Show',
        filters: 'Filters'
    },
    sorting_options: {
        sort_by_name_az: 'Name A-Z',
        sort_by_name_za: 'Name Z-A',
        founded_year_19: 'Founded Year 1-9',
        founded_year_91: 'Founded Year 9-1',
        sort_by_total_capital_19: 'Total Capital 1-9',
        sort_by_total_capital_91: 'Total Capital 9-1'
    },
    filters: {
        filter: {
            title: {
                founded_year: undefined,
                region: undefined,
                country: undefined,
                industry: undefined,
                category: undefined,
                total_capital: undefined,
                investment_stage: undefined,
                deal_size: undefined,
                is_send_message: undefined
            }
        }
    },
    data: {
        field: {
            name_firm: 'VC Fund',
            founded_year: 'Founded',
            industry: 'Industry',
            category: 'Category',
            name_region: 'Region',
            name_country: 'Country',
            total_capital: 'Total capital',
            investment_stage: 'Investment stage',
            deal_size: 'Deal size',
            focus: 'Focus',
            is_send_message: 'Message'
        }
    }
}


export const FooterTranslation = {
    email: {
        text: "Email needs to be verified. Send verification email",
        button: "now."
    },
    copyright: {
        text: "Copyright © <<YEAR>> Private Tech Network. All rights reserved."
    },
    trial_active: {
        text: "Your trial period is active until <<subscription_end_date>> (<<days_left>> days left). <a href='subscribes'>Buy subscription</a>"
    },
    trial_ended: {
        text: "Your trial period ended. <a href='subscribes'>Buy subscription</a>"
    }
}


export const PersonTranslation = {
    card: {
        field: {
            first_name: "First name",
            last_name: "Last name",
            patronymic: "Patronymic",
            full_name: "Full name",
            aka_name: "Also known as",
            gender: "Gender",
            date_birthday: "Date birthday",
            description: "Description",
            name_company: "Company",
            company_relations: "Company member",
            phone: "Phone",
            email: "E-mail",
            website: "Web site",
            social_networks: "Social networks",
            name_country: "Country",
            name_state: "State",
            name_city: "City",
            post_address: "Post address",
            title: "Title"
        },
        anchor: {
            general: "General",
            locations: "Locations"
        }
    },
    message_form: {
        attach: "Attach presentation",
        button: {
            cansel: "CANSEL",
            send: "SEND",
            close: "CLOSE",
            back: "BACK"
        },
        done: "Message has been sent",
        firms: {
            title: "Send a message from the selected company",
            presentation: "Presentation available"
        },
        last: "The last message was sent on",
        message: "Message:",
        recipient: "Recipient:",
        subject: "Subject:",
        sender: "Sender:",
        text: "The message will be sent on behalf of the PTN to the address of the selected person. The text of the message will contain the details of the sender. The recipient will independently decide whether to respond to the sender.",
        title: "Send a message",
        total: "total"
    },
    button: {
        message: "Send a message"
    }
}


export const VerifyEmailTranslation = {
    title: {
        text: "EMAIL VERIFICATION"
    },
    where: {
        text: "You got on this page for confirmation of Email on reference from the letter created during registration on a web-site PrivateTechNetwork.com"
    },
    done: {
        text: "Email is confirmed"
    },
    error: {
        text: "Reference to confirmation of Email became antiquated or was already used before"
    },
    next: {
        text: "For completion of confirmation of Email it is necessary to login repeatedly"
    },
    signin: {
        button: "Log In"
    }
}


export const PasswordRecoveryTranslation = {
    form: {
        text: "Enter new password"
    },
    title: {
        text: "PASSWORD RECOVERY"
    },
    where: {
        text: "You got on this page for recovery of password on reference from a letter that was inquired at an entrance on a web-site PrivateTechNetwork.com"
    },
    done: {
        text: "A new password is saved"
    },
    error: {
        text: "Reference for recovery of password became antiquated or was already used before"
    },
    next: {
        text: "After maintenance of new password it is necessary to execute Login repeatedly"
    },
    signin: {
        button: "Log In"
    },
    password: {
        field: "Password *",
        error: {
            required: "Password must be entered",
            passwordstrengthvalidator: "The password should be more complex"
        }
    },
    confirm_password: {
        field: "Confirm password *",
        message: "The password must match"
    },
    save: {
        button: "Save new Password"
    }
}


export const PasswordNotRecoveryTranslation = {
    title: {
        text: "RECOVERY PASSWORD DID NOT REQUEST"
    },
    where: {
        text: "You got on this page on reference from a letter for recovery of password on a web-site PrivateTechNetwork.com for that,\nto report that you did not inquire recovery of password.\nWe thank you for the shown vigilance.\nIf you will have difficulties at an entrance on a web-site, we ask to appeal to service of support."
    },

}


export const ForgotPasswordTranslation = {
    form_title: {
        signin: {
            text: "Account Login"
        },
        forgot_pass: {
            text: "Forgot Password"
        }
    },
    email: {
        field: "E-Mail address *",
        placeholder: "Enter E-Mail to send password reset link",
        error: {
            required: "E-Mail must be entered",
            pattern: "E-Mail does not match format"
        }
    },
    reset_password: {
        button: "Reset password"
    }
}


export const TermsOsUseTranslation = {
    page: {
        text: 'page',
        title: "TERMS OF USE"
    }
}


export const PrivatePolicyTranslation = {
    page: {
        text: "page",
        title: "PRIVACY POLICY"
    }
}


export const AboutTranslation = {
    page: {
        text: '<br>|<p>Look at the Future</p>|<p>Reach out to investors</p>|<p>Close deals</p>|<br>|<br>|<br>|<p>Private Tech Network is decentralized AI driven VCaaS (Venture Capital as a Service) platform in Private Technology Markets.  It provides market intelligence ',
        title: "PRIVATE TECH NETWORK",
        bullets: {
            line_1: "SEE TECH LANDSCAPE",
            line_2: "REACH GLOBAL INVESTORS",
            line_3: "CLOSE DEALS FAST"
        }
    }
}


export const DataCollectTranslation = {
    page: {
        text: '<p>We collect publically available data on private tech companies, Venture Capital funds, private family offices, deals, and founders using proprietary AI algorithms and parsing billions of internet pages from variety of sources.   The data is processed according to ',
        title: "DATA COLLECTION"
    }
}


export const AnalysisTranslation = {
    analysis: {
        top_in_industry: {
            title: "Top in the industry",
            text: "Description of the Top in the indastry diagramm.",
            view_more: "VIEW MORE"
        },
        sector_map: {
            title: "SECTOR MAP",
            text: "Description of the Sector map diagramm.",
            view_more: "VIEW MORE"
        },
        bubble_map: {
            title: "BUBBLE MAP",
            text: "Description of the Bubble map diagramm.",
            view_more: "VIEW MORE"
        }    
    },
    bubble_map: {
        limit_of_lines_0: "Limit the number of lines",
        subscribe_level_0: "Required subscription level",
        diagram: {
            title: "OVERVIEW BY REGION"
        },
        vc_funds: {
            title: "VC FUNDS"
        },
        companies: {
            title: "COMPANIES"
        },
        graph_options: {
            industries: {
                all_industries: "ALL INDUSTRIES"
            }
        }
    },
    sector_map: {
        description: "Description of Sector map",
        limit_of_lines_0: "Limit the number of lines",
        title: "SECTOR MAP",
        title_companies: "Companies",
        title_industry: "Industries",
        button: {
            companies: "COMPANIES",
            industries: "INDUSTRIES"
        }

    },
    top_in_industry: {
        description: "The graph shows the TOP-10 companies by the valuation in the industry",
        title_industry: "Industries",
        graph_description: "The graph shows the TOP-10 companies by the valuation in the industry",
        y_axis: "Valuation/Funding $",
        x_axis: "Companies",
        valuation: {
            title: "Valuation, $"
        },
        funding: {
            title: "Funding, $"
        },
        title: "Top in the industry",
        button: {
            diagram: "DIAGRAM",
            industries: "INDUSTRIES"
        }
    }
}


export const TranslationPtnIndex = {
    sorting_options: {
        sort_by_name: "Industry Name",
        sort_by_ptnindex: "PTN Index",
        sort_by_subindex: "Sub-Index"
    },
    data: {
        name: "Industry Name",
        ptnindex: "PTN Index",
        subindex: "Sub-Index"
    },
    title: {
        title: "Abount PTN Index",
        text: "The PTN Index represents the overall valuation of companies in the sector, the sub-index represents the average valuation of a company in the sector."
    }
}


export const MenuServiceTranslation = {
    about: "ABOUT",
    data_collection: "DATA COLLECTION",
    login: "LOGIN",
    privacy: "PRIVACY",
    register: "REGISTER",
    sign_out: "SIGN OUT",
    subscribe: "SUBSCRIBE",
    terms_of_use: "TERMS OF USE",
    view_as_photo: "(View as photo)",
    synthetic_vc: "Synthetic VC",
    settings: "SETTINGS",
    support: {
        caption: "(SUPPORT)",
        email: ")support@privatetechnetwork.com)"
    }
}


export const EmailObjectTranslation = {
    reset_pass: {
        from_name: "PrivateTechNetwork",
        subject: "Forgotten password reset",
        body: "<body>\r\n<h2>Dear <<first_name>></h2>\r\n<p>Somebody (hopefully you) requested a new password for the PrivateTechNetwork account for <<login>>.<br>\r\nNo changes have been made to your account yet.</p>\r\n<p>You can reset your password by clicking the link below:<br>\r\n<h3><a href=\"<<link_reset>>\"><<link_reset>></a></h3>\r\nThis password reset is only valid for the next <<valid_hours>> hours.</p>\r\n<p>If you did not request a new password, please let us know by clicking the link below:<br>\r\n<a href=\"<<link_wrong>>\"><<link_wrong>></a><br>\r\nor you can safely ignore this email.</p>\r\n<p>Yours,<br>\r\nThe PrivateTechNetwork team</p>\r\n</body>",
        msg_after: "An email was sent to <<email>>. Check your email."
    },
    person_message: {
        subject: "Message from (sender_full_name), (sender_name_firm)",
        html_mail_body: "<html>\n<head></head>\n<body>\n<h2>Dear, (recipient_name).</h2>\n<p>You received this service letter from PrivateTechNetwork.com with a message from:<br>\n(sender_full_name)<br>\n(sender_name_firm)<br>\n(sender_position)<br>\n(sender_email).</p>\n<p>Please do not reply to this email.</p>\n<h3>Link to the sender page on PrivateTechNetwork.com:\n<a href = \"(link_to_sender_person)\"> (link_to_sender_person) </a></h3>\n<p>Yours,<br>\nThe PrivateTechNetwork team</p>\n<p>MESSAGE:</p>\n<table border = 1 width = 640>\n  <tbody>\n    <tr>\n      <td>(message)</td>\n </tr>\n  </tbody>\n</table>\n</body>\n</html>"
    },
    signup: {
        subject: "Information for moderation (PTN, user)",
        html_mail_body: "<html>\n<head></head>\n<body>\n<h2>Hello</h2>\n<p>Information for moderation about a new user has appeared.</p>\n<p>Yours,<br>\nThe PrivateTechNetwork team</p>\n(NEW_DATA)\n</body>\n</html>",
        html_mail_body_field: "    <tr>\n      <td>(NAME_FIELD)</td>\n      <td>(INSERTED_VALUE)</td>\n    </tr>"
    },
    verify_email: {
        from_name : "PrivateTechNetwork",
        subject: "Please verify your email address",
        body: "<body>\r\n<h2>Thanks for signing up, <<first_name>></h2>\r\n<p>Please verify your email address to get access to PrivateTechNetwork.com</p>\r\n<h3>Verify Email Now <a href=\"<<link_verify>>\"><<link_verify>></a></h3>\r\n<p>Please do not replay to this email.</p>\r\n<p>Yours,<br>\r\nThe PrivateTechNetwork team</p>\r\n</body>",
        msg_after: "An email was sent to <<email>>. Check your email."
    }
}


export const LoginRequirementTranslation = {
    massege_form: {
        text: "To access this page, you need to register on the site or log in if you already have an account."
    }
}


export const VisorTranslation = {
    subscribe_level_0: {
        no_account:{
            title: "UNLIMITED FREE ACCESS",
            text: "Please register to get unlimited free access to information on private technology companies, venture capital funds and private technology investors",
            button: "REGISTER"
        },
        no_email: {
            title: "UNLIMITED FREE ACCESS",
            text: "Please verify your email address to get unlimited free access to information on private technology companies, venture capital funds and private technology investors",
            button: "SEND VERIFICATION E-MAIL"
        },
        normal: {
            title: "UNLIMITED FREE ACCESS",
            text: "Please subscribe to get unlimited access to information on private technology companies, venture capital funds, private technology investors and communication with companies and investors",
            button: "GET UNLIMITED ACCESS"
        }
    }
/*    
    button: {
        register: "REGISTER",
        subscribe: "GET A FREE TRIAL"
    },
    text: "After registering and subscribing, you will have full access to information viewing, filtering and communication with companies and investors.\nSubscribe with a free trial period.",
    title: "UNLIMITED VIEWING OF RESULTS"
*/    
}

export const SubscribeTranslation = {
    level_1: {
        title: 'What do you get with Private Tech Network subscription',
        description: `see world-changing private technology companies post seed and pre-IPO
                      view companies key facts including data on investments, valuations and founders
                      see information on leading global Venture Capital funds
                      see information on Private Investors in technology companies
                      work with analytics and graphs including sector landscape, valuation maps and bubble maps
                      send messages and communicate with thousands of Venture Capital Funds and Private Investors and speed up your capital raising process
                      list your company and get global visibility with top VC funds and private technology investors
                      10 users can work under one account (the same login must be used by all users)`,
        trial: 'Free for <<trial_period>> days',
        per_month: '$<<price>> / month',
        button: {
            subscribe: 'SUBSCRIBE',
            cancel: 'CANCEL',
            signin: 'SIGNIN'
        },
        need_login: 'To subscribe, you need to register on the site or log in if you already have an account',
        paid_date: 'Paid before',
        trial_inner: 'After registering and confirming E-mail, you will have <<trial_period_inner>> days of full access',
    },
    partners: `<p>Technology development partner is <a href="https://en.vimas.com/">VIMAS Group International</a> (FL USA)<br>
                   Payment processing is completed via <a href="https://stripe.com/">Stripe Network</a> (USA)<br>
                   Private Tech Network operates as Swiss legal entity Private Tech Network AG.</p>`,
    popup: {
        need_email_confirm: 'E-mail confirmation required.'
    }
}

export const CopyObjectTranslation = {
    link_text: 'For more details, see:'
}
