import { Component, OnInit } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { PlatformService } from '../services/platform.service';
import { RouterHistoryService } from '../services/router-history.service';
import { SessionStorageService } from '../services/session-storage-service';
import { TranslateObject, TranslationService } from '../services/translation.service';
import { langPAGE_PRIV_INVESTORS } from '../shared/constant.module';
import { FilterInvestorsTranslation } from '../shared/translation.const';

@Component({
  selector: 'ptn-filter-private-investors',
  templateUrl: './filter-private-investors.component.html',
  styleUrls: ['./filter-private-investors.component.scss']
})
export class FilterPrivateInvestorsComponent implements OnInit {

  filterInvestorsTranslation = FilterInvestorsTranslation;
  sortingInvestorsList: Array<TranslateObject> = [];
  
  constructor(private translationService: TranslationService, private sessionStorageService: SessionStorageService, private filterService: FilterService, public platformService: PlatformService, private routerHistoryService: RouterHistoryService) {
    this.translationService.getTranslate(langPAGE_PRIV_INVESTORS, this.filterInvestorsTranslation);
    this.translationService.getCategoryObject('PAGE_PRIV_INVESTORS.SORTING_OPTIONS', this.sortingInvestorsList);
    
    this.translationService.languageChanged$.subscribe((changeObj) => {
      this.translationService.getTranslate(langPAGE_PRIV_INVESTORS, this.filterInvestorsTranslation);
      this.filterInvestorsTranslation = JSON.parse(JSON.stringify(this.filterInvestorsTranslation)); //to fire ngOnChanges for FilterComponent

      this.sortingInvestorsList = [];
      this.translationService.getCategoryObject('PAGE_PRIV_INVESTORS.SORTING_OPTIONS', this.sortingInvestorsList);
    });

    // if (this.sessionStorageService.filterInvestorsParamsInput) {
    //   this.filterService.filterParamsInput = this.sessionStorageService.filterInvestorsParamsInput;
    // }

    console.log(this.filterInvestorsTranslation);
    console.log(this.sortingInvestorsList);
  }

  ngOnInit(): void {
    if (this.sessionStorageService.isShowFilterMenuM === true) {
      let prevUrl = this.routerHistoryService.previousUrl$.value || '/';
      if (prevUrl.indexOf('filter-private-investors') == -1) {
        this.sessionStorageService.isShowFilterMenuM = false;
      }
    }
  }
}
