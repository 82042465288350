<footer>
    <div class="second-floor" *ngIf="accountNeedVerified">
        <div class="warning" *ngIf="!verifyMessage.msg_after_sending">
            {{translation.email.text}}
            <a [routerLink]="[]" routerLinkActive="active" (click)="onVerifyClick()">
                {{translation.email.button}}
            </a>
        </div>
        <div class="notify" *ngIf="verifyMessage.msg_after_sending">
            {{verifyMessage.msg_after_sending}}
        </div>
    </div>

    <div class="first-floor">
        <div class="user_name">
            <span>{{userName}}</span>
            <div [innerHtml]="trialSubscribe"></div>
        </div>
        <div class="contain" [ngClass]="{ emailVisible: userName }">
            <div class="copyright">
                {{translation.copyright.text}}
            </div>
        </div>
<!--
        <a [routerLink]="[]" routerLinkActive="active" (click)="onClearCacheClick()" *ngIf="userName">
            Clear cache
        </a>
-->        
    </div>    
</footer>