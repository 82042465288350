import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { DeviceInfo } from '../models/device-info';
import { UUID } from 'angular2-uuid';
import { LocalStorageService } from './local-storage-service';
import { SessionStorageService } from './session-storage-service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class DeviceInfoService {

    deviceInfo: DeviceInfo;

    constructor(private deviceService: DeviceDetectorService, private http: HttpClient, private localStorageService: LocalStorageService,
        private sessionStorage: SessionStorageService) {
    }

    private getDevInfo(): Observable<DeviceInfo> {
        
        let devInfo = new Observable<DeviceInfo>((observer) => {
            let dInfo: DeviceInfo = {
                userAgent: this.deviceService.userAgent,
                os: this.deviceService.os,
                browser: this.deviceService.browser,
                device: this.deviceService.device,
                osVersion: this.deviceService.os_version,
                browserVersion: this.deviceService.browser_version,
                isMobile: this.deviceService.isMobile(),
                isTablet: this.deviceService.isTablet(),
                isDesktop: this.deviceService.isDesktop()
            };
            observer.next(dInfo);
        });
        
        return devInfo;
    }

    private GetIdDevice(): string {
        return this.localStorageService.idDevice || (this.localStorageService.idDevice = UUID.UUID());
    }

    getDeviceInfo(): Observable<DeviceInfo> {

        let devInfo = new Observable<DeviceInfo>((observer) => {
            if (this.deviceInfo) {
                observer.next(this.deviceInfo);
            }
            else {
                this.deviceInfo = this.sessionStorage.deviceInfo;
                if (this.deviceInfo) {
                    observer.next(this.deviceInfo);
                }
                else {
                    this.getDevInfo().subscribe((res: DeviceInfo) => {
                        this.deviceInfo = res;
                        this.deviceInfo.idDevice = this.GetIdDevice();

                        let finishGetIp = () => {
                            this.sessionStorage.deviceInfo = this.deviceInfo;
                            observer.next(this.deviceInfo);
                        };

                        this.http.get("https://api.ipify.org/?format=json").pipe(finalize(finishGetIp)).subscribe((resIp: any) => {
                            this.deviceInfo.ipAddress = resIp.ip;                            
                        },
                        (error) => {
                            this.deviceInfo.ipAddress = 'unknown';
                            console.error(error);
                        });
                    });
                }
            }
        });

        return devInfo;
    }
}
