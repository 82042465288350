import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterService } from '../../../services/filter.service';
import { UserService } from '../../../services/user.service';
import { FilterFirm } from '../../../models/filter';

@Component({
  selector: 'ptn-card-view-result-m',
  templateUrl: './card-view-result-m.component.html',
  styleUrls: ['./card-view-result-m.component.scss']
})
export class CardViewResultMComponent implements OnInit {

  private messageSubscribeLevel: number = 0;

  @Input()
  typeFirm: string;
  
  @Input()
  filterTranslation: any;
  
  @Input()
  filter_firms: Array<FilterFirm>;

  constructor(private router: Router, private userService: UserService, private filterService: FilterService) { }

  ngOnInit(): void {
    this.messageSubscribeLevel = this.filterService.getMessageSubscribeLavel(this.typeFirm);
  }

  onSendMessageClick(firm: FilterFirm) {
    if (this.neadMessageSubscribe()) {
      this.router.navigate(['/subscribes']);
    } else {
      this.router.navigate(['/send-message'], { queryParams: { person_id: null, company_id: firm.code_firm,
                                                                for_person: 0 } });
    }
  }


  onSendMessageFounderClick(firm) {

    if (this.neadMessageSubscribe()) {

      this.router.navigate(['/subscribes']);
    } else {

        this.router.navigate(['/send-message'], { queryParams: { person_id: null,
                                                                company_id: firm.code_firm,
                                                                for_person: 1 } });
    }
  }


  neadMessageSubscribe() {
    let userSubsribeLevel = this.userService.user?.subscribe_level || 0;
    return this.messageSubscribeLevel > userSubsribeLevel;
  }
}
