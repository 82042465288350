import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HomeHeaderService } from './../../services/home.header.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateObject } from './../../services/translation.service';
import { RepresentService } from './../../services/represent.service';
import { PlatformService } from './../../services/platform.service';
import { TranslationService } from './../../services/translation.service';
import { LocalStorageService } from './../../services/local-storage-service';


@Component({
  selector: 'ptn-represent',
  templateUrl: './represent.component.html',
  styleUrls: ['./represent.component.scss']
})

export class RepresentComponent implements OnInit, AfterViewInit {

    private currentSortIndex: number = undefined;
    selectedSort: string = '';
    sortingTile: Array<TranslateObject> = [];

    translationCompanyViewing: Array<TranslateObject> = [];
    viewingCompanies: string = "";

    // Яка кнопка обрана Large або Small
    tileSize = {
        bigSize: false
    }

      
    constructor(private homeHeaderService: HomeHeaderService, private router: Router, private location: Location, 
            private representService: RepresentService, public platformService: PlatformService,
            private translationService: TranslationService, private localStorage: LocalStorageService,
            private activatedRoute: ActivatedRoute) {

        this.representService.setSortList$.subscribe((sortList: Array<TranslateObject>) => {

            setTimeout(() => { 

                this.sortingTile = [];
                this.sortingTile = sortList;
            }, 50);
   
        });

        this.representService.selectedSort$.subscribe((sortText: string) => {

            setTimeout(() => { 

                if (this.sortingTile && this.sortingTile.length > 0) {
                    for (let i = 0; i < this.sortingTile.length; i++) {
                        if (this.sortingTile[i].translation == sortText) {
                            this.currentSortIndex = i;
                            break;
                        }
                    }
                }
                this.selectedSort = sortText;
            }, 100);
        });

        this.viewingCompanies = this.localStorage.get("viewingCompanies");

//        this.takeCompanyViewing();
        this.translationService.getCategoryObject('PAGE_HOME.COMPANY.VIEWING_OPTIONS', this.translationCompanyViewing);
    }


    ngOnInit(): void {

        this.translationService.languageChanged$.subscribe((changeObj) => {
           
            this.translationService.getCategoryObject('PAGE_HOME.COMPANY.VIEWING_OPTIONS', this.translationCompanyViewing);
            this.takeCompanyViewing();

            this.activatedRoute.queryParams.subscribe((queryParam: any) => {

                if (queryParam['view_type']) {
    
                    let сompView: TranslateObject = undefined;
    
                    switch (queryParam['view_type']) {
                        case "PC": {
                            сompView = this.translationCompanyViewing.find(сompView => сompView.object_name === 'PAGE_HOME.COMPANY.VIEWING_OPTIONS.COMPANIES');
                        }
                        break;
                
                        case "VC": {
                            сompView = this.translationCompanyViewing.find(сompView => сompView.object_name === 'PAGE_HOME.COMPANY.VIEWING_OPTIONS.VC_FUNDS');
                        }
                        break;
            
                        case "PI": {
                            сompView = this.translationCompanyViewing.find(сompView => сompView.object_name === 'PAGE_HOME.COMPANY.VIEWING_OPTIONS.PRIVATE_INVESTOR');
                        }
                        break;
                    }
    
                    if (сompView) {
    
                        this.viewingCompanies = сompView.translation;
                        this.localStorage.set("viewingCompanies", this.viewingCompanies);
                    }
                }
            });
   
        });

        this.takeCompanyViewing();
    }


    ngAfterViewInit() {

        this.representService.setTileSize$.subscribe((value: boolean) => {

            this.tileSize.bigSize = value;
        });
    }


    private takeCompanyViewing() {

//        this.translationService.getCategoryObject('PAGE_HOME.COMPANY.VIEWING_OPTIONS', this.translationCompanyViewing);
//        this.viewingCompanies = this.localStorage.get("viewingCompanies");

        let сompView: TranslateObject = undefined;
        if (!this.viewingCompanies) {
            сompView = this.translationCompanyViewing.find(сompView => сompView.translation === "Companies");
            if (сompView)
                this.viewingCompanies = сompView.translation;
        } else {
            сompView = this.translationCompanyViewing.find(сompView => сompView.translation === this.viewingCompanies);
        }

//        this.changeViewingCompanies(сompView);
    }

    
    private changeViewingCompanies(сompView: TranslateObject) {

        if (!сompView)
            return;

        this.representService.viewingCompaniesChanged$.next(this.representService.getCompanyViewType(сompView.object_name));
    }


    public getLocation(): string {

        if (!this.router.parseUrl(this.router.url).root.children.primary)
            return;

        let location: string;
        let segmentsCount = this.router.parseUrl(this.router.url).root.children.primary.segments.length;
  
        if (segmentsCount == 1) {
            location = 'industry';
        } else {
            if (segmentsCount > 1) {
                let nameRootIndustrySegment: string = this.router.parseUrl(this.router.url).root.children.primary.segments[1].path;
                if (nameRootIndustrySegment == 'i') {
                    location = 'companies';
                } else if (nameRootIndustrySegment == 'ic') {
                    if (segmentsCount == 3) {
                        location = 'category';
                    } else if (segmentsCount == 4) {
                        location = 'companies';
                    }
                }
            } else {
                location = this.location.path();
            }
        }
  
        return location;
    }
  
      
    onLargeClick() {

        this.tileSize.bigSize = true;
        this.homeHeaderService.saveTileSize(this.getLocation() , true);
        this.representService.tileBigSizeChanged$.next(this.tileSize.bigSize);
    }
    
    
    onSmallClick() {
    
        this.tileSize.bigSize = false;
        this.homeHeaderService.saveTileSize(this.getLocation() , false);
        this.representService.tileBigSizeChanged$.next(this.tileSize.bigSize);
    }


    onChangeSort(selectedIndex: number) {

        this.currentSortIndex = selectedIndex;
        this.homeHeaderService.sortTileChanged$.next(this.sortingTile[selectedIndex]);
    }


    onChangeTypeCompany(selectedIndex: number) {

        if (this.translationCompanyViewing && this.translationCompanyViewing.length >= selectedIndex) {

            this.viewingCompanies = this.translationCompanyViewing[selectedIndex].translation;
            this.localStorage.set("viewingCompanies", this.viewingCompanies);
            this.changeViewingCompanies(this.translationCompanyViewing[selectedIndex]);
        }
    }


    iconVisible(iconName: string): boolean {

        if (!this.sortingTile || !this.sortingTile[this.currentSortIndex]) {
            return false;
        } else {
            return this.sortingTile[this.currentSortIndex].object_name.indexOf(iconName) > 0;
        }
    }

}