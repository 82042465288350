import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { ErrorService } from './error.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignInTranslation } from '../shared/translation.const';
import { langFORM_SIGNIN } from '../shared/constant.module';
import { TranslationService } from './translation.service';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { LocalStorageService } from './local-storage-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {  
    
    private signInTranslation = SignInTranslation;
    private authGuardRoutes: string[] = ['/list'];

    constructor(private router: Router, private userService: UserService, private localstorageService: LocalStorageService, private errorService: ErrorService, private translationService: TranslationService, private _snackBar: MatSnackBar) {}

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        //handle your auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            //navigate /delete cookies or whatever        
            console.error(`AuthInterceptor: ${err.status} ${err.message}`);
            console.error(this.errorService.getErrorPTNMessage(err));
            console.log(window.location.pathname);
            this.localstorageService.removeSession();
            this.localstorageService.removeUser();
            this.userService.x_vms_session = 'notauthenticate';
            this.userService.user = null;
            this.userService.isAuthenticated = false;
            this.userService.Authenticated$.next(false);
            this.userService.loginChanged$.next(null);
            //this.userService.errorStatusCode$.next(err.status);

            if (err.status === 403 && !window.location.pathname.includes('/login/')) {
                this.translationService.getTranslate(langFORM_SIGNIN, this.signInTranslation);

                if (this.authGuardRoutes.includes(window.location.pathname)) {
                    let snackBarRef = this._snackBar.open(this.signInTranslation.session_time_out.message_error, 'Ok', {
                        duration: 10000,
                        panelClass: ['snack-bar']
                    });
                    snackBarRef.afterDismissed().subscribe(() => {
                        this.router.navigate(['/login/sign-in']);
                    });
                }
                else {
                    let snackBarRef = this._snackBar.open(this.signInTranslation.session_time_out.message_error, null, {
                        duration: 5000,
                        panelClass: ['snack-bar']
                    });
                    snackBarRef.afterDismissed().subscribe(() => {
                        window.location.reload();
                    });
                }
            }            
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.            
            //return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        const authReq = req.clone();
        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
        return next.handle(authReq).pipe(catchError(x=> this.handleAuthError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
    }
}