import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslationService, TranslateObject } from './translation.service';
import { PtnAppConfig } from './../shared/ptn-app-config';
import { LoaderService } from './loader.service';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from './../services/user.service';
import { SessionStorageService } from '../services/session-storage-service';
import { LocalStorageService } from '../services/local-storage-service';


@Injectable()
export class IndustryService {

    private url = PtnAppConfig.url_v2;


    constructor(private http: HttpClient, private translationService: TranslationService, private loaderService: LoaderService,
            private userService: UserService, private sessionStorage: SessionStorageService, private localStorage: LocalStorageService) { }
    

    getCategories(industryID: number, sortIndex: number): Observable<any> {

        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.get(this.url + '/category?sort_object_id=' + sortIndex.toString() + '&code_industry=' + industryID.toString(), 
                { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading));
    }


    getIndustries(sortIndex: number): Observable<any> {

        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.get(this.url + '/industry?sort_object_id=' + sortIndex, { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading));
    }


    getIndustriesSilent(sortIndex: number) {

        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.http.get(this.url + '/industry?sort_object_id=' + sortIndex, { headers: myHeaders }).subscribe(
            response => {
                let json = response;
                if (json) {
                    this.sessionStorage.set('listIndustry', json);
                }
            },
            error => console.log(error));
    }


    getRounds(industryID: number): Observable<any> {

        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.get(this.url + '/industry/rounds/' + industryID.toString(), 
                { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading));
    }


    getSortObjectID(): number {

        let sortIndustryID = this.localStorage.get('sortIndustryID');

        if (!sortIndustryID) {

            let sortingTile: Array<TranslateObject> = [];
            this.translationService.getObjectBySubscribe('PAGE_HOME.INDUSTRY.SORTING_OPTIONS', sortingTile, this.userService.getUserSubscribeLevel());
            if (sortingTile && sortingTile.length > 0) {

                sortIndustryID = sortingTile[0].object_id;
            }
        } 
        return sortIndustryID;
    }

}    
