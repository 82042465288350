import { Injectable } from '@angular/core';
import { TranslateObject } from './../services/translation.service';
import { Subject } from 'rxjs';
import { LocalStorageService } from './../services/local-storage-service';


@Injectable()
export class HomeHeaderService {

    sortTileChanged$ = new Subject<TranslateObject>();

    private sortCategoryID: number = 0;


    constructor(private localStorage: LocalStorageService) {

        this.sortCategoryID = this.localStorage.get('sortCategoryID');
    }
    

    isSortObjectExist(selectedObject: TranslateObject, sortingTile: Array<TranslateObject>): boolean {

        let result = false;
        for (let i = 0; i < sortingTile.length; i++) {
            if (selectedObject.object_id == sortingTile[i].object_id) {
                result = true;
                break;
            }
        }
        return result;
    }


    changeCategorySortID(sortID: number) {

        this.sortCategoryID = sortID;
        this.localStorage.set('sortCategoryID', this.sortCategoryID);
    }


    changeIndustrySortID(sortID: number) {

        this.localStorage.set('sortIndustryID', sortID);
    }


    getCategorySortID(): number {

        return this.sortCategoryID;
    }


    loadTileSize(segment: string): boolean {

        return this.localStorage.get(segment + 'LargeTile');
    }


    saveTileSize(segment: string, largeTile: boolean) {

        this.localStorage.set(segment + 'LargeTile', largeTile);
    }


    isSortIndexExists(sortingTile: Array<TranslateObject>, sortIndex: number): boolean {

        let res: boolean = false;

        for (let i = 0; i < sortingTile.length; i++) {
            if (sortingTile[i].object_id == sortIndex) {
                res = true;
                break;
            }
        }

        return res;
    }

}    
