import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PtnAppConfig } from './../shared/ptn-app-config';
import { Observable } from 'rxjs';


@Injectable()
export class VersionService {

//    private url = PtnAppConfig.api_url;
    private url_v2 = PtnAppConfig.url_v2;


    constructor(private http: HttpClient) { }
    

    getVersion(): Observable<any> {

        let url = PtnAppConfig.url_v2;

        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8"
        });        

        return this.http.get(url + '/version/main', { headers: myHeaders });
    }


    getPaymentVersion(): Observable<any> {

        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8"
        });        

        return this.http.get(this.url_v2 + '/payment/version', { headers: myHeaders });
    }


    getUserVersion(): Observable<any> {

        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8"
        });        

        return this.http.get(this.url_v2 + '/user/version', { headers: myHeaders });
    }

    
    getAuthenticateVersion(): Observable<any> {

        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8"
        });        

        return this.http.get(this.url_v2 + '/authenticate/version', { headers: myHeaders });
    }

}    
