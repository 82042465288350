<div class="card-m" *ngFor='let firm of filter_firms'>
    <div class="card-container">
        <div class="card-title-m">
            <div class="card-title-main">
                <a [routerLink]="['./', firm.code_firm]"><img src={{firm.image_url}} alt={{firm.code_firm}} title={{firm.code_firm}}></a>
                <a class='firm-name' [routerLink]="['./', firm.code_firm]">{{firm.name_firm}}</a>
            </div>

            <div class="send-message-block">
                <a class="send-message-button" *ngIf="firm.is_send_message == 1 || firm.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                        (click)="onSendMessageFounderClick(firm)">
                    <img src="/assets/imgs/messagebubble_1.png">
                    <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                </a>

                <a class="send-message-button" *ngIf="firm.is_send_message == 2 || firm.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                        (click)="onSendMessageClick(firm)">
                    <img src="/assets/imgs/messagebubble_2.png">
                    <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                </a>
            </div>

<!--
            <a class="send-message-button" *ngIf="firm.is_send_message > '0'" (click)="onSendMessageClick(firm)" [routerLink]="[]" routerLinkActive="active">
                <img src="/assets/imgs/messagebubble_{{firm.is_send_message}}.png">
                <img *ngIf="neadMessageSubscribe()" src="/assets/imgs/subscribe.svg">
            </a>
-->            
        </div>
        <div *ngIf="typeFirm=='company'">
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.name_country}}:</span>
                <span class="card-detail-value">{{firm.name_country}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.number_of_emploees}}:</span>
                <span class="card-detail-value">{{firm.name_number_of_emploees}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.total_funding_amount}}:</span>
                <span class="card-detail-value">{{firm.total_funding_amount | bignumber}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.valuation}}:</span>
                <span class="card-detail-value">{{firm.valuation | bignumber}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.founded_year}}:</span>
                <span class="card-detail-value">{{firm.founded_year}}</span>
            </div>
        </div>
        <div *ngIf="typeFirm=='fund'">
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.name_country}}:</span>
                <span class="card-detail-value">{{firm.name_country}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.number_of_emploees}}:</span>
                <span class="card-detail-value">{{firm.name_number_of_emploees}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.total_capital}}:</span>
                <span class="card-detail-value">{{firm.total_capital | bignumber}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.deal_size}}:</span>
                <span class="card-detail-value">{{firm.name_deal_size}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.founded_year}}:</span>
                <span class="card-detail-value">{{firm.founded_year}}</span>
            </div>
        </div>
        <div *ngIf="typeFirm=='private-investor'">
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.name_country}}:</span>
                <span class="card-detail-value">{{firm.name_country}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.investment_stage}}:</span>
                <span class="card-detail-value">{{firm.name_investment_stage_str}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.total_capital}}:</span>
                <span class="card-detail-value">{{firm.total_capital | bignumber}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.deal_size}}:</span>
                <span class="card-detail-value">{{firm.name_deal_size}}</span>
            </div>
            <div class="card-detail">
                <span class="card-detail-name">{{filterTranslation.data.field.founded_year}}:</span>
                <span class="card-detail-value">{{firm.founded_year}}</span>
            </div>
        </div>
    </div>    
</div>