<div class="main" *ngIf="!platformService.handsetPlatform">
    <div class="body">
        <div class="body-header">
            <span>{{analisysTranslation.top_in_industry.title_industry}}</span>
            <span>{{analisysTranslation.top_in_industry.graph_description}}</span>

            <select class="company-limit" [(ngModel)]="selectedLimit" #companyLimit (change)="onChangeLimit(companyLimit.selectedIndex)" title="Limit">
                <option *ngFor="let limit of listLimit">{{limit.translation}}</option>
            </select>
        </div>
    
        <div class="body-body">
            <div class="list-industry">
                <div class="rows" *ngFor="let industry of listIndustry">
                    <div class="mark" [ngClass]="{ selectedMark: industry.code_industry == currentIndustryID}"></div>
                    <a class="link" id="industry{{industry.code_industry}}" [routerLink]="[]" routerLinkActive="active"
                            title={{industry.name_industry}} (click)="onIndustryClick(industry)"
                            [ngClass]="{ 'selectedIndustry': industry.code_industry == currentIndustryID,
                                        'disabledIndustry': industry.company_count_all <= 0 }">
                        <img class="industry-img image-back" *ngIf="industry.code_industry == currentIndustryID" src="{{industry.image_over_url}}">
                        <img class="industry-img image-front" *ngIf="industry.code_industry != currentIndustryID" src="{{industry.image_url}}">
                        <span>{{industry.name_industry}}</span>
                        <img *ngIf="neadSubscribe(industry.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                </div>
            </div>

            <div class="main-body">
                <span class="y-axis">{{analisysTranslation.top_in_industry.y_axis}}</span>
                <div class="chart-body">
                    <div class="chart">
                        <canvas baseChart 
                                [datasets]="barChartData"
                                [labels]="barChartLabels"
                                [options]="barChartOptions"
                                [plugins]="barChartPlugins"
                                [legend]="barChartLegend"
                                [chartType]="barChartType"
                                [colors]="barChartColors"
                                (chartClick)="chartClicked($event)"
                            >
                        </canvas>
                    </div>
                </div>
                <span class="x-axis">{{analisysTranslation.top_in_industry.x_axis}}</span>

                <ptn-visor *ngIf="isVisorVisible()"></ptn-visor>
            </div>
        </div>
    </div>
</div>


<!-- Mobile -->

<div class="mob-main" *ngIf="platformService.handsetPlatform">

    <div class="mob-header" [ngClass]="{ headerJustify: showIndustries }">

        <a *ngIf="showIndustries" [routerLink]="[]" routerLinkActive="active" (click)="onShowDiagramClick()" [ngClass]="{ showRoundsDisabled: !activatedIndustryID }">
            <span>{{analisysTranslation.top_in_industry.button.diagram}}</span>
            <img src="/assets/imgs/next_white.svg">
        </a>
        <a class="mob-header-industry" *ngIf="!showIndustries" [routerLink]="[]" routerLinkActive="active" (click)="onShowIndustryClick()">
            <img src="/assets/imgs/prev_white.svg">
            <span>{{nameIndustry}}</span>
        </a>
    </div>

    <div class="mob-industries" [ngClass]="{ industriesHide: !showIndustries }">
        <div class="mob-list-industry">
            <div class="mob-industry-rows" *ngFor="let industry of listIndustry">
                <a class="mob-link" id="industry{{industry.code_industry}}" [routerLink]="[]" routerLinkActive="active"
                        title={{industry.name_industry}} (click)="onIndustryClick(industry)"
                        [ngClass]="{ 'selectedIndustry': industry.code_industry == currentIndustryID,
                                    'disabledIndustry': industry.company_count_all <= 0 }">

                    <img class="mob-industry-img image-back" *ngIf="industry.code_industry == currentIndustryID" src="{{industry.image_over_url}}">
                    <img class="mob-industry-img image-front" *ngIf="industry.code_industry != currentIndustryID" src="{{industry.image_url}}">

                    <div class="subscribe">
                        <span>{{industry.name_industry}}</span>
                        <img class="subscribe-img" *ngIf="neadSubscribe(industry.subscribe_level)" src="/assets/imgs/subscribe.svg">
                    </div>

                    <img *ngIf="industry.code_industry != currentIndustryID" src="/assets/imgs/to_rounds.svg">
                    <img *ngIf="industry.code_industry == currentIndustryID" src="/assets/imgs/selected_to_rounds.svg">

                </a>
            </div>
        </div>
    </div>

    <div class="mob-diagram">
        <div class="mob-chart">
            <canvas baseChart 
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [legend]="barChartLegend"
                    [chartType]="barChartType"
                    [colors]="barChartColors"
                    (chartClick)="chartClicked($event)"
                    height="320px"
            >
            </canvas>
        </div>
    </div>

    <ptn-visor *ngIf="isVisorVisible() && !showIndustries" [ngClass]="{ mobVisor: true }"></ptn-visor>    

</div>
