import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { PersonData } from 'src/app/models/person.data';
import { CompanyAdvanced } from 'src/app/models/company.data';
import { UserCompanyRelations, SendMessageObject, SendMessageResult, SendMessageResponse } from 'src/app/models/user-company-relations';
import { ErrorService } from "./../../services/error.service";
import { TranslationService } from "./../../services/translation.service";
import { UserService } from "./../../services/user.service";
import { User } from "./../../models/user";
import { EmailObjectTranslation, PersonTranslation } from "./../../shared/translation.const";
import { PersonService } from "./../../services/person.service";
import { CompanyService } from "./../../services/company.service";
import { PlatformService } from './../../services/platform.service';
import { ActivatedRoute } from '@angular/router';
import { RouterHistoryService } from './../../services/router-history.service';


@Component({
    selector: 'ptn-send-message',
    templateUrl: './send.message.html',
    styleUrls: ['./send.message.css']
})
  
export class SendMessageComponent implements OnInit {

    sentResult: boolean = false;
    sendResultMessages: Array<SendMessageResult> = [];
    sendMailForm: FormGroup;
    selectedCompany: UserCompanyRelations = {
        code_firm: null,
        name_firm: null, 
        is_exist_presentation: null,
        sort_index: null,
        name_position: null
    }

    sendMessageObject: SendMessageObject;
    recipientCount: number = 1;

    @Input() personID: number = null;
    @Input() companyID: number = null;
    @Input() companyForFounder: number = 0;
    @Output() onCloseMessage = new EventEmitter<number>();
    @Input() private _init: any;

    public get init(): any {
        return this._init;
    }

    public set init(value: any) {

        this._init = value;

        this.sentResult = false;
        if (!this.sendMailForm) 
            return;
        let form = this.sendMailForm;
        let controlMessage = form.get("textMessage");
        if (controlMessage)
            controlMessage.enable();
    }

    currentAccount: User;
    currentSubject: string;

    emailTranslation = EmailObjectTranslation;
    personTranslation = PersonTranslation;

    private previousUrl: string;


    constructor(private errorService: ErrorService, private translationService: TranslationService,
                private userService: UserService, private personService: PersonService,
                private companyService: CompanyService, public platformService: PlatformService,
                private activatedRoute: ActivatedRoute, private routerHistoryService: RouterHistoryService) {

        this.translationService.getTranslate('email_object', this.emailTranslation);
        this.translationService.getTranslate('page_person', this.personTranslation);

        this.clearSendMessageObject();

        this.currentAccount = this.userService.user;
    }


    ngOnInit() {

        this.activatedRoute.queryParams.subscribe((queryParam: any) => {
              
            if (queryParam['person_id'])
                this.personID = queryParam['person_id'];
            if (queryParam['company_id'])
                this.companyID = queryParam['company_id'];
            if (queryParam['for_person'])
                this.companyForFounder = queryParam['for_person'];
        });
  
        if (this.personID)
            this.getPerson()
        else {
            if (this.companyID)
                this.getCompany();
        }

        this.buildForm();
        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('page_person', this.personTranslation);
            this.translationService.getTranslate('email_object', this.emailTranslation);
        });
    
        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.prepareSubject();
        });

        this.previousUrl = this.routerHistoryService.previousUrl$.value || '/';
        if (this.previousUrl.indexOf("send-message") >= 1) {
            this.previousUrl = "";
        }
    }


    private getPerson() {

        let person: PersonData;

        if (this.personService.person && this.personService.person.code_person == this.personID) {

            person = this.personService.person;
            this.personCardTaked(person);

            return;
        }

        if (person)
            this.clearSendMessageObject();

        this.personService.getPerson(this.personID).subscribe(
            response => {
                let json = response;

                if (json) {
                    person = json;
                    this.personCardTaked(person);
                    this.personService.person = person;
                }
            },
            error => {
                console.log(error);
                let errorStr = this.errorService.getErrorPTNMessage(error);
                if (errorStr != 'undefined')
                    alert(errorStr);
                this.onCloseMessage.emit(1);
            }
        );
    }


    private personCardTaked(person: PersonData) {

        this.preparePersonMessageData(person);
        this.prepareWeb();

        if (this.sendMessageObject.current_user_company_relations && this.sendMessageObject.current_user_company_relations.length > 0)
            this.onCompanyChange(0);

        this.prepareSubject();
    }


    private companyCardTaked(company: CompanyAdvanced) {

        this.prepareCompanyMessageData(company);
        this.prepareWeb();

        if (this.sendMessageObject.current_user_company_relations && this.sendMessageObject.current_user_company_relations.length > 0)
            this.onCompanyChange(0);

        this.prepareSubject();

        for (let i = 0; i < this.sendMessageObject.listRecipient.length; i++) {
            let senderControl = new FormControl({value: false});
            senderControl.setValue(i == 0);
            this.sendMailForm.addControl('recipient_' + i.toString(), senderControl);
        }
    }


    private getCompany() {

        let company: CompanyAdvanced;

        this.companyService.getCompany(this.companyID).subscribe(
            response => {
                let json = response;

                if (json) {
                    company = json;

                    this.companyCardTaked(company);
                }
            },
            error => {
                console.log(error);
                let errorStr = this.errorService.getErrorPTNMessage(error);
                if (errorStr != 'undefined')
                    alert(errorStr);
                this.onCloseMessage.emit(1);
            }
        );
    }


    private preparePersonMessageData(person: PersonData) {
        
        this.clearSendMessageObject();

        this.sendMessageObject = {
            code_instance: person.code_person,
            date_chat_message_last: person.date_chat_message_last,
            cnt_messages: person.cnt_messages,
            listRecipient: [
                {
                    code_recipient: person.code_person,
                    recipient_type: 2,
                    first_name: person.first_name,
                    last_name: person.last_name,
                    full_name: person.first_name + ' ' + person.last_name,
                    position: null
                }
            ],
/*            
            first_name: person.first_name,
            last_name: person.last_name,
*/            
            current_user_company_relations: person.current_user_company_relations
        }
    }


    private prepareCompanyMessageData(company: CompanyAdvanced) {

        this.clearSendMessageObject();

        this.sendMessageObject = {
            code_instance: company.code_firm,
            date_chat_message_last: null,
            cnt_messages: null,
            listRecipient: [],
            current_user_company_relations: company.current_user_company_relations
        }

        if (company.is_send_message == 1 && this.companyForFounder != 1) {
            this.sendMessageObject.listRecipient.push(
                {
                    code_recipient: company.code_firm,
                    recipient_type: 1,
                    first_name: null,
                    last_name: null,
                    full_name: company.name_firm,
                    position: null
                }
            );
        }

        if (this.companyForFounder == 1) {

            for (let i = 0; i < company.founders.length; i++) {
                if (company.founders[i].is_send_message == 1) {

                    let recipient = this.sendMessageObject.listRecipient.find(recipient => recipient.code_recipient === company.founders[i].code_person);
                    if (recipient && recipient.recipient_type == 2)
                        continue;

                    this.sendMessageObject.listRecipient.push(
                        {
                            code_recipient: company.founders[i].code_person,
                            recipient_type: 2,
                            first_name: null,
                            last_name: null,
                            full_name: company.founders[i].full_name,
                            position: 'founder'
                        }
                    );
                }
            }
        }

        if (this.companyForFounder == 1) {

            for (let i = 0; i < company.principals.length; i++) {
                if (company.principals[i].is_send_message == 1) {

                    let recipient = this.sendMessageObject.listRecipient.find(recipient => recipient.code_recipient === company.principals[i].code_person);
                    if (recipient && recipient.recipient_type == 2)
                        continue;

                    this.sendMessageObject.listRecipient.push(
                        {
                            code_recipient: company.principals[i].code_person,
                            recipient_type: 2,
                            first_name: null,
                            last_name: null,
                            full_name: company.principals[i].full_name,
                            position: 'principal'
                        }
                    );
                }
            }
        }
    }


    private clearSendMessageObject() {

        this.sendMessageObject = {
            code_instance: null,
            date_chat_message_last: null,
            cnt_messages: null,
            listRecipient: [],
            current_user_company_relations: []
        }
    }


    private prepareWeb() {

        if (!this.sendMessageObject)
            return;

//        this.sendMessageObject.websiteToShow = this.webAddressService.cutProtocol(this.sendMessageObject.website);

        if (this.sendMessageObject.date_chat_message_last && this.sendMessageObject.date_chat_message_last) {

            this.sendMessageObject.date_chat_message_last = this.sendMessageObject.date_chat_message_last * 1000;
        }
    }


    private buildForm() {

        this.sendMailForm = new FormGroup({
            companyID: new FormControl(""),
            textMessage: new FormControl("", Validators.required),
            attachPresentation: new FormControl({value: false, disabled: true}),
        });
    }


    onCompanyChange(companyIndex: number) {

        this.selectedCompany = this.sendMessageObject.current_user_company_relations[companyIndex];

        this.prepareSubject();

        if (!this.sendMailForm) 
            return;
        let form = this.sendMailForm;
        let controlAttach = form.get("attachPresentation");
        if (controlAttach) {
            if (this.selectedCompany.is_exist_presentation == 1) {
                controlAttach.enable();
            } else {
                controlAttach.disable();
            }
        }
    }


    onSubmit() {

        if (!this.sendMailForm) return;
        let form = this.sendMailForm;
        let controlMessage = form.get("textMessage");
        let controlAttach = form.get("attachPresentation");
        let attach: number;

        if (controlAttach.value)
            attach = 1
        else
            attach = 0;

        let recipientPerson: number = null;
        let recipientCompany: number = null;

        for (let i = 0; i < this.sendMessageObject.listRecipient.length; i++) {

            if (!this.personID && this.companyID) {
                let controlRecipient = form.get('recipient_' + i.toString());

                if (!controlRecipient || !controlRecipient.value)
                    continue;

                switch(this.sendMessageObject.listRecipient[i].recipient_type) {

                    case 1: {

                        recipientPerson = null;
                        recipientCompany = this.sendMessageObject.listRecipient[i].code_recipient;
                    }
                    break;

                    case 2: {

                        recipientPerson = this.sendMessageObject.listRecipient[i].code_recipient;
                        recipientCompany = null;
                    }
                    break;
                }

            } else {

                recipientPerson = this.sendMessageObject.listRecipient[i].code_recipient;
                recipientCompany = null;
            }

            let sendMessageResponse: SendMessageResponse;
            this.userService.userSendMessage(recipientCompany, recipientPerson, this.selectedCompany.code_firm,
                        controlMessage.value, attach).subscribe(
                response => {

                    let json = response;

                    if (json) {
                        sendMessageResponse = json;
                        this.prepareResponseMessage(sendMessageResponse);
                    }

                    this.sentResult = true;
                },
                error => {

                    this.sendResultMessages.push(
                        {
                            sentResult: -1,
                            message: this.errorService.getErrorPTNMessage(error)
                        }
                    );

                    this.sentResult = true;
                    console.log(error)
            });
        }

//        this.sentResult = true;
        controlMessage.disable();
    }


    private prepareResponseMessage(sendMessageResponse: SendMessageResponse) {

        let recipientType: number;
        let recipientCode: number;
        if (sendMessageResponse.code_firm) {
            recipientType = 1;
            recipientCode = sendMessageResponse.code_firm;
        } else {
            recipientType = 2;
            recipientCode = sendMessageResponse.code_person;
        }

        let recipient = this.sendMessageObject.listRecipient.find(recipient => recipient.code_recipient === recipientCode &&
                    recipient.recipient_type === recipientType);

        if (recipient) {

            this.sendResultMessages.push(
                {
                    sentResult: 1,
                    message: this.personTranslation.message_form.done + ' ' + recipient.full_name
                }
            );
        }

        if (!this.platformService.handsetPlatform) {
            let element = document.getElementById('result-end');
            if (element) {
                element.scrollIntoView();
            }
        }
    }


    private prepareSubject() {

        if (!this.currentAccount)
            return;

        const senderFullName = '(sender_full_name)';
        const senderNameFirm = '(sender_name_firm)';
        if (this.emailTranslation && this.emailTranslation.person_message.subject) {

            let subj: string = this.emailTranslation.person_message.subject;
            let pos: number = subj.indexOf(senderFullName);
            if (pos > 0) {
                subj = subj.replace(senderFullName, this.currentAccount.first_name + ' ' + this.currentAccount.last_name);
            }

            pos = subj.indexOf(senderNameFirm);
            if (pos > 0 && this.selectedCompany.name_firm) {
                subj = subj.replace(senderNameFirm, this.selectedCompany.name_firm);
            } else {
                return;
            }

            this.currentSubject = subj;
        }
    }


    onCloseClick() {

        this.onCloseMessage.emit(1);
    }


    onRecipientChange() {
   
        if (this.sendMessageObject.listRecipient.length > 1) {

            this.recipientCount = 0;
            for (let i = 0; i < this.sendMessageObject.listRecipient.length; i++) {

                let controlRecipient = this.sendMailForm.get('recipient_' + i.toString());

                if (controlRecipient && controlRecipient.value) {
                    this.recipientCount++;
                    break;
                }
            }
        }
    }


    onBackClick() {

        window.location.href = window.location.origin + this.previousUrl;
    }
}  