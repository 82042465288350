<div class="main">
    
    <div class="header" [ngClass]="{ headerJustify: showIndustries }">
        <a *ngIf="showIndustries" [routerLink]="[]" routerLinkActive="active" (click)="onShowRoundsClick()" [ngClass]="{ showRoundsDisabled: !activatedIndustryID }">
            <span>{{translationDeals.button.rounds}}</span>
            <img src="/assets/imgs/next_white.svg">
        </a>
        <a *ngIf="!showIndustries" [routerLink]="[]" routerLinkActive="active" (click)="onShowIndustryClick()">
            <img src="/assets/imgs/prev_white.svg">
            <span>{{nameIndustry}}</span>
        </a>
    </div>

    <div class="rounds">
        <div class="card" *ngFor="let round of industryRounds; index as i" [ngClass]="{ 'roundGauze': neadSubscribe(round.round_subscribe_level) }">
            <div class="round-row">
                <span class="round-row-title">{{translationDeals.rank}}</span>
                <span>{{round.pos_number}}</span>
            </div>

            <div class="round-row">
                <span class="round-row-title">{{translationDeals.company}}</span>
                <a class="company" [routerLink]="['./', round.code_firm]"
                            [ngClass]="{ 'companyGauze': neadSubscribe(round.firm_subscribe_level) || neadSubscribe(round.round_subscribe_level) }">
                    <img url={{round.image_url}}>
                    <span>{{round.name_firm}}</span>
                </a>
            </div>
                
            <div class="round-col">
                <span class="round-row-title">{{translationDeals.investors}}</span>
                <div class="div-list">
                    <a class="comma-text" *ngFor="let investor of round.investors; index as i; let lastItem = last;"
                        [ngClass]="{ 'investorGauze': neadSubscribe(investor.subscribe_level) || neadSubscribe(round.round_subscribe_level),
                                        'linkDisabled': investor.type_firm == 'UK' }"
                                        [routerLink]="['./', investor.code_firm]">
                        <img *ngIf="!neadSubscribe(investor.subscribe_level) && !neadSubscribe(round.round_subscribe_level)" src={{investor.image_url}}>
                        <div *ngIf="investor.image_url">&nbsp;&nbsp;</div>
                        {{investor.name_firm}}
                        <div *ngIf="!lastItem">,&nbsp;</div>
                    </a>
                </div>
            </div>

            <div class="round-row">
                <span class="round-row-title">{{translationDeals.funding_year}}</span>
                <span>{{round.date_round | date: 'MMM yyyy' }}</span>
            </div>

            <div class="round-row">
                <span class="round-row-title">{{translationDeals.funding_type}}</span>
                <span>{{round.type_round}}</span>
            </div>

            <div class="round-col">
                <div class="value-round">
                    <span class="round-row-title">{{translationDeals.funding_amount}}</span>
                    <div>{{ round.value_round | currency : '' : 'symbol' : '1.0' }} M</div>
                </div>                
                <div class="money">
                    <div class="ribbon ribbon-bottom">
                        <div class="ribbon ribbon-top-round" [ngStyle]="getSumRoundWidth(round)"></div>
                    </div>

                    <div class="space" *ngIf="round.post_valuation"></div>

                    <div class="ribbon ribbon-bottom" *ngIf="round.post_valuation">
                        <div class="ribbon ribbon-top-post" [ngStyle]="getPostValuationWidth(round)"></div>
                    </div>

                    <div class="post-valuation" *ngIf="round.post_valuation">{{ round.post_valuation | currency : '' : 'symbol' : '1.0' }} M</div>
                </div>
            </div>

        </div>
    </div>

    <div class="industries" [ngClass]="{ industriesHide: !showIndustries }">
        <div class="list-industry">
            <div class="one-row" *ngFor="let industry of listIndustry">
                <a class="link" [routerLink]="[]" routerLinkActive="active" title={{industry.name_industry}} (click)="onIndustryClick(industry)"
                        [ngClass]="{ 'selectedIndustry': industryRounds.length > 0 && industry.code_industry == industryRounds[0].code_industry,
                                      'disabledIndustry': industry.is_exists_rounds <= 0 }">

                    <img class="industry-img image-back" *ngIf="industry.code_industry == activatedIndustryID" src="{{industry.image_over_url}}">
                    <img class="industry-img image-front" *ngIf="industry.code_industry != activatedIndustryID" src="{{industry.image_url}}">

                    <div class="subscribe">
                        <span>{{industry.name_industry}}</span>
                        <img class="subscribe-img" *ngIf="neadSubscribe(industry.subscribe_level)" src="/assets/imgs/subscribe.svg">
                    </div>

                    <img *ngIf="industry.code_industry != activatedIndustryID" src="/assets/imgs/to_rounds.svg">
                    <img *ngIf="industry.code_industry == activatedIndustryID" src="/assets/imgs/selected_to_rounds.svg">
                </a>
            </div>
        </div>
    </div>

    <ptn-visor *ngIf="isVisorVisible() && !showIndustries"></ptn-visor>    

</div>
