import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Customer, PaymentData, PaymentSystemSession } from '../models/user';
import { UserSubscribe } from '../models/user-subscribe';
import { ErrorService } from '../services/error.service';
import { SessionStorageService } from '../services/session-storage-service';
import { UserService } from '../services/user.service';
import { DialogQuestionComponent } from '../shared/dialog-question/dialog-question.component';
import { langPAGE_SUBSCRIBE } from '../shared/constant.module';
import { SubscribeTranslation } from '../shared/translation.const';
import { TranslationService } from '../services/translation.service';
import { DialogInfoComponent } from '../shared/dialog-info/dialog-info.component';
import { PlatformService } from '../services/platform.service';

declare var Stripe;

@Component({
  selector: 'ptn-subscribes',
  templateUrl: './subscribes.component.html',
  styleUrls: ['./subscribes.component.scss']
})
export class SubscribesComponent implements OnInit, OnDestroy {

  subscribeTranslation = SubscribeTranslation;

  customer: Customer;
  userSubscribes: Array<UserSubscribe>;
  paymentSystemSession: PaymentSystemSession;
  errorPtn: string;
  listSubscribeDescription: string[];
  isAuthenticated: boolean;

  constructor(private router: Router, private userService: UserService, private errorService: ErrorService, public dialog: MatDialog,
    private translationService: TranslationService, public platformService: PlatformService) {

    this.translationService.getTranslate(langPAGE_SUBSCRIBE, this.subscribeTranslation);
    this.listSubscribeDescription = this.subscribeTranslation.level_1.description.split('\n');
    this.isAuthenticated = this.userService.isAuthenticated;

    this.translationService.languageChanged$.subscribe((changeObj) => {
      this.translationService.getTranslate(langPAGE_SUBSCRIBE, this.subscribeTranslation);
      this.listSubscribeDescription = this.subscribeTranslation.level_1.description.split('\n');

      this.userSubscribes?.forEach((item) => {
        item.trial = this.subscribeTranslation.level_1.trial.replace('<<trial_period>>', item.trial_period.toString());
        item.trial_inner = this.subscribeTranslation.level_1.trial_inner.replace('<<trial_period_inner>>', item.trial_period_inner.toString());
        item.per_month = this.subscribeTranslation.level_1.per_month.replace('<<price>>', item.price.toString());
      });
    });
  }

  ngOnInit(): void {
    if (this.isAuthenticated) {
      this.addStripeScript();
      this.getCustomer();
    } else {
      this.getSubscription();
    }
  }

  ngOnDestroy(): void {
    const script = document.getElementById('stripeScript');
    if (script) {
      script.parentNode.removeChild(script);
    }
  }

  private addStripeScript(): void {
    if (!document.getElementById('stripeScript')) {
      const script = document.createElement('script');
      script.setAttribute('id', 'stripeScript');
      script.src = 'https://js.stripe.com/v3';
      document.head.appendChild(script);
    }
  }

  private getCustomer(): void {
    this.userService.getCustomer().subscribe(
      result => {
           if (result) {
            this.customer = result;
            this.customer.prices.forEach((item) => {
              item.isActive = item.id_price === this.customer.id_price_payment_system;
              item.trial = this.subscribeTranslation.level_1.trial.replace('<<trial_period>>', item.trial_period.toString());
              item.trial_inner = this.subscribeTranslation.level_1.trial_inner.replace('<<trial_period_inner>>', item.trial_period_inner.toString());
              item.per_month = this.subscribeTranslation.level_1.per_month.replace('<<price>>', item.price.toString());
            });
            this.userSubscribes = this.customer.prices.sort((a, b) => a.subscribe_level - b.subscribe_level);
            console.log(this.customer);
           }
      },
      (error) => {
        this.errorPtn = this.errorService.getErrorPTNMessage(error);
        console.error(this.errorPtn);
      }
    );
  }

  private getSubscription(): void {
    this.userService.getSubscription().subscribe(
      result => {
           if (result) {
            this.userSubscribes = result;
            this.userSubscribes.forEach((item) => {
              item.isActive = false;
              item.trial = this.subscribeTranslation.level_1.trial.replace('<<trial_period>>', item.trial_period.toString());
              item.trial_inner = this.subscribeTranslation.level_1.trial_inner.replace('<<trial_period_inner>>', item.trial_period_inner.toString());
              item.per_month = this.subscribeTranslation.level_1.per_month.replace('<<price>>', item.price.toString());
            });
            this.userSubscribes = this.userSubscribes.sort((a, b) => a.subscribe_level - b.subscribe_level);
            console.log(this.userSubscribes);
           }
      },
      (error) => {
        this.errorPtn = this.errorService.getErrorPTNMessage(error);
        console.error(this.errorPtn);
      }
    );
  }

  private handleResult = function(result) {
    if (result.error) {
      console.log('Error -> handleResult');
      console.log(result.error.message);
    }
  };

  private buySubscribe(userSubscribe: UserSubscribe): void {
    console.log(`Buy ${userSubscribe.name_price}`);    

    let paymentData: PaymentData = {
      id_price: userSubscribe.id_price,
      success_url: `${environment.domain}/success-payment`,
      canceled_url: `${environment.domain}/subscribes`
    };

    console.log(JSON.stringify(paymentData));
    this.userService.createCheckoutSession(paymentData).subscribe(
      result => {
        if (result) {
          console.log(result);
          this.paymentSystemSession = result;
          console.log(this.paymentSystemSession);
          let stripe = Stripe(this.paymentSystemSession.pubkey);
          stripe.redirectToCheckout({
            sessionId: this.paymentSystemSession.sessionId
          }).then(this.handleResult);
        }
      },
      (error) => {
        this.errorPtn = this.errorService.getErrorPTNMessage(error);
        console.error(this.errorPtn);
      }
    );
  }

  onBuySubscribe(userSubscribe: UserSubscribe): void {

    if (this.userService.user.account_identifier_is_verified === '1') {
      if (this.customer.id_price_payment_system) {
        const dialogRef = this.dialog.open(DialogQuestionComponent, {
          width: '350px',
          data: { title: 'Buy subscription', question: 'Your current subscription will be canceled. Would you like to buy the new one?', no_label: 'No', yes_label: 'Yes' }
        });
  
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.buySubscribe(userSubscribe);
          }
        });
      }
      else {
        this.buySubscribe(userSubscribe);
      }
    }
    else {
      this.dialog.open(DialogInfoComponent, {
        width: '300px',
        panelClass: 'dialogWarningClass',
        data: { info: this.subscribeTranslation.popup.need_email_confirm, ok_label: 'Ok' }
      });
    }
  }

  onCancelSubscription(): void {
    const dialogRef = this.dialog.open(DialogQuestionComponent, {
      width: '350px',
      data: { title: 'Cancel subscription', question: 'Your subscription will be canceled. Would you like to cancel it?', no_label: 'No', yes_label: 'Yes' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.cancelSubscription().subscribe(() => {
          console.log('cancelSubscription complete')
          
          this.userService.signIn(this.userService.user).subscribe((res) => {
            this.getCustomer();
          },
            (error) => {
              this.errorPtn = this.errorService.getErrorPTNMessage(error);
              console.error(this.errorPtn);
              this.userService.actionOnSignInError();
            }
          );
        },
          (error) => {
            this.errorPtn = this.errorService.getErrorPTNMessage(error);
            console.error(this.errorPtn);
          }
        );
      }
    });
  }

  onLogIn(): void {
    this.router.navigate(['/login/sign-in']);
  }
}
