import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'bignumber'})
export class BigNumberPipe implements PipeTransform {
  public transform(numb: number): string {
    if (isNaN(parseFloat('' + numb)) || !isFinite(numb)) return '-';
    if (numb <= 0 ) return '0';
    let units = ['', 'k', 'm', 'b'],
        number = Math.floor(Math.log(numb) / Math.log(1000));
    return number == 0 ? (numb / Math.pow(1000, Math.floor(number))) + units[number] : (numb / Math.pow(1000, Math.floor(number))).toFixed(1) + ' ' + units[number];
  }
}