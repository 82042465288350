<div class="search" id="search">
    <div class="main">

        <div class="not-found" *ngIf="searchAllResultPC.length == 0 && searchAllResultFI.length == 0 && searchAllResultPI.length == 0 && searchAllResultPE.length == 0 && wasSearch">
                {{allTranslation.not_found}}
        </div>

        <div class="search-result">
<!-- PC -->
            <div class="search-container" *ngIf="searchAllResultPC && searchAllResultPC.length > 0 && searchResultPC">
                <div class="container-header">
                    {{allTranslation.type_object.private_company}}&nbsp;&nbsp;&nbsp;&nbsp;
                    {{foundCountPC}}
                </div>

                <div class="content" *ngIf="searchResultPC">
                    <div class="group" *ngFor="let resultPC of searchResultPC; let lastItem = last;" [ngClass]="{ linkDisabled: resultPC.subscribe_level > userSubscribeLevel }">
                        <div class="one-line" [ngClass]="{ oneLineLast: lastItem && !(searchResultPC && searchResultPC.length > 0 && searchResultPC[0].type_entity_count > searchResultPC.length),
                                                 linkDisabled: resultPC.subscribe_level > userSubscribeLevel }" id="{{resultPC.type_entity}}{{resultPC.code_entity}}">
                            <div class="link">
                                <a [routerLink]="[]" routerLinkActive="active" (click)="onEntityClick(resultPC)">
<!--                                <a [routerLink]="['./', resultPC.code_entity]"> -->
                                    <img class="img-entity" src="{{resultPC.image_url}}"/>
                                    {{resultPC.name_entity}}
                                </a>
                            </div>

                            <a class="send-message-button" *ngIf="resultPC.is_send_message == 1 || resultPC.is_send_message == 3" (click)="onSendMessageCompanyFounderClick(resultPC)">
                                <img src="/assets/imgs/messagebubble_1.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <a class="send-message-button" *ngIf="resultPC.is_send_message == 2 || resultPC.is_send_message == 3" (click)="onSendMessageCompanyClick(resultPC)">
                                <img src="/assets/imgs/messagebubble_2.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <div class="social-network" *ngIf="resultPC.socialNetworks && resultPC.socialNetworks.length > 0" >
                                <div *ngFor="let social of resultPC.socialNetworks; let lastIten = last;">
                                    <a href="{{social.address}}" target="_blank" *ngIf="social.type_address=='LI'">
                                        <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                    </a>
                                </div>
                            </div>

                            <div class="company-info">
                                <div *ngFor="let focus of resultPC.focus; let lastItem = last;">
                                    <span>{{focus.text}}</span>
                                    <a [routerLink]="['./', focus.code_firm]">{{focus.name_firm}}</a>
                                    <div *ngIf="!lastItem">,&nbsp;</div>
                                </div>
                            </div>
                        </div>            
                    </div>
                                    
                    <div class="show-more" *ngIf="searchResultPC && searchResultPC.length > 0 && searchResultPC[0].type_entity_count > searchResultPC.length">
                        <a [routerLink]="[]" routerLinkActive="active" (click)="onShowMoreClick('PC')">
                            {{allTranslation.show_more}}&nbsp;
                            <img src="/assets/imgs/ShowMore.svg">
                            <img *ngIf="neadSubscribeShowMore(showMoreObject[0])" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                        </a>
                    </div>
                </div>
            </div>

<!-- FI -->
            <div class="search-container" *ngIf="searchAllResultFI && searchAllResultFI.length > 0 && searchResultFI">
                <div class="container-header">
                    {{allTranslation.type_object.firm_investor}}&nbsp;&nbsp;&nbsp;&nbsp;
                    {{foundCountFI}}
                </div>

                <div class="content" *ngIf="searchResultFI">
                    <div class="group" *ngFor="let resultFI of searchResultFI; let lastItem = last;" [ngClass]="{ linkDisabled: resultFI.subscribe_level > userSubscribeLevel }">
                        <div class="one-line" [ngClass]="{ oneLineLast: lastItem && !(searchResultFI && searchResultFI.length > 0 && searchResultFI[0].type_entity_count > searchResultFI.length),
                                                linkDisabled: resultFI.subscribe_level > userSubscribeLevel }" id="{{resultFI.type_entity}}{{resultFI.code_entity}}">

                            <div class="link">
                                <a [routerLink]="[]" routerLinkActive="active" (click)="onEntityClick(resultFI)">
<!--                                <a [routerLink]="['./', resultFI.code_entity]"> -->
                                    <img class="img-entity" src="{{resultFI.image_url}}"/>
                                    {{resultFI.name_entity}}
                                </a>
                            </div>

                            <a class="send-message-button" *ngIf="resultFI.is_send_message == 1 || resultFI.is_send_message == 3" (click)="onSendMessageCompanyFounderClick(resultFI)">
                                <img src="/assets/imgs/messagebubble_1.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <a class="send-message-button" *ngIf="resultFI.is_send_message == 2 || resultFI.is_send_message == 3" (click)="onSendMessageCompanyClick(resultFI)">
                                <img src="/assets/imgs/messagebubble_2.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>


                            <div class="social-network" *ngIf="resultFI.socialNetworks && resultFI.socialNetworks.length > 0" >
                                <div *ngFor="let social of resultFI.socialNetworks; let lastIten = last;">
                                    <a href="{{social.address}}" target="_blank" *ngIf="social.type_address=='LI'">
                                        <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                    </a>
                                </div>
                            </div>

                            <div class="company-info">
                                <div  *ngFor="let focus of resultFI.focus; let lastItem = last;">
                                    <span>{{focus.text}}</span>
                                    <a [routerLink]="['./', focus.code_firm]">{{focus.name_firm}}</a>
                                    <div *ngIf="!lastItem">,&nbsp;</div>
                                </div>
                            </div>

                        </div>            
                    </div>
                                    
                    <div class="show-more" *ngIf="searchResultFI && searchResultFI.length > 0 && searchResultFI[0].type_entity_count > searchResultFI.length">
                        <a [routerLink]="[]" routerLinkActive="active" (click)="onShowMoreClick('FI')">
                            {{allTranslation.show_more}}&nbsp;
                            <img src="/assets/imgs/ShowMore.svg">
                            <img *ngIf="neadSubscribeShowMore(showMoreObject[0])" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                        </a>
                    </div>
                </div>
            </div>

<!-- PI -->
            <div class="search-container" *ngIf="searchAllResultPI && searchAllResultPI.length > 0 && searchResultPI">
                <div class="container-header">
                    {{allTranslation.type_object.private_investor}}&nbsp;&nbsp;&nbsp;&nbsp;
                    {{foundCountPI}}
                </div>

                <div class="content" *ngIf="searchResultPI">
                    <div class="group" *ngFor="let resultPI of searchResultPI; let lastItem = last;" [ngClass]="{ linkDisabled: resultPI.subscribe_level > userSubscribeLevel }">
                        <div class="one-line" [ngClass]="{ oneLineLast: lastItem && !(searchResultPI && searchResultPI.length > 0 && searchResultPI[0].type_entity_count > searchResultPI.length),
                                                linkDisabled: resultPI.subscribe_level > userSubscribeLevel }" id="{{resultPI.type_entity}}{{resultPI.code_entity}}">
                            <div class="link">
                                <a [routerLink]="[]" routerLinkActive="active" (click)="onEntityClick(resultPI)">
<!--                                <a [routerLink]="['./', resultPI.code_entity]"> -->
                                    <img class="img-entity" src="{{resultPI.image_url}}"/>
                                    {{resultPI.name_entity}}
                                </a>
                            </div>

                            <a class="send-message-button" *ngIf="resultPI.is_send_message == 1 || resultPI.is_send_message == 3" (click)="onSendMessageCompanyFounderClick(resultPI)">
                                <img src="/assets/imgs/messagebubble_1.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <a class="send-message-button" *ngIf="resultPI.is_send_message == 2 || resultPI.is_send_message == 3" (click)="onSendMessageCompanyClick(resultPI)">
                                <img src="/assets/imgs/messagebubble_2.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <div class="social-network" *ngIf="resultPI.socialNetworks && resultPI.socialNetworks.length > 0" >
                                <div *ngFor="let social of resultPI.socialNetworks; let lastIten = last;">
                                    <a href="{{social.address}}" target="_blank" *ngIf="social.type_address=='LI'">
                                        <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                    </a>
                                </div>
                            </div>

                            <div class="company-info">
                                <div *ngFor="let focus of resultPI.focus; let lastItem = last;">
                                    <span>{{focus.text}}</span>
                                    <a [routerLink]="['./', focus.code_firm]">{{focus.name_firm}}</a>
                                    <div *ngIf="!lastItem">,&nbsp;</div>
                                </div>
                            </div>                            

                        </div>            
                    </div>
                                    
                    <div class="show-more" *ngIf="searchResultPI && searchResultPI.length > 0 && searchResultPI[0].type_entity_count > searchResultPI.length">
                        <a [routerLink]="[]" routerLinkActive="active" (click)="onShowMoreClick('PI')">
                            {{allTranslation.show_more}}&nbsp;
                            <img src="/assets/imgs/ShowMore.svg">
                            <img *ngIf="neadSubscribeShowMore(showMoreObject[0])" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                        </a>
                    </div>
                </div>
            </div>

<!-- PE -->
            <div class="search-container" *ngIf="searchAllResultPE && searchAllResultPE.length > 0 && searchResultPE">
                <div class="container-header">
                    {{allTranslation.type_object.person}}&nbsp;&nbsp;&nbsp;&nbsp;
                    {{foundCountPE}}
                </div>

                <div class="content" *ngIf="searchResultPE">
                    <div class="group" *ngFor="let resultPE of searchResultPE; let lastItem = last;" [ngClass]="{ linkDisabled: resultPE.subscribe_level > userSubscribeLevel }">
                        <div class="one-line" [ngClass]="{ oneLineLast: lastItem && !(searchResultPE && searchResultPE.length > 0 && searchResultPE[0].type_entity_count > searchResultPE.length),
                                                linkDisabled: resultPE.subscribe_level > userSubscribeLevel }" id="{{resultPE.type_entity}}{{resultPE.code_entity}}">
                            <div class="link">
                                <a (click)="onPersonClick($event, resultPE)">
                                    <img class="img-entity" src="{{resultPE.image_url}}"/>
                                    {{resultPE.name_entity}}
                                </a>
                            </div>

                            <a class="send-message-button" *ngIf="resultPE.is_send_message == 1 || resultPE.is_send_message == 3" (click)="onSendMessagePersonClick(resultPE)">
                                <img src="/assets/imgs/messagebubble_1.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <div class="social-network" *ngIf="resultPE.socialNetworks && resultPE.socialNetworks.length > 0" >
                                <div *ngFor="let social of resultPE.socialNetworks; let lastIten = last;">
                                    <a href="{{social.address}}" target="_blank" *ngIf="social.type_address=='LI'">
                                        <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                    </a>
                                </div>
                            </div>

                            <div class="company-info">
                                <div *ngFor="let focus of resultPE.focus; let lastItem = last;">
                                    <span>{{focus.text}}&nbsp;</span>
                                    <a [routerLink]="[]" routerLinkActive="active" (click)="onEntityClick(resultPE, focus.code_firm)">{{focus.name_firm}}</a>
<!--                                    <a [routerLink]="['./', focus.code_firm]">{{focus.name_firm}}</a> -->
                                    <div *ngIf="!lastItem">,&nbsp;</div>
                                </div>
                            </div>

                        </div>            
                    </div>
                                    
                    <div class="show-more" *ngIf="searchResultPE && searchResultPE.length > 0 && searchResultPE[0].type_entity_count > searchResultPE.length">
                        <a [routerLink]="[]" routerLinkActive="active" (click)="onShowMoreClick('PE')">
                            {{allTranslation.show_more}}&nbsp;
                            <img src="/assets/imgs/ShowMore.svg">
                            <img *ngIf="neadSubscribeShowMore(showMoreObject[0])" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
