import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router"
import { HomeHeaderService } from "./../../services/home.header.service";
import { Category } from "./../../models/home.body";
import { TranslateObject } from './../../services/translation.service';
import { TranslationService } from '../../services/translation.service';
import { IndustryService } from "./../../services/industry.service";
import { UserService } from "./../../services/user.service";
import { RepresentService } from './../../services/represent.service';
import { TranslationPtnIndex } from './../../shared/translation.const';
import { User } from './../../models/user';
import { Subscription } from 'rxjs';
import { PlatformService } from './../../services/platform.service';
import { viewAsPhotoChanged$ } from "./../../header/header.component";
import { LocalStorageService } from './../../services/local-storage-service';


@Component({
    selector: 'ptn-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.css']
  })
  
export class CategoryComponent implements OnInit, AfterViewChecked, OnDestroy {

    private industryID: number;
    private widthHomeBody: number;
    private subscriptionSort: Subscription;
    private currentCategoryID: number = undefined;
    private subscriptionFragment: Subscription;

    listCategory: Array<Category>;

    sortingTile: Array<TranslateObject> = [];

    selectedSort: string;

    tileBigSize: boolean = false

    translationPtnIndex = TranslationPtnIndex;

    viewAsPhoto: boolean = true;


    constructor(private homeHeaderService: HomeHeaderService, private translationService: TranslationService,
            private industryService: IndustryService, private userService: UserService,
            private activatedRoute: ActivatedRoute, private representService: RepresentService,
            public platformService: PlatformService, private localStorage: LocalStorageService,
            private router: Router) {


        let tmpViewAsPhoto = this.localStorage.get("viewAsPhoto");
        if (tmpViewAsPhoto != undefined) {
            this.viewAsPhoto = tmpViewAsPhoto;
        }
              
        viewAsPhotoChanged$.subscribe((view: boolean) => {
              
            this.viewAsPhoto = view;
        });
      
        this.translationService.getTranslate('PAGE_PTN_INDEX', this.translationPtnIndex);

        let tmpSize = this.homeHeaderService.loadTileSize('category');
        if (tmpSize != undefined)
            this.tileBigSize = tmpSize;


        this.subscriptionFragment = this.activatedRoute.fragment.subscribe(
            (fragment: string) => {
                this.currentCategoryID = +fragment;
            }
        );
  
    }


    ngOnInit() {

      this.takeSortList();

      this.industryID = +this.activatedRoute.snapshot.paramMap.get('industryId');      

      this.getCategories(this.homeHeaderService.getCategorySortID())

      this.subscriptionSort = this.homeHeaderService.sortTileChanged$.subscribe((selectedObject: TranslateObject) => {

        if (!selectedObject)
          return;

        if (this.homeHeaderService.isSortObjectExist(selectedObject, this.sortingTile)) {
          this.homeHeaderService.changeCategorySortID(selectedObject.object_id);

          this.getCategories(selectedObject.object_id);
        }
      });

      this.translationService.languageChanged$.subscribe((changeObj) => {

        this.takeSortList();
        this.getSelectedSort();
        this.getCategories(this.homeHeaderService.getCategorySortID());
        this.translationService.getTranslate('PAGE_PTN_INDEX', this.translationPtnIndex);
      });

      this.representService.tileBigSizeChanged$.subscribe((bigSize: boolean) => {

        this.tileBigSize = bigSize;
      });

      setTimeout(() => {

        const element = document.getElementById('main');
        if (element) {

            element.focus();
        }
      }, 0);

      this.userService.loginChanged$.subscribe((user: User) => {

        this.takeSortList();
      });

      setTimeout(() => { 
        this.representService.setTileSize$.next(this.tileBigSize);
      }, 50);

    }


    ngAfterViewChecked() {

      if (this.currentCategoryID) {
          let element = document.getElementById("category" + this.currentCategoryID.toString());
          if (element) {
              element.scrollIntoView();
              this.currentCategoryID = undefined;
          }
      }
    }


    ngOnDestroy() {

      this.subscriptionSort.unsubscribe();
    }


    getCategories(sortIndex: number) {

      if ((!sortIndex && this.sortingTile && this.sortingTile.length > 0) || (!this.homeHeaderService.isSortIndexExists(this.sortingTile, sortIndex) && this.sortingTile && this.sortingTile.length > 0))
        sortIndex = this.sortingTile[0].object_id;

      if (!sortIndex)
          return;

      let srtIndex: number = sortIndex;

      if (this.subscriptionFragment && !this.subscriptionFragment.closed)
      this.subscriptionFragment.unsubscribe();

      this.industryService.getCategories(this.industryID, srtIndex).subscribe(
          response => {
              let json = response;

              if (json) {
                  this.listCategory = json;
              }
          },
          error => console.log(error));
    }


    private getSelectedSort() {

      if (this.sortingTile.length > 0) {
        let storedSortID = this.homeHeaderService.getCategorySortID();
          if (storedSortID) {
            for (let i = 0; i < this.sortingTile.length; i++) {
              if (this.sortingTile[i].object_id == storedSortID) {

                this.selectedSort = this.sortingTile[i].translation;
                break;
              }
            }
          } 

          if (!this.selectedSort) {
            this.selectedSort = this.sortingTile[0].translation;
            this.homeHeaderService.changeCategorySortID(this.sortingTile[0].object_id);
        }

          this.representService.selectedSort$.next(this.selectedSort);
        }
    }


  private takeSortList() {

      this.sortingTile = [];
      this.translationService.getObjectBySubscribe('PAGE_HOME.CATEGORY.SORTING_OPTIONS', this.sortingTile, this.userService.getUserSubscribeLevel());

      if (this.sortingTile && this.sortingTile.length > 0) {

        this.representService.setSortList$.next(this.sortingTile);
        this.getSelectedSort();
      }
  }


  neadSubscribe(subscribeLevel: number): boolean {

      let userSubsribeLevel: number = 0;

      if (this.userService.user && this.userService.user.subscribe_level)
          userSubsribeLevel = this.userService.user.subscribe_level;

      return (subscribeLevel > userSubsribeLevel);
  }


  getCanvasWidth() {

    this.widthHomeBody = document.getElementById('main').clientWidth;
    let canvasWidth = this.widthHomeBody;
    let sizeTile: number;

    if (this.tileBigSize) {
        sizeTile = 345
    } else {
        sizeTile = 228
    }

    canvasWidth = canvasWidth / (sizeTile + 6 * 2);
    canvasWidth = Math.floor(canvasWidth) * (sizeTile + 6 * 2);

    let styles = {
        "width": canvasWidth.toString() + 'px'
    };

    return styles;
  }


  getCanvasWidthOld() {

    this.widthHomeBody = document.getElementById('main').clientWidth;

    let canvasWidth = this.widthHomeBody;
    let sizeTile: number;

    if (this.tileBigSize) {
        sizeTile = 225
    } else {
        sizeTile = 130
    }

    canvasWidth = canvasWidth / (sizeTile + 6 * 2);
    canvasWidth = Math.floor(canvasWidth) * (sizeTile + 6 * 2);

    let styles = {
        "width": canvasWidth.toString() + 'px'
    };

    return styles;
  }


  onResize(event) {

    this.widthHomeBody = document.getElementById('main').clientWidth;
  }


  getPtnIndexWidth(category: Category) {

    let styles;

    let itemWidth: number = 200;
    let element = document.getElementById('ptn-index');
    if (element) 
        itemWidth = element.clientWidth - 60;

    let width: number = Math.floor((itemWidth * category.ptn_index_prc) / 100);

    styles = {
        "width": width.toString() + 'px'
    };

    return styles;
  }


  getPtnSubindexWidth(category: Category) {

    let styles;

    let itemWidth: number = 200;
    let element = document.getElementById('sub-index');
    if (element) 
        itemWidth = element.clientWidth - 60;

    let width: number = Math.floor((itemWidth * category.ptn_subindex_prc) / 100);

    styles = {
        "width": width.toString() + 'px'
    };

    return styles;
  }


  getTileSize() {
     
    let canvasWidth = document.querySelector('div.mob-main').clientWidth;

    let tileWidth: number = Math.floor((canvasWidth * 44) / 100);
    let tileHeight: number = Math.floor(tileWidth / 1.33);

    let styles = {
        "width": tileWidth.toString() + 'px',
        "height": tileHeight.toString() + 'px'
    };

    return styles;
  }


  onCategoryClick(category: Category) {

    this.router.navigate([], { fragment: category.code_category.toString() });

    setTimeout(() => { 
        this.router.navigate(['./', category.code_category], {relativeTo: this.activatedRoute});
    }, 0);
  }

}    
