<div class="main">
    <div class="body">
        <div class="body-header">
            <span>{{dealsTranslation.industry_list}}</span>
            <span>{{dealsTranslation.rounds_table}}</span>
        </div>

        <div class="body-body">
            <div class="list-industry">
                <div class="rows" *ngFor="let industry of listIndustry">
                    <div class="mark" [ngClass]="{ selectedMark: industry.code_industry == activatedIndustryID }"></div>

                    <a class="link" [routerLink]="[]" routerLinkActive="active"
                            title={{industry.name_industry}} (click)="onIndustryClick(industry)"
                            [ngClass]="{ 'selectedIndustry': industryRounds.length > 0 && industry.code_industry == industryRounds[0].code_industry,
                                        'disabledIndustry': industry.is_exists_rounds <= 0 }">
                        <img class="industry-img image-back" *ngIf="industry.code_industry == activatedIndustryID" src="{{industry.image_over_url}}">
                        <img class="industry-img image-front" *ngIf="industry.code_industry != activatedIndustryID" src="{{industry.image_url}}">

                        <span>{{industry.name_industry}}</span>
                        <img class="subscribe-img" *ngIf="neadSubscribe(industry.subscribe_level)" src="/assets/imgs/subscribe.svg">
                    </a>
                </div>
            </div>

            <div class="rounds">
                <div class="grid-header">
                    <div>{{dealsTranslation.rank}}</div>
                    <div>{{dealsTranslation.company}}</div>
                    <div>{{dealsTranslation.investors}}</div>
                    <div>{{dealsTranslation.funding_year}} </div>
                    <div>{{dealsTranslation.funding_type}} </div>
                    <div>{{dealsTranslation.funding_amount}} </div>
                </div>

                <div class="grid-container">
                    <div class="grid-lines" *ngFor="let round of industryRounds; index as i"
                            [ngClass]="{ 'roundGauze': neadSubscribe(round.round_subscribe_level) }">
                        <div>{{round.pos_number}}</div>
            
                        <a class="company" [routerLink]="['./', round.code_firm]"
                                [ngClass]="{ 'companyGauze': neadSubscribe(round.firm_subscribe_level) || neadSubscribe(round.round_subscribe_level) }">
                            <img url={{round.image_url}}>
                            {{round.name_firm}}
                        </a>
            
                        <div class="div-list">
                            <a class="comma-text" *ngFor="let investor of round.investors; index as i; let lastItem = last;"
                                [ngClass]="{ 'investorGauze': neadSubscribe(investor.subscribe_level) || neadSubscribe(round.round_subscribe_level),
                                                'linkDisabled': investor.type_firm == 'UK' }"
                                                [routerLink]="['./', investor.code_firm]">
                                <img *ngIf="!neadSubscribe(investor.subscribe_level) && !neadSubscribe(round.round_subscribe_level)" src={{investor.image_url}}>
                                <div *ngIf="investor.image_url">&nbsp;&nbsp;</div>
                                {{investor.name_firm}}
                                <div *ngIf="!lastItem">,&nbsp;</div>
                            </a>
                        </div>
                
                        <div>{{round.date_round | date: 'MMM yyyy' }}</div>
                        <div>{{round.type_round}}</div>
                        <div class="money">
                            <div class="value-round">{{ round.value_round | currency : '' : 'symbol' : '1.0' }} M</div>
                            <div class="ribbon ribbon-bottom">
                                <div [ngStyle]="getSumRoundWidth(round)" class="ribbon ribbon-top-round"></div>
                            </div>

                            <div class="space" *ngIf="round.post_valuation"></div>

                            <div class="ribbon ribbon-bottom" *ngIf="round.post_valuation">
                                <div [ngStyle]="getPostValuationWidth(round)" class="ribbon ribbon-top-post"></div>
                            </div>

                            <div class="post-valuation" *ngIf="round.post_valuation">{{ round.post_valuation | currency : '' : 'symbol' : '1.0' }} M</div>

                        </div>
                    </div>
                </div>                

                <ptn-visor *ngIf="isVisorVisible()"></ptn-visor>    

            </div>
        </div>
    </div>
</div>
