import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { IdentifierData } from '../../models/identifier-data';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { DeviceInfoService } from '../../services/device-info.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../services/error.service';
import { LocalStorageService } from '../../services/local-storage-service';
import { SocialnetAction } from '../../shared/socialnet-action';
import { TranslationService } from '../../services/translation.service';
import { SessionStorageService } from '../../services/session-storage-service';
import { RouterHistoryService } from '../../services/router-history.service';

@Component({
  selector: 'ptn-sign-in-social',
  templateUrl: './sign-in-social.component.html',
  styleUrls: ['./sign-in-social.component.css']
})
export class SignInSocialComponent implements OnInit {

  private previousUrl: string;

  errorPtn: string;

  constructor(private router: Router, private commonServise: CommonService, private userService: UserService, private errorService: ErrorService, 
    private deviceInfoService: DeviceInfoService, private localStorageService: LocalStorageService, private sessionStorageService: SessionStorageService,
    private translationService: TranslationService, private routerHistoryService: RouterHistoryService) { }

  ngOnInit(): void {
    console.log('Init SignInSocialComponent');    
    console.log(this.localStorageService.socialnetAction);

    this.previousUrl = this.routerHistoryService.previousUrl$.value || '/';
    console.log(`previousUrl: ${this.previousUrl}`);

    let userSocial =  this.commonServise.getUrlSearchParams();
    console.log(userSocial);

    if (userSocial.error_message) {
      this.errorPtn = userSocial.error_message;
    }
    else {

      if (this.localStorageService.socialnetAction == SocialnetAction.SignIn) {
        let identifierData: IdentifierData = {
          id: userSocial.id,
          email: userSocial.email,
          name: userSocial.name,
          first_name: userSocial.first_name,
          last_name: userSocial.last_name,
          work: userSocial.work ? userSocial.work : null
        };

        let user: User = {
          identifier: identifierData.id,
          password: null,
          type_identifier: userSocial.type_identifier,
          identifier_data: identifierData,
          device_data: this.deviceInfoService.deviceInfo,
          language_id: this.translationService.currentLanguage,
          access_token: userSocial.accessToken.substr(0, userSocial.accessToken.length - 1)
        };
        console.log(user);

        this.userService.user = user;
        this.userService.signIn(user).subscribe((res) => {
          window.location.href = window.location.origin + this.previousUrl;          
        },
          (error) => {
            this.errorPtn = this.errorService.getErrorPTNMessage(error);
            console.log(this.errorPtn);
            this.userService.actionOnSignInError();
          }
        );
      }
      else 
      if (this.localStorageService.socialnetAction == SocialnetAction.Register) {

        this.router.navigate(['/login/register'], { queryParams: userSocial });
      }
    }
  }

  onOk(): void {
    if (this.localStorageService.socialnetAction == SocialnetAction.SignIn)
      this.router.navigate(['/login/sign-in']);
    else if (this.localStorageService.socialnetAction == SocialnetAction.Register) {
     
      this.router.navigate(['/login/register']);
    }
  }
}
