<div class='body-style'>
    <div class="main">
        <div class="home-header" *ngIf="!platformService.handsetPlatform && (getActiveComponent() == 'home' || getActiveComponent() == 'category')" >
            <div class="slogan">{{homeTranslation.slogan}}</div>

            <div>
                <a class="reg-button" [routerLink]="['/login/register']">REGISTER FREE</a>
            </div>
        </div>

        <ptn-industry *ngIf="getActiveComponent() == 'home'"></ptn-industry>

        <ptn-category *ngIf="getActiveComponent() == 'category'"></ptn-category>

        <ptn-companies *ngIf="getActiveComponent() == 'companies'"></ptn-companies>

        <div class="partner" *ngIf="!platformService.handsetPlatform && getActiveComponent() != 'companies'">
            <span>{{homeTranslation.partnership}}</span>
            <a href="https://mvp.vc/" target="_blank">
                <img class="partner-img" src="/assets/imgs/MVP header-logo.png">
            </a>
            <span>{{homeTranslation.partner_slogan}}</span>

            <div class="social-networks">
                <div *ngFor="let socNet of translateSocialNetworks">
                    <a href="{{socNet.translation}}" target="_blank" *ngIf="neadSubscribe(socNet.subscribe_level)" title="{{socNet.translation}}">
                        <img src="{{getSocialNetImage(socNet)}}">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
