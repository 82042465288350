import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceInfoService } from './../../services/device-info.service';
import { UserService } from './../../services/user.service';
import { TranslationService } from './../../services/translation.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { PasswordRecoveryTranslation } from '../../shared/translation.const';
import { passwordStrengthValidator } from '../../shared/password-strength-validator';
import { RecoveryPassword } from "../../models/user";
import { ErrorService } from './../../services/error.service';


@Component({
    selector: 'recovery-password',
    templateUrl: './recovery.password.component.html',
    styleUrls: ['./recovery.password.component.css'],
})

export class RecoveryPasswordComponent implements OnInit {

private queryUUID: string;

recoveryForm: FormGroup;
password: FormControl;
confirm_password: FormControl;

controlErrors = {
  password: "",
  confirmPassword: ""
}

controlValid = {
  password: {
    notValid: false
  },
  confirmPassword: {
    notValid: false
  }
}

hide: boolean = true;


/* resultAction - Результат операції зміни пароля. 
  0 - запит не відправлено
  1 - запит відправлено і отримано результат seccess
  2 - запит відправлено і отримано error
*/
resultAction: number = 0;

recoveryTranslation = PasswordRecoveryTranslation;

@Output() 
errorEvent = new EventEmitter<string>();


    constructor(private activatedRoute: ActivatedRoute, private deviceInfoService: DeviceInfoService,
          private userService: UserService, private translationService: TranslationService,
          private fBuilder: FormBuilder, private router: Router, private errorService: ErrorService) {

        this.translationService.getTranslate('PAGE_PASS_RECOVERY', this.recoveryTranslation);

        this.activatedRoute.queryParams.subscribe((queryParam: any) => {
              
          this.queryUUID = queryParam['uuid'];
      });
    }


    ngOnInit() {

      this.translationService.languageChanged$.subscribe((changeObj) => {

        this.translationService.getTranslate('PAGE_PASS_RECOVERY', this.recoveryTranslation);
      });

      this.buildForm();
    }


    private buildForm() {

        this.recoveryForm = this.fBuilder.group({
            password: [this.password, [
                Validators.required,
                Validators.minLength(8),
                passwordStrengthValidator
            ]],
            confirm_password: [this.confirm_password, [
                Validators.required,
                Validators.minLength(8),
                passwordStrengthValidator
            ]]
        });
    }
  

    onSubmit() {

      this.errorEvent.emit('');

        let recoveryPassword: RecoveryPassword = {
            "uuid": this.queryUUID,
            "password": this.recoveryForm.get("password").value,
            "device_data": this.deviceInfoService.deviceInfo,
            "language_id": this.translationService.currentLanguage
        };

        this.userService.recoveryPassword(recoveryPassword).subscribe(
            response => { 
              console.log(response);
              this.resultAction = 1;
             },
            error => {
              this.errorEvent.emit(this.errorService.getErrorPTNMessage(error));
              console.log(error);
              this.resultAction = 2;
            }
        );
    }

    
    onChange(ctrl: any) {

      if (!this.recoveryForm) 
        return;
  
      let form = this.recoveryForm;

      for (let field in this.controlErrors) {

        this.controlErrors[field] = "";
        this.controlValid[field].notValid = false;
  
        let control = form.get(field);
  
        if (control && control.dirty && !control.valid) {
          let controlMessage = this.recoveryTranslation[field];
  
          for (let key in control.errors) {
  
            key = key.toLowerCase();
            if (!this.controlErrors[field] && controlMessage.error[key])
              this.controlErrors[field] += controlMessage.error[key] + " ";
          }
  
          this.controlValid[field].notValid = true;
        }
      }
  
      let confirmPassword = form.get("confirm_password");
      let password = form.get("password");
  
      if (password.value && confirmPassword.value && (password.value != confirmPassword.value)) {
  
        this.controlErrors.confirmPassword = this.recoveryTranslation.confirm_password.message;
        this.controlValid.confirmPassword.notValid = true;
      }
    }

    
    onLoginClick() {

      this.router.navigate(['/login/sign-in']);
    }


    onEyeClick() {

        this.hide = !this.hide;
    }

}
