import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './../services/user.service';
import { TranslationService } from './../services/translation.service';
import { PtnAppConfig } from '../shared/ptn-app-config';
import { SearchTranslation } from '../shared/translation.const';
import { langPAGE_HOME } from "./../shared/constant.module";
import { SearchObgect } from "./../models/search.data";
import { WebAddressService } from './../services/web.address.service';
import { TranslateObject } from './../services/translation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorService } from './../services/error.service';
import { Subscription } from 'rxjs';


const searchLimit: number = 20;

@Component({
    selector: 'desctop-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {

    searchResultPC: Array<SearchObgect> = [];
    searchAllResultPC: Array<SearchObgect> = [];

    searchResultFI: Array<SearchObgect> = [];
    searchAllResultFI: Array<SearchObgect> = [];

    searchResultPI: Array<SearchObgect> = [];
    searchAllResultPI: Array<SearchObgect> = [];

    searchResultPE: Array<SearchObgect> = [];
    searchAllResultPE: Array<SearchObgect> = [];

    foundCountPC = '';
    foundCountFI = '';
    foundCountPI = '';
    foundCountPE = '';
    
    searchValue = '';
    searchOffset: number = 0;
    paramLimit: number = undefined;
    paramFragment: string = undefined;
    private subscriptionFragment: Subscription;
    private subscriptionParam: Subscription;

    slogan = {
        slogan: "()"
    }

    sloganVisible: boolean = true;

    allTranslation = SearchTranslation;
    showMoreObject: Array<TranslateObject> = [];
    sendMessageObject: Array<TranslateObject> = [];

    wasSearch: boolean = false;
    private firstSearch: boolean = false;

    private url = PtnAppConfig.url_v2;
    userSubscribeLevel: number = 0;
    private timerID = undefined;

    private personClickX: number = 0;
    private personClickY: number = 0;
    showPersonCard: boolean = false;
    personID: number = 0;
    private personCardBusy: boolean = false;
    private searchPersonCardBusy: boolean = false;

    private searchPhase: number = 0;
    private showMoreTypeEntity: string = 'PC';

    showSendMessage: boolean = false;
    messagePersonID: number = null;
    messageCompanyID: number = null;
    companyForFounder: boolean = false;


    constructor(private userService: UserService, private http: HttpClient, private router: Router,
            private translationService: TranslationService, private webAddressService: WebAddressService, 
            private errorService: ErrorService, private activatedRoute: ActivatedRoute) {

        if (this.userService.user && this.userService.user.subscribe_level)
            this.userSubscribeLevel = this.userService.user.subscribe_level;

        this.initSearchResult();

        if (this.translationService.translationDict && this.translationService.translationDict.length > 0) {

            this.translationService.getTranslate('search_object', this.allTranslation);
            this.translationService.getTranslate(langPAGE_HOME, this.slogan);
            this.translationService.getCategoryObject('SEARCH_OBJECT.SHOW_MORE', this.showMoreObject);
            this.translationService.getCategoryObject('SEARCH_OBJECT.TEXT.BUTTON.MESSAGE', this.sendMessageObject);
        } else {
            this.translationService.init();
        }
    }


    ngOnInit() {

        this.subscriptionParam = this.activatedRoute.queryParams.subscribe((queryParam: any) => {
              
            if (queryParam['search'])
                this.searchValue = queryParam['search'];

            if (queryParam['limit'])
                this.paramLimit = queryParam['limit'];
        });
  
        this.subscriptionFragment = this.activatedRoute.fragment.subscribe(
            (fragment: string) => {
                this.paramFragment = fragment;
            }
        );

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('search_object', this.allTranslation);
            this.translationService.getTranslate(langPAGE_HOME, this.slogan);
            this.translationService.getCategoryObject('SEARCH_OBJECT.SHOW_MORE', this.showMoreObject);
            this.translationService.getCategoryObject('SEARCH_OBJECT.TEXT.BUTTON.MESSAGE', this.sendMessageObject);
        });

        this.userService.Authenticated$.subscribe((isAuthenticated: boolean) => {
            if (isAuthenticated)
                this.userSubscribeLevel = this.userService.user.subscribe_level
            else {
                this.userSubscribeLevel = 0;
            }
        });

        this.initSearchResult();
        this.startSearch();
    }


    private initSearchResult() {

        this.searchResultPC = [];
        this.searchAllResultPC = [];

        this.searchResultFI = [];
        this.searchAllResultFI = [];
    
        this.searchResultPI = [];
        this.searchAllResultPI = [];
    
        this.searchResultPE = [];
        this.searchAllResultPE = [];
    
        this.searchPhase = 0;
        this.wasSearch = false;
    }


    private startSearch() {        

        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        let searchParam: string = '';
        if (this.searchValue && this.searchValue.length > 0)
            searchParam = '&search_string=' + this.searchValue;

        let commandLimit: number = searchLimit;
        if (this.paramLimit && this.paramLimit > 0)
            commandLimit = this.paramLimit;

        this.makeParamString();

        this.http.get<Array<SearchObgect>>(this.url + '/object/search?limit=' + commandLimit.toString() + '&offset=' + this.searchOffset + searchParam, 
                { headers: myHeaders }).subscribe(

                response => {

                    let json = response;

                    if (json) {
                        this.parseSearchResult(json);
                    }

                    if (this.paramLimit && this.paramLimit > 0)
                        this.searchOffset = this.paramLimit - searchLimit;
        
                    this.paramLimit = undefined;
                    if (this.subscriptionParam && !this.subscriptionParam.closed)
                        this.subscriptionParam.unsubscribe();

                    setTimeout(() => {

                        let element = document.getElementById('searchInput');
                        if (element)
                            element.focus();

                        element = document.getElementById(this.paramFragment);
                        if (element) {
                            element.scrollIntoView();
                            this.paramFragment = undefined;
                        }
                
                        if (this.subscriptionFragment && !this.subscriptionFragment.closed)
                            this.subscriptionFragment.unsubscribe();
                    }, 0);

                    this.wasSearch = true;
                },
                error => {
                    this.wasSearch = true;
                    console.log(this.errorService.getErrorPTNMessage(error));
                    console.log(error);
                });
    }


    parseSearchResult(searchResult: Array<SearchObgect>) {

        this.foundCountPC = '';
        this.foundCountFI = '';
        this.foundCountPI = '';
        this.foundCountPE = '';
        
        let userSubsribeLevel: number = 0;
        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;

        for (let i = 0; i < searchResult.length; i++) {

            switch (searchResult[i].type_entity) {

                case "PC": {
                    this.searchAllResultPC.push(searchResult[i]);
                    this.foundCountPC = searchResult[i].type_entity_count.toString() + '/' + searchResult[i].count_all.toString();
                }
                break;

                case "FI": {
                    this.searchAllResultFI.push(searchResult[i]);
                    this.foundCountFI = searchResult[i].type_entity_count.toString() + '/' + searchResult[i].count_all.toString();
                }
                break;

                case "PI": {
                    this.searchAllResultPI.push(searchResult[i]);
                    this.foundCountPI = searchResult[i].type_entity_count.toString() + '/' + searchResult[i].count_all.toString();
                }
                break;

                case "PE": {
                    this.searchAllResultPE.push(searchResult[i]);
                    this.foundCountPE = searchResult[i].type_entity_count.toString() + '/' + searchResult[i].count_all.toString();
                }
                break;
            }
        }

        switch (this.searchPhase) {

            case 0: {
                this.showAll();
            }
            break;

            case 1: {
                this.showMore(this.showMoreTypeEntity);
            }
            break;
        }

        this.searchPhase = 1;
    }


    private showAll() {

        for (let i = this.searchResultPC.length; i < this.searchAllResultPC.length; i++) {

            this.searchResultPC.push(this.searchAllResultPC[i]);
            if (this.searchOffset == 0 && i >= 4 && !this.paramFragment)
                break;
        }

        for (let i = this.searchResultFI.length; i < this.searchAllResultFI.length; i++) {

            this.searchResultFI.push(this.searchAllResultFI[i]);
            if (this.searchOffset == 0 && i >= 4 && !this.paramFragment)
                break;
        }

        for (let i = this.searchResultPI.length; i < this.searchAllResultPI.length; i++) {

            this.searchResultPI.push(this.searchAllResultPI[i]);
            if (this.searchOffset == 0 && i >= 4 && !this.paramFragment)
                break;
        }

        for (let i = this.searchResultPE.length; i < this.searchAllResultPE.length; i++) {

            this.searchResultPE.push(this.searchAllResultPE[i]);
            if (this.searchOffset == 0 && i >= 4 && !this.paramFragment)
                break;
        }
    }


    private showMore(typeEntity: string) {

        let currentCount: number = 0;

        switch (typeEntity) {

            case "PC": {
                currentCount = this.searchResultPC.length;
                for (let i = this.searchResultPC.length; i < this.searchAllResultPC.length; i++) {
        
                    this.searchResultPC.push(this.searchAllResultPC[i]);
                    if (i >= currentCount + searchLimit)
                        break;
                }
            }
            break;

            case "FI": {

                currentCount = this.searchResultFI.length;
                for (let i = this.searchResultFI.length; i < this.searchAllResultFI.length; i++) {
        
                    this.searchResultFI.push(this.searchAllResultFI[i]);
                    if (i >= currentCount + searchLimit)
                        break;
                }
            }
            break;

            case "PI": {
                currentCount = this.searchResultPI.length;
                for (let i = this.searchResultPI.length; i < this.searchAllResultPI.length; i++) {
        
                    this.searchResultPI.push(this.searchAllResultPI[i]);
                    if (i >= currentCount + searchLimit)
                        break;
                }
            }
            break;

            case "PE": {
                currentCount = this.searchResultPE.length;
                for (let i = this.searchResultPE.length; i < this.searchAllResultPE.length; i++) {
        
                    this.searchResultPE.push(this.searchAllResultPE[i]);
                    if (i >= currentCount + searchLimit)
                        break;
                }
            }
            break;
        }
    }

    
    onShowMoreClick(typeEntity: string) {
            
        if (this.neadSubscribeShowMore(this.showMoreObject[0])) {
            this.router.navigate(['/subscribes']);
            return;
        }

        this.showMoreTypeEntity = typeEntity;

        switch (this.showMoreTypeEntity) {

            case "PC": {

                if (this.searchResultPC.length < this.searchAllResultPC.length) {
                    this.showMore(this.showMoreTypeEntity);
                } else {
                    this.searchOffset = this.searchResultPC.length;
                    this.startSearch();
                }
            }
            break;

            case "FI": {

                if (this.searchResultFI.length < this.searchAllResultFI.length) {
                    this.showMore(this.showMoreTypeEntity);
                } else {
                    this.searchOffset = this.searchResultFI.length;
                    this.startSearch();
                }
            }
            break;

            case "PI": {
                if (this.searchResultPI.length < this.searchAllResultPI.length) {
                    this.showMore(this.showMoreTypeEntity);
                } else {
                    this.searchOffset = this.searchResultPI.length;
                    this.startSearch();
                }
            }
            break;

            case "PE": {
                if (this.searchResultPE.length < this.searchAllResultPE.length) {
                    this.showMore(this.showMoreTypeEntity);
                } else {
                    this.searchOffset = this.searchResultPE.length;
                    this.startSearch();
                }
            }
            break;
        }

        this.makeParamString();
/*
        if (this.searchValue && this.searchValue.length > 0) {
            this.router.navigate([], { queryParams: {"search": this.searchValue,
                                    "offset": this.searchOffset} });
        }
*/        
    }


    onSearchInput(value: string) {

        if (this.timerID) {
            clearTimeout(this.timerID);
            this.timerID = undefined;
        }
    
        this.timerID = setTimeout(() => { 

            this.initSearchResult();
            this.searchValue = value;
            this.searchOffset = 0;
            this.startSearch();

        }, 500);
    }
 
    
    onSearchFocus() {
 
        this.sloganVisible = false;
    }


    onPersonClick(event: MouseEvent, personSearch: SearchObgect) {

        if (event) {
            this.personClickX = event.pageX;
            this.personClickY = event.pageY;
        }

        this.personID = personSearch.code_entity;
        this.showPersonCard = true;
    }


    onPersonMouseMove(event: MouseEvent, person: SearchObgect) {

        if (event && !this.showPersonCard) {
            this.personClickX = event.pageX;
            this.personClickY = event.pageY;
        }

        this.searchPersonCardBusy = true;

        if (this.timerID) {
            clearTimeout(this.timerID);
            this.timerID = undefined;
        }

        if (this.personID != person.code_entity) {

            this.searchPersonCardBusy = false;
            this.showPersonCard = false;
        }

        this.timerID = setTimeout(() => { 

            this.personID = person.code_entity;
            this.showPersonCard = true;
        }, 500);
    }


    onPersonMouseOut(event: MouseEvent, person: SearchObgect) {

        if (this.timerID) {
            clearTimeout(this.timerID);
            this.timerID = undefined;
        }

        this.searchPersonCardBusy = false;

        this.timerID = setTimeout(() => { 

            if (!this.searchPersonCardBusy && !this.personCardBusy)
                this.showPersonCard = false;
        }, 2000);
    }


    getPersonCardPosition() {

        if (this.personClickX <= 0 || this.personClickY <= 0)
            return undefined;

        let founderClickX = this.personClickX;
        let founderClickY = this.personClickY;

        let element = document.getElementById('person-card');
        if (element) {

            founderClickY = founderClickY - element.clientHeight / 2;
            if (founderClickY < 0)
                founderClickY = 5;
            founderClickX = founderClickX - element.clientWidth / 2;
            if (founderClickX < 0)
                founderClickX = 5;
        }

        let styles = {
            "top": founderClickY.toString() + 'px',
            "left": founderClickX.toString() + 'px'
        };

        return styles;
    }


    onClosePersonCard(event: boolean) {

        this.showPersonCard = !event;
    }


    onPersonCardBusy(event: boolean) {

        if (this.timerID) {
            clearTimeout(this.timerID);
            this.timerID = undefined;
        }

        this.personCardBusy = event;

        if (!this.personCardBusy) {

            this.timerID = setTimeout(() => { 

                if (!this.searchPersonCardBusy && !this.personCardBusy)
                    this.showPersonCard = false;
            }, 1000);
        }
    }


    getSocialNetImage(socialTypeAddress: string): string {

        return this.webAddressService.getSocialImg(socialTypeAddress);
    }


    neadSubscribeShowMore(showMoreObject: TranslateObject): boolean {

        let userSubsribeLevel: number = 0;
  
        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        if (showMoreObject)
            return (showMoreObject.subscribe_level > userSubsribeLevel)
        else
            return false;
    }


    onClearSearchClick() {

        this.initSearchResult();
        this.searchValue = "";
        this.searchOffset = 0;
        this.startSearch();
    }


    neadMessageSubscribe() {

        if (!this.sendMessageObject || (this.sendMessageObject.length <= 0))
            return false;

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;

         return (this.sendMessageObject[0].subscribe_level > userSubsribeLevel);
    }


    onSendMessageCompanyClick(entity: SearchObgect) {

        if (this.neadMessageSubscribe()) {

            this.router.navigate(['/subscribes']);
        } else {

            this.messagePersonID = null;
            this.messageCompanyID = entity.code_entity;
            this.companyForFounder = false;
            this.showSendMessage = true;
        }
    }


    onSendMessageCompanyFounderClick(entity: SearchObgect) {

        if (this.neadMessageSubscribe()) {

            this.router.navigate(['/subscribes']);
        } else {

            this.messagePersonID = null;
            this.messageCompanyID = entity.code_entity;
            this.companyForFounder = true;
            this.showSendMessage = true;
        }
    }


    onSendMessagePersonClick(entity: SearchObgect) {

        if (this.neadMessageSubscribe()) {

            this.router.navigate(['/subscribes']);
        } else {

            this.messagePersonID = entity.code_entity;
            this.messageCompanyID = null;
            this.showSendMessage = true;
        }
    }    


    onCloseMessage(event: any) {

        this.showSendMessage = false;
    }


    private makeParamString() {

        let parLim: number = 0;
        if (this.paramLimit && this.paramLimit > 0)
            parLim = this.paramLimit
        else
            parLim = this.searchOffset + searchLimit;

        if (this.searchValue && this.searchValue.length > 0) {
            this.router.navigate([], { queryParams: {"search": this.searchValue,
                                    "limit": parLim}, 
                                    fragment: this.paramFragment });
        } else {

            if (this.paramFragment && this.paramFragment.length > 0)
                this.router.navigate([], { fragment: this.paramFragment })
            else
                this.router.navigate([]);
        }
    }


    onEntityClick(obj: SearchObgect, codeFirm?: number) {

        this.paramFragment = obj.type_entity + obj.code_entity.toString();

        this.makeParamString();

        setTimeout(() => { 
            if (obj.type_entity == 'PE')
                this.router.navigate(['./', codeFirm], {relativeTo: this.activatedRoute})
            else
                this.router.navigate(['./', obj.code_entity], {relativeTo: this.activatedRoute});
        }, 10);

    }

}