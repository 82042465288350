// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domain: 'https://dev.privatetechnetwork.com', // 'http://localhost:4200',
  api_domain: 'https://dev-api.privatetechnetwork.com',
  version: '/v1',
  baseHref: '/',
  googleAnalyticsMeasurementId: 'G-K3WTK27HFW',
  version2: '/v2'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
