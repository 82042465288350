import { Injectable } from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';


@Injectable()
export class PlatformService {

    private _handsetPlatform: boolean = false;
    private _handsetOrientation = {
        landscapeOrientation: false
    }

    public get handsetPlatform(): boolean {
        return this._handsetPlatform;
    }

    public get handsetOrientation(): any {
        return this._handsetOrientation;
    }

    constructor(private breakpointObserver: BreakpointObserver) {

        this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((state: BreakpointState) => {
            if (state.matches) {
                this._handsetPlatform = true;
                if (state.breakpoints[Breakpoints.HandsetLandscape]) {
                    this._handsetOrientation.landscapeOrientation = true;
                }
                else {
                    this._handsetOrientation.landscapeOrientation = false;
                }
            } else {
                this._handsetPlatform = false;
                this._handsetOrientation.landscapeOrientation = false;
            }
            //console.log(this.handsetPlatform);
            //console.log(this.handsetOrientation.landscapeOrientation);
        });
    }
}