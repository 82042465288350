import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Industry } from '../models/home.body';
import { SessionStorageService } from './session-storage-service';
import { UserService } from './user.service';

@Injectable()
export class SubscribeLevelGuard implements CanActivate, CanActivateChild {
    
    constructor(private router: Router, private userService: UserService, private sessionStorageService: SessionStorageService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {      
        
        let userSubscribeLevel: number = 0;
        if (this.userService.user && this.userService.user.subscribe_level) {
            userSubscribeLevel = this.userService.user.subscribe_level;
        }
        //console.log(userSubscribeLevel);

        let itemSubscribeLevel: number;
        if (route.params.categoryId || route.params.industryId) {
            //console.log(route.params.categoryId || route.params.industryId);
            if (route.queryParams.l) {
                //console.log(route.queryParams.l);
                itemSubscribeLevel = route.queryParams.l;
            }
        }
        // else {
        //     let industries: Industry[] = this.sessionStorageService.get('listIndustry');
        //     if (industries) {
        //         itemSubscribeLevel = industries.find((val) => val.code_industry == route.params.industryId)?.subscribe_level;
        //     }
        //     //console.log(route.params.industryId);            
        // }

        //console.log(itemSubscribeLevel);
        if (isNaN(itemSubscribeLevel) || (userSubscribeLevel < itemSubscribeLevel)) {
            this.router.navigate(['/subscribes']);
        }
        return true;
    }
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}