<div class="sign-in-form" *ngIf="!platformService.handsetPlatform">
  <form novalidate [formGroup]="signInForm" (ngSubmit)="onSubmit()">      
    <div class="form-group">
      <label>{{translatedCaptions.email.field}}</label>
      <input type="email" class="form-control" formControlName="email" placeholder={{translatedCaptions.email.placeholder}}
              (change)="onChange('email')" [ngClass]="controlValid.email">
      <div *ngIf="controlErrors.email" class="error-message">
        {{ controlErrors.email }}
      </div>
      <div *ngIf="!controlErrors.email">
          &nbsp;
      </div>
    </div>
  
    <div class="form-group">
      <label for="">{{translatedCaptions.password.field}}</label>
      <div class="password-group">
        <input [type]="hide ? 'password' : 'text'" class="password form-control" formControlName="password" placeholder={{translatedCaptions.password.placeholder}}
            (input)="onPasswordChange($event.target.value)" [ngClass]="controlValid.password" #password>
        <img class="eye" *ngIf="hide && password.value" src="/assets/imgs/eye-open.svg" (click)="onEyeClick()">
        <img class="eye" *ngIf="!hide && password.value" src="/assets/imgs/eye-close.svg" (click)="onEyeClick()">
      </div>

      <div *ngIf="controlErrors.password" class="error-message">
        {{ controlErrors.password }}
      </div>
      <div *ngIf="!controlErrors.password">
          &nbsp;
      </div>
    </div>

    <div class="forgot-pass">
      <a [routerLink]="[]" routerLinkActive="active" (click)="onForgotPassClick()">{{translatedCaptions.forgot_password.button}}</a>
    </div>

    <div class="div-button">
      <button [disabled]='signInForm.invalid || trialBegined' type='submit' >{{translatedCaptions.signin.button}}</button>
    </div>
  </form>
</div>


<!-- Mobile -->

<div class="mob-sign-in-form" *ngIf="platformService.handsetPlatform">
  <form novalidate [formGroup]="signInForm" (ngSubmit)="onSubmit()">      
    <div class="mob-form-group">
      <label>{{translatedCaptions.email.field}}</label>
      <input type="email" class="form-control" formControlName="email" placeholder={{translatedCaptions.email.placeholder}}
              (change)="onChange('email')" [ngClass]="controlValid.email">
      <div *ngIf="controlErrors.email" class="error-message">
        {{ controlErrors.email }}
      </div>
      <div *ngIf="!controlErrors.email">
          &nbsp;
      </div>
    </div>

    <div class="mob-form-group">
      <label for="">{{translatedCaptions.password.field}}</label>
      <div class="password-group">
        <input [type]="hide ? 'password' : 'text'" class="password form-control" formControlName="password" placeholder={{translatedCaptions.password.placeholder}}
            (input)="onPasswordChange($event.target.value)" [ngClass]="controlValid.password" #password>
        <img class="mob-eye" *ngIf="hide && password.value" src="/assets/imgs/eye-open.svg" (click)="onEyeClick()">
        <img class="mob-eye" *ngIf="!hide && password.value" src="/assets/imgs/eye-close.svg" (click)="onEyeClick()">
      </div>

      <div class="error-message" *ngIf="controlErrors.password">
        {{ controlErrors.password }}
      </div>
      <div *ngIf="!controlErrors.password">
          &nbsp;
      </div>
    </div>

    <div class="forgot-pass">
      <a [routerLink]="[]" routerLinkActive="active" (click)="onForgotPassClick()">{{translatedCaptions.forgot_password.button}}</a>
    </div>

    <div class="div-button">
      <button [disabled]='signInForm.invalid || trialBegined' type='submit' >{{translatedCaptions.signin.button}}</button>
    </div>
    
  </form>
</div>
