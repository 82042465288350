<div class="verify-email">
    <div class="header">
        {{translation.title.text}}
    </div>

    <div class="body">
        <span class="where">
            {{translation.where.text}}
        </span>

        <span class="done" *ngIf="verifySuccess == true">
            {{translation.done.text}}
        </span>

        <span class="error" *ngIf="verifySuccess == false">
            {{translation.error.text}}
        </span>

        <span *ngIf="verifySuccess == undefined">&nbsp;</span>

        <span class="next">
            {{translation.next.text}}

            <button (click)="onLoginClick()">
                {{translation.signin.button}}
            </button>
        </span>
    </div>
</div>