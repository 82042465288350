import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorPTN } from '../models/error';

@Injectable()
export class ErrorService {

    constructor() {
    }

    getErrorPTNMessage(httpErrorResponse: HttpErrorResponse): string {
        let errorPtn: ErrorPTN;

        if (httpErrorResponse.error) {
            errorPtn = httpErrorResponse.error;
        }
        else {
            errorPtn = {code: null, message: httpErrorResponse.message};
        }
        
        return `${errorPtn.message}`;
    }
}
