import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Industry } from "./../../models/home.body";
import { IndustryService } from "./../../services/industry.service";
import { HomeHeaderService } from './../../services/home.header.service';
import { UserService } from './../../services/user.service';
import { TranslateObject } from './../../services/translation.service';
import { TranslationService } from '../../services/translation.service';
import { SessionStorageService } from '../../services/session-storage-service';
import { RepresentService } from './../../services/represent.service';
import { TranslationPtnIndex } from './../../shared/translation.const';
import { User } from './../../models/user';
import { Subscription } from 'rxjs';
import { PlatformService } from './../../services/platform.service';
import { LocalStorageService } from './../../services/local-storage-service';
import { viewAsPhotoChanged$ } from "./../../header/header.component";
import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector: 'ptn-industry',
    templateUrl: './home.body.component.html',
    styleUrls: ['./home.body.component.css']
})

export class HomeBodyComponent implements OnInit, AfterViewChecked, OnDestroy {

    private widthHomeBody: number;
    private subscriptionSort: Subscription;
    private subscriptionFragment: Subscription;
    private currentIndustryID: number = undefined;

    listIndustry: Array<Industry>;

    sortingTile: Array<TranslateObject> = [];

    selectedSort: string;
    
    tileBigSize: boolean = false

    translationPtnIndex = TranslationPtnIndex;

    viewAsPhoto: boolean = true;


    constructor(private sessionStorage: SessionStorageService, private industryService: IndustryService, 
            private homeHeaderService: HomeHeaderService, private userService: UserService, 
            private translationService: TranslationService, private representService: RepresentService,
            public platformService: PlatformService, private localStorage: LocalStorageService,
            private router: Router, private activatedRoute: ActivatedRoute) { 

        let tmpViewAsPhoto = this.localStorage.get("viewAsPhoto");
        if (tmpViewAsPhoto != undefined) {
            this.viewAsPhoto = tmpViewAsPhoto;
        }
        
        this.subscriptionFragment = this.activatedRoute.fragment.subscribe(
            (fragment: string) => {
                this.currentIndustryID = +fragment;
            }
        );

        viewAsPhotoChanged$.subscribe((view: boolean) => {
        
            this.viewAsPhoto = view;
        });
        
        // Отримуємо розмір плиток для розмітки [ngClass]
        let tmpSize = this.homeHeaderService.loadTileSize('industry');
        if (tmpSize != undefined)
            this.tileBigSize = tmpSize;
    }


    ngOnInit(): void {

        this.subscriptionSort = this.homeHeaderService.sortTileChanged$.subscribe((selectedObject: TranslateObject) => {

            // Перевіряємо, чи з цого списку об'єкт сортування
            if (selectedObject && this.homeHeaderService.isSortObjectExist(selectedObject, this.sortingTile)) {

                this.homeHeaderService.changeIndustrySortID(selectedObject.object_id);

                // Отримуємо з бази перелік industry за новим сортуванням
                this.getIndustries(selectedObject.object_id);
            }
        });

        // Отримуємо перелік сортувань для combobox
        this.takeSortList();

        // Перевіряємо, чи існує збережений список Industry. Якщо немає, завантажуємо з сервера
        this.listIndustry = this.sessionStorage.get("listIndustry");
        if (!this.listIndustry) {

            this.getIndustries(this.industryService.getSortObjectID());
        }

        // Підписуємось на зміну мови
        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.takeSortList();

            this.translationService.getTranslate('PAGE_PTN_INDEX', this.translationPtnIndex);

            if (!this.listIndustry) {

                this.getIndustries(this.industryService.getSortObjectID());
            }
        });

        this.representService.tileBigSizeChanged$.subscribe((bigSize: boolean) => {

            this.tileBigSize = bigSize;
        });

        setTimeout(() => {

            const element = document.getElementById('main');
            if (element) {

                element.focus();
            }
        }, 0);

        this.userService.loginChanged$.subscribe((user: User) => {

            this.takeSortList();
        });


        setTimeout(() => { 
            this.representService.setTileSize$.next(this.tileBigSize);
        }, 50);
    }


    ngAfterViewChecked() {

        if (this.currentIndustryID) {
            let element = document.getElementById("industry" + this.currentIndustryID.toString());
            if (element) {
                element.scrollIntoView();
                this.currentIndustryID = undefined;
            }
        }
    }


    ngOnDestroy() {

        this.subscriptionSort.unsubscribe();
    }


    private getSelectedSort() {

        if (this.sortingTile.length > 0) {
            let industrySortID: number = this.industryService.getSortObjectID();
            if (industrySortID) {
              for (let i = 0; i < this.sortingTile.length; i++) {
                if (this.sortingTile[i].object_id == industrySortID) {

                  this.selectedSort = this.sortingTile[i].translation;
                  break;
                }
              }
            } 

            if (!this.selectedSort) {
                this.selectedSort = this.sortingTile[0].translation;
                this.homeHeaderService.changeIndustrySortID(this.sortingTile[0].object_id);
            }

            this.representService.selectedSort$.next(this.selectedSort);
        }
    }


    getIndustries(sortIndex: number) {

        if (!this.sortingTile || this.sortingTile.length <= 0)
            return;
            
        if ((!sortIndex && this.sortingTile && this.sortingTile.length > 0) || !this.homeHeaderService.isSortIndexExists(this.sortingTile, sortIndex))
            sortIndex = this.sortingTile[0].object_id;

        if (!sortIndex)
            return;

        if (this.subscriptionFragment && !this.subscriptionFragment.closed)
            this.subscriptionFragment.unsubscribe();

        this.industryService.getIndustries(sortIndex).subscribe(
            response => {
                let json = response;
                if (json) {
                    this.listIndustry = json;
                    this.sessionStorage.set('listIndustry', this.listIndustry);
                }
            },
            error => console.log(error));
    }


    neadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        return (subscribeLevel > userSubsribeLevel);
    }
 

    private takeSortList() {

        this.sortingTile = [];
        this.translationService.getObjectBySubscribe('PAGE_HOME.INDUSTRY.SORTING_OPTIONS', this.sortingTile, this.userService.getUserSubscribeLevel());
        if (this.sortingTile && this.sortingTile.length > 0) {

            this.representService.setSortList$.next(this.sortingTile);
            this.getSelectedSort();
        }
    }


    getCanvasWidth() {

        this.widthHomeBody = document.getElementById('main').clientWidth;

        let canvasWidth = this.widthHomeBody;
        let sizeTile: number;

        if (this.tileBigSize)
            sizeTile = 345
        else
            sizeTile = 228;

        canvasWidth = canvasWidth / (sizeTile + 6 * 2);

        if (this.tileBigSize) {
            if (canvasWidth > 4)
                canvasWidth = 4;
        } else {
            if (canvasWidth > 6)
                canvasWidth = 6;
        }

        canvasWidth = Math.floor(canvasWidth) * (sizeTile + 6 * 2);

        let styles = {
            "width": canvasWidth.toString() + 'px'
        };

        return styles;
    }


    getCanvasWidthOld() {

        this.widthHomeBody = document.getElementById('main').clientWidth;

        let canvasWidth = this.widthHomeBody;
        let sizeTile: number;

        if (this.tileBigSize) {
            sizeTile = 225
        } else {
            sizeTile = 130
        }

        canvasWidth = canvasWidth / (sizeTile + 6 * 2);
        canvasWidth = Math.floor(canvasWidth) * (sizeTile + 6 * 2);

        let styles = {
            "width": canvasWidth.toString() + 'px'
        };

        return styles;
    }


    onResize(event) {

        this.widthHomeBody = document.getElementById('main').clientWidth;
    }


    getPtnIndexWidth(industry: Industry) {

        let styles;

        let itemWidth: number = 200;
        let element = document.getElementById('ptn-index');
        if (element) 
            itemWidth = element.clientWidth - 60;

        let width: number = Math.floor((itemWidth * industry.ptn_index_prc) / 100);
    
        styles = {
            "width": width.toString() + 'px'
        };
    
        return styles;
      }
    
    
    getPtnSubindexWidth(industry: Industry) {
    
        let styles;

        let itemWidth: number = 200;
        let element = document.getElementById('sub-index');
        if (element) 
            itemWidth = element.clientWidth - 60;
    
        let width: number = Math.floor((itemWidth * industry.ptn_subindex_prc) / 100);
    
        styles = {
            "width": width.toString() + 'px'
        };
    
        return styles;
    }

    
    getTileSize() {
     
        let canvasWidth = document.querySelector('div.mob-main').clientWidth;
  
        let tileWidth: number = Math.floor((canvasWidth * 44) / 100);
        let tileHeight: number = Math.floor(tileWidth / 1.33);

        let styles = {
            "width": tileWidth.toString() + 'px',
            "height": tileHeight.toString() + 'px'
        };

        return styles;
    }
  
    
    onIndustryClick(industry: Industry) {

        this.router.navigate([], { fragment: industry.code_industry.toString() });

        setTimeout(() => { 
            if (industry.category_count_all > 0) {
                this.router.navigate(['ic', industry.code_industry], {relativeTo: this.activatedRoute});
            }
            else
                if (industry.company_count_all > 0)
                    this.router.navigate(['i', industry.code_industry], {relativeTo: this.activatedRoute});
        }, 0);
    }
}
  