import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';
import { FilterFirm } from '../../../models/filter';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { FilterService } from '../../../services/filter.service';
import { PlatformService } from '../../../services/platform.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ptn-card-view-result',
  templateUrl: './card-view-result.component.html',
  styleUrls: ['./card-view-result.component.scss']
})
export class CardViewResultComponent implements OnInit, AfterViewChecked {

  @Input()
  typeFirm: string;
  
  @Input()
  filterTranslation: any;
  
  @Input()
  filter_firms: Array<FilterFirm>;
  
  private messageSubscribeLevel: number = 0;
  showSendMessage: boolean = false;
  messagePersonID: number = null;
  messageCompanyID: number = null;
  companyForFounder: boolean = false;

  private subscriptionFragment: Subscription;
  private currentCompanyID: number = undefined;


  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, private filterService: FilterService,
          public platformService: PlatformService) {

      this.subscriptionFragment = this.activatedRoute.fragment.subscribe(
        (fragment: string) => {
  
            this.currentCompanyID = +fragment;
        }
      );
  }


  ngOnInit(): void {

    this.messageSubscribeLevel = this.filterService.getMessageSubscribeLavel(this.typeFirm);
  }


  ngAfterViewChecked() {

    if (this.currentCompanyID) {
      let element = document.getElementById("company" + this.currentCompanyID.toString());
      if (element) {

          element.scrollIntoView();
          this.currentCompanyID = undefined;
      }
    }
  }


  onSendMessageClick(firm) {

    if (this.neadMessageSubscribe()) {

      this.router.navigate(['/subscribes']);
    } else {

      if (this.platformService.handsetPlatform) {
        this.router.navigate(['/send-message'], { queryParams: { person_id: null,
                                                                company_id: firm.code_firm,
                                                                for_person: 0 } });
        } else {
          this.messagePersonID = null;
          this.messageCompanyID = firm.code_firm;
          this.companyForFounder = false;
          this.showSendMessage = true;
        }
    }
  }


  onSendMessageFounderClick(firm) {

    if (this.neadMessageSubscribe()) {

      this.router.navigate(['/subscribes']);
    } else {

      if (this.platformService.handsetPlatform) {
        this.router.navigate(['/send-message'], { queryParams: { person_id: null,
                                                                company_id: firm.code_firm,
                                                                for_person: 1 } });
        } else {
          this.messagePersonID = null;
          this.messageCompanyID = firm.code_firm;
          this.companyForFounder = true;
          this.showSendMessage = true;
        }
    }
  }


  neadMessageSubscribe() {

    let userSubsribeLevel = this.userService.user?.subscribe_level || 0;

    return (this.messageSubscribeLevel > userSubsribeLevel);
  }

  onCloseMessage(event: any) {

    this.showSendMessage = false;
  }


  getSendMessagePos() {
    let elementMsg = document.getElementById('send-msg');

    if (elementMsg) {

      let styles = {
            "top": '-100px',
        };

        return styles;        
    }
  }


  onOpenCardClick(codeCompany: number) {

    let par = this.activatedRoute.snapshot.queryParams;
    
    this.router.navigate([], { queryParams: par, fragment: codeCompany.toString() });

    setTimeout(() => { 
        this.router.navigate(['./', codeCompany], {relativeTo: this.activatedRoute});
    }, 10);
  }

}
