import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { DeviceInfo } from '../models/device-info';
import { FilterParams } from '../models/filter';

@Injectable()
export class SessionStorageService {
 
    constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {
    }
 
    has(key: string): boolean {
        return this.storage.has(key);
    }

    get(key: string): any {
        return this.storage.get(key);
    }

    set(key: string, value: any): void {
        this.storage.set(key, value);
    }

    remove(key: string): void {
        this.storage.remove(key);
    }

    clear(): void {
        this.storage.clear();
    }

    public get deviceInfo(): DeviceInfo {
        return this.storage.get('deviceInfo');
    }
 
    public set deviceInfo(value: DeviceInfo) {
        this.storage.set('deviceInfo', value);
    }

    public get filterCompaniesParamsInput(): FilterParams {
        return this.storage.get('filterCompaniesParamsInput');
    }
 
    public set filterCompaniesParamsInput(value: FilterParams) {
        this.storage.set('filterCompaniesParamsInput', value);
    }

    public get filterFundsParamsInput(): FilterParams {
        return this.storage.get('filterFundsParamsInput');
    }
 
    public set filterFundsParamsInput(value: FilterParams) {
        this.storage.set('filterFundsParamsInput', value);
    }

    public get filterInvestorsParamsInput(): FilterParams {
        return this.storage.get('filterInvestorsParamsInput');
    }
 
    public set filterInvestorsParamsInput(value: FilterParams) {
        this.storage.set('filterInvestorsParamsInput', value);
    }

    public get viewFilterResult(): string {
        return this.storage.get('viewFilterResult');
    }
 
    public set viewFilterResult(value: string) {
        this.storage.set('viewFilterResult', value);
    }

    public get isShowFilterMenuM(): boolean {
        return this.storage.get('isShowFilterMenuM') || false;
    }
 
    public set isShowFilterMenuM(value: boolean) {
        this.storage.set('isShowFilterMenuM', value);
    }
}