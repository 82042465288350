<div class="main" *ngIf="!platformService.handsetPlatform">
    <div class="header">
        <img src="/assets/imgs/Icons_Dev__0000s_0051_ptn.png">
        <span>{{aboutTranslation.page.title}}</span>
    </div>
    <div class="sub-header">
        <div>
            <img class="img-bottom" src="assets/imgs/splash-bottom.png">
            <span>{{aboutTranslation.page.bullets.line_1}}</span>
        </div>
        <div>
            <img class="img-bottom" src="assets/imgs/splash-bottom.png">
            <span>{{aboutTranslation.page.bullets.line_2}}</span>
        </div>
        <div>
            <img class="img-bottom" src="assets/imgs/splash-bottom.png">
            <span>{{aboutTranslation.page.bullets.line_3}}</span>
        </div>
    </div>
    <div class="body" [innerHtml]="aboutTranslation.page.text"></div>
</div>

<!-- Mobile -->

<div class="mob-main" *ngIf="platformService.handsetPlatform">

    <div class="mob-header">
        <img src="/assets/imgs/Icons_Dev__0000s_0051_ptn.png">
        <span>{{aboutTranslation.page.title}}</span>
    </div>

    <div class="mob-sub-header">
        <div>
            <img class="img-bottom" src="assets/imgs/splash-bottom.png">
            <span>{{aboutTranslation.page.bullets.line_1}}</span>
        </div>
        <div>
            <img class="img-bottom" src="assets/imgs/splash-bottom.png">
            <span>{{aboutTranslation.page.bullets.line_2}}</span>
        </div>
        <div>
            <img class="img-bottom" src="assets/imgs/splash-bottom.png">
            <span>{{aboutTranslation.page.bullets.line_3}}</span>
        </div>
    </div>

    <div class="mob-body" [innerHtml]="aboutTranslation.page.text"></div>

</div>
