import { Component, OnInit } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { PlatformService } from '../services/platform.service';
import { RouterHistoryService } from '../services/router-history.service';
import { SessionStorageService } from '../services/session-storage-service';
import { TranslateObject, TranslationService } from '../services/translation.service';
import { langPAGE_COMPANIES } from '../shared/constant.module';
import { FilterCompaniesTranslation } from '../shared/translation.const';

@Component({
  selector: 'ptn-filter-companies',
  templateUrl: './filter-companies.component.html',
  styleUrls: ['./filter-companies.component.scss']
})
export class FilterCompaniesComponent implements OnInit {

  filterCompaniesTranslation = FilterCompaniesTranslation;
  sortingCompaniesList: Array<TranslateObject> = [];
  
  constructor(private translationService: TranslationService, private sessionStorageService: SessionStorageService, private filterService: FilterService, public platformService: PlatformService, private routerHistoryService: RouterHistoryService) {
    this.translationService.getTranslate(langPAGE_COMPANIES, this.filterCompaniesTranslation);
    this.translationService.getCategoryObject('PAGE_COMPANIES.SORTING_OPTIONS', this.sortingCompaniesList);
    
    this.translationService.languageChanged$.subscribe((changeObj) => {
      this.translationService.getTranslate(langPAGE_COMPANIES, this.filterCompaniesTranslation);
      this.filterCompaniesTranslation = JSON.parse(JSON.stringify(this.filterCompaniesTranslation)); //to fire ngOnChanges for FilterComponent

      this.sortingCompaniesList = [];
      this.translationService.getCategoryObject('PAGE_COMPANIES.SORTING_OPTIONS', this.sortingCompaniesList);
    });

    // if (this.sessionStorageService.filterCompaniesParamsInput) {
    //   this.filterService.filterParamsInput = this.sessionStorageService.filterCompaniesParamsInput;
    // }

    console.log(this.filterCompaniesTranslation);
    console.log(this.sortingCompaniesList);
  }

  ngOnInit(): void {
    if (this.sessionStorageService.isShowFilterMenuM === true) {
      let prevUrl = this.routerHistoryService.previousUrl$.value || '/';
      if (prevUrl.indexOf('filter-companies') == -1) {
        this.sessionStorageService.isShowFilterMenuM = false;
      }
    }
  }
}
