<div class="ptn-index" *ngIf="!platformService.handsetPlatform">
    <div class="main">
        <div class="header">
            <div class="header-left">{{translationPtnIndex.title.title}}</div>
            <div class="header-right">{{translationPtnIndex.title.text}}</div>
        </div>

        <div class="space">
        </div>

        <div class="body-header">
            <a id="one" [routerLink]="[]" routerLinkActive="active" (click)="onSortClick(0)">{{translationPtnIndex.sorting_options.sort_by_name}}
                <img *ngIf="selectedIndex == 0" src="/assets/imgs/sort-down.svg">
            </a>
            <a id="two" [routerLink]="[]" routerLinkActive="active" (click)="onSortClick(1)">{{translationPtnIndex.sorting_options.sort_by_ptnindex}}
                <img *ngIf="selectedIndex == 1" src="/assets/imgs/sort-down.svg">
            </a>
            <a id="three" [routerLink]="[]" routerLinkActive="active" (click)="onSortClick(2)">{{translationPtnIndex.sorting_options.sort_by_subindex}}
                <img *ngIf="selectedIndex == 2" src="/assets/imgs/sort-down.svg">
            </a>
        </div>

        <div class="body">
            <div class="rows" *ngFor="let indstr of listIndustry; index as i">
                <div class="one-row">
                    <div class="first-col">
                        <a [routerLink]="['/home', indstr.category_count_all > 0 ? 'ic' : 'i', indstr.code_industry]" title={{indstr.name_industry}}
                                [ngClass]="{ 'industryDisabled': indstr.company_count_all <= 0 && indstr.category_count_all <= 0 }">

                                <img class="industry-img image-back" src="{{indstr.image_over_url}}">
                                <img class="industry-img image-front" src="{{indstr.image_url}}">
                            <span>{{indstr.name_industry}}</span>
                            <img *ngIf="neadSubscribe(indstr.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                        </a>
                    </div>
                </div>                

                <div class="graph index">
                    <span class="div_label index-label">{{indstr.ptn_index}}</span>
                    <div class="graph-container">
                        <div class="graph-bottom" #graphbottom>
                            <div [ngStyle]="getPtnIndexWidth(indstr)" class="graph-top"></div>
                        </div>
                    </div>
                </div>
                
                <div class="graph subindex">
                    <span class="div_label subindex-label">{{indstr.ptn_subindex}}</span>
                    <div class="graph-container">
                        <div class="graph-bottom">
                            <div [ngStyle]="getPtnSubindexWidth(indstr)" class="graph-top"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Mobile -->

<div class="mob-ptn-index" *ngIf="platformService.handsetPlatform">

    <div class="mob-header">
        <a [routerLink]="[]" routerLinkActive="active" (click)="onSortClick(0)">{{translationPtnIndex.sorting_options.sort_by_name}}
            <img *ngIf="selectedIndex == 0" src="/assets/imgs/sort-down.svg">
        </a>
        <a [routerLink]="[]" routerLinkActive="active" (click)="onSortClick(1)">{{translationPtnIndex.sorting_options.sort_by_ptnindex}}
            <img *ngIf="selectedIndex == 1" src="/assets/imgs/sort-down.svg">
        </a>
        <a [routerLink]="[]" routerLinkActive="active" (click)="onSortClick(2)">{{translationPtnIndex.sorting_options.sort_by_subindex}}
            <img *ngIf="selectedIndex == 2" src="/assets/imgs/sort-down.svg">
        </a>
    </div>

    <div class="mob-body">
        <div class="mob-card" *ngFor="let indstry of listIndustry; index as i">

            <div class="mob-row">
                <a [routerLink]="['/home', indstry.category_count_all > 0 ? 'ic' : 'i', indstry.code_industry]" title={{indstry.name_industry}}
                        [ngClass]="{ 'industryDisabled': indstry.company_count_all <= 0 && indstry.category_count_all <= 0 }">

                    <img class="mob-industry-img" src="{{indstry.image_url}}">
                    <span>{{indstry.name_industry}}</span>
                    <img *ngIf="neadSubscribe(indstry.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                </a>
            </div>

            <div class="mob-row-graph">
                <div class="mob-row-graph-txt">
                    <span class="mob-row-title">{{translationPtnIndex.data.ptnindex}}</span>
                    <span>{{indstry.ptn_index}}</span>
                </div>

                <div class="index">
                    <div class="mob-graph-container">
                        <div class="graph-bottom" #graphbottom>
                            <div [ngStyle]="getPtnIndexWidth(indstry)" class="graph-top"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mob-row-graph">
                <div class="mob-row-graph-txt">
                    <span class="mob-row-title">{{translationPtnIndex.data.subindex}}</span>
                    <span>{{indstry.ptn_subindex}}</span>
                </div>

                <div class="subindex">
                    <div class="mob-graph-container">
                        <div class="graph-bottom">
                            <div [ngStyle]="getPtnSubindexWidth(indstry)" class="graph-top"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
