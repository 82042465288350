import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { TranslationService, TranslateObject } from '../services/translation.service';
import { langMENU_MAIN, langMenuService } from "../shared/constant.module";
import { HeaderTranslation, MenuServiceTranslation, AnalysisTranslation, FooterTranslation } from '../shared/translation.const';
import { Location } from '@angular/common';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Subject } from 'rxjs';
import { PlatformService } from '../services/platform.service';
import { SearchTranslation } from '../shared/translation.const';
import { RouterHistoryService } from './../services/router-history.service';
import { User } from '../models/user';
import { VersionService } from './../services/version.service';
import { HomeTranslation } from "./../shared/translation.const";
import { langPAGE_HOME } from "./../shared/constant.module";


export var searchTextInput$ = new Subject<string>();
export var showSearch$ = new Subject<boolean>();
export var searchTextParams$ = new Subject<string>();

@Component({
    selector: 'ptn-header-m',
    templateUrl: './m-header.component.html',
    styleUrls: ['./m-header.component.css'],
})

export class HeaderComponentM implements OnInit {

    showMenu: boolean = false;
    showServiceMenu: boolean = false;
    menuTurn: boolean = false;

    isAuthenticated: boolean;
    account = {
        accountNotVerified: false
    }

    // Caption для пунктів меню
    mnuCaptions = HeaderTranslation;
    mnSrvTranslation = MenuServiceTranslation;
    analisysTranslation = AnalysisTranslation;
    searchTranslation = SearchTranslation;
    footerTranslation = FooterTranslation;
    homeTranslation = HomeTranslation;

    translateSocialNetworks: Array<TranslateObject> = [];

    startMoveSearch: boolean = false;
    searchValue: string = "";

    trialSubscribe: string = "";

    private timerSearchID = undefined;
    private previousUrl: string;

    private setStaticLabelsBreadCrumbs(): void {
        this.breadcrumbService.set('@home', this.mnuCaptions.home);
        this.breadcrumbService.set('@deals', this.mnuCaptions.deals);
        this.breadcrumbService.set('@subscribe', this.mnSrvTranslation.subscribe);
        this.breadcrumbService.set('@list', this.mnuCaptions.list);
        this.breadcrumbService.set('@ptn-index', this.mnuCaptions.ptn_index);
        this.breadcrumbService.set('@search', this.mnuCaptions.search);
        this.breadcrumbService.set('@analysis', this.mnuCaptions.analysis);
        this.breadcrumbService.set('@top-industry', this.analisysTranslation.analysis.top_in_industry.title);
        this.breadcrumbService.set('@sector-map', this.analisysTranslation.analysis.sector_map.title);
        this.breadcrumbService.set('@bubble-map', this.analisysTranslation.analysis.bubble_map.title);
        this.breadcrumbService.set('@filter-companies', this.mnuCaptions.companies);
        this.breadcrumbService.set('@filter-funds', this.mnuCaptions.vc_funds);
        this.breadcrumbService.set('@filter-private-investors', this.mnuCaptions.privat_investors);
        this.breadcrumbService.set('@sign-in', this.mnSrvTranslation.login);
        this.breadcrumbService.set('@register', this.mnSrvTranslation.register);
        this.breadcrumbService.set('@private-policy', this.mnSrvTranslation.privacy);
        this.breadcrumbService.set('@term-of-use', this.mnSrvTranslation.terms_of_use);
        this.breadcrumbService.set('@data-collect', this.mnSrvTranslation.data_collection);
        this.breadcrumbService.set('@about', this.mnSrvTranslation.about);
    }

  constructor(private router: Router, public userService: UserService, private translationService: TranslationService,
            private location: Location, private breadcrumbService: BreadcrumbService, public platformService: PlatformService, 
            private routerHistoryService: RouterHistoryService, private versionService: VersionService) {

        this.translationService.getTranslate(langMENU_MAIN, this.mnuCaptions);
        this.translationService.getTranslate(langMenuService, this.mnSrvTranslation);
        this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);
        this.translationService.getTranslate('search_object', this.searchTranslation);
        this.translationService.getTranslate("FOOTER", this.footerTranslation);
        this.footerTranslation.copyright.text = this.footerTranslation.copyright.text.replace("<<YEAR>>", (new Date()).getFullYear().toString());

        this.setStaticLabelsBreadCrumbs();

        this.isAuthenticated = this.userService.isAuthenticated;
        if (this.userService.user)
            this.account.accountNotVerified = this.userService.user.account_identifier_is_verified == '0';

        this.trialSubscribe = this.userService.checkSubscribeDate();

        this.translationService.getTranslate(langPAGE_HOME, this.homeTranslation);
        this.translationService.getCategoryObject('PAGE_HOME.SOCIAL_NETWORKS', this.translateSocialNetworks);
    }


    ngOnInit(): void { 

        this.previousUrl = this.routerHistoryService.previousUrl$.value || '/';

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate(langMENU_MAIN, this.mnuCaptions);
            this.translationService.getTranslate(langMenuService, this.mnSrvTranslation);
            this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);
            this.translationService.getTranslate('search_object', this.searchTranslation);
            this.translationService.getTranslate(langPAGE_HOME, this.homeTranslation);

            this.translationService.getTranslate("FOOTER", this.footerTranslation);
            this.footerTranslation.copyright.text = this.footerTranslation.copyright.text.replace("<<YEAR>>", (new Date()).getFullYear().toString());

            this.translationService.getCategoryObject('PAGE_HOME.SOCIAL_NETWORKS', this.translateSocialNetworks);

            this.setStaticLabelsBreadCrumbs();
        });

        this.userService.Authenticated$.subscribe((authenticated) => {

            this.isAuthenticated = authenticated;
            if (!authenticated)
                this.account.accountNotVerified = false
            else
                this.account.accountNotVerified = this.userService.user.account_identifier_is_verified == '0';

            this.trialSubscribe = this.userService.checkSubscribeDate();
        });

        showSearch$.subscribe((action) => {

            setTimeout(() => { 
                this.startMoveSearch = true;
            }, 100);
        });

        searchTextParams$.subscribe((value: string) => {

            setTimeout(() => { 
                this.searchValue = value;
            }, 100);
        });

        this.userService.loginChanged$.subscribe((user: User) => {

            this.trialSubscribe = "";
    
            if (user) {
                this.trialSubscribe = this.userService.checkSubscribeDate();
            }
        });
    
    }


    onRegister(): void {    

        this.router.navigate(['/login/register']);
    }


    onSignIn(): void {

        this.router.navigate(['/login/sign-in']);
    }


    onSignOut(): void { 
        if (this.userService.isAuthenticated) {
        this.userService.signOut().subscribe((changeObj) => {
            this.userService.loginChanged$.next(null);
        });
        }
    }


    onSubscribe(): void {
        this.router.navigate(['/subscribes']);
    }


    onChangeLanguage(value) {
        this.translationService.getDictionary(value);
    }


    getLocation(): string {

        if (this.router.parseUrl(this.router.url).root.children.primary) {
            if (this.router.parseUrl(this.router.url).root.children.primary.segments.length > 0) {
                return '/' + this.router.parseUrl(this.router.url).root.children.primary.segments[0].path;
            }
        } else {
            return this.location.path();
        }
    }


    searchPageLocate(): boolean {

        let partLen: number = 0;
        let partName: string = "";

        if (this.router.parseUrl(this.router.url).root.children.primary) {
            partLen = this.router.parseUrl(this.router.url).root.children.primary.segments.length;
            if (partLen > 0) {
                partName = this.router.parseUrl(this.router.url).root.children.primary.segments[0].path;
            }
        }
        
        return partName == "search" && partLen == 1;
    }


    onAbout() {
        this.router.navigate(['/about']);
    }  


    onDataCollection() {
        this.router.navigate(['/data-collect']);
    }


    onPtnIndexClick() {
        this.router.navigate(['/ptn-index']);
    }


    onListClick() {
        this.router.navigate(['/list']);
    }


    onTrendsClick() {
        this.router.navigate(['/']);
    }


    onAnalysisClick() {
        this.router.navigate(['/analysis']);
    }


    onDealsClick() {
        this.router.navigate(['/deals']);
    }


    onPrivateInvClick() {
        this.router.navigate(['/filter-private-investors']);
    }


    onFundsClick() {
        this.router.navigate(['/filter-funds']);
    }


    onCompaniesClick() {
        this.router.navigate(['/filter-companies']);
    }


    onHomeClick() {
        this.router.navigate(['/home']);
    }


    onTermOfUse() {
        this.router.navigate(['/term-of-use']);
    }

    
    onPrivacy() {
        this.router.navigate(['/private-policy']);
    }


    onSyntheticVC() {

        window.location.href = 'https://www.syntheticvc.com/';
    }


    onSearchInput(value: string) {

        if (this.timerSearchID) {
            clearTimeout(this.timerSearchID);
            this.timerSearchID = undefined;
        }

        this.timerSearchID = setTimeout(() => { 
            searchTextInput$.next(value);
        }, 600);
    }


    onCloseSearchClick() {

        this.previousUrl = this.routerHistoryService.previousUrl$.value || '/';

        let pathTarget: string;
        let segmentCount = this.router.parseUrl(this.router.url).root.children.primary.segments.length;
        if (segmentCount == 1 && this.router.parseUrl(this.router.url).root.children.primary.segments[0].path == 'search' && this.previousUrl == '/search') {
            pathTarget = window.location.origin;
        } else {
            pathTarget = window.location.origin + this.previousUrl;
        }

        this.startMoveSearch = false;

        setTimeout(() => { 
            window.location.href = pathTarget;
        }, 300);
           
    }


    searchVisible(): boolean {

        let locat: string = this.getLocation();
        
        return (locat != '/search') && (locat != '/login');
    }


    onMenuClick() {

        if (!this.menuTurn) {

            this.menuTurn = true;

            setTimeout(() => { 

                this.showMenu = !this.showMenu;
                if (!this.showMenu)
                    this.showServiceMenu = false;
            }, 50);
        } else {

            this.showMenu = !this.showMenu;
            if (!this.showMenu)
                this.showServiceMenu = false;

            setTimeout(() => { 
                this.menuTurn = false;
            }, 550);
        }
    }


    private someMenuSelected() {

        this.showMenu = false;
        this.showServiceMenu = false;
        setTimeout(() => { 
            this.menuTurn = false;
        }, 550);
    }


    onHomeMenuClick() {
        this.someMenuSelected();
        this.router.navigate(['/home']);
    }


    onCompaniesMenuClick() {
        this.someMenuSelected();
        this.router.navigate(['/filter-companies']);
    }


    onFundsMenuClick() {
        this.someMenuSelected();
        this.router.navigate(['/filter-funds']);
    }


    onInvestorsMenuClick() {
        this.someMenuSelected();
        this.router.navigate(['/filter-private-investors']);
    }


    onDealsMenuClick() {
        this.someMenuSelected();
        this.router.navigate(['/deals']);
    }


    onAnalysisMenuClick() {
        this.someMenuSelected();
        this.router.navigate(['/analysis']);
    }


    onListMenuClick() {
        this.someMenuSelected();
        this.router.navigate(['/list']);
    }


    onPtnIndexMenuClick() {
        this.someMenuSelected();
        this.router.navigate(['/ptn-index']);
    }


    onServiceMenuClick() {

        this.versionService.getPaymentVersion().subscribe(
            response => {
                let json = response;
            },
            error => console.log(error)
        );

        this.versionService.getAuthenticateVersion().subscribe(
            response => {
                let json = response;
            },
            error => console.log(error)
        );
      
        this.showServiceMenu = true;
    }


    onSignInClick() {
        this.someMenuSelected();
        this.router.navigate(['/login/sign-in']);
    }


    onAboutClick() {
        this.someMenuSelected();
        this.router.navigate(['/about']);
    }


    onDataCollectClick() {
        this.someMenuSelected();
        this.router.navigate(['/data-collect']);
    }


    onTermOfUseClick() {
        this.someMenuSelected();
        this.router.navigate(['/term-of-use']);
    }


    onPrivatePolicyClick() {
        this.someMenuSelected();
        this.router.navigate(['/private-policy']);
    }


    onSubscribesClick() {
        this.someMenuSelected();
        this.router.navigate(['/subscribes']);
    }


    onRegisterClick() {
        this.someMenuSelected();
        this.router.navigate(['/login/register']);
    }


    onLogoClick() {
        this.someMenuSelected();
        this.router.navigate(['/']);
    }

    onSearchClick() {

        this.router.navigate(['/search']);
    }


    socNeadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel = this.userService.user?.subscribe_level || 0;
        return (userSubsribeLevel >= subscribeLevel);
    }

    
    getSocialNetImage(socNet: TranslateObject): string {

        let addr: string = "";
        switch(socNet.object_name) {
    
          case 'PAGE_HOME.SOCIAL_NETWORKS.LINKEDIN': {
            addr = "/assets/imgs/SOCIAL PTN-11 LinkedIn Blue.svg";
          }
          break;
    
          case 'PAGE_HOME.SOCIAL_NETWORKS.FACEBOOK': {
            addr = "/assets/imgs/SOCIAL PTN-13 FaceBook Blue.svg";
          }
          break;
    
          case 'PAGE_HOME.SOCIAL_NETWORKS.TWITTER': {
            addr = "/assets/imgs/SOCIAL PTN-15 Twitter Blue.svg";
          }
          break;
    
          case 'PAGE_HOME.SOCIAL_NETWORKS.WIKIPEDIA': {
            addr = "/assets/imgs/SOCIAL PTN-17 Wikipedia Blue.svg";
          }
          break;
    
          case 'PAGE_HOME.SOCIAL_NETWORKS.INSTAGRAM': {
            addr = "/assets/imgs/SOCIAL PTN-19 Instagram Blue.svg";
          }
          break;
        }
    
        return addr;
    }
    
}
