import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFilterIntervalParam } from '../../../models/filter';

@Component({
  selector: 'ptn-interval-criterion',
  templateUrl: './interval-criterion.component.html',
  styleUrls: ['./interval-criterion.component.scss']
})
export class IntervalCriterionComponent implements OnInit {

  @Input()
  title: string;
  
  @Input()
  param: IFilterIntervalParam;

  @Input()
  showDivider: boolean = true;
  
  @Output() change = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  onChange(event?: any): void {
    this.change.emit(event);
  }
}
