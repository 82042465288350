<div class="main" *ngIf="!platformService.handsetPlatform">
    <div class="vellum"></div>
    <div class="water-mark">
        <div class="body">
            <div class="header">{{captionTitle}}</div>
            <span>{{captionText}}</span>
        </div>
        <div class="buttons">
            <button (click)="onSubscribeClick()">
                {{captionButton}}
            </button>
        </div>
    </div>
</div>


<!-- Mobile -->

<div class="main" *ngIf="platformService.handsetPlatform">
    <div class="vellum"></div>
    <div class="mob-water-mark">
        <div class="mob-body">
            <div class="mob-header">{{captionTitle}}</div>
            <span class="body-text">{{captionText}}</span>
        </div>
        <div class="mob-buttons">
            <button (click)="onSubscribeClick()">
                {{captionButton}}
            </button>
        </div>
    </div>
</div>
