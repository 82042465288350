<ptn-splash [ngClass]="{ hideSplash: splashCompleted }" (splashCompleted)="onSplashCompleted($event)"
        *ngIf="!splashCompleted && !platformService.handsetPlatform">
</ptn-splash>
<ptn-splash-m [ngClass]="{ hideSplash: splashCompleted }" (splashCompleted)="onSplashCompleted($event)"
        *ngIf="!splashCompleted && platformService.handsetPlatform">
</ptn-splash-m>

<ptn-header *ngIf="splashCompleted && !platformService.handsetPlatform"></ptn-header>
<ptn-header-m *ngIf="splashCompleted && platformService.handsetPlatform"></ptn-header-m>

<div class="breadcrumb-header">
    <div class="breadcrumb-header-inner">
        <xng-breadcrumb [autoGenerate]="false" [ngClass]="{ 'breadcrumb-handset': platformService.handsetPlatform }"></xng-breadcrumb>
        <ptn-represent *ngIf="showRepresent"></ptn-represent>
    </div>
</div>

<router-outlet *ngIf="splashCompleted"></router-outlet>

<ptn-footer *ngIf="!platformService.handsetPlatform"></ptn-footer>
<div class='loading' *ngIf='loading === true'>
    <mat-spinner></mat-spinner>
</div>
