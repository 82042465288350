import { Component, OnInit } from '@angular/core';
import { Industry } from "./../../models/home.body";
import { IndustryService } from "./../../services/industry.service";
import { UserService } from "./../../services/user.service";
import { IndustryRounds } from "./../../models/home.body";
import { IndustryDealsTranslation } from "./../../shared/translation.const";
import { TranslationService } from "./../../services/translation.service";
import { langPAGE_DEALS } from "./../../shared/constant.module";
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateObject } from './../../services/translation.service';

@Component({
    selector: 'desctop-deals',
    templateUrl: './industry.deals.component.html',
    styleUrls: ['./industry.deals.component.css']
})
  
export class IndustryDealsComponent implements OnInit {

    listIndustry: Array<Industry> = [];
    industryRounds: Array<IndustryRounds> = [];
    dealsTranslation = IndustryDealsTranslation;
    activatedIndustryID: number = undefined;

    private maxValueRound: number = 0;
    private maxPostValuation: number = null;
    private industrySort: Array<TranslateObject> = [];


    constructor(private industryService: IndustryService, private userService: UserService, 
            private translationService: TranslationService, private router: Router, private activatedRoute: ActivatedRoute) {

        this.activatedRoute.fragment.subscribe(
            (fragment: string) => {
                this.activatedIndustryID = +fragment;
            }
        );

        // Переклади
        this.translationService.getTranslate(langPAGE_DEALS, this.dealsTranslation);

        this.industrySort = [];

        if (this.translationService.translationDict && this.translationService.translationDict.length > 0) {
            this.translationService.getCategoryObject('PAGE_HOME.INDUSTRY.SORTING_OPTIONS.SORT_BY_NAME', this.industrySort);
        } else {
            this.translationService.init();
        }

        this.getIndustries();
    }


    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate(langPAGE_DEALS, this.dealsTranslation);

            this.industrySort = [];
            this.translationService.getCategoryObject('PAGE_HOME.INDUSTRY.SORTING_OPTIONS.SORT_BY_NAME', this.industrySort);

            this.listIndustry = [];
            this.getIndustries();
        });
    }


    private getIndustries() {
    
        if (!this.industrySort || this.industrySort.length <= 0)
            return;

        this.industryService.getIndustries(this.industrySort[0].object_id).subscribe(
            response => {
                let json = response;
                if (json) {
                    this.listIndustry = json;
                    if (this.listIndustry.length > 0) {

                        if (!this.activatedIndustryID) {

                            for (let i = 0; i < this.listIndustry.length; i++) {
                                if (this.listIndustry[i].is_exists_rounds) {
                                    this.activatedIndustryID = this.listIndustry[i].code_industry;
                                    break;
                                }
                            }
                        }
            
                        this.showRounds();
                    }
                }
            },
            error => console.log(error));
    }


    getRounds(codeIndustry: number) {

        this.industryRounds = [];
        this.maxValueRound = 0;
        this.maxPostValuation = 0;
    
        this.industryService.getRounds(codeIndustry).subscribe(
            response => {
                let json = response;
                if (json) {
                    this.industryRounds = json;

                    for (let i = 0; i < this.industryRounds.length; i++) {
                        this.industryRounds[i].value_round = this.industryRounds[i].value_round / 1000000;
                        this.industryRounds[i].sum_value_round = this.industryRounds[i].sum_value_round / 1000000;
                        if (this.industryRounds[i].post_valuation)
                            this.industryRounds[i].post_valuation = this.industryRounds[i].post_valuation / 1000000;
                        this.industryRounds[i].date_round = this.industryRounds[i].date_round * 1000;

                        if (this.industryRounds[i].value_round > this.maxValueRound)
                            this.maxValueRound = this.industryRounds[i].value_round;
                        if (this.industryRounds[i].post_valuation && this.industryRounds[i].post_valuation > this.maxPostValuation)
                            this.maxPostValuation = this.industryRounds[i].post_valuation;
                    }
                }
            },
            error => console.log(error));
    }


    showRounds() {

        let firstIndex: number = -1;
        let completed: boolean = false;

        if (this.activatedIndustryID > 0) {
            this.getRounds(this.activatedIndustryID);
        } else {
            for (let i = 0; i < this.listIndustry.length; i++)
                if (this.listIndustry[i].is_exists_rounds > 0) {

                    firstIndex = i;
                    if (!this.neadSubscribe(this.listIndustry[i].subscribe_level)) {
                        this.getRounds(this.listIndustry[i].code_industry);
                        completed = true;
                        break;
                    }
                }

            if (!completed && firstIndex >= 0) {
                this.getRounds(this.listIndustry[firstIndex].code_industry);
            }
        }
    }


    onIndustryClick(industry: Industry) {

        this.router.navigate(['deals'], { fragment: industry.code_industry.toString() });

        if (this.neadSubscribe(industry.subscribe_level))
            this.router.navigate(['/subscribes'])
        else {
            this.activatedIndustryID = industry.code_industry;

            this.getRounds(industry.code_industry);
        }
    }


    neadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;

        return (subscribeLevel > userSubsribeLevel);
    }


    getSumRoundWidth(round: IndustryRounds) {

        if (!round)
            return undefined;

//        let width: number = Math.round(((round.value_round * 100 / round.sum_value_round) * 120) / 100);
        let width: number = (((round.value_round * 100) / this.maxValueRound) * 140) / 100;

        let styles = {
            "width": width.toString() + 'px'
        };

        return styles;
    }


    getPostValuationWidth(round: IndustryRounds) {

        let width: number = 0;

        if (round && round.post_valuation) {

            width = (((round.post_valuation * 100) / this.maxPostValuation) * 140) / 100;
        }

        let styles = {
            "width": width.toString() + 'px'
        };

        return styles;        
    }


    isVisorVisible() {

        let userSubsribeLevel = this.userService.user?.subscribe_level || 0;
        let res: boolean = false;

        if (this.industryRounds) {
            for (let i = 0; i < this.industryRounds.length; i++) {
                if (userSubsribeLevel < this.industryRounds[i].firm_subscribe_level) {
                    res = true;
                    break;
                }
            }
        }

        return res;

/*
        let res = this.userService != undefined && this.userService.user != undefined;

        if (res) {

            if (this.industryRounds) {

                for (let i = 0; i < this.industryRounds.length; i++) {

                    if (this.userService.user.subscribe_level < this.industryRounds[i].firm_subscribe_level) {
              
                        return true;
                    }
                }
            } else
                return false;
        } else
            return true;
*/            
    }

}
