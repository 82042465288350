import { Component, OnInit } from '@angular/core';
import { TermsOsUseTranslation } from '../shared/translation.const';
import { TranslationService } from './../services/translation.service';
import { PlatformService } from './../services/platform.service';


@Component({
    selector: 'terms-of-use',
    templateUrl: './terms.of.use.component.html',
    styleUrls: ['./terms.of.use.component.css']
})
  
export class TermsOfUseComponent implements OnInit {

    termsUseTranslation = TermsOsUseTranslation;


    constructor(private translationService: TranslationService, public platformService: PlatformService) {

        this.translationService.getTranslate('PAGE_TERMS', this.termsUseTranslation);
    }


    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('PAGE_TERMS', this.termsUseTranslation);
        });
    }
}  