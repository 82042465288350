<div class="company" *ngIf="!platformService.handsetPlatform" [ngClass]="{ sendMessage: showSendMessage, personCard: showPersonCard }">
    <div class="main">
        <div class="header">
            <div class="header-left">
                <div class="header-img">
                    <img src={{company.image_url}}>
                    <div class="name-company">{{company.name_firm}}</div>
                </div>
                <div class="header-buttons">
                    <a class="send-message-button" *ngIf="company.is_send_message == 1" [routerLink]="[]" routerLinkActive="active"
                            (click)="onSendMessageCompanyClick()">
                        <span>{{companyTranslation.firm.card.button.message}}</span>
                        <img src="/assets/imgs/messagebubble_2.png" title="Send message">
                        <img *ngIf="neadMessageCompanySubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                    <div>
                        <a class="presentation" *ngIf="company.presentation_url" [routerLink]="[]" routerLinkActive="active"
                                (click)="onPresentationClick(company.code_firm)">
                            {{companyTranslation.firm.card.button.view_presentation}}
                            <img *ngIf="neadPresentationSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                        </a>

                        <a class="deals" *ngIf="company.is_exists_rounds == 1" [routerLink]="['deals', company.code_firm]">
                            {{companyTranslation.firm.card.button.deals}}
                            <img *ngIf="neadDealsSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                        </a>
                    </div>
                </div>
            </div>
            <div class="header-text">
                <div class="heder-line">
                    <img src="/assets/imgs/focus.svg">
                    {{company.focus}}
                </div>
                <div class="heder-line">
                    <a *ngIf="company.websiteToShow" href="{{company.website}}" target="_blank">
                        <img class="www1" src="/assets/imgs/www.svg">
                        <span>{{company.websiteToShow}}</span>
                        <img class="www2" src="/assets/imgs/www2.svg">
                    </a>
                    
                    <div class="info-item" *ngIf="company.socialNetworks && company.socialNetworks.length > 0">
                        <div class="div-list">
                            <div class="subscribe comma-img" *ngFor="let social of company.socialNetworks; let lastItem = last;">
                                <a href={{social.address}} target="_blank">
                                    <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                    <img *ngIf="neadSubscribe(social.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                                </a>
                            </div>
                        </div>           
                    </div>           

                </div>
            </div>
        </div>

        <div class="company-card">
            <div class="one-row">
<!-- general -->                
                <div class="tile general" *ngIf="visibleGeneral()">
                    <div class="tile-body">
                        <span class="tile-title">{{companyTranslation.firm.card.anchor.general}}</span>

                        <div class="info-item" *ngIf="company.founded_year">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.founded_year}}</div>
                            <span>{{company.founded_year}}</span>
                        </div>
                        <div class="info-item" *ngIf="company.name_type_firm">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.name_type_firm}}</div>
                            <span>{{company.name_type_firm}}</span>
                        </div>
                        <div class="info-item" *ngIf="company.phone">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.phone}}</div>
                            <span>{{company.phone}}</span>
                        </div>           
                        <div class="info-item" *ngIf="company.email">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.email}}</div>
                            <a href="mailto:{{company.email}}">{{company.email}}</a>
                        </div>           
                        <div class="info-item" *ngIf="company.name_country">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.name_country}}</div>
                            <span>{{company.name_country}}</span>
                        </div>           
                        <div class="info-item" *ngIf="company.name_state">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.name_state}}</div>
                            <span>{{company.name_state}}</span>
                        </div>           
                        <div class="info-item" *ngIf="company.name_city">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.name_city}}</div>
                            <span>{{company.name_city}}</span>
                        </div>           
                        <div class="info-item" *ngIf="company.product_list">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.product_list}}</div>
                            <span>{{company.product_list}}</span>
                        </div>
                        <div class="info-item" *ngIf="company.competitors && company.competitors.length > 0">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.competitors}}</div> 
                            <div class="div-list">
                                <a class="subscribe comma-text" *ngFor="let competitor of company.competitors; let lastItem = last;"
                                                href="/companies/company/{{competitor.code_firm}}" [ngClass]="{ 'linkDisabled': competitor.type_firm == 'UK' }">
                                    {{competitor.name_firm}}
                                    <img *ngIf="neadSubscribe(competitor.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                                    <span *ngIf="!lastItem">,&nbsp;</span>
                                </a>
                            </div>           
                        </div>           
                    </div>
                </div>
<!-- financials -->
                <div class="tile financials" *ngIf="visibleFinancials()">
                    <div class="tile-body">
                        <span class="tile-title">{{companyTranslation.firm.card.anchor.financials}}</span>

                        <div class="info-item" *ngIf="company.total_funding_amount">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.total_funding_amount}}</div>
                            <span>{{company.total_funding_amount | currency : '' : 'symbol' : '1.0'}}</span>
                        </div>           
                        <div class="info-item" *ngIf="company.valuation">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.valuation}}</div>
                            <span>{{company.valuation | currency : '' : 'symbol' : '1.0'}}</span>
                        </div>
                        <div class="info-item" *ngIf="company.total_capital">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.total_capital}}</div>
                            <span>{{company.total_capital | currency : '' : 'symbol' : '1.0'}}</span>
                        </div>
                        <div class="info-item" *ngIf="company.top_deals && (company.type_firm == 'VC' || company.type_firm == 'PI')">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.top_deal}}</div>
                            <span>{{company.top_deals}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="one-row">
<!-- investments -->
                <div class="tile investments" *ngIf="visibleInvestments()">
                    <div class="tile-body">
                        <span class="tile-title">{{companyTranslation.firm.card.anchor.investments}}</span>

                        <div class="info-item" *ngIf="company.investmentStage && company.investmentStage.length > 0">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.investment_stage}}</div> 
                            <div class="div-list">
                                <div class="comma-text">
                                    <div *ngFor="let stage of company.investmentStage; let lastItem = last;">
                                        {{stage.name_investment_stage}}
                                        <div *ngIf="!lastItem">,&nbsp;</div>
                                    </div>
                                </div>
                            </div>           
                        </div>           

                        <div class="info-item" *ngIf="company.name_deals_size">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.deal_size}}</div>
                            <span>{{company.name_deals_size}}</span>
                        </div>           

                        <div class="info-item" *ngIf="company.investors && company.investors.length > 0">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.investors}}</div> 
                            <div class="div-list">
                                <div class="subscribe comma-text" *ngFor="let investor of company.investors; let lastItem = last;">
                                    <a class="comma-link-button" [routerLink]="[getRootHref(), investor.code_firm]" [ngClass]="{ 'linkDisabled': investor.type_firm == 'UK' }">
                                        <span>{{investor.name_firm}}</span>
                                        <img *ngIf="neadSubscribe(investor.subscribe_level) && investor.type_firm != 'UK'" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                                    </a>
                                </div>                            
                            </div>           
                        </div>

                        <div class="info-item" *ngIf="company.portfolioCompanies && company.portfolioCompanies.length > 0">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.portfolio_companies}}</div> 
                            <div class="div-list">
                                <div class="subscribe comma-text" *ngFor="let portfolio of company.portfolioCompanies; let lastItem = last;">
                                    <a class="comma-link-button" [routerLink]="[getRootHref(), portfolio.code_firm]" [ngClass]="{ 'linkDisabled': portfolio.type_firm == 'UK' }">
                                        {{portfolio.name_firm}}
                                        <img *ngIf="neadSubscribe(portfolio.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                                    </a>
<!--                                    <span *ngIf="!lastItem">&nbsp;</span> -->
                                </div>                            
                            </div>           
                        </div>
                    </div>

                </div>
<!-- Sectors -->
                <div class="tile sectors" *ngIf="(company.industry && company.industry.length > 0) ||
                            (company.category && company.category.length > 0)">
                    <div class="tile-body">
                        <span class="tile-title">{{companyTranslation.firm.card.anchor.sectors}}</span>

                        <div class="info-item" *ngIf="company.industry && company.industry.length > 0">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.industry}}</div>
                            <div class="div-list">
                                <div class="subscribe comma-text" *ngFor="let industry of company.industry; let lastItem = last;">
                                    <a class="comma-link-button" [routerLink]="['/home/i', industry.code_industry]">
                                        {{industry.name_industry}}
                                        <img *ngIf="neadSubscribe(industry.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                                    </a>
                                </div>                            
                            </div>           
                        </div>

                        <div class="info-item" *ngIf="company.category && company.category.length > 0">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.category}}</div> 
                            <div class="div-list">
                                <div class="subscribe comma-text" *ngFor="let category of company.category; let lastItem = last;">
                                    <a class="comma-link-button" [routerLink]="['/home/ic', category.code_industry, category.code_category]" [queryParams]="{l: category.subscribe_level}">
                                        {{category.name_category}}
                                        <img *ngIf="neadSubscribe(category.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                                    </a>
<!--                                    <span *ngIf="!lastItem">&nbsp;</span> -->
                                </div>                            
                            </div>           
                        </div>
                    </div>
                </div>
            </div>

            <div class="one-row">
<!-- key people -->
                <div class="tile key-people" *ngIf="(company.founders && company.founders.length > 0) ||
                            (company.principals && company.principals.length > 0)">
                    <div class="tile-body">
                        <span class="tile-title">{{companyTranslation.firm.card.anchor.key_people}}</span>
                        
                        <div class="info-item" *ngIf="company.founders && company.founders.length > 0">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.founders}}</div> 
                            <div class="div-list">
                                <div class="subscribe comma-text" *ngFor="let founder of company.founders; let lastItem = last;">
                                    <div>
                                        <a class="comma-link" (mousemove)="onFounderMouseMove($event, founder)" (mouseout)="onFounderMouseOut($event, founder)"
                                                (click)="onFounderClick($event, founder)" [ngClass]="{ commaLinkMessage: founder.is_send_message == 1 }">
                                            <span>{{founder.full_name}}</span>
                                        </a>
                                        <a class="comma-link-button" *ngIf="founder.is_send_message == 1" (click)="onSendMessagePersonClick(founder)">
                                            <img src="/assets/imgs/messagebubble_1.png">
                                            <img *ngIf="neadMessagePersonSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                                        </a>

                                        <div class="founder-social" *ngFor="let social of founder.social_networks; let lastIten = last;">
                                            <a href="{{social.address}}" *ngIf="social.type_address=='LI'" target="_blank">
                                                <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                            </a>
                                        </div>

                                        <span *ngIf="!lastItem && founder.is_send_message != 1">,</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="info-item" *ngIf="company.principals && company.principals.length > 0">
                            <div class="fixed-col">{{companyTranslation.firm.card.field.principals}}</div> 
                            <div class="div-list">
                                <div class="subscribe comma-text" *ngFor="let principal of company.principals; let lastItem = last;">
                                    <div>
                                        <a class="comma-link" (mousemove)="onFounderMouseMove($event, principal)" (mouseout)="onFounderMouseOut($event, principal)"
                                                (click)="onFounderClick($event, principal)" [ngClass]="{ commaLinkMessage: principal.is_send_message == 1 }">
                                            {{principal.full_name}}
                                        </a>

                                        <a class="comma-link-button" *ngIf="principal.is_send_message == 1" (click)="onSendMessagePersonClick(principal)">
                                            <img src="/assets/imgs/messagebubble_1.png">
                                            <img *ngIf="neadMessagePersonSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                                        </a>

                                        <div class="founder-social" *ngFor="let social of principal.social_networks; let lastIten = last;">
                                            <a href="{{social.address}}" *ngIf="social.type_address=='LI'" target="_blank">
                                                <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                            </a>
                                        </div>

                                        <span *ngIf="!lastItem && principal.is_send_message != 1">,</span>
                                        <span *ngIf="!lastItem">&nbsp;</span>
                                    </div>
                                </div>
                            </div>           
                        </div>

                    </div>           
                </div>
            </div>
        </div>
    </div>
</div>    

<div class="person-card" id="prsn-crd" *ngIf="showPersonCard && !platformService.handsetPlatform" [ngStyle]="getPersonCardPosition()">
    <ptn-person-card [personID]="personID" (onClosePersonCard)="onClosePersonCard($event)"
            (onPersonCardBusy)="onPersonCardBusy($event)"></ptn-person-card>
</div>

<div id="send-msg" *ngIf="showSendMessage && !platformService.handsetPlatform" [ngStyle]="getSendMessagePos()">
    <ptn-send-message [personID]="messagePersonID" [companyID]="messageCompanyID" (onCloseMessage)="onCloseMessage($event)"></ptn-send-message>
</div>

<!-- 457, 449, 10074, 1728, 1612 -->
<!-- presentation 926,  -->

<!-- Mobile -->

<div class="mob-company" *ngIf="platformService.handsetPlatform">
    <div class="mob-main">
        <div class="mob-header">
            <div class="mob-header-top">
                <div class="mob-company-logo">
                    <img src={{company.image_url}}>
                </div>

                <div class="mob-header-text">
                    <div class="mob-name-company">{{company.name_firm}}</div>
                    <div class="heder-line">{{company.focus}}</div>
                </div>
            </div>

            <div class="heder-line">
                <a *ngIf="company.websiteToShow" href="{{company.website}}" target="_blank">
                    <div>{{company.websiteToShow}}</div>
                </a>
                
                <div class="mob-info-item" *ngIf="company.socialNetworks && company.socialNetworks.length > 0">
                    <div class="div-list">
                        <div class="subscribe mob-comma-img" *ngFor="let social of company.socialNetworks; let lastItem = last;">
                            <a href={{social.address}} target="_blank">
                                <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                <img *ngIf="neadSubscribe(social.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>
                        </div>
                    </div>           
                </div>           

            </div>
        </div>
        <div class="mob-header-buttons">
            <a class="send-message-button" *ngIf="company.is_send_message == 1" [routerLink]="[]" routerLinkActive="active"
                    (click)="onSendMessageCompanyClick()">
                <span>{{companyTranslation.firm.card.button.message}}</span>
                <img src="/assets/imgs/messagebubble_2.png" title="Send message">
                <img *ngIf="neadMessageCompanySubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
            </a>

            <a class="presentation" *ngIf="company.presentation_url" [routerLink]="[]" routerLinkActive="active"
                    (click)="onPresentationClick(company.code_firm)">
                {{companyTranslation.firm.card.button.view_presentation}}
                <img *ngIf="neadPresentationSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
            </a>

            <a class="deals" *ngIf="company.is_exists_rounds == 1" [routerLink]="['deals', company.code_firm]">
                {{companyTranslation.firm.card.button.deals}}
                <img *ngIf="neadDealsSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
            </a>
        </div>

<!-- general -->
        <div class="mob-tile general" *ngIf="visibleGeneral()">
            <div class="mob-tile-body">
                <span class="tile-title">{{companyTranslation.firm.card.anchor.general}}</span>
                <div class="mob-info-item" *ngIf="company.founded_year">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.founded_year}}</div>
                    <span>{{company.founded_year}}</span>
                </div>
                <div class="mob-info-item" *ngIf="company.name_type_firm">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.name_type_firm}}</div>
                    <span>{{company.name_type_firm}}</span>
                </div>
                <div class="mob-info-item" *ngIf="company.phone">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.phone}}</div>
                    <span>{{company.phone}}</span>
                </div>           
                <div class="mob-info-item" *ngIf="company.email">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.email}}</div>
                    <a href="mailto:{{company.email}}">{{company.email}}</a>
                </div>           
                <div class="mob-info-item" *ngIf="company.name_country">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.name_country}}</div>
                    <span>{{company.name_country}}</span>
                </div>           
                <div class="mob-info-item" *ngIf="company.name_state">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.name_state}}</div>
                    <span>{{company.name_state}}</span>
                </div>           
                <div class="mob-info-item" *ngIf="company.name_city">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.name_city}}</div>
                    <span>{{company.name_city}}</span>
                </div>           
                <div class="mob-info-item" *ngIf="company.product_list">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.product_list}}</div>
                    <span>{{company.product_list}}</span>
                </div>
                <div class="mob-info-list" *ngIf="company.competitors && company.competitors.length > 0">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.competitors}}</div> 
                    <div class="div-list">
                        <a class="subscribe mob-comma-text" *ngFor="let competitor of company.competitors; let lastItem = last;"
                                        href="/companies/company/{{competitor.code_firm}}" [ngClass]="{ 'linkDisabled': competitor.type_firm == 'UK' }">
                            {{competitor.name_firm}}
                            <img *ngIf="neadSubscribe(competitor.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            <span *ngIf="!lastItem">,&nbsp;</span>
                        </a>
                    </div>           
                </div>           
            </div>
        </div>

<!-- financials -->
        <div class="mob-tile financials" *ngIf="visibleFinancials()">
            <div class="mob-tile-body">
                <span class="tile-title">{{companyTranslation.firm.card.anchor.financials}}</span>

                <div class="mob-info-item" *ngIf="company.total_funding_amount">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.total_funding_amount}}</div>
                    <span>{{company.total_funding_amount | currency : '' : 'symbol' : '1.0'}}</span>
                </div>           
                <div class="mob-info-item" *ngIf="company.valuation">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.valuation}}</div>
                    <span>{{company.valuation | currency : '' : 'symbol' : '1.0'}}</span>
                </div>
                <div class="mob-info-item" *ngIf="company.total_capital">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.total_capital}}</div>
                    <span>{{company.total_capital | currency : '' : 'symbol' : '1.0'}}</span>
                </div>
                <div class="mob-info-item" *ngIf="company.top_deals && (company.type_firm == 'VC' || company.type_firm == 'PI')">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.top_deal}}</div>
                    <span>{{company.top_deals}}</span>
                </div>
            </div>
        </div>

<!-- investments -->
        <div class="mob-tile investments" *ngIf="visibleInvestments()">
            <div class="mob-tile-body">
                <span class="tile-title">{{companyTranslation.firm.card.anchor.investments}}</span>

                <div class="mob-info-list" *ngIf="company.investmentStage && company.investmentStage.length > 0">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.investment_stage}}</div> 
                    <div class="div-list">
                        <div class="mob-comma-text">
                            <div *ngFor="let stage of company.investmentStage; let lastItem = last;">
                                {{stage.name_investment_stage}}
                                <div *ngIf="!lastItem">,&nbsp;</div>
                            </div>
                        </div>
                    </div>           
                </div>           

                <div class="mob-info-item" *ngIf="company.name_deals_size">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.deal_size}}</div>
                    <span>{{company.name_deals_size}}</span>
                </div>           

                <div class="mob-info-list" *ngIf="company.investors && company.investors.length > 0">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.investors}}</div> 
                    <div class="div-list">
                        <div class="subscribe mob-comma-text" *ngFor="let investor of company.investors; let lastItem = last;">
                            <a class="comma-link-button" [routerLink]="[getRootHref(), investor.code_firm]" [ngClass]="{ 'linkDisabled': investor.type_firm == 'UK' }">
                                <span>{{investor.name_firm}}</span>
                                <img *ngIf="neadSubscribe(investor.subscribe_level) && investor.type_firm != 'UK'" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>
                        </div>                            
                    </div>           
                </div>

                <div class="mob-info-list" *ngIf="company.portfolioCompanies && company.portfolioCompanies.length > 0">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.portfolio_companies}}</div> 
                    <div class="div-list">
                        <div class="subscribe mob-comma-text" *ngFor="let portfolio of company.portfolioCompanies; let lastItem = last;">
                            <a class="comma-link-button" [routerLink]="[getRootHref(), portfolio.code_firm]" [ngClass]="{ 'linkDisabled': portfolio.type_firm == 'UK' }">
                                {{portfolio.name_firm}}
                                <img *ngIf="neadSubscribe(portfolio.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>
<!--                            <span *ngIf="!lastItem">&nbsp;</span> -->
                        </div>                            
                    </div>           
                </div>
            </div>
        </div>

<!-- Sectors -->
        <div class="mob-tile sectors" *ngIf="(company.industry && company.industry.length > 0) ||
            (company.category && company.category.length > 0)">
            <div class="mob-tile-body">
                <span class="tile-title">{{companyTranslation.firm.card.anchor.sectors}}</span>

                <div class="mob-info-list" *ngIf="company.industry && company.industry.length > 0">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.industry}}</div>
                    <div class="div-list">
                        <div class="subscribe mob-comma-text" *ngFor="let industry of company.industry; let lastItem = last;">
                            <a class="comma-link-button" [routerLink]="['/home/i', industry.code_industry]">
                                {{industry.name_industry}}
                                <img *ngIf="neadSubscribe(industry.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>
                        </div>                            
                    </div>           
                </div>

                <div class="mob-info-list" *ngIf="company.category && company.category.length > 0">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.category}}</div> 
                    <div class="div-list">
                        <div class="subscribe mob-comma-text" *ngFor="let category of company.category; let lastItem = last;">
                            <a class="comma-link-button" [routerLink]="['/home/ic', category.code_industry, category.code_category]" [queryParams]="{l: category.subscribe_level}">
                                {{category.name_category}}
                                <img *ngIf="neadSubscribe(category.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>
<!--                            <span *ngIf="!lastItem">&nbsp;</span> -->
                        </div>                            
                    </div>           
                </div>
            </div>
        </div>

<!-- key people -->
        <div class="mob-tile key-people" *ngIf="(company.founders && company.founders.length > 0) ||
            (company.principals && company.principals.length > 0)">
            <div class="mob-tile-body">
                <span class="tile-title">{{companyTranslation.firm.card.anchor.key_people}}</span>
                
                <div class="mob-info-list" *ngIf="company.founders && company.founders.length > 0">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.founders}}</div> 
                    <div class="div-list">
                        <div class="subscribe mob-comma-text" *ngFor="let founder of company.founders; let lastItem = last;">
                            <div>
                                <a class="comma-link" (click)="onFounderClick($event, founder)" [ngClass]="{ commaLinkMessage: founder.is_send_message == 1 }">
                                    <span>{{founder.full_name}}</span>
                                </a>
                                <a class="comma-link-button" *ngIf="founder.is_send_message == 1" (click)="onSendMessagePersonClick(founder)">
                                    <img src="/assets/imgs/messagebubble_1.png">
                                    <img *ngIf="neadMessagePersonSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                                </a>

                                <div class="founder-social" *ngFor="let social of founder.social_networks; let lastIten = last;">
                                    <a href="{{social.address}}" *ngIf="social.type_address=='LI'" target="_blank">
                                        <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                    </a>
                                </div>

                                <span *ngIf="!lastItem && founder.is_send_message != 1">,</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mob-info-list" *ngIf="company.principals && company.principals.length > 0">
                    <div class="fixed-col">{{companyTranslation.firm.card.field.principals}}</div> 
                    <div class="div-list">
                        <div class="subscribe mob-comma-text" *ngFor="let principal of company.principals; let lastItem = last;">
                            <div>
                                <a class="comma-link" (click)="onFounderClick($event, principal)" [ngClass]="{ commaLinkMessage: principal.is_send_message == 1 }">
                                    {{principal.full_name}}
                                </a>

                                <a class="comma-link-button" *ngIf="principal.is_send_message == 1" (click)="onSendMessagePersonClick(principal)">
                                    <img src="/assets/imgs/messagebubble_1.png">
                                    <img *ngIf="neadMessagePersonSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                                </a>

                                <div class="founder-social" *ngFor="let social of principal.social_networks; let lastIten = last;">
                                    <a href="{{social.address}}" *ngIf="social.type_address=='LI'" target="_blank">
                                        <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                    </a>
                                </div>

                                <span *ngIf="!lastItem && principal.is_send_message != 1">,</span>
                                <span *ngIf="!lastItem">&nbsp;</span>
                            </div>
                        </div>
                    </div>           
                </div>
            </div>           
        </div>

    </div>    
</div>    
