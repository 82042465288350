import { Component, OnInit } from '@angular/core';
import { AboutTranslation } from '../shared/translation.const';
import { TranslationService } from './../services/translation.service';
import { PlatformService } from './../services/platform.service';

declare let fbq: Function;

@Component({
    selector: 'about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css']
})
  
export class AboutComponent implements OnInit {

    aboutTranslation = AboutTranslation;


    constructor(private translationService: TranslationService, public platformService: PlatformService) {

        this.translationService.getTranslate('PAGE_ABOUT', this.aboutTranslation);
    }


    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('PAGE_ABOUT', this.aboutTranslation);
        });

        fbq('track', 'ViewContent', {content_name: "Visit about page"});
    }
}  