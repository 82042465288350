<div class="main" *ngIf="!platformService.handsetPlatform">
    <div class="grid">
        <div class="left-side">
            <h1 *ngIf="selectedTabIndex == 0">{{registerTranslation.form_title.text}}</h1>
            <h1 *ngIf="selectedTabIndex == 1">{{loginTranslation.form_title.signin.text}}</h1>
            <h1 *ngIf="selectedTabIndex == 2">{{forgotPassTranslation.form_title.signin.text}}</h1>
            <h1 *ngIf="selectedTabIndex == 3">{{recoveryTranslation.form.text}}</h1>
            <div class="div-image">
                <img src="/assets/imgs/login.png">
            </div>

            <div class="password-policy" *ngIf="selectedTabIndex == 0" [innerHtml]="registerTranslation.password_policy.text"></div>

            <div class="recovery" *ngIf="selectedTabIndex == 3">
                <p>{{recoveryTranslation.where.text}}</p>
            </div>
        </div>

        <div class="right-side">
            <mat-tab-group class="tab-group" *ngIf="selectedTabIndex < 2" #loginTabGroup dynamicHeight animationDuration="0ms" (selectedTabChange)="onSelectedTabChange(loginTabGroup)"
                        [(selectedIndex)]="selectedTabIndex">
                <mat-tab label="{{loginTranslation.signup.button}}" *ngIf="selectedTabIndex < 2">
                    <ptn-registration (errorEvent)="errorEvent($event)"></ptn-registration>
                </mat-tab>
                <mat-tab label="{{loginTranslation.signin.button}}" *ngIf="selectedTabIndex < 2">
                    <ptn-sign-in (errorEvent)="errorEvent($event)"></ptn-sign-in>
                </mat-tab>
            </mat-tab-group>

            <div class="single-tab" *ngIf="selectedTabIndex >= 2">
                <div *ngIf="selectedTabIndex == 2">
                    <div>
                        <span>{{forgotPassTranslation.form_title.forgot_pass.text}}</span>
                    </div>
                    <forgot-password (errorEvent)="errorEvent($event)"></forgot-password>
                </div>
                <div *ngIf="selectedTabIndex == 3">
                    <div>
                        <span>{{recoveryTranslation.title.text}}</span>
                    </div>
                    <recovery-password (errorEvent)="errorEvent($event)"></recovery-password>
                </div>
            </div>

            <div class="errors" *ngIf="errorMessage">{{errorMessage}}</div>
            <div class="trial" *ngIf="selectedTabIndex == 0 && !errorMessage">{{registerTranslation.free_trial.text}}</div>

            <div class="social-net" *ngIf="selectedTabIndex < 2">
                {{displayedTranslation.using_sa.text}}
                <div class="social-link">
                    <a href="{{facebookLink}}" title={{displayedTranslation.using_sa.text}}>
                        <img class='facebook-img' src='assets/imgs/facebook.svg' title={{displayedTranslation.using_sa.text}}>
                    </a>
                    <a href="{{googleLink}}" title={{displayedTranslation.using_sa.text}}>
                        <img class='google-img' src='assets/imgs/google.svg' title={{displayedTranslation.using_sa.text}}>
                    </a>
                </div>
            </div>

            <div class="privacy-policy" [innerHtml]="registerTranslation.privacy_policy.text" *ngIf="selectedTabIndex == 0"></div>
        </div>    
    </div>
</div>


<!-- Mobile -->
<div class="mob-main" *ngIf="platformService.handsetPlatform">

    <mat-tab-group class="mob-tab-group" *ngIf="selectedTabIndex < 2" #loginTabGroup dynamicHeight animationDuration="0ms" (selectedTabChange)="onSelectedTabChange(loginTabGroup)"
                [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="{{loginTranslation.signup.button}}" *ngIf="selectedTabIndex < 2">
            <ptn-registration (errorEvent)="errorEvent($event)"></ptn-registration>
        </mat-tab>
        <mat-tab label="{{loginTranslation.signin.button}}" *ngIf="selectedTabIndex < 2">
            <ptn-sign-in (errorEvent)="errorEvent($event)"></ptn-sign-in>
        </mat-tab>
    </mat-tab-group>
   
    <div class="mob-single-tab">
        <div *ngIf="selectedTabIndex == 2">
            <forgot-password (errorEvent)="errorEvent($event)"></forgot-password>
        </div>
        <div *ngIf="selectedTabIndex == 3">
            <recovery-password (errorEvent)="errorEvent($event)"></recovery-password>
        </div>
    </div>

    <div class="mob-errors" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="mob-no-errors" *ngIf="!errorMessage">&nbsp;</div>

    <div class="mob-social-net" *ngIf="selectedTabIndex < 2">
        {{displayedTranslation.using_sa.text}}
        <div class="mob-social-link">
            <a href="{{facebookLink}}" title={{displayedTranslation.using_sa.text}}>
                <img class='facebook-img' src='assets/imgs/facebook.svg' title={{displayedTranslation.using_sa.text}}>
            </a>
            <a href="{{googleLink}}" title={{displayedTranslation.using_sa.text}}>
                <img class='google-img' src='assets/imgs/google.svg' title={{displayedTranslation.using_sa.text}}>
            </a>
        </div>
    </div>
</div>
