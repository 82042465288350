<div class="dont-recovery">
    <div class="header">
        {{translation.title.text}}
    </div>

    <div class="body">
        <span class="where">
            {{translation.where.text}}
        </span>
    </div>
</div>