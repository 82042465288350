import { Component, OnInit } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { PlatformService } from '../services/platform.service';
import { RouterHistoryService } from '../services/router-history.service';
import { SessionStorageService } from '../services/session-storage-service';
import { TranslateObject, TranslationService } from '../services/translation.service';
import { langPAGE_VC_FUNDS } from '../shared/constant.module';
import { FilterFundsTranslation } from '../shared/translation.const';

@Component({
  selector: 'ptn-filter-funds',
  templateUrl: './filter-funds.component.html',
  styleUrls: ['./filter-funds.component.scss']
})
export class FilterFundsComponent implements OnInit {

  filterFundsTranslation = FilterFundsTranslation;
  sortingFundsList: Array<TranslateObject> = [];
  
  constructor(private translationService: TranslationService, private sessionStorageService: SessionStorageService, private filterService: FilterService, public platformService: PlatformService, private routerHistoryService: RouterHistoryService) {
    this.translationService.getTranslate(langPAGE_VC_FUNDS, this.filterFundsTranslation);
    this.translationService.getCategoryObject('PAGE_VC_FUNDS.SORTING_OPTIONS', this.sortingFundsList);
    
    this.translationService.languageChanged$.subscribe((changeObj) => {
      this.translationService.getTranslate(langPAGE_VC_FUNDS, this.filterFundsTranslation);
      this.filterFundsTranslation = JSON.parse(JSON.stringify(this.filterFundsTranslation)); //to fire ngOnChanges for FilterComponent

      this.sortingFundsList = [];
      this.translationService.getCategoryObject('PAGE_VC_FUNDS.SORTING_OPTIONS', this.sortingFundsList);
    });

    // if (this.sessionStorageService.filterFundsParamsInput) {
    //   this.filterService.filterParamsInput = this.sessionStorageService.filterFundsParamsInput;
    // }

    console.log(this.filterFundsTranslation);
    console.log(this.sortingFundsList);
  }

  ngOnInit(): void {
    if (this.sessionStorageService.isShowFilterMenuM === true) {
      let prevUrl = this.routerHistoryService.previousUrl$.value || '/';
      if (prevUrl.indexOf('filter-funds') == -1) {
        this.sessionStorageService.isShowFilterMenuM = false;
      }
    }
  }
}
