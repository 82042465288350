import { Injectable, OnInit } from '@angular/core';
import { User, RegisterResponse, Customer, PaymentData, PaymentSystemSession, SendEmail, VerifyEmail, RecoveryPassword, DontRecoveryPassword } from '../models/user';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { PtnAppConfig } from './../shared/ptn-app-config';
import { LocalStorageService } from './local-storage-service';
import { map, finalize, catchError } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { TranslationService } from './translation.service';
import { DeviceInfoService } from './device-info.service';
import { UserSubscribe } from '../models/user-subscribe';
import { FooterTranslation } from '../shared/translation.const';


@Injectable()
export class UserService implements OnInit {

    private translationFooter = FooterTranslation;

    isAuthenticated = false;
    Authenticated$ = new Subject<boolean>();
    x_vms_session: string;
    user: User;
    registerResponse: RegisterResponse;
    // Подія для стану login
    public loginChanged$ = new Subject<User>();

//    private url = PtnAppConfig.api_url;
    private url_v2 = PtnAppConfig.url_v2;

    constructor(private http: HttpClient, private router: Router, private localstorageService: LocalStorageService, private loaderService: LoaderService, 
                private translationService: TranslationService, private deviceInfoService: DeviceInfoService) {
        this.x_vms_session = this.localstorageService.x_vms_session;
        if (!this.x_vms_session) {
            this.x_vms_session = 'notauthenticate';
        }
        
        this.user = this.localstorageService.user;
        if (this.user) {
            this.isAuthenticated = true;
            this.Authenticated$.next(this.isAuthenticated);
        }
        else {
            this.isAuthenticated = false;
            this.Authenticated$.next(this.isAuthenticated);
        }

        this.translationService.getTranslate("FOOTER", this.translationFooter);
    }


    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate("FOOTER", this.translationFooter);
        });
    }


    actionOnSignInError = () => {
        if (this.isAuthenticated) {
            this.isAuthenticated = false;
            this.x_vms_session = 'notauthenticate';
            this.user = null;
            this.Authenticated$.next(this.isAuthenticated);
            this.localstorageService.removeSession();
            this.localstorageService.removeUser();
        }
    }

    signIn(user: User): Observable<User> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8"
        });

        this.loaderService.startLoading();
        return this.http.post<User>(this.url_v2 + '/signin', user, { headers: myHeaders, observe: 'response' }).pipe(finalize(this.loaderService.finishLoading)).pipe(map((res: HttpResponse<User>) => {
            Object.assign(this.user, res.body);
            this.x_vms_session = res.headers.get('x-vms-session');
            this.isAuthenticated = true;
            this.Authenticated$.next(this.isAuthenticated);
            this.loginChanged$.next(this.user);
            this.localstorageService.x_vms_session = this.x_vms_session;
            this.localstorageService.user = this.user;
            return this.user;
        }));
    }


    signUp(user: User): Observable<any> {

        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8"
        });

        this.loaderService.startLoading();
        return this.http.post(this.url_v2 + "/signup", user, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }


    signOut(): Observable<any> {        
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json",
            "x-vms-session": this.x_vms_session
        });

        let finishSignOutLoading = () => {
            if (this.isAuthenticated) {
                this.isAuthenticated = false;
                this.x_vms_session = 'notauthenticate';
                this.user = null;
                this.Authenticated$.next(this.isAuthenticated);
                this.localstorageService.removeSession();
                this.localstorageService.removeUser();
                this.router.navigate(['/login/sign-in']);
                this.loaderService.finishLoading();
            }
        }

        this.loaderService.startLoading();
        return this.http.put(this.url_v2 + '/signout', null, { headers: myHeaders }).pipe(map(() => {
            console.log('signOut complete');            
        }), catchError((error) => throwError(`Sign Out error: ${error}`))).pipe(finalize(finishSignOutLoading));
    }

    // for testing
    expireSession(): Observable<any> {        
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json",
            "x-vms-session": this.x_vms_session
        });

        this.loaderService.startLoading();
        return this.http.put(this.url_v2 + '/signout', null, { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading)).pipe(map(() => {
            console.log('expireSession complete');            
        }), catchError((error) => throwError(`Expire Session error: ${error}`)));
    }

    getCustomer(): Observable<Customer> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-session": this.x_vms_session,
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.post<Customer>(`${this.url_v2}/payment/customer`, null, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }

    getSubscription(): Observable<Array<UserSubscribe>> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-session": "notauthenticate",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.get<Array<UserSubscribe>>(`${this.url_v2}/payment/subscription`, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }

    createCheckoutSession(paymentData: PaymentData): Observable<PaymentSystemSession> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-session": this.x_vms_session,
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.post<PaymentSystemSession>(`${this.url_v2}/payment/create-checkout-session`, paymentData, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }

    checkoutSession(paymentSystemSession: PaymentSystemSession): Observable<PaymentSystemSession> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-session": this.x_vms_session,
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.post<PaymentSystemSession>(`${this.url_v2}/payment/checkout-session`, paymentSystemSession, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }

    cancelSubscription(): Observable<any> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-session": this.x_vms_session,
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.post(`${this.url_v2}/payment/cancel-subscription`, null, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }


    sendEmail(sendEmail: SendEmail): Observable<any> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8"
        });        

        this.loaderService.startLoading();
        return this.http.post(this.url_v2 + "/ses/send", sendEmail, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }


    verifyEmail(verifyEmail: VerifyEmail): Observable<any> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8"
        });        

        this.loaderService.startLoading();
        return this.http.put(this.url_v2 + "/ses/verify-email", verifyEmail, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }


    recoveryPassword(recoveryPass: RecoveryPassword): Observable<any> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8"
        });        

        this.loaderService.startLoading();
        return this.http.put(this.url_v2 + "/ses/recovery-request", recoveryPass, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }


    dontRecoveryPassword(dontRecoveryPass: DontRecoveryPassword): Observable<any> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8"
        });        

        this.loaderService.startLoading();
        return this.http.put(this.url_v2 + "/ses/recovery-did-not-request", dontRecoveryPass, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }


    userSendMessage(recipientCompany: number, recipientPerson: number, codeSender: number, message: string, isAttach: number): Observable<any> {
        
        let myHeaders = new HttpHeaders({
            "x-vms-session": this.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        let body = { 
            recipient_code_firm: recipientCompany,
            recipient_code_person: recipientPerson,
            sender_code_firm: codeSender, 
            message: message,
            is_attach_presentation: isAttach,
            device_data: this.deviceInfoService.deviceInfo
        };
        
        this.loaderService.startLoading();
        return this.http.post(this.url_v2 + "/user/send-message", body, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }


    getUserSubscribeLevel(): number {

        if (this.user && this.user.subscribe_level) {
            return this.user.subscribe_level;
        } else {
            return 0;
        }
    }


    checkSubscribeDate(): string {

        if (this.user && !this.user.id_subscription_payment_system && this.user.subscription_end_date) {

            let today = new Date();
            let subscribeDate = new Date(this.user.subscription_end_date * 1000);
            let trialSubscribe: string = null;
        
            if (subscribeDate >= today) {
        
                let day: string = subscribeDate.getDate().toString();
                if (day.length < 2)
                    day = '0' + day;
            
                let month: string = (subscribeDate.getMonth() + 1).toString();
                if (month.length < 2)
                    month = '0' + month;
            
                let year = subscribeDate.getFullYear();
                trialSubscribe = this.translationFooter.trial_active.text.replace("<<subscription_end_date>>", day + '.' + month + '.' + year.toString());
            
                let diff = Math.abs(subscribeDate.getTime() - today.getTime())
                trialSubscribe = trialSubscribe.replace("<<days_left>>", (Math.ceil(diff / (1000 * 3600 * 24))).toString());
            } else {
                trialSubscribe = this.translationFooter.trial_ended.text;
            }

            return trialSubscribe;
        } else {
            return null;
        }
    }
    
    
// Временная функция
/*
    clearCache(): Observable<any> {
        let myHeaders = new HttpHeaders({
            "x-vms-session": this.x_vms_session
        });        

        this.loaderService.startLoading();
        return this.http.put(this.url + "/ses/clearCache", null, {headers: myHeaders}).pipe(finalize(this.loaderService.finishLoading));
    }
*/
}
