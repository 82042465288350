import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { TranslationService } from '../services/translation.service';
import { langMENU_MAIN, langMenuService } from "../shared/constant.module";
import { HeaderTranslation, MenuServiceTranslation, AnalysisTranslation } from '../shared/translation.const';
import { Location } from '@angular/common';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Subject } from 'rxjs';
import { LocalStorageService } from '../services/local-storage-service';
import { PlatformService } from '../services/platform.service';
import { VersionService } from '../services/version.service';


export var viewAsPhotoChanged$ = new Subject<boolean>();

@Component({
  selector: 'ptn-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})

export class HeaderComponent implements OnInit {

  isAuthenticated: boolean;
  account = {
    accountNotVerified: false
  }

  viewAsPhoto: boolean = true;

    // Caption для пунктів меню
  mnuCaptions = HeaderTranslation;
  mnSrvTranslation = MenuServiceTranslation;
  analisysTranslation = AnalysisTranslation;

  private setStaticLabelsBreadCrumbs(): void {
    this.breadcrumbService.set('@home', this.mnuCaptions.home);
    this.breadcrumbService.set('@deals', this.mnuCaptions.deals);
    this.breadcrumbService.set('@subscribe', this.mnSrvTranslation.subscribe);
    this.breadcrumbService.set('@list', this.mnuCaptions.list);
    this.breadcrumbService.set('@ptn-index', this.mnuCaptions.ptn_index);
    this.breadcrumbService.set('@search', this.mnuCaptions.search);
    this.breadcrumbService.set('@analysis', this.mnuCaptions.analysis);
    this.breadcrumbService.set('@top-industry', this.analisysTranslation.analysis.top_in_industry.title);
    this.breadcrumbService.set('@sector-map', this.analisysTranslation.analysis.sector_map.title);
    this.breadcrumbService.set('@bubble-map', this.analisysTranslation.analysis.bubble_map.title);
    this.breadcrumbService.set('@filter-companies', this.mnuCaptions.companies);
    this.breadcrumbService.set('@filter-funds', this.mnuCaptions.vc_funds);
    this.breadcrumbService.set('@filter-private-investors', this.mnuCaptions.privat_investors);
    this.breadcrumbService.set('@sign-in', this.mnSrvTranslation.login);
    this.breadcrumbService.set('@register', this.mnSrvTranslation.register);
    this.breadcrumbService.set('@private-policy', this.mnSrvTranslation.privacy);
    this.breadcrumbService.set('@term-of-use', this.mnSrvTranslation.terms_of_use);
    this.breadcrumbService.set('@data-collect', this.mnSrvTranslation.data_collection);
    this.breadcrumbService.set('@about', this.mnSrvTranslation.about);
  }

  constructor(private router: Router, public userService: UserService, private translationService: TranslationService,
        private location: Location, private breadcrumbService: BreadcrumbService, private localStorage: LocalStorageService,
        public platformService: PlatformService, private versionService: VersionService) {

    let tmpViewAsPhoto = this.localStorage.get("viewAsPhoto");
      if (tmpViewAsPhoto != undefined) {
        this.viewAsPhoto = tmpViewAsPhoto;
    }

    this.translationService.getTranslate(langMENU_MAIN, this.mnuCaptions);
    this.translationService.getTranslate(langMenuService, this.mnSrvTranslation);
    this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);

    this.setStaticLabelsBreadCrumbs();

    this.isAuthenticated = this.userService.isAuthenticated;
    if (this.userService.user)
      this.account.accountNotVerified = this.userService.user.account_identifier_is_verified == '0';
  }


  ngOnInit(): void { 

    this.translationService.languageChanged$.subscribe((changeObj) => {

      this.translationService.getTranslate(langMENU_MAIN, this.mnuCaptions);
      this.translationService.getTranslate(langMenuService, this.mnSrvTranslation);
      this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);
      this.setStaticLabelsBreadCrumbs();
    });

    this.userService.Authenticated$.subscribe((authenticated) => {

      this.isAuthenticated = authenticated;
      if (!authenticated)
        this.account.accountNotVerified = false
      else
        this.account.accountNotVerified = this.userService.user.account_identifier_is_verified == '0';
    });
  }


  onRegister(): void {    

    this.router.navigate(['/login/register']);
  }


  onSignIn(): void {

    this.router.navigate(['/login/sign-in']);
  }


  onSignOut(): void { 
    if (this.userService.isAuthenticated) {
      this.userService.signOut().subscribe((changeObj) => {
        this.userService.loginChanged$.next(null);
      });
    }
  }


  onSubscribe(): void {
    this.router.navigate(['/subscribes']);
  }


  onChangeLanguage(value) {
    this.translationService.getDictionary(value);
  }


  getLocation(): string {

    if (this.router.parseUrl(this.router.url).root.children.primary) {
      if (this.router.parseUrl(this.router.url).root.children.primary.segments.length > 0) {

        return '/' + this.router.parseUrl(this.router.url).root.children.primary.segments[0].path;
      }
    } else {

      return this.location.path();
    }
  }


  onAbout() {
    this.router.navigate(['/about']);
  }  


  onDataCollection() {
    this.router.navigate(['/data-collect']);
  }


  onPtnIndexClick() {
    this.router.navigate(['/ptn-index']);
  }


  onListClick() {
    this.router.navigate(['/list']);
  }


  onTrendsClick() {
    this.router.navigate(['/']);
  }


  onAnalysisClick() {
    this.router.navigate(['/analysis']);
  }


  onDealsClick() {
    this.router.navigate(['/deals']);
  }


  onPrivateInvClick() {
    this.router.navigate(['/filter-private-investors']);
  }


  onFundsClick() {
    this.router.navigate(['/filter-funds']);
  }


  onCompaniesClick() {
    this.router.navigate(['/filter-companies']);
  }


  onTermOfUse() {
    this.router.navigate(['/term-of-use']);
  }

  
  onPrivacy() {
    this.router.navigate(['/private-policy']);
  }


  onViewAsPhotoChange(event: any) {

    this.localStorage.set("viewAsPhoto", event.checked);
    viewAsPhotoChanged$.next(event.checked);
  }


  onSyntheticVC() {

    window.location.href = 'https://www.syntheticvc.com/';
  }


  onMenuButtonClick() {

    this.versionService.getPaymentVersion().subscribe(
      response => {
          let json = response;
      },
      error => console.log(error)
    );

    this.versionService.getAuthenticateVersion().subscribe(
      response => {
          let json = response;
      },
      error => console.log(error)
    );

  }

  onSupport() {

    window.location.href = 'mailto:' + this.mnSrvTranslation.support.email;
  }
}
