import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  public loading$: Observable<boolean>;
  private loadingSource = new Subject<boolean>();
  private counter = 0;

  public get IsLoading(): boolean {
    return this.counter != 0;
  }

  constructor() {
    this.loading$ = this.loadingSource.asObservable();
  }

  public startLoading = () => {
    this.counter++;
    this.loadingSource.next(true);
  }

  public finishLoading = () => {
    this.counter--;
    if (this.counter <= 0) {
      this.counter = 0;
      this.loadingSource.next(false);
    }
  }
}
