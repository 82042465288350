<!-- Desktop version -->
<div class='ptn-list' *ngIf="!platformService.handsetPlatform">
    <form class='list-body' novalidate [formGroup]="listForm" (ngSubmit)="onSubmit()">
        <div class='left-list-body'>
            <div class="form-group">
                <label for="first_name">{{listTranslation.first_name.field}}</label>
                <input type="text" class="form-control" [ngClass]='{notValid: first_name.invalid && first_name.touched}'
                    id="first_name" formControlName="first_name" (blur)="onBlur()">
            </div>
            <div class="form-group">
                <label for="last_name">{{listTranslation.last_name.field}}</label>
                <input type="text" class="form-control" [ngClass]='{notValid: last_name.invalid && last_name.touched}'
                    id="last_name" formControlName="last_name" (blur)="onBlur()">
            </div>
            <div class="form-group">
                <label for="email">{{listTranslation.email.field}}</label>
                <input type="text" class="form-control" [ngClass]='{notValid: email.invalid && email.touched}' id="email"
                    formControlName="email" (blur)="onBlur()">
            </div>
            <div class="form-group">
                <label for="title">{{listTranslation.title.field}}</label>
                <input type="text" class="form-control" [ngClass]='{notValid: title.invalid && title.touched}' id="title"
                    formControlName="title" (blur)="onBlur()">
            </div>
            <div class="form-group">
                <label for="company">{{listTranslation.company.field}}</label>
                <input type="text" class="form-control" [ngClass]='{notValid: company.invalid && company.touched}'
                    id="company" formControlName="company" (blur)="onBlur()">
            </div>
            <div class="form-group">
                <label for="website">{{listTranslation.website.field}}</label>
                <input type="text" class="form-control" [ngClass]='{notValid: website.invalid && website.touched}'
                    id="website" formControlName="website" (blur)="onBlur()">
            </div>
            <div class="form-group">
                <label for="website">{{listTranslation.presentation.field}}</label>

                <mat-form-field appearance="outline">
                    <ngx-mat-file-input #presentationInput formControlName="presentation" (change)="onFileChange($event.target.files)" [accept]="'.pdf'"></ngx-mat-file-input>

                    <mat-icon matSuffix *ngIf="presentationInput.empty">folder</mat-icon>
                    <button mat-icon-button matSuffix *ngIf="!presentationInput.empty"
                        (click)="onClearPresentationInput($event)">
                        <mat-icon class="cancel-icon">cancel</mat-icon>
<!--
                        <mat-icon class="clear-icon">clear</mat-icon>
-->                        
                    </button>
                </mat-form-field>
                <ptn-file-upload *ngIf="!presentationInput.empty" fileAlias="presentation" [httpUrl]="presentationPresignInfo?.s3PresignedUrl"
                    [file]="filePresentation" [id]="0" (removeEvent)="onClearPresentationInput()" (onUpload)="onUploadFilePresentation($event)" (onUploadComplete)="onUploadFilePresentationComplete($event)">
                </ptn-file-upload>
            </div>
        </div>

        <div class='right-list-body'>
            <h3>{{listTranslation.description.title}}</h3>
            <div [innerHtml]="listTranslation.description.text"></div>
            <h4>{{listTranslation.consent.title}}</h4>
            <div [innerHtml]="listTranslation.consent.text"></div>
            <div class="button">
                <div class="div_message">
                    {{errorMessage}}
                </div>
    
                <button [disabled]='isListFormInvalid' type='submit'>{{listTranslation.add.button}}</button>
            </div>
        </div>
    </form>
</div>
<!-- Mobile version -->
<div class='ptn-list-m' *ngIf="platformService.handsetPlatform">
    <div class='list-header-m'>
        <div class='list-header-inner-m'>{{listTranslation.list}}</div>        
    </div>
    <form class='list-body-m' novalidate [formGroup]="listForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="first_name">{{listTranslation.first_name.field}}</label>
            <input type="text" class="form-control" [ngClass]='{notValid: first_name.invalid && first_name.touched}'
                id="first_name" formControlName="first_name" (blur)="onBlur()">
        </div>
        <div class="form-group">
            <label for="last_name">{{listTranslation.last_name.field}}</label>
            <input type="text" class="form-control" [ngClass]='{notValid: last_name.invalid && last_name.touched}'
                id="last_name" formControlName="last_name" (blur)="onBlur()">
        </div>
        <div class="form-group">
            <label for="email">{{listTranslation.email.field}}</label>
            <input type="text" class="form-control" [ngClass]='{notValid: email.invalid && email.touched}' id="email"
                formControlName="email" (blur)="onBlur()">
        </div>
        <div class="form-group">
            <label for="title">{{listTranslation.title.field}}</label>
            <input type="text" class="form-control" [ngClass]='{notValid: title.invalid && title.touched}' id="title"
                formControlName="title" (blur)="onBlur()">
        </div>
        <div class="form-group">
            <label for="company">{{listTranslation.company.field}}</label>
            <input type="text" class="form-control" [ngClass]='{notValid: company.invalid && company.touched}'
                id="company" formControlName="company" (blur)="onBlur()">
        </div>
        <div class="form-group">
            <label for="website">{{listTranslation.website.field}}</label>
            <input type="text" class="form-control" [ngClass]='{notValid: website.invalid && website.touched}'
                id="website" formControlName="website" (blur)="onBlur()">
        </div>
        <div class="form-group">
            <label>{{listTranslation.presentation.field}}</label>
            <mat-form-field appearance="outline">
                <ngx-mat-file-input #presentationInput formControlName="presentation" (change)="onFileChange($event.target.files)" [accept]="'.pdf'"></ngx-mat-file-input>
                <mat-icon matSuffix *ngIf="presentationInput.empty">folder</mat-icon>
                <button mat-icon-button matSuffix *ngIf="!presentationInput.empty" (click)="onClearPresentationInput($event)">
                    <mat-icon class="cancel-icon">cancel</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="form-group" *ngIf="!presentationInput.empty">
            <ptn-file-upload *ngIf="!presentationInput.empty" fileAlias="presentation"
                [httpUrl]="presentationPresignInfo?.s3PresignedUrl" [file]="filePresentation" [id]="0"
                (removeEvent)="onClearPresentationInput()" (onUpload)="onUploadFilePresentation($event)"
                (onUploadComplete)="onUploadFilePresentationComplete($event)">
            </ptn-file-upload>
        </div>
        <div class="form-group">
            <h3 class="description-title">
                <a [routerLink]="[]" routerLinkActive="active" (click)='showDescription=!showDescription'>
                    <span>{{listTranslation.description.title}}</span>
                    <img *ngIf="!showDescription" src="/assets/imgs/next_blue.png">
                    <img *ngIf="showDescription" src="/assets/imgs/down_blue.png">
                </a>
            </h3>
            <div class="description" *ngIf="showDescription" [innerHtml]="listTranslation.description.text"></div>
        </div>
        <div class="form-group">
            <button class="add-btn" [disabled]='isListFormInvalid' type='submit'>{{listTranslation.add.button}}</button>
            <div class="error_message">{{errorMessage}}</div>
        </div>
        <div class="form-group">
            <div class="consent" [innerHtml]="listTranslation.consent.text"></div>
        </div>
    </form>
</div>