import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { SignInSocialComponent } from './user/sign-in-social/sign-in-social.component';
import { SubscribesComponent } from './subscribes/subscribes.component';
import { CompanyCardComponent } from './companies/company.card.component';
import { SuccessPaymentComponent } from './subscribes/success-payment/success-payment.component';
import { CompanyDealsComponent } from './companies/company.deals.component';
import { PtnIndexComponent } from './ptn-index/ptn.index.component';
import { SearchContainer } from './search/search.container';
import { ListComponent } from './list/list.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { SectorMapComponent } from './analysis/sector.map.component';
import { TopIndustryComponent } from './analysis/top.industry.component';
import { BubbleMapComponent } from './analysis/bubble.map.component';
import { IndustryDealsContainer } from './home/deals/industry.deals.container';
import { FilterCompaniesComponent } from './filter-companies/filter-companies.component';
import { VerifyEmailComponent } from './user/verify.email/verify.email.component';
import { DontRecoveryPasswordComponent } from './user/dont.recovery.password.component/dont.recovery.password.component';
import { PersonCardComponent } from './person/person.card.component';
import { LoginComponent } from './user/login/login.component';
import { TermsOfUseComponent } from './terms.of.use/terms.of.use.component';
import { PrivatePolicyComponent } from './private.policy/private.policy.component';
import { AboutComponent } from './about/about.component';
import { DataCollectionComponent } from './data.collection/data.collection.component';
import { FilterFundsComponent } from './filter-funds/filter-funds.component';
import { FilterPrivateInvestorsComponent } from './filter-private-investors/filter-private-investors.component';
import { HomeComponent } from './home/home.component';
import { SubscribeLevelGuard } from './services/subscribe-level-guard.service';
import { BreadCrumbDynamicLabelGuard } from './services/breadcrumb-dynamic-label-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SendMessageComponent } from './shared/send.message/send.message';
import { PersonCardShortComponent } from './shared/person.card.short/person.card.short.component';



const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    data: { 
      breadcrumb: {
        label: 'HOME',
        alias: 'home'
      }
    },
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'ic/:industryId',
        data: {
          breadcrumb: {
            label: 'INDUSTRY_NAME',
            alias: 'industryName'
          }
        },
        children: [
          {
            path: '',
            component: HomeComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          },
          {
            path: ':categoryId',
            data: {
              breadcrumb: {
                label: 'CATEGORY_NAME',
                alias: 'categoryName'
              }
            },
            children: [
              {
                path: '',
                component: HomeComponent,
                canActivate: [BreadCrumbDynamicLabelGuard]
              },
              {
                path: ':companyId',
                data: {
                  breadcrumb: {
                    label: 'FIRM_NAME',
                    alias: 'firmName'
                  }
                },
                children: [                  
                  {
                    path: '',
                    component: CompanyCardComponent,
                    canActivate: [BreadCrumbDynamicLabelGuard]
                  },
                  {
                    path: 'deals/:dealsId',
                    data: { 
                      breadcrumb: {
                        label: 'DEALS',
                        alias: 'deals'
                      }
                    },
                    component: CompanyDealsComponent,
                    canActivate: [BreadCrumbDynamicLabelGuard]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'i/:industryId',
        data: {
          breadcrumb: {
            label: 'INDUSTRY_NAME',
            alias: 'industryName'
          }
        },
        children: [
          {
            path: '',
            component: HomeComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          },
          {
            path: ':companyId',
            data: {
              breadcrumb: {
                label: 'FIRM_NAME',
                alias: 'firmName'
              }
            },
            children: [
              {
                path: '',
                component: CompanyCardComponent,
                canActivate: [BreadCrumbDynamicLabelGuard]
              },
              {
                path: 'deals/:dealsId',
                data: { 
                  breadcrumb: {
                    label: 'DEALS',
                    alias: 'deals'
                  }
                },
                component: CompanyDealsComponent,
                canActivate: [BreadCrumbDynamicLabelGuard]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'company/:companyId',
    data: {
      breadcrumb: {
        label: 'FIRM_NAME',
        alias: 'firmName'
      }
    },
    children: [
      {
        path: '',
        component: CompanyCardComponent,
        canActivate: [BreadCrumbDynamicLabelGuard]
      },
      {
        path: 'deals/:dealsId',
        data: { 
          breadcrumb: {
            label: 'DEALS',
            alias: 'deals'
          }
        },
        component: CompanyDealsComponent,
        canActivate: [BreadCrumbDynamicLabelGuard]
      }
    ]
  },
  {
    path: 'sign-in-social',
    component: SignInSocialComponent
  },
  {
    path: 'subscribes',
    data: { 
      breadcrumb: {
        label: 'SUBSCRIBE',
        alias: 'subscribe'
      }
    },
    component: SubscribesComponent
  },
  {
    path: 'success-payment',
    component: SuccessPaymentComponent
  },
  {
    path: 'list',
    data: { 
      breadcrumb: {
        label: 'LIST',
        alias: 'list'
      }
    },
    component: ListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ptn-index',
    data: { 
      breadcrumb: {
        label: 'PTN INDEX',
        alias: 'ptn-index'
      }
    },
    component: PtnIndexComponent
  },
  {
    path: 'search',
    data: { 
      breadcrumb: {
        label: 'SEARCH',
        alias: 'search'
      }
    },
    children: [
      {
        path: '',
        component: SearchContainer
      },
      {
        path: ':companyId',
        data: {
          breadcrumb: {
            label: 'FIRM_NAME',
            alias: 'firmName'
          }
        },
        children: [
          {
            path: '',
            component: CompanyCardComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          },
          {
            path: 'deals/:dealsId',
            data: { 
              breadcrumb: {
                label: 'DEALS',
                alias: 'deals'
              }
            },
            component: CompanyDealsComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          }
        ]
      }
    ]    
  },

  {
    path: 'send-message',
    data: { 
      breadcrumb: {
        label: 'SEND MESSAGE',
        alias: 'send-message'
      }
    },
    children: [
      {
        path: "",
        component: SendMessageComponent
      }
    ]
  },
  {
    path: 'person-card',
    data: { 
      breadcrumb: {
        label: 'PERSON CARD',
        alias: 'person-card'
      }
    },
    children: [
      {
        path: "",
        component: PersonCardShortComponent
      }
    ]
  },

  {
    path: 'analysis',
    data: { 
      breadcrumb: {
        label: 'ANALYSIS',
        alias: 'analysis'
      }
    },
    children: [
      {
        path: "",
        component: AnalysisComponent
      },
      {
        path: 'top-industry',
        data: { 
          breadcrumb: {
            label: 'TOP IN THE INDUSTRY',
            alias: 'top-industry'
          }
        },
        children: [
          {
            path: '',
            component: TopIndustryComponent,
          },
          {
            path: ':companyId',
            data: {
              breadcrumb: {
                label: 'FIRM_NAME',
                alias: 'firmName'
              }
            },
            children: [
              {
                path: '',
                component: CompanyCardComponent,
                canActivate: [BreadCrumbDynamicLabelGuard]
              },
              {
                path: 'deals/:dealsId',
                data: { 
                  breadcrumb: {
                    label: 'DEALS',
                    alias: 'deals'
                  }
                },
                component: CompanyDealsComponent,
                canActivate: [BreadCrumbDynamicLabelGuard]
              }
            ]
          }
        ]
      },
      {
        path: 'sector-map',
        data: { 
          breadcrumb: {
            label: 'SECTOR MAP',
            alias: 'sector-map'
          }
        },
        children: [
          {
            path: '',
            component: SectorMapComponent,
          },
          {
            path: ':companyId',
            data: {
              breadcrumb: {
                label: 'FIRM_NAME',
                alias: 'firmName'
              }
            },
            children: [
              {
                path: '',
                component: CompanyCardComponent,
                canActivate: [BreadCrumbDynamicLabelGuard]
              },
              {
                path: 'deals/:dealsId',
                data: { 
                  breadcrumb: {
                    label: 'DEALS',
                    alias: 'deals'
                  }
                },
                component: CompanyDealsComponent,
                canActivate: [BreadCrumbDynamicLabelGuard]
              }
            ]
          }
        ]
      },      
      {
        path: 'bubble-map',
        data: { 
          breadcrumb: {
            label: 'BUBBLE MAP',
            alias: 'bubble-map'
          }
        },
        children: [
          {
            path: '',
            component: BubbleMapComponent,
          },
          {
            path: ':companyId',
            data: {
              breadcrumb: {
                label: 'FIRM_NAME',
                alias: 'firmName'
              }
            },
            children: [
              {
                path: '',
                component: CompanyCardComponent,
                canActivate: [BreadCrumbDynamicLabelGuard]
              },
              {
                path: 'deals/:dealsId',
                data: { 
                  breadcrumb: {
                    label: 'DEALS',
                    alias: 'deals'
                  }
                },
                component: CompanyDealsComponent,
                canActivate: [BreadCrumbDynamicLabelGuard]
              }
            ]
          }
        ]
      }    
    ]
  },
  {
    path: 'deals',
    data: { 
      breadcrumb: {
        label: 'DEALS',
        alias: 'deals'
      }
    },
    children: [
      {
        path: '',
        component: IndustryDealsContainer
      },
      {
        path: ':companyId',
        data: {
          breadcrumb: {
            label: 'FIRM_NAME',
            alias: 'firmName'
          }
        },
        children: [
          {
            path: '',
            component: CompanyCardComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          },
          {
            path: 'deals/:dealsId',
            data: { 
              breadcrumb: {
                label: 'DEALS',
                alias: 'deals'
              }
            },
            component: CompanyDealsComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          }
        ]
      }
    ]    
  },
  { 
    path: 'filter-companies',
    data: { 
      breadcrumb: {
        label: 'COMPANIES',
        alias: 'filter-companies'
      }
    },
    children: [
      {
        path: '',
        component: FilterCompaniesComponent
      },
      {
        path: ':companyId',
        data: {
          breadcrumb: {
            label: 'FIRM_NAME',
            alias: 'firmName'
          }
        },
        children: [
          {
            path: '',
            component: CompanyCardComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          },
          {
            path: 'deals/:dealsId',
            data: { 
              breadcrumb: {
                label: 'DEALS',
                alias: 'deals'
              }
            },
            component: CompanyDealsComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          }
        ]
      }
    ]
  },
  { 
    path: 'filter-funds',
    data: { 
      breadcrumb: {
        label: 'VC FUNDS',
        alias: 'filter-funds'
      }
    },
    children: [
      {
        path: '',
        component: FilterFundsComponent
      },
      {
        path: ':companyId',
        data: {
          breadcrumb: {
            label: 'FIRM_NAME',
            alias: 'firmName'
          }
        },
        children: [
          {
            path: '',
            component: CompanyCardComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          },
          {
            path: 'deals/:dealsId',
            data: { 
              breadcrumb: {
                label: 'DEALS',
                alias: 'deals'
              }
            },
            component: CompanyDealsComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          }
        ]
      }
    ]
  },
  { 
    path: 'filter-private-investors',
    data: { 
      breadcrumb: {
        label: 'PRIVATE INVESTORS',
        alias: 'filter-private-investors'
      }
    },
    children: [
      {
        path: '',
        component: FilterPrivateInvestorsComponent
      },
      {
        path: ':companyId',
        data: {
          breadcrumb: {
            label: 'FIRM_NAME',
            alias: 'firmName'
          }
        },
        children: [
          {
            path: '',
            component: CompanyCardComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          },
          {
            path: 'deals/:dealsId',
            data: { 
              breadcrumb: {
                label: 'DEALS',
                alias: 'deals'
              }
            },
            component: CompanyDealsComponent,
            canActivate: [BreadCrumbDynamicLabelGuard]
          }
        ]
      }
    ]
  },
  {
    path: 'verification',
    component: VerifyEmailComponent
  },
  {
    path: 'recovery',
    redirectTo: 'login/recovery',
    pathMatch: 'full'
  },
  {
    path: 'recovery_did_not_request',
    component: DontRecoveryPasswordComponent
  },
  {
    path: 'person/:personId',
    data: {
      breadcrumb: {
        label: 'PERSON_NAME',
        alias: 'personName'
      }
    },
    component: PersonCardComponent,
    canActivate: [BreadCrumbDynamicLabelGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },  
  {
    path: 'private-policy',
    data: { 
      breadcrumb: {
        label: 'PRIVACY',
        alias: 'private-policy'
      }
    },
    component: PrivatePolicyComponent
  },
  {
    path: 'term-of-use',
    data: { 
      breadcrumb: {
        label: 'TERM OF USE',
        alias: 'term-of-use'
      }
    },
    component: TermsOfUseComponent
  },
  {
    path: 'data-collect',
    data: { 
      breadcrumb: {
        label: 'DATA COLLECTION',
        alias: 'data-collect'
      }
    },
    component: DataCollectionComponent
  },
  {
    path: 'about',
    data: { 
      breadcrumb: {
        label: 'ABOUT',
        alias: 'about'
      }
    },
    component: AboutComponent
  },
  {
    path: 'login/sign-in',
    data: { 
      breadcrumb: {
        label: 'LOGIN',
        alias: 'sign-in'
      }
    },
    component: LoginComponent
  },
  {
    path: 'login/register',
    data: { 
      breadcrumb: {
        label: 'REGISTER',
        alias: 'register'
      }
    },
    component: LoginComponent
  },
  {
    path: 'login/forgot-pass',    
    component: LoginComponent
  },
  {
    path: 'login/recovery',
    component: LoginComponent
  },
  { 
    path: '404',
    component: PageNotFoundComponent},
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
