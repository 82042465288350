<div class="main" *ngIf="!platformService.handsetPlatform">
    <span class="header">{{dataCollectTranslation.page.title}}</span>
    <div class="body" [innerHtml]="dataCollectTranslation.page.text"></div>
</div>


<!-- Mobile -->

<div class="mob-main" *ngIf="platformService.handsetPlatform">
    <span class="mob-header">{{dataCollectTranslation.page.title}}</span>
    <div class="mob-body" [innerHtml]="dataCollectTranslation.page.text"></div>
</div>
