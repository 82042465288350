<div class="search" id="search" [ngClass]="{ sendMessage: showSendMessage }">
    <div class="main">
        <div class="search-box" >
            <div class="search-form">
                <input id="searchInput" type="text" placeholder="{{allTranslation.text}}" #searchInput (input)="onSearchInput(searchInput.value)"
                    (focus)="onSearchFocus()" autofocus [(value)]="searchValue">
                <img class="magnifier" *ngIf="!searchInput.value" src="/assets/imgs/SearchBlack.svg">
                <a class="cancel-magnifier" (click)="onClearSearchClick()" [routerLink]="[]" routerLinkActive="active">
                    <img *ngIf="searchInput.value" src="/assets/imgs/filter-clear.png">
                </a>
            </div>
        </div>

        <div class="not-found" *ngIf="searchAllResultPC.length == 0 && searchAllResultFI.length == 0 && searchAllResultPI.length == 0 && searchAllResultPE.length == 0 && wasSearch">
                {{allTranslation.not_found}}
        </div>

        <div class="search-result">
        <!-- PC -->
            <div class="container-header" *ngIf="searchAllResultPC && searchAllResultPC.length > 0 && searchResultPC">
                {{allTranslation.type_object.private_company}}&nbsp;&nbsp;&nbsp;&nbsp;
                {{foundCountPC}}
            </div>
            
            <div class="content" *ngIf="searchResultPC">
                <div class="group group-pc" *ngFor="let resultPC of searchResultPC" [ngClass]="{ 'linkDisabled': resultPC.subscribe_level > userSubscribeLevel }">
                    <div class="one-line">
                        <div class="link" id="{{resultPC.type_entity}}{{resultPC.code_entity}}">
<!--                            <a [routerLink]="['./', resultPC.code_entity]"> -->
                            <a [routerLink]="[]" routerLinkActive="active" (click)="onEntityClick(resultPC)">
                                <img src="{{resultPC.image_url}}">
                                {{resultPC.name_entity}}
                            </a>

                            <a class="send-message-button" *ngIf="resultPC.is_send_message == 1 || resultPC.is_send_message == 3" (click)="onSendMessageCompanyFounderClick(resultPC)">
                                <img src="/assets/imgs/messagebubble_1.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <a class="send-message-button" *ngIf="resultPC.is_send_message == 2 || resultPC.is_send_message == 3" (click)="onSendMessageCompanyClick(resultPC)">
                                <img src="/assets/imgs/messagebubble_2.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <div class="social-network" *ngFor="let social of resultPC.socialNetworks; let lastIten = last;">
                                <a href="{{social.address}}" target="_blank" *ngIf="social.type_address=='LI'">
                                    <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                </a>
                            </div>

                            <div class="company-info" *ngFor="let focus of resultPC.focus; let lastItem = last;">
                                <span>{{focus.text}}</span>
                                <a [routerLink]="['./', focus.code_firm]">{{focus.name_firm}}</a>
                                <div *ngIf="!lastItem">,</div>
                            </div>
                        </div>
                    </div>            
                </div>
                                
                <div class="show-more" *ngIf="searchResultPC && searchResultPC.length > 0 && searchResultPC[0].type_entity_count > searchResultPC.length">
                    <a [routerLink]="[]" routerLinkActive="active" (click)="onShowMoreClick('PC')">
                        {{allTranslation.show_more}}&nbsp;
                        <img src="/assets/imgs/ShowMore.svg">
                        <img *ngIf="neadSubscribeShowMore(showMoreObject[0])" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                </div>
            </div>

        <!-- FI -->
            <div class="container-header" *ngIf="searchAllResultFI && searchAllResultFI.length > 0 && searchResultFI">
                {{allTranslation.type_object.firm_investor}}&nbsp;&nbsp;&nbsp;&nbsp;
                {{foundCountFI}}
            </div>
            <div class="content" *ngIf="searchResultFI">
                <div class="group group-fi" *ngFor="let resultFI of searchResultFI" [ngClass]="{ 'linkDisabled': resultFI.subscribe_level > userSubscribeLevel }">
                    <div class="one-line">
                        <div class="link" id="{{resultFI.type_entity}}{{resultFI.code_entity}}">
<!--                            <a [routerLink]="['./', resultFI.code_entity]"> -->
                            <a [routerLink]="[]" routerLinkActive="active" (click)="onEntityClick(resultFI)">
                                <img src="{{resultFI.image_url}}">
                                {{resultFI.name_entity}}
                            </a>

                            <a class="send-message-button" *ngIf="resultFI.is_send_message == 1 || resultFI.is_send_message == 3" (click)="onSendMessageCompanyFounderClick(resultFI)">
                                <img src="/assets/imgs/messagebubble_1.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <a class="send-message-button" *ngIf="resultFI.is_send_message == 2 || resultFI.is_send_message == 3" (click)="onSendMessageCompanyClick(resultFI)">
                                <img src="/assets/imgs/messagebubble_2.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <div class="social-network" *ngFor="let social of resultFI.socialNetworks; let lastIten = last;">
                                <a href="{{social.address}}" target="_blank" *ngIf="social.type_address=='LI'">
                                    <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                </a>
                            </div>

                            <div class="company-info" *ngFor="let focus of resultFI.focus; let lastItem = last; let firstItem = first;">
                                <span>{{focus.text}}</span>
                                <a [routerLink]="['./', focus.code_firm]">{{focus.name_firm}}</a>
                                <div *ngIf="!lastItem">,</div>
                            </div>

                        </div>
                    </div>            
                </div>
                <div class="show-more" *ngIf="searchResultFI && searchResultFI.length > 0 && searchResultFI[0].type_entity_count > searchResultFI.length">
                    <a [routerLink]="[]" routerLinkActive="active" (click)="onShowMoreClick('FI')"
                        >{{allTranslation.show_more}}&nbsp;
                        <img src="/assets/imgs/ShowMore.svg">
                        <img *ngIf="neadSubscribeShowMore(showMoreObject[0])" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                </div>
            </div>

        <!-- PI -->
            <div class="container-header" *ngIf="searchAllResultPI && searchAllResultPI.length > 0 && searchResultPI">
                {{allTranslation.type_object.private_investor}}&nbsp;&nbsp;&nbsp;&nbsp;
                {{foundCountPI}}
            </div>
            <div class="content" *ngIf="searchResultPI">
                <div class="group group-pi" *ngFor="let resultPI of searchResultPI" [ngClass]="{ 'linkDisabled': resultPI.subscribe_level > userSubscribeLevel }">
                    <div class="one-line">
                        <div class="link" id="{{resultPI.type_entity}}{{resultPI.code_entity}}">
<!--                            <a [routerLink]="['./', resultPI.code_entity]"> -->
                            <a [routerLink]="[]" routerLinkActive="active" (click)="onEntityClick(resultPI)">
                                <img src="{{resultPI.image_url}}">
                                {{resultPI.name_entity}}
                            </a>

                            <a class="send-message-button" *ngIf="resultPI.is_send_message == 1 || resultPI.is_send_message == 3" (click)="onSendMessageCompanyFounderClick(resultPI)">
                                <img src="/assets/imgs/messagebubble_1.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <a class="send-message-button" *ngIf="resultPI.is_send_message == 2 || resultPI.is_send_message == 3" (click)="onSendMessageCompanyClick(resultPI)">
                                <img src="/assets/imgs/messagebubble_2.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <div class="social-network" *ngFor="let social of resultPI.socialNetworks; let lastIten = last;">
                                <a href="{{social.address}}" target="_blank" *ngIf="social.type_address=='LI'">
                                    <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                </a>
                            </div>

                            <div class="company-info" *ngFor="let focus of resultPI.focus; let lastItem = last;">
                                <span>{{focus.text}}</span>
                                <a [routerLink]="['./', focus.code_firm]">{{focus.name_firm}}</a>
                                <div *ngIf="!lastItem">,</div>
                            </div>
                        </div>
                    </div>            
                </div>
                <div class="show-more" *ngIf="searchResultPI && searchResultPI.length > 0 && searchResultPI[0].type_entity_count > searchResultPI.length">
                    <a [routerLink]="[]" routerLinkActive="active" (click)="onShowMoreClick('PI')">
                        {{allTranslation.show_more}}&nbsp;
                        <img src="/assets/imgs/ShowMore.svg">
                        <img *ngIf="neadSubscribeShowMore(showMoreObject[0])" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                </div>
            </div>

        <!-- PE -->
            <div class="container-header" *ngIf="searchAllResultPE && searchAllResultPE.length > 0 && searchResultPE">
                {{allTranslation.type_object.person}}&nbsp;&nbsp;&nbsp;&nbsp;
                {{foundCountPE}}
            </div>
            <div class="content" *ngIf="searchResultPE">
                <div class="group group-pe" *ngFor="let resultPE of searchResultPE" [ngClass]="{ 'linkDisabled': resultPE.subscribe_level > userSubscribeLevel }">
                    <div class="one-line">
                        <div class="link" id="{{resultPE.type_entity}}{{resultPE.code_entity}}">
                            <a (click)="onPersonClick($event, resultPE)" (mousemove)="onPersonMouseMove($event, resultPE)" (mouseout)="onPersonMouseOut($event, resultPE)">
                                <img src="/assets/imgs/human.png">
                                {{resultPE.name_entity}}
                            </a>

                            <a class="send-message-button" *ngIf="resultPE.is_send_message == 1 || resultPE.is_send_message == 3" (click)="onSendMessagePersonClick(resultPE)">
                                <img src="/assets/imgs/messagebubble_1.png">
                                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <div class="social-network" *ngFor="let social of resultPE.socialNetworks ; let lastIten = last;">
                                <a href="{{social.address}}" target="_blank" *ngIf="social.type_address=='LI'">
                                    <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                </a>
                            </div>

                            <div class="company-info" *ngFor="let focus of resultPE.focus; let lastItem = last;">
                                <span>{{focus.text}}&nbsp;</span>
<!--                                <a [routerLink]="['./', focus.code_firm]">{{focus.name_firm}}</a> -->
                                <a [routerLink]="[]" routerLinkActive="active" (click)="onEntityClick(resultPE, focus.code_firm)">{{focus.name_firm}}</a>
                                <div *ngIf="!lastItem">,</div>
                            </div>
                            
                        </div>
                    </div>            
                </div>
                <div class="show-more" *ngIf="searchResultPE && searchResultPE.length > 0 && searchResultPE[0].type_entity_count > searchResultPE.length">
                    <a [routerLink]="[]" routerLinkActive="active" (click)="onShowMoreClick('PE')">
                        {{allTranslation.show_more}}&nbsp;
                        <img src="/assets/imgs/ShowMore.svg">
                        <img *ngIf="neadSubscribeShowMore(showMoreObject[0])" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="person-card" id="person-card" *ngIf="showPersonCard" [ngStyle]="getPersonCardPosition()">
    <ptn-person-card [personID]="personID" (onClosePersonCard)="onClosePersonCard($event)"
            (onPersonCardBusy)="onPersonCardBusy($event)"></ptn-person-card>
</div>

<div class="send-message" *ngIf="showSendMessage">
    <ptn-send-message [personID]="messagePersonID" [companyID]="messageCompanyID" [companyForFounder]="companyForFounder" (onCloseMessage)="onCloseMessage($event)"></ptn-send-message>
</div>
