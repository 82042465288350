import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { User } from '../models/user';
import { SocialnetAction } from '../shared/socialnet-action';

@Injectable()
export class LocalStorageService {
 
    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
    }
 
    has(key: string): boolean {
        return this.storage.has(key);
    }

    get(key: string): any {
        return this.storage.get(key);
    }

    set(key: string, value: any): void {
        this.storage.set(key, value);
    }

    remove(key: string): void {
        this.storage.remove(key);
    }

    clear(): void {
        this.storage.clear();
    }    

    public get x_vms_session(): string {
        return this.storage.get('x_vms_session');
    }
 
    public set x_vms_session(value: string) {
        this.storage.set('x_vms_session', value);
    }

    removeSession(): void {
        this.storage.remove('x_vms_session');
    }

    public get user(): User {
        return this.storage.get('user');
    }
 
    public set user(value: User) {
        this.storage.set('user', value);
    }

    removeUser(): void {
        this.storage.remove('user');
    }
    
    public get socialnetAction(): SocialnetAction {
        return this.storage.get('socialnetAction');
    }
 
    public set socialnetAction(value: SocialnetAction) {
        this.storage.set('socialnetAction', value);
    }

    public get idDevice(): string {
        return this.storage.get('idDevice');
    }
 
    public set idDevice(value: string) {
        this.storage.set('idDevice', value);
    }
}