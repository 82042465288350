<div class="forgot-password">

    <form novalidate [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
    
        <div class="form-group">
            <label>{{forgotPassTranslation.email.field}}</label>
            <input type="email" class="form-control" formControlName="email" placeholder={{forgotPassTranslation.email.placeholder}}
                    (change)="onChange('email')" [ngClass]="controlValid.email">
            <div *ngIf="controlErrors.email" class="error-message">
              {{ controlErrors.email }}
            </div>
            <div *ngIf="!controlErrors.email">
                &nbsp;
            </div>
        </div>

        <div class="div-button">
            <button type="submit" [disabled]="!forgotPasswordForm.valid">{{forgotPassTranslation.reset_password.button}}</button>
        </div>

    </form>

</div>