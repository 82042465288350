import { Component, OnInit, AfterViewChecked, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Company, CompanyListInfo, Founder } from "./../../models/company.data";
import { HomeHeaderService } from './../../services/home.header.service';
import { TranslationService } from '../../services/translation.service';
import { langPAGE_HOME } from "./../../shared/constant.module";
import { CompanyService } from "./../../services/company.service";
import { TranslateObject } from './../../services/translation.service';
import { CompaniesTranslation } from './../../shared/translation.const';
import { WebAddressService } from './../../services/web.address.service';
import { limitCompanyTiles } from './../../shared/constant.module';
import { SessionStorageService } from './../../services/session-storage-service';
import { UserService } from './../../services/user.service';
import { RepresentService } from './../../services/represent.service';
import { LoaderService } from './../../services/loader.service';
import { User } from './../../models/user';
import { LocalStorageService } from './../../services/local-storage-service';
import { Subscription } from 'rxjs';
import { PlatformService } from './../../services/platform.service';


@Component({
    selector: 'ptn-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.css']
  })
  
export class CompaniesComponent implements OnInit, AfterViewChecked, OnDestroy {

    private widthHomeBody: number;
    private currentOffset: number = 0;
    private realOffset: number = 0;
    private currentLimit: number = limitCompanyTiles;
    private currentCompanyID: number = undefined;

    private founderClickX: number = 0;
    private founderClickY: number = 0;

    private subscriptionSort: Subscription;
    private subscriptionParams: Subscription;
    private subscriptionFragment: Subscription;
    private subscriptionViewingCompanies: Subscription;

    private viewingCompanies: string = "PC";

    listCompany: Array<Company>;
    sendButtonPerson: Array<TranslateObject> = [];
    sendButtonCompany: Array<TranslateObject> = [];
    showMoreObject: Array<TranslateObject> = [];

    tileSize = {
        bigSize: true
    }

    noMore = {
      disabledMore: true
    }

    showPersonCard: boolean = false;
    showSendMessage: boolean = false;

    categoryID: number = null;
    industryID: number = null;

    selectedSort: string = "";

    sortingTile: Array<TranslateObject> = [];

    // Переклади для елементів інтерфесу
    companiesTranslation = CompaniesTranslation;

    private personCardBusy: boolean = false;
    private companyPersonCardBusy: boolean = false;
    private timerID = undefined;

    personID: number = 0;
    companyID: number = null;
    companyForFounder: boolean = false;

    isVisorVisible: boolean = false;
    

    constructor(private homeHeaderService: HomeHeaderService, private translationService: TranslationService,
            public companyService: CompanyService, private webAddressService: WebAddressService,
            private activatedRoute: ActivatedRoute, private sessionStorage: SessionStorageService,
            private userService: UserService, private router: Router, private representService: RepresentService,
            private loaderService: LoaderService, private localStorage: LocalStorageService,
            public platformService: PlatformService) {

        this.industryID = this.activatedRoute.snapshot.paramMap.get('industryId') ? +this.activatedRoute.snapshot.paramMap.get('industryId') : 0;
        this.categoryID = this.activatedRoute.snapshot.paramMap.get('categoryId') ? +this.activatedRoute.snapshot.paramMap.get('categoryId') : 0;

        this.viewingCompanies = this.representService.takeCompanyViewType(this.localStorage.get("viewingCompanies"));

        // Отримуємо перелік сортувань для combobox
        this.takeSortList();

        this.subscriptionFragment = this.activatedRoute.fragment.subscribe(
            (fragment: string) => {

                this.currentCompanyID = +fragment;
            }
        );

        this.subscriptionParams = this.activatedRoute.queryParams.subscribe((queryParam: any) => {
              
            if (queryParam['offset']) {

                this.currentOffset = queryParam['offset'];

                if (this.currentOffset >= limitCompanyTiles) {

                    this.currentLimit = Number(this.currentOffset) + Number(limitCompanyTiles);
                    this.realOffset = this.currentOffset;
                    this.currentOffset = 0;
                }
            }

            if (queryParam['view_type']) {

                this.viewingCompanies = queryParam['view_type'];                
            }    

            if (!this.listCompany || this.listCompany.length <= 0 || (this.listCompany[0].code_industry != this.industryID && this.listCompany[0].code_category != this.categoryID)) {

                this.listCompany = [];
                this.getCompanyList();
            }

            if (this.subscriptionParams && !this.subscriptionParams.closed)
                this.subscriptionParams.unsubscribe();
        });

        if (this.listCompany && this.listCompany.length > 0) {

            this.noMore.disabledMore = (this.listCompany[0].all_count <= this.listCompany.length);
        }

        let tmpSize = this.homeHeaderService.loadTileSize('companies');
        if (tmpSize != undefined)
            this.tileSize.bigSize = tmpSize;

        setTimeout(() => { 
            this.representService.setTileSize$.next(this.tileSize.bigSize);
        }, 50);

        this.translationService.getTranslate(langPAGE_HOME, this.companiesTranslation);
        this.translationService.getCategoryObject('PAGE_PERSON.BUTTON.MESSAGE', this.sendButtonPerson);
        this.translationService.getCategoryObject('PAGE_HOME.FIRM.CARD.BUTTON.MESSAGE', this.sendButtonCompany);
        this.translationService.getCategoryObject('PAGE_HOME.COMPANY.SHOW_MORE', this.showMoreObject);
    }


    ngOnInit() {

        this.subscriptionSort = this.homeHeaderService.sortTileChanged$.subscribe((selectedObject) => {

            if (selectedObject) {
                this.listCompany = [];
                this.currentOffset = 0;
                this.localStorage.set('sortCompanyID', selectedObject.object_id);
                this.getCompanyList();
            }
        });

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate(langPAGE_HOME, this.companiesTranslation);
            this.translationService.getCategoryObject('PAGE_PERSON.BUTTON.MESSAGE', this.sendButtonPerson);
            this.translationService.getCategoryObject('PAGE_HOME.FIRM.CARD.BUTTON.MESSAGE', this.sendButtonCompany);
            this.translationService.getCategoryObject('PAGE_HOME.COMPANY.SHOW_MORE', this.showMoreObject);

            this.listCompany = [];
            this.takeSortList();
            this.getCompanyList();
        });

        this.userService.Authenticated$.subscribe((isAuthenticated: boolean) => {

            this.sessionStorage.remove('listCompany');
            this.listCompany = [];
            this.getCompanyList();
        });

        this.representService.tileBigSizeChanged$.subscribe((bigSize: boolean) => {

            this.tileSize.bigSize = bigSize;
        });
        
        setTimeout(() => {

            const element = document.getElementById('body');
            if (element) {

                element.focus();
            }
        }, 0);

        this.userService.loginChanged$.subscribe((user: User) => {

            this.takeSortList();
        });

        this.subscriptionViewingCompanies = this.representService.viewingCompaniesChanged$.subscribe((viewingCompaniesType: string) => {

            this.viewingCompanies = viewingCompaniesType;
            this.listCompany = [];
            this.getCompanyList();
        });

    }


    ngOnDestroy() {

        this.subscriptionSort.unsubscribe();
        this.subscriptionViewingCompanies.unsubscribe();
    }


    private takeSortList() {

        this.sortingTile = [];
        this.translationService.getObjectBySubscribe('PAGE_HOME.COMPANY.SORTING_OPTIONS', this.sortingTile, this.userService.getUserSubscribeLevel());
        if (this.sortingTile && this.sortingTile.length > 0) {

            this.representService.setSortList$.next(this.sortingTile);
            this.getSelectedSort();
        }
    }


    ngAfterViewChecked() {

        if (document.querySelector('div.main'))
            this.widthHomeBody = document.querySelector('div.main').clientWidth;

        if (this.currentCompanyID /* && !this.platformService.handsetPlatform */) {
            let element = document.getElementById("company" + this.currentCompanyID.toString());
            if (element) {

                element.scrollIntoView();
                this.currentCompanyID = undefined;
            }
        }
    }


    getSelectedSort() {

        if (this.sortingTile.length > 0) {
            let storedSortID = this.localStorage.get('sortCompanyID');
            if (storedSortID) {
                for (let i = 0; i < this.sortingTile.length; i++) {
                    if (this.sortingTile[i].object_id == storedSortID) {

                        this.selectedSort = this.sortingTile[i].translation;
                        break;
                    }
                }
            }

            if (!this.selectedSort) {
                this.selectedSort = this.sortingTile[0].translation;
                this.localStorage.set('sortCompanyID', this.sortingTile[0].object_id);
            }
    
            this.representService.selectedSort$.next(this.selectedSort);
        }
    }


    getCompanyList() {

        if (!this.sortingTile || this.sortingTile.length <= 0)
            return;

        let sortID = this.localStorage.get('sortCompanyID');

        if (!sortID || !this.homeHeaderService.isSortIndexExists(this.sortingTile, sortID))
            sortID = this.sortingTile[0].object_id;

        if (this.subscriptionParams && !this.subscriptionParams.closed)
            this.subscriptionParams.unsubscribe();

        if (this.subscriptionFragment && !this.subscriptionFragment.closed)
            this.subscriptionFragment.unsubscribe();

        this.companyService.getCompanyList(this.industryID, this.categoryID, this.currentLimit, this.currentOffset, sortID, this.viewingCompanies).subscribe(
            response => {
                let json = response;

                if (json && (json.length > 0)) {
                    if (!this.listCompany) {
                        this.listCompany = json;
                    } else {
                        for (let i = 0; i < json.length; i++)
                            this.listCompany.push(json[i]);
                    }
                    
                    this.prepareWeb();
                    this.checkSubscribeLevel();
                    this.sessionStorage.set('listCompany', this.listCompany);
                    this.noMore.disabledMore = (this.listCompany[0].all_count <= this.listCompany.length);

                    let companyListInfo: CompanyListInfo = {
                        code_industry: this.industryID,
                        code_category: this.categoryID,
                        name_industry: this.listCompany[0].name_industry.toUpperCase(),
                        name_category: this.categoryID < 0 ? this.listCompany[0].name_industry.toUpperCase() : this.listCompany[0].name_category?.toUpperCase(),
                        all_count: this.listCompany[0].all_count,
                        loaded_count: this.listCompany.length,
                        name_type_firm: this.listCompany[0].name_type_firm
                    };

                    this.companyService.listCompanyLoaded$.next(companyListInfo);
                } else {
                    this.noMore.disabledMore = true;
                }

                this.execNavigate();
            },
            error => {
                
                this.currentLimit = limitCompanyTiles;
                console.log(error);

                this.execNavigate();
            }
        );
    }


    private execNavigate() {

        if (this.realOffset > 0 && this.realOffset > this.currentOffset)
            this.currentOffset = this.realOffset;

        this.currentLimit = limitCompanyTiles;

        if (this.currentOffset > 0) {
            this.router.navigate([], { queryParams: { "offset": this.currentOffset, "view_type": this.viewingCompanies} });
        } else {
            this.router.navigate([], { queryParams: { "view_type": this.viewingCompanies} });
        }
    }


    getCanvasWidthSmall() {
 
      let canvasWidth = this.widthHomeBody;
      let sizeTile: number;

      if (this.tileSize.bigSize)
        return null
      else
        sizeTile = 225;

      canvasWidth = canvasWidth / (sizeTile + 6 * 2);
      canvasWidth = Math.floor(canvasWidth) * (sizeTile + 6 * 2);

      let styles = {
          "width": canvasWidth.toString() + 'px'
      };

      return styles;
    }


    getCanvasWidthBig() {

        let canvasWidth = this.widthHomeBody;
        let sizeTile: number;

        if (this.tileSize.bigSize) {

            if (canvasWidth <= 1440 && canvasWidth > 1250) {
                sizeTile = 605;
            } else {
                sizeTile = 700;
            }
        }
        else
            sizeTile = 225;

        canvasWidth = canvasWidth / (sizeTile + 6 * 2);

        canvasWidth = Math.floor(canvasWidth) * (sizeTile + 6 * 2) + 18;

        let styles = {
            "width": canvasWidth.toString() + 'px'
        };
  
        return styles;
      }
  

    onResize(event) {

        this.widthHomeBody = document.querySelector('div.main').clientWidth;
    }


    private prepareWeb() {

        for (let i = 0; i < this.listCompany.length; i++)
            this.listCompany[i].websiteToShow = this.webAddressService.cutProtocol(this.listCompany[i].website);
    }


    private showMoreClick() {

        if (this.neadSubscribe(this.showMoreObject[0].subscribe_level)) {
            this.router.navigate(['/subscribes']);
        } else {
/*
            if (this.realOffset > 0 && this.realOffset > this.currentOffset)
                this.currentOffset = this.realOffset;
*/
            this.currentOffset = Number(this.currentOffset) + Number(limitCompanyTiles);
            this.getCompanyList();
        }
    }


    onShowMoreClick() {
        this.showMoreClick();
    }


    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        if (!this.loaderService.IsLoading && !this.noMore.disabledMore && Math.ceil(event.target.offsetHeight + event.target.scrollTop) >= event.target.scrollHeight) {
            console.log('OnScrollEnd');
            this.showMoreClick();
        }
    }


    neadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel: number = 0;
  
        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        return (subscribeLevel > userSubsribeLevel);
    }
 

    neadSubscribeShowMore(showMore: TranslateObject): boolean {

        let userSubsribeLevel: number = 0;
  
        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        if (!showMore) {

            return false;
        } else {

            return (showMore.subscribe_level > userSubsribeLevel);
        }
    }

    
    getSocialNetImage(socialTypeAddress: string): string {

        return this.webAddressService.getSocialImg(socialTypeAddress);
    }


    onSendMessageFounderClick(founder: Founder) {

        if (this.neadMessagePersonSubscribe()) {

            this.router.navigate(['/subscribes']);
        } else {

            if (this.platformService.handsetPlatform) {
                this.router.navigate(['/send-message'], { queryParams: { person_id: founder.code_person, company_id: null } });
            } else {
                this.personID = founder.code_person;
                this.companyID = null;
                this.showSendMessage = true;
            }
        }
    }


    onSendMessageCompanyClick(company: Company) {

        if (this.currentOffset > 0) {
            this.router.navigate([], { queryParams: { "offset": this.currentOffset}, fragment: company.code_firm.toString() });
        } else {
            this.router.navigate([], { fragment: company.code_firm.toString() });
        }

        setTimeout(() => { 

            if (this.neadMessageCompanySubscribe()) {
                this.router.navigate(['/subscribes']);
            } else {
                if (this.platformService.handsetPlatform) {
                    this.router.navigate(['/send-message'], { queryParams: { person_id: null, company_id: company.code_firm, for_person: 0 } });
                } else {
                    this.personID = null;
                    this.companyID = company.code_firm;
                    this.companyForFounder = false;
                    this.showSendMessage = true;
                }
            }
        }, 10);
    }


    onSendMessageCompanyFounderClick(company: Company) {

        if (this.currentOffset > 0) {
            this.router.navigate([], { queryParams: { "offset": this.currentOffset}, 
                                    fragment: company.code_firm.toString() });
        } else {
            this.router.navigate([], { fragment: company.code_firm.toString() });
        }

        setTimeout(() => { 
            if (this.neadMessageCompanySubscribe()) {
                this.router.navigate(['/subscribes']);
            } else {
                if (this.platformService.handsetPlatform) {
                    this.router.navigate(['/send-message'], { queryParams: { person_id: null, company_id: company.code_firm, for_person: 1 } });
                } else {
                    this.personID = null;
                    this.companyID = company.code_firm;
                    this.companyForFounder = true;
                    this.showSendMessage = true;
                }
            }
        }, 10);
    }


    getPersonCardPosition() {

        if (this.founderClickX <= 0 || this.founderClickY <= 0)
            return undefined;

        let founderClickX = this.founderClickX;
        let founderClickY = this.founderClickY;

        let element = document.getElementById('person-card');
        if (element) {

            founderClickY = founderClickY - element.clientHeight - 20;
            if (founderClickY < 0)
                founderClickY = 5;
            founderClickX = founderClickX - element.clientWidth / 2;
            if (founderClickX < 0)
                founderClickX = 5;
        }

        let styles = {
            "top": founderClickY.toString() + 'px',
            "left": founderClickX.toString() + 'px'
        };

        return styles;
    }


    onFounderClick(event: MouseEvent, founder: Founder) {

        if (event) {
            this.founderClickX = event.pageX;
            this.founderClickY = event.pageY;
        }

        this.personID = founder.code_person;
        this.showPersonCard = true;
    }


    onClosePersonClick() {

        this.showPersonCard = false;
    }


    neadMessagePersonSubscribe(): boolean {

        if (!this.sendButtonPerson || (this.sendButtonPerson.length <= 0))
            return false;

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        return (this.sendButtonPerson[0].subscribe_level > userSubsribeLevel);
    }


    neadMessageCompanySubscribe(): boolean {

        if (!this.sendButtonCompany || (this.sendButtonCompany.length <= 0))
            return false;

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        return (this.sendButtonCompany[0].subscribe_level > userSubsribeLevel);
    }


    onCloseMessage(event: any) {

        this.showSendMessage = false;
    }


    onClosePersonCard(event: boolean) {

        this.showPersonCard = !event;
    }


    onFounderMouseMove(event: MouseEvent, founder: Founder) {

        if (event && !this.showPersonCard) {
            this.founderClickX = event.pageX;
            this.founderClickY = event.pageY;
        }

        this.companyPersonCardBusy = true;

        if (this.timerID) {
            clearTimeout(this.timerID);
            this.timerID = undefined;
        }

        if (this.personID != founder.code_person) {

            this.companyPersonCardBusy = false;
            this.showPersonCard = false;
        }

        this.timerID = setTimeout(() => { 

            this.personID = founder.code_person;
            this.showPersonCard = true;
        }, 500);
    }


    onFounderMouseOut(event: any, founder: Founder) {

        if (this.timerID) {
            clearTimeout(this.timerID);
            this.timerID = undefined;
        }

        this.companyPersonCardBusy = false;

        this.timerID = setTimeout(() => { 

            if (!this.companyPersonCardBusy && !this.personCardBusy)
                this.showPersonCard = false;
        }, 2000);
    }


    onPersonCardBusy(event: boolean) {

        if (this.timerID) {
            clearTimeout(this.timerID);
            this.timerID = undefined;
        }

        this.personCardBusy = event;

        if (!this.personCardBusy) {

            this.timerID = setTimeout(() => { 

                if (!this.companyPersonCardBusy && !this.personCardBusy)
                    this.showPersonCard = false;
            }, 2000);
        }
    }


    private checkSubscribeLevel() {

        this.isVisorVisible = false;

        if (!this.listCompany)
            return;

        let subsLvl: number = 0;
        let res = this.userService != undefined && this.userService.user != undefined;

        if (res)
            subsLvl = this.userService.user.subscribe_level;

        for (let i = 0; i < this.listCompany.length; i ++) {
            if (this.listCompany[i].subscribe_level > subsLvl) {
                this.isVisorVisible = true;
                break;
            }
        }
    }


    getVisorStyle() {

        let top: number;
        let height: string;

        if (this.tileSize.bigSize) {
            top = 390 + 10;
            if (window.outerHeight <= 800) {
                height = 'calc(100% - 404px)';
            } else {
                height = 'calc(100% - 400px)';
            }
        } else {
            top = 240 + 10;
            height = 'calc(100% - 254px)';
        }

        let styles = {
            "top": top.toString() + 'px',
            "height": height
        };

        return styles;
    }


    getVisorStyleMob() {

        let top: string = "33%";
        let height: string = '66%';

        let styles = {
            "top": top,
            "height": height
        };

        return styles;
    }


    private prepareParamString(codeCompany: number) {

        if (this.realOffset > 0 && this.realOffset > this.currentOffset)
            this.currentOffset = this.realOffset;

        this.currentLimit = limitCompanyTiles;

        if (this.currentOffset > 0) {
            this.router.navigate([], { queryParams: { "offset": this.currentOffset, "view_type": this.viewingCompanies}, fragment: codeCompany.toString() });
        } else {
            this.router.navigate([], { queryParams: { "view_type": this.viewingCompanies}, fragment: codeCompany.toString() });
        }
    }

    onOpenCardClick(codeCompany: number) {

        this.prepareParamString(codeCompany);

        setTimeout(() => { 
            this.router.navigate(['./', codeCompany], {relativeTo: this.activatedRoute});
        }, 10);
    }


    onOpenInvestorCardClick(codeCompany: number, codeInvestor: number) {

        this.prepareParamString(codeCompany);

        setTimeout(() => { 
            this.router.navigate(['/company', codeInvestor], {relativeTo: this.activatedRoute});
        }, 10);
    }    

    onMobFounderClick(founder: Founder, company: Company) {

        if (this.currentOffset > 0) {
            this.router.navigate([], { queryParams: { "offset": this.currentOffset}, fragment: company.code_firm.toString() });
        } else {
            this.router.navigate([], { fragment: company.code_firm.toString() });
        }

        setTimeout(() => { 
            this.router.navigate(['/person-card'], { queryParams: { person_id: founder.code_person } });
        }, 10);
    }


    getMobTileHeight(id: string) {

        let element = document.getElementById("company" + id);

        if (element) {

            let styles = {
                "height": element.clientWidth.toString() + "px"
            };

            return styles;
        }
    }

}