import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import { LoaderService } from "./../services/loader.service";
import { finalize } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })

export class DownloadFileService {

  constructor(private http: HttpClient, private loaderService: LoaderService) {}

  downloadFile(url: string, fileName: string): Observable <Blob> {

    let myHeaders = new HttpHeaders({
      "Content-Type": "application/pdf",
      "Content-Disposition": `inline; filename="${fileName}"`
    });        

    this.loaderService.startLoading();
    return this.http.get(url, { headers: myHeaders, responseType: 'blob' }).pipe(finalize(this.loaderService.finishLoading));
  }
   
}