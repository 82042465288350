<div class="main" *ngIf="!platformService.handsetPlatform">
    <div class="header">
        <div class="header-left">
            <img src={{company.image_url}}>
            <div class="company-info">
                <div class="name-company">{{company.name_firm}}</div>
                {{company.name_country}} {{company.founded_year}}
            </div>
        </div>
        <div class="header-right">
            <div class="fixed-col">{{companyTranslation.firm.card.field.investors}}</div> 
            <div class="div-list">
                <div class="subscribe comma-text" *ngFor="let investor of company.investors; let lastItem = last;">
                    <a [routerLink]="['/company', investor.code_firm]" [ngClass]="{ 'investorDisabled': investor.type_firm == 'UK' }">
                        {{investor.name_firm}}
                        <img *ngIf="neadSubscribe(investor.subscribe_level) && investor.type_firm != 'UK'" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                </div>                            
            </div>           
        </div>
    </div>

    <div class="body">
        <div class="body-header">
            <span>{{companyDealsTranslation.company.deals.rounds_table}}</span>
            <span>{{companyDealsTranslation.company.deals.diagram}}</span>
        </div>

        <div class="body-body">
            <div class="table">
                <div class="grid-header">
                    <div class="column-one">{{companyDealsTranslation.company.deals.funding_year}}</div>
                    <div class="column-two">{{companyDealsTranslation.company.deals.funding_type}}</div>
                    <div class="column-three">{{companyDealsTranslation.company.deals.investors}}</div>
                    <div class="column-fore">{{companyDealsTranslation.company.deals.funding_amount}} </div>
                </div>

                <div class="grid-body" [ngStyle]="getBodyStyle()">
                    <div class="grid-container" *ngFor="let round of companyRounds; index as i" [ngClass]="{ 'roundDisabled': neadSubscribe(round.subscribe_level) }">
                        <div class="date-rounds">{{round.date_round | date: 'MMM yyyy' }}</div>
                        <div class="type-rounds">{{round.type_round}}</div>
                        <div class="div-list">
                            <a class="comma-text" *ngFor="let investor of round.investors; index as i; let lastItem = last;"
                                    [ngClass]="{ 'investorDisabled': neadSubscribe(investor.subscribe_level) || neadSubscribe(round.subscribe_level),
                                                'linkDisablet': investor.type_firm == 'UK' }"
                                    [routerLink]="['/company', investor.code_firm]">
                                <img src={{investor.image_url}}>
                                <div *ngIf="investor.image_url">&nbsp;</div>
                                {{investor.name_firm}}
                                <div *ngIf="!lastItem">,&nbsp;</div>
                            </a>
                        </div>
                        <div class="money">{{round.value_round | currency : '' : 'symbol' : '1.0'}} M</div>
                    </div>
                </div>                
            </div>

            <div class="chart-body">
                <div class="chart-top">
                    <span>{{companyDealsTranslation.company.deals.axis_y.title}}</span>
                    <div class="chart">
                        <canvas baseChart
                        [datasets]="barChartData"
                        [labels]="barChartLabels"
                        [options]="barChartOptions"
                        [plugins]="barChartPlugins"
                        [legend]="barChartLegend"
                        [colors]="barChartColors"
                        [chartType]="barChartType"
                        height="220px">
                        </canvas>
                    </div>
                </div>
                <span>{{companyDealsTranslation.company.deals.axis_x.title}}</span>
            </div>

            <ptn-visor *ngIf="isVisorVisible()"></ptn-visor>    

        </div>
    </div>
</div>


<!-- Mobile -->

<div class="mob-main" *ngIf="platformService.handsetPlatform">

    <div class="mob-header" [ngClass]="{ headerJustify: showRounds }">
        <a *ngIf="showRounds" [routerLink]="[]" routerLinkActive="active" (click)="onShowDiagramClick()" [ngClass]="{ showRoundsDisabled: !activatedIndustryID }">
            <span>{{companyDealsTranslation.company.deals.button.diagram}}</span>
            <img src="/assets/imgs/next_white.svg">
        </a>
        <a *ngIf="!showRounds" [routerLink]="[]" routerLinkActive="active" (click)="onShowRoundsClick()">
            <img src="/assets/imgs/prev_white.svg">
            <span>{{companyDealsTranslation.company.deals.button.rounds}}</span>
        </a>
    </div>

    <div class="mob-round" [ngClass]="{ roundsHide: !showRounds }">
        <div class="mob-card" *ngFor="let round of companyRounds; index as i" [ngClass]="{ 'roundDisabled': neadSubscribe(round.subscribe_level) }">
            <div class="mob-row">
                <span class="mob-row-title">{{companyDealsTranslation.company.deals.funding_year}}</span>
                <span class="date-rounds">{{round.date_round | date: 'MMM yyyy' }}</span>
            </div>

            <div class="mob-row">
                <span class="mob-row-title">{{companyDealsTranslation.company.deals.funding_type}}</span>
                <span class="type-rounds">{{round.type_round}}</span>
            </div>

            <div class="mob-round-col">
                <span class="mob-row-title">{{companyDealsTranslation.company.deals.investors}}</span>

                <div class="mob-div-list">
                    <a class="mob-comma-text" *ngFor="let investor of round.investors; index as i; let lastItem = last;"
                            [ngClass]="{ 'investorDisabled': neadSubscribe(investor.subscribe_level) || neadSubscribe(round.subscribe_level),
                                        'linkDisablet': investor.type_firm == 'UK' }"
                            [routerLink]="['/company', investor.code_firm]">
                        <img src={{investor.image_url}}>
                        <div *ngIf="investor.image_url">&nbsp;</div>
                        {{investor.name_firm}}
                        <div *ngIf="!lastItem">,&nbsp;</div>
                    </a>
                </div>
            </div>

            <div class="mob-row">
                <span class="mob-row-title">{{companyDealsTranslation.company.deals.funding_amount}}</span>
                <span class="money">{{round.value_round | currency : '' : 'symbol' : '1.0'}} M</span>
            </div>
        </div>
    </div>

    <div class="mob-diagram">
        <div class="mob-chart">
            <canvas baseChart
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [legend]="barChartLegend"
                [colors]="barChartColors"
                [chartType]="barChartType"
                height="300px">
            </canvas>
        </div>
    </div>

    <ptn-visor *ngIf="isVisorVisible()" [ngClass]="{ mobVisor: true }"></ptn-visor>    
    
</div>
