import { Injectable } from '@angular/core';

@Injectable()
export class WebAddressService {

    constructor() {
    }

    cutProtocol(web: string): string {

        if (!web)
            return null;

        if (web.substr(0, 7) == 'http://') {
          return web.substr(7, web.length - 7);
        } else {
            if (web.substr(0, 8) == 'https://') {
                return web.substr(8, web.length - 8);
            } else {
                return web;
            }
        }
    }


    getSocialImg(typeAddress: string): string {

        if (!typeAddress)
            return null;
            
        switch(typeAddress) {
            case 'LI': {
              return 'assets/imgs/linkedin.png';
            }
            break;

            case 'FB': {
                return 'assets/imgs/facebook.png';
            }
            break;

            case 'TW': {
              return 'assets/imgs/twitter.png';
            }
            break;

            case 'IG': {
              return 'assets/imgs/instagram.png';
            }
            break;
        }
    }

}
