<div class="main" (click)="onClick()">
    <img class="logo" src="assets/imgs/Logo.png" [ngClass]="{ logoShow: showLogo }">
    <div class="under_logo">AI driven VC as Service platform</div>
    <div class="bottom">
        <div class="block block1" [ngClass]="{ block1Right: rightBlock1, block1Top: topBlock1 }">
            <div class="heder-row">
                <img class="img-bottom" src="assets/imgs/splash-bottom.png">
                <span class="block-title">FOR FOUNDERS</span>
            </div>
            <ul class="add-text" [ngClass]="{ addTextShow1: showAddText1 }">
                <li>
                    <a [routerLink]="['/filter-funds']">Connect with Venture Capital Funds
                        <img class="www2" src="/assets/imgs/www2-white.svg">
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/filter-private-investors']">Connect with Private Investors
                        <img class="www2" src="/assets/imgs/www2-white.svg">
                    </a>
                </li>
                <li>
                    <a href="https://privatetech.network/platform/login">Transact
                        <img class="www2" src="/assets/imgs/www2-white.svg">
                    </a>
                </li>
            </ul>
        </div>

        <div class="block block2" [ngClass]="{ block2Right: rightBlock2, block2Top: topBlock2 }">
            <div class="heder-row">
                <img class="img-bottom" src="assets/imgs/splash-bottom.png">
                <span class="block-title">FOR INVESTORS</span>
            </div>
            <ul class="add-text" [ngClass]="{ addTextShow2: showAddText2 }">
                <li>
                    <a [routerLink]="['/home']">View Tech Companies
                        <img class="www2" src="/assets/imgs/www2-white.svg">
                    </a>
                </li>
            </ul>
        </div>

        <div class="grid-container" [ngClass]="{ gridShow: showGrid }">
            <div class="grid-cell">
                <div class="digits">{{counterCountries}}</div>
                <div class="cell-caption">Countries</div>
            </div>

            <div class="grid-cell">
                <div class="digits">{{counterCompanies}}+</div>
                <div class="cell-caption">Companies</div>
            </div>

            <div class="grid-cell">
                <div class="digits">{{counterUnicorns}}+</div>
                <div class="cell-caption">Unicorns</div>
            </div>

            <div class="grid-cell">
                <div class="digits">{{counterVCFunds}}+</div>
                <div class="cell-caption">VC Funds</div>
            </div>

            <div class="grid-cell">
                <div class="digits">{{counterPrivateInvestors}}+</div>
                <div class="cell-caption">Private Investors</div>
            </div>

            <div class="grid-cell">
                <div class="digits">${{counterTotalValuation}}T+</div>
                <div class="cell-caption">Total Valuation</div>
            </div>

            <div class="grid-cell">
                <div class="digits">${{counterTotalVCAUM}}T+</div>
                <div class="cell-caption">Total VC AUM</div>
            </div>

            <div class="grid-cell">
                <div class="digits">{{counterDeals}}+</div>
                <div class="cell-caption">Deals</div>
            </div>
        </div>

        <a class="reg-button" [routerLink]="['/login/register']" [ngClass]="{ registerShow: showRegister }">REGISTER FREE</a>

    </div>
</div>