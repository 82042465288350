<table class="noselect" mat-table [dataSource]='filter_firms' [ngClass]="{ sendMessage: showSendMessage }">
    <ng-container matColumnDef="name_firm">
        <th mat-header-cell *matHeaderCellDef> {{filterTranslation.data.field.name_firm}} </th>
        <td mat-cell *matCellDef="let element" id="element{{element.code_firm}}">
            <div class='company-cell'>

                <a [routerLink]="[]" routerLinkActive="active" (click)="onOpenCardClick(element.code_firm)">
<!--                <a [routerLink]="['./', element.code_firm]"> -->
                    <img src={{element.image_url}} alt={{element.code_firm}} title={{element.code_firm}}>
                    {{element.name_firm}}
                </a>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="name_country">
        <th mat-header-cell *matHeaderCellDef> {{filterTranslation.data.field.name_country}} </th>
        <td mat-cell *matCellDef="let element"> {{element.name_country}} </td>
    </ng-container>
    <ng-container matColumnDef="name_number_of_emploees">
        <th mat-header-cell *matHeaderCellDef> {{filterTranslation.data.field.number_of_emploees}} </th>
        <td mat-cell *matCellDef="let element"> {{element.name_number_of_emploees}} </td>
    </ng-container>
    <ng-container matColumnDef="name_deal_size">
        <th mat-header-cell *matHeaderCellDef> {{filterTranslation.data.field.deal_size}} </th>
        <td mat-cell *matCellDef="let element"> {{element.name_deal_size}} </td>
    </ng-container>
    <ng-container matColumnDef="total_funding_amount">
        <th mat-header-cell *matHeaderCellDef> {{filterTranslation.data.field.total_funding_amount}} </th>
        <td mat-cell *matCellDef="let element"> {{element.total_funding_amount | bignumber}} </td>
    </ng-container>
    <ng-container matColumnDef="total_capital">
        <th mat-header-cell *matHeaderCellDef> {{filterTranslation.data.field.total_capital}} </th>
        <td mat-cell *matCellDef="let element"> {{element.total_capital | bignumber}} </td>
    </ng-container>
    <ng-container matColumnDef="valuation">
        <th mat-header-cell *matHeaderCellDef> {{filterTranslation.data.field.valuation}} </th>
        <td mat-cell *matCellDef="let element"> {{element.valuation | bignumber}} </td>
    </ng-container>
    <ng-container matColumnDef="name_investment_stage_str">
        <th mat-header-cell *matHeaderCellDef> {{filterTranslation.data.field.investment_stage}} </th>
        <td mat-cell *matCellDef="let element"> {{element.name_investment_stage_str}} </td>
    </ng-container>
    <ng-container matColumnDef="founded_year">
        <th mat-header-cell *matHeaderCellDef> {{filterTranslation.data.field.founded_year}} </th>
        <td mat-cell *matCellDef="let element"> {{element.founded_year}} </td>
    </ng-container>
    <ng-container matColumnDef="is_send_message">
        <th mat-header-cell *matHeaderCellDef> {{filterTranslation.data.field.is_send_message}} </th>
        <td mat-cell *matCellDef="let element">
            <div class='is_send_message-cell'>

                <a class="send-message-button" *ngIf="element.is_send_message == 1 || element.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                        (click)="onSendMessageFounderClick(element)">
                    <img src="/assets/imgs/messagebubble_1.png">
                    <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                </a>

                <a class="send-message-button" *ngIf="element.is_send_message == 2 || element.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                        (click)="onSendMessageClick(element)">
                    <img src="/assets/imgs/messagebubble_2.png">
                    <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                </a>
            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'resultDisabled': neadSubscribe(row.firm_subscribe_level)}"></tr>
</table>

<div id="send-msg" *ngIf="showSendMessage && !platformService.handsetPlatform" [ngStyle]="getSendMessagePos()">
    <ptn-send-message [personID]="messagePersonID" [companyID]="messageCompanyID" [companyForFounder]="companyForFounder" (onCloseMessage)="onCloseMessage($event)"></ptn-send-message>
</div>
