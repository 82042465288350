// ObjectName для перекладів
export const langMENU_MAIN = "menu_main";
export const langMenuService = "menu_service";
export const langFORM_SIGNUP = "form_signup";
export const langFORM_SIGNIN = "form_signin";
export const langPAGE_HOME = "page_home";
export const langPAGE_LIST = "page_list";
export const langPAGE_DEALS = "page_deals";
export const langPAGE_COMPANIES = "page_companies";
export const langPAGE_VC_FUNDS = "page_vc_funds";
export const langPAGE_PRIV_INVESTORS = "page_priv_investors";
export const langPAGE_LOGIN_REQUIREMENT = "page_login_requirement";
export const langPAGE_SUBSCRIBE = "page_subscribe";
export const langCOPY_OBJECT = "copy_object";

export const limitCompanyTiles: number = 20;
