import { Component, OnInit } from '@angular/core';
import { TranslationService, TranslateObject } from '../services/translation.service';
import { langPAGE_HOME } from "./../shared/constant.module";
import { HomeTranslation } from "./../shared/translation.const";
import { Router } from '@angular/router';
import { viewAsPhotoChanged$ } from "./../header/header.component";
import { LocalStorageService } from './../services/local-storage-service';
import { PlatformService } from './../services/platform.service';
import { UserService } from './../services/user.service';


@Component({
  selector: 'ptn-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit {

    // Переклади для елементів інтерфесу
    homeTranslation = HomeTranslation;

    viewAsPhoto: boolean = true;

    translateSocialNetworks: Array<TranslateObject> = [];

    
    constructor(private translationService: TranslationService, private router: Router, 
            private localStorage: LocalStorageService, public platformService: PlatformService,
            private userService: UserService) {

        let tmpViewAsPhoto = this.localStorage.get("viewAsPhoto");
        if (tmpViewAsPhoto != undefined) {
            this.viewAsPhoto = tmpViewAsPhoto;
        }

        viewAsPhotoChanged$.subscribe((view: boolean) => {

          this.viewAsPhoto = view;
        });

        // Отримуємо переклади
        this.translationService.getTranslate(langPAGE_HOME, this.homeTranslation);
        this.translationService.getCategoryObject('PAGE_HOME.SOCIAL_NETWORKS', this.translateSocialNetworks);
      }


  ngOnInit(): void {

    if (!this.homeTranslation)
      this.translationService.getTranslate(langPAGE_HOME, this.homeTranslation);

    // Підписуємось на зміну мови
    this.translationService.languageChanged$.subscribe((changeObj) => {

      this.translationService.getTranslate(langPAGE_HOME, this.homeTranslation);
      this.translationService.getCategoryObject('PAGE_HOME.SOCIAL_NETWORKS', this.translateSocialNetworks);
    });

  }


  getActiveComponent(): string {
    let nameActiveComponent: string;
    let segmentsCount = this.router.parseUrl(this.router.url).root.children.primary.segments.length;

    if (segmentsCount == 1) {
      nameActiveComponent = 'home';
    } else {
      let nameRootIndustrySegment: string = this.router.parseUrl(this.router.url).root.children.primary.segments[1].path;
      if (nameRootIndustrySegment == 'i') {
        nameActiveComponent = 'companies';
      } else if (nameRootIndustrySegment == 'ic') {
        if (segmentsCount == 3) {
          nameActiveComponent = 'category';
        } else if (segmentsCount == 4) {
          nameActiveComponent = 'companies';
        }
      }
    }

    return nameActiveComponent;    
  }


  neadSubscribe(subscribeLevel: number): boolean {

    let userSubsribeLevel = this.userService.user?.subscribe_level || 0;
    return (userSubsribeLevel >= subscribeLevel);
  }


  getSocialNetImage(socNet: TranslateObject): string {

    let addr: string = "";
    switch(socNet.object_name) {

      case 'PAGE_HOME.SOCIAL_NETWORKS.LINKEDIN': {
        addr = "/assets/imgs/SOCIAL PTN-01 LinkedIn Blue Box.svg";
      }
      break;

      case 'PAGE_HOME.SOCIAL_NETWORKS.FACEBOOK': {
        addr = "/assets/imgs/SOCIAL PTN-03 FaceBook Blue Box.svg";
      }
      break;

      case 'PAGE_HOME.SOCIAL_NETWORKS.TWITTER': {
        addr = "/assets/imgs/SOCIAL PTN-05 Twitter Blue Box.svg";
      }
      break;

      case 'PAGE_HOME.SOCIAL_NETWORKS.WIKIPEDIA': {
        addr = "/assets/imgs/SOCIAL PTN-07 Wikipedia Blue Box.svg";
      }
      break;

      case 'PAGE_HOME.SOCIAL_NETWORKS.INSTAGRAM': {
        addr = "/assets/imgs/SOCIAL PTN-09 Instagram Blue Box.svg";
      }
      break;
    }

    return addr;
  }

}
