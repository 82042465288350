import { PasswordStrength } from '../shared/password-strength';
import { FormControl } from '@angular/forms';

function checkStrength(p: string): number {
    let force = 0;
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;

    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    const flags = [lowerLetters, upperLetters, numbers, symbols];

    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }
    
    force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
    force += passedMatches * 10;

    // short password
    force = (p.length <= 6) ? Math.min(force, 10) : force;

    // poor variety of characters
    force = (passedMatches === 1) ? Math.min(force, 10) : force;
    force = (passedMatches === 2) ? Math.min(force, 20) : force;
    force = (passedMatches === 3) ? Math.min(force, 30) : force;
    force = (passedMatches === 4) ? Math.min(force, 40) : force;

    return force;
  }

export function getPasswordStrength(password: string): PasswordStrength {
    
    let force = checkStrength(password);

    let passwordStrength: PasswordStrength;
    if (force <= 10) {
        passwordStrength = PasswordStrength.Poor;
    } else if (force <= 20) {
        passwordStrength = PasswordStrength.NotGood;
    } else if (force <= 30) {
        passwordStrength = PasswordStrength.Average;
    } else if (force <= 40) {
        passwordStrength = PasswordStrength.Good;
    } else {
        passwordStrength = PasswordStrength.Good;
    }

    return passwordStrength;
}

export function getPasswordColor(password: string): string {

    let passwordStrength = getPasswordStrength(password);
    let colors = ['rgb(187, 121, 121)', 'rgb(252, 143, 103)', 'rgb(250, 199, 103)', 'rgb(200, 223, 154)'];
    
    return colors[passwordStrength];
}

export function passwordStrengthValidator(control: FormControl) {

    let value = control.value;
    
    if (!value)
        return;

    let passwStrength = getPasswordStrength(value);
    
    switch (passwStrength) {
        case PasswordStrength.Good:
        case PasswordStrength.Average:
            return null;
        case PasswordStrength.NotGood:
        case PasswordStrength.Poor: 
            return {
                    "passwordStrengthValidator": {
                         valid: false
                   }
            }
        default:
            break;
    }    
}
