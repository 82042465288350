import { Component, Output, EventEmitter } from '@angular/core';
import { SessionStorageService } from './../services/session-storage-service';
import { TranslationService } from './../services/translation.service';
import { Industry } from "./../models/home.body";


@Component({
  selector: 'ptn-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css']
})

export class SplashComponent {

    showLogo: boolean = false;
    
    rightBlock1: boolean = false;
    topBlock1: boolean = false;
    showAddText1: boolean = false;

    rightBlock2: boolean = false;
    topBlock2: boolean = false;
    showAddText2: boolean = false;

    showGrid: boolean = false;
    showRegister: boolean = false;

    counterCountries: number = 0;
    counterCompanies: number = 0;
    counterUnicorns: number = 0;
    counterVCFunds: number = 0;
    counterPrivateInvestors: number = 0;
    counterTotalValuation: number = 0;
    counterTotalVCAUM: number = 0;
    counterDeals: number = 0;

    @Output() 
    splashCompleted = new EventEmitter<void>();


    constructor(private sessionStorage: SessionStorageService, private translationService: TranslationService) {

        setTimeout(() => { 

            this.showLogo = true;
            setTimeout(() => {

                this.rightBlock1 = true;
                setTimeout(() => {

                    this.rightBlock2 = true;

                        setTimeout(() => {

                            this.topBlock1 = true;
                            setTimeout(() => {

                                this.showAddText1 = true;
                                setTimeout(() => {

                                    this.topBlock2 = true;
                                    setTimeout(() => {

                                        this.showAddText2 = true;
                                        setTimeout(() => {

                                            this.showGrid = true;
                                            setTimeout(() => {
                                                this.startCounters();
                                            }, 800);
                                        }, 2000);
                                    }, 1000);
                                }, 3000);
                            }, 1000);
                        }, 2000);
                }, 2000);
            }, 3000);
        }, 1000);
    }


    private startCounters() {

        let intervalCountries = setInterval(() => {
            this.counterCountries += 2;
            if (this.counterCountries >= 93) {
                this.counterCountries = 93;
                clearInterval(intervalCountries);
            }
        }, 30);

        let intervalCompanies = setInterval(() => {
            this.counterCompanies += 176;
            if (this.counterCompanies >= 8837) {
                this.counterCompanies = 8837;
                clearInterval(intervalCompanies);
            }
        }, 30);

        let intervalUnicorns = setInterval(() => {
            this.counterUnicorns += 21;
            if (this.counterUnicorns >= 1249) {
                this.counterUnicorns = 1249;
                clearInterval(intervalUnicorns);
            }
        }, 30);

        let intervalVCFunds = setInterval(() => {
            this.counterVCFunds += 79;
            if (this.counterVCFunds >= 4392) {
                this.counterVCFunds = 4392;
                clearInterval(intervalVCFunds);

                setTimeout(() => {
                    this.showRegister = true;
                }, 1000);
            }
        }, 30);

        let intervalPrivateInvestors = setInterval(() => {
            this.counterPrivateInvestors += 11;
            if (this.counterPrivateInvestors >= 587) {
                this.counterPrivateInvestors = 587;
                clearInterval(intervalPrivateInvestors);
            }
        }, 30);

        let intervalTotalValuation = setInterval(() => {
            this.counterTotalValuation += .1;
            this.counterTotalValuation = Math.round(this.counterTotalValuation * 100) / 100;
            if (this.counterTotalValuation >= 5.3) {
                this.counterTotalValuation = 5.3;
                clearInterval(intervalTotalValuation);
            }
        }, 30);

        let intervalTotalVCAUM = setInterval(() => {
            this.counterTotalVCAUM += .05;
            this.counterTotalVCAUM = Math.round(this.counterTotalVCAUM * 1000) / 1000;
            if (this.counterTotalVCAUM >= 1.3) {
                this.counterTotalVCAUM = 1.3;
                clearInterval(intervalTotalVCAUM);
            }
        }, 50);

        let intervalDeals = setInterval(() => {
            this.counterDeals += 32;
            if (this.counterDeals >= 1783) {
                this.counterDeals = 1783;
                clearInterval(intervalDeals);
            }
        }, 30);
    }


    onClick() {

        if (!this.translationService.translationDict || this.translationService.translationDict.length <= 0)
            return;

        let listIndustry: Array<Industry> = this.sessionStorage.get("listIndustry");

        if (!listIndustry || listIndustry.length <= 0)
            return;

        this.splashCompleted.emit();
        this.sessionStorage.set('splashCompleted', true);
    }

    onRegisterClick() {
        
    }
}
