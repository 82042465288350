import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'callbackbool',
    pure: false
})
export class CallbackboolPipe implements PipeTransform {
    transform(items: any[], callbackbool: (item: any) => boolean): any {
        if (!items || !callbackbool) {
            return items;
        }
        return items.filter(item => callbackbool(item));
    }
}