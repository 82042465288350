<div class="analisys" *ngIf="!platformService.handsetPlatform">
    <div class="main">
        <div class="main-group">
            <div class="group top-industry">
                <a class="group-header"  [routerLink]="['/analysis/top-industry']">
                    {{analisysTranslation.analysis.top_in_industry.title}}
                </a>
                <div class="group-body">
                    <a class="link-image" [routerLink]="['/analysis/top-industry']">
                        <img src="/assets/imgs/top_in_industri.png" />
                    </a>
                    <span class="group-text">{{analisysTranslation.analysis.top_in_industry.text}}</span>
                    <a class="view-more" [routerLink]="['/analysis/top-industry']">
                        {{analisysTranslation.analysis.top_in_industry.view_more}}
                        <img src="/assets/imgs/view-more.svg">
                    </a>
                </div>
            </div>

            <div class="group sector-map">
                <a class="group-header" [routerLink]="['/analysis/sector-map']">
                    {{analisysTranslation.analysis.sector_map.title}}
                </a>
                <div class="group-body">
                    <a class="link-image" [routerLink]="['/analysis/sector-map']">
                        <img src="/assets/imgs/sector_map.png" />
                    </a>
                    <span class="group-text">{{analisysTranslation.analysis.sector_map.text}}</span>
                    <a class="view-more" [routerLink]="['/analysis/sector-map']">
                        {{analisysTranslation.analysis.sector_map.view_more}}
                        <img src="/assets/imgs/view-more.svg">
                    </a>
                </div>
            </div>

            <div class="group bubble-map">
                <a class="group-header" [routerLink]="['/analysis/bubble-map']">
                    {{analisysTranslation.analysis.bubble_map.title}}
                </a>
                <div class="group-body">
                    <a class="link-image" [routerLink]="['/analysis/bubble-map']">
                        <img src="/assets/imgs/bubble_map.png" />
                    </a>
                    <span class="group-text">{{analisysTranslation.analysis.bubble_map.text}}</span>
                    <a class="view-more" [routerLink]="['/analysis/bubble-map']">
                        {{analisysTranslation.analysis.bubble_map.view_more}}
                        <img src="/assets/imgs/view-more.svg">
                    </a>
                </div>
            </div>
        </div>

        <div class="partner" *ngIf="!platformService.handsetPlatform">
            <span>{{homeTranslation.partnership}}</span>
            <a href="https://mvp.vc/" target="_blank">
                <img src="/assets/imgs/MVP header-logo.png">
            </a>
            <span>{{homeTranslation.partner_slogan}}</span>
        </div>
    </div>    
</div>


<!-- Mobile -->

<div class="mob-analisys" *ngIf="platformService.handsetPlatform">
    <div class="mob-main">

        <div class="mob-group">
            <a class="mob-group-header"  [routerLink]="['/analysis/top-industry']">
                {{analisysTranslation.analysis.top_in_industry.title}}
            </a>

            <div class="mob-group-body">
                <a class="mob-link-image" [routerLink]="['/analysis/top-industry']">
                    <img src="/assets/imgs/top_in_industri.png">
                </a>
            
                <span class="mob-group-text">{{analisysTranslation.analysis.top_in_industry.text}}</span>
                <a class="mob-view-more" [routerLink]="['/analysis/top-industry']">
                    {{analisysTranslation.analysis.top_in_industry.view_more}}
                    <img src="/assets/imgs/view-more.svg">
                </a>
            </div>
        </div>

        <div class="mob-group">
            <a class="mob-group-header" [routerLink]="['/analysis/sector-map']">
                {{analisysTranslation.analysis.sector_map.title}}
            </a>
            <div class="mob-group-body">
                <a class="mob-link-image" [routerLink]="['/analysis/sector-map']">
                    <img src="/assets/imgs/sector_map.png" />
                </a>
                <span class="mob-group-text">{{analisysTranslation.analysis.sector_map.text}}</span>
                <a class="mob-view-more" [routerLink]="['/analysis/sector-map']">
                    {{analisysTranslation.analysis.sector_map.view_more}}
                    <img src="/assets/imgs/view-more.svg">
                </a>
            </div>
        </div>

        <div class="mob-group">
            <a class="mob-group-header" [routerLink]="['/analysis/bubble-map']">
                {{analisysTranslation.analysis.bubble_map.title}}
            </a>
            <div class="mob-group-body">
                <a class="mob-link-image" [routerLink]="['/analysis/bubble-map']">
                    <img src="/assets/imgs/bubble_map.png" />
                </a>
                <span class="mob-group-text">{{analisysTranslation.analysis.bubble_map.text}}</span>
                <a class="mob-view-more" [routerLink]="['/analysis/bubble-map']">
                    {{analisysTranslation.analysis.bubble_map.view_more}}
                    <img src="/assets/imgs/view-more.svg">
                </a>
            </div>
        </div>

    </div>    
</div>
