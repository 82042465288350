import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PtnAppConfig } from '../shared/ptn-app-config';
import { UserService } from './user.service';
import { TranslationService } from './translation.service';
import { DeviceInfo } from '../models/device-info';
import { map, catchError } from 'rxjs/operators';

export interface AccessObjectBySubscribeLevel {
    code_object: string;
    name_object: string;
    code_error: number;
    message: string;
}

export interface AnnalyticData {
    page_url: string;
    device_data: DeviceInfo;
}

@Injectable()
export class CommonService {

    private url = PtnAppConfig.url_v2;

    constructor(private http: HttpClient, private userService: UserService, private translationService: TranslationService) {}

    getUrlSearchParams(): any {
        let args = {};

        let query = decodeURIComponent(window.location.search.substring(1));
        let pairs = query.split('&');

        for (let i = 0; i < pairs.length; i++) {

            let pos = pairs[i].indexOf('=');
            if (pos == -1) {
                continue;                    
            }

            let argname = pairs[i].substring(0, pos);
            let value = pairs[i].substring(pos + 1);

            args[argname] = value;
        }

        return args;
    }

    accessNavigation(typeObject: string, codeObject: number): Observable<AccessObjectBySubscribeLevel> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-session": this.userService.x_vms_session,
            "x-vms-lang": this.translationService.currentLanguage
        });        

        return this.http.get<AccessObjectBySubscribeLevel>(`${this.url}/object/access-navigation?type_object=${typeObject}&code_object=${codeObject}`, { headers: myHeaders });
    }

    private handlePtnAnalyticsError(err: any): Observable<any> {
        console.error(err);
        return of(err);
    };

    ptnAnalytics(analyticData: AnnalyticData): Observable<any> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-session": this.userService.x_vms_session,
            "x-vms-lang": this.translationService.currentLanguage
        });        

        return this.http.post(`${this.url}/ptn-analytics`, analyticData, {headers: myHeaders}).pipe(map((res: any) => {
            //console.log(res.createauditpage);
        }), catchError((error) => this.handlePtnAnalyticsError(error) ));
    }
}
