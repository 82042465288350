import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { LoaderService } from './loader.service';
import { finalize } from 'rxjs/operators';
import { PtnAppConfig } from '../shared/ptn-app-config';
import { LocalStorageService } from './../services/local-storage-service';


export interface TranslateObject {
    object_id: number,
    object_name: string,
    sort_index: number,
    subscribe_level: number,
    translation: string
}


@Injectable()
export class TranslationService {
 
    translationDict: Array<TranslateObject>;
    public languageChanged$ = new Subject<string>();
    public currentLanguage: string = "ENG";
    private url = PtnAppConfig.url_v2;


    constructor(private http: HttpClient, private loaderService: LoaderService, private localStorage: LocalStorageService) {
    }


    init() {

        let timerID = setTimeout(() => { 

            this.translationDict = [];

            this.loadDictionary();
            console.log('Reload translation timer done.');
        }, 86400000);

        this.loadDictionary();
    }


    private loadDictionary() {

        let storedLanguage = this.localStorage.get('languageName');

        if (storedLanguage)
            this.getDictionary(storedLanguage)
        else
            this.getDictionary('ENG');
    }


    getDictionary(language: string) {

        if ((language == this.currentLanguage) && (this.translationDict))
            return;

        this.localStorage.set('languageName', language);

        this.currentLanguage = language;

        let myHeaders = new HttpHeaders({
            "x-vms-session": "notauthenticate",
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.currentLanguage
        });

        this.loaderService.startLoading();
        this.http.get<Array<TranslateObject>>(this.url + '/object/translation', { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading)).subscribe(
                response => {
                    let json = response;

                    if (json) {
                        this.translationDict = json;
                    }
                    this.languageChanged$.next(this.currentLanguage);
                },
                error => console.log(error));
    }


    getDictionarySilent() {

        let language = this.localStorage.get('languageName');

        if (!language)
            language = 'ENG';

        this.localStorage.set('languageName', language);

        this.currentLanguage = language;
        this.translationDict = [];

        let myHeaders = new HttpHeaders({
            "x-vms-session": "notauthenticate",
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.currentLanguage
        });

        this.http.get<Array<TranslateObject>>(this.url + '/object/translation', { headers: myHeaders }).subscribe(
                response => {
                    let json = response;

                    if (json) {
                        this.translationDict = json;
                    }
                    this.languageChanged$.next(this.currentLanguage);
                },
                error => console.log(error));
    }


    // Функція повертає переклади
    getTranslate(objectName: string, obj) {

        if (!this.translationDict)
            return;

        for (let i = 0; i < this.translationDict.length; i++) {

            let lowerObjectName = this.translationDict[i].object_name.toLowerCase();

            // Отримуємо першу частину імені об'єкту (до першої крапки)
            let objectPart1 = lowerObjectName.substr(0, lowerObjectName.indexOf("."));

            if (objectPart1 && (objectPart1 == objectName.toLowerCase())) {

                // Отримуємо решту імені об'єкту (після першої крапки)
                let objectRest = lowerObjectName.substr(lowerObjectName.indexOf(".") + 1, lowerObjectName.length - lowerObjectName.indexOf("."));

                // Готуємо масив для частин імені об'єкту
                let objectParts: Array<string> = [];

                while (objectRest.length > 0) {

                    if (objectRest.indexOf(".") < 0) {
                        // Якщо це остання частина, записуємо переклад
                        objectParts.push(objectRest);
                        objectRest = "";
                    } else {
                        // Отримуємо наступну частину (до наступної крапки)
                        let objectPart = objectRest.substr(0, objectRest.indexOf("."));
                        if (objectPart)
                            objectParts.push(objectPart);
                        let pointPos = objectRest.indexOf(".");
                        // Вирізаємо наступну частину (до наступної крапки)
                        objectRest = objectRest.substr(pointPos + 1, objectRest.length - pointPos - 1);
                    }

                }

                switch (objectParts.length) {
                    case 1: {
                        if (obj[objectParts[0]] != undefined)
                            obj[objectParts[0]] = this.translationDict[i].translation;
                    }
                    break;

                    case 2: {
                        if (obj[objectParts[0]] && obj[objectParts[0]][objectParts[1]])
                                obj[objectParts[0]][objectParts[1]] = this.translationDict[i].translation;

                    }
                    break;

                    case 3: {
                        if (obj[objectParts[0]] && obj[objectParts[0]][objectParts[1]] && obj[objectParts[0]][objectParts[1]][objectParts[2]])
                                    obj[objectParts[0]][objectParts[1]][objectParts[2]] = this.translationDict[i].translation;
                    }
                    break;

                    case 4: {
                        if (obj[objectParts[0]] && obj[objectParts[0]][objectParts[1]] &&
                                obj[objectParts[0]][objectParts[1]][objectParts[2]] &&
                                obj[objectParts[0]][objectParts[1]][objectParts[2]].hasOwnProperty(objectParts[3]))
                                    obj[objectParts[0]][objectParts[1]][objectParts[2]][objectParts[3]] = this.translationDict[i].translation;
                    }
                    break;
                }
            }            
        }
    }


    getCategoryObject(objectName: string, categoryObject: Array<TranslateObject>) {

        if (!this.translationDict)
            return;

        for (let i = 0; i < this.translationDict.length; i++) {
            let obj = this.translationDict[i].object_name;
            if (obj.indexOf(objectName) >= 0) {
                categoryObject.push(this.translationDict[i]);
            }
        }
    }


    getTranslateObject(objectName: string): TranslateObject {

        if (!this.translationDict)
            return null;
       
        return this.translationDict.find(obg => obg.object_name === objectName);
    }


    getObjectBySubscribe(objectName: string, translateObject: Array<TranslateObject>, userSubsribeLevel: number) {

        if (!this.translationDict)
            return;
   
        for (let i = 0; i < this.translationDict.length; i++) {

            if (this.translationDict[i].object_name.indexOf(objectName) >= 0 && this.translationDict[i].subscribe_level <= userSubsribeLevel) {
                translateObject.push(this.translationDict[i]);
            }
        }
    }

}