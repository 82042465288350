import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { CommonService } from './common.service';
import { ErrorService } from './error.service';

export interface AccessObjectBySubscribeLevel {
    code_object: string;
    name_object: string;
    code_error: number;
    message: string;
}

@Injectable()
export class BreadCrumbDynamicLabelGuard implements CanActivate, CanActivateChild {
    
    private industryId: number;
    private categoryId: number;
    private firmId: number;
    private personId: number;    
    
    constructor(private breadcrumbService: BreadcrumbService, private errorService: ErrorService, private commonService: CommonService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {      
        
        if (route.params.personId) {
            if (!this.personId || this.personId !== route.params.personId) {
                this.personId = route.params.personId;
                //console.log(this.personId);
                this.breadcrumbService.set('@personName', ' ');
                this.commonService.accessNavigation('P', this.personId).subscribe((obj) => {
                    //console.log(obj);
                    this.breadcrumbService.set('@personName', obj.name_object);
                },
                (error) => {
                    console.error(this.errorService.getErrorPTNMessage(error));
                });
            }
        } 
        if (route.params.companyId) {            
            if (!this.firmId || this.firmId !== route.params.companyId) {
                this.firmId = route.params.companyId;
                //console.log(this.firmId);
                this.breadcrumbService.set('@firmName', ' ');
                this.commonService.accessNavigation('F', this.firmId).subscribe((obj) => {
                    //console.log(obj);
                    this.breadcrumbService.set('@firmName', obj.name_object);
                },
                (error) => {
                    console.error(this.errorService.getErrorPTNMessage(error));
                });
            }
        } 
        if (route.params.categoryId) {
            if (!this.categoryId || this.categoryId !== route.params.categoryId) {
                this.categoryId = route.params.categoryId;
                //console.log(this.categoryId);
                this.breadcrumbService.set('@categoryName', ' ');
                if (route.params.companyId) {
                    this.commonService.accessNavigation('C', this.categoryId).subscribe((obj) => {
                        //console.log(obj);
                        this.breadcrumbService.set('@categoryName', obj.name_object);
                    },
                    (error) => {
                        console.error(this.errorService.getErrorPTNMessage(error));
                    });
                }
            }
        } 
        if (route.params.industryId) {            
            if (!this.industryId || this.industryId !== route.params.industryId) {
                this.industryId = route.params.industryId;
                //console.log(this.industryId);
                this.breadcrumbService.set('@industryName', ' ');
                if (route.parent.url.map((x) => x.path).includes('ic') || (route.params.companyId)) {
                    this.commonService.accessNavigation('I', this.industryId).subscribe((obj) => {
                        //console.log(obj);
                        this.breadcrumbService.set('@industryName', obj.name_object);
                    },
                    (error) => {
                        console.error(this.errorService.getErrorPTNMessage(error));
                    });
                }
            }
        }
        
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}