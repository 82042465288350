<div class="main" id="main" *ngIf="!platformService.handsetPlatform && viewAsPhoto" tabindex="0" (window:resize)="onResize($event)">
    <div class="home_body" #home_body [ngStyle]="getCanvasWidth()">
        <div class="tile" [ngClass]="{ tileBigSize: tileBigSize }" *ngFor="let category of listCategory; index as i">
            <div class="tile-content">
                <a class="link" (click)="onCategoryClick(category)"
                            [ngClass]="{ 'disabled': category.company_count_all <= 0 }">

<!--
                <a class="link" [routerLink]="['./', category.code_category]"
                            [ngClass]="{ 'disabled': category.company_count_all <= 0 }">
-->                            
                    <div class="image">
                        <img class="img-front" src={{category.image_photo_url}} alt={{category.code_category}}>
                    </div>
                    <div class="fade-away"></div>

                    <div class="caption" [ngClass]="{ tileBigSize: tileBigSize }">
                        <span>{{category.name_category}}</span>

                        <div class="graph">
                            <div class="one-item" id="ptn-index" title="{{translationPtnIndex.data.ptnindex}}" title="{{translationPtnIndex.data.ptnindex}}">
                                <div class="graph-text">{{category.ptn_index}}</div>
                                <div [ngStyle]="getPtnIndexWidth(category)" class="graph-index"></div>
                            </div>              
                            <div class="one-item" id="sub-index" title="{{translationPtnIndex.data.subindex}}">
                                <div class="graph-text">{{category.ptn_subindex}}</div>
                                <div [ngStyle]="getPtnSubindexWidth(category)" class="graph-subindex"></div>
                            </div>                            
                        </div>
                    </div>
                </a>
                <img *ngIf="neadSubscribe(category.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
            </div>  
        </div>
    </div>
</div>


<!-- Old -->

<div class="old-main" id="main" *ngIf="!platformService.handsetPlatform && !viewAsPhoto" tabindex="0" (window:resize)="onResize($event)">
    <div class="old-home_body" #home_body [ngStyle]="getCanvasWidthOld()">
        <div class="old-tile" [ngClass]="{ tileBigSizeOld: tileBigSize }" *ngFor="let category of listCategory; index as i">
            <div class="old-tile-content">
                <a class="old-link" [routerLink]="['./', category.code_category]"
                            title={{category.code_category}}:{{category.name_category}}
                            [ngClass]="{ 'disabled': category.company_count_all <= 0 }">
                    <div class="old-image">
                        <img class="old-img-front" src={{category.image_url}} alt={{category.code_category}} title={{category.code_category}}:{{category.name_category}}>
                        <img class="old-img-back" src={{category.image_over_url}} alt={{category.code_category}} title={{category.code_category}}:{{category.name_category}}>
                    </div>
                    <div class="old-caption" [ngClass]="{ tileBigSizeOld: tileBigSize }">
                        {{category.name_category}}
                    </div>
                </a>
                <img *ngIf="neadSubscribe(category.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
            </div>
        </div>
    </div>
</div>


<!-- Mobile -->

<div class="mob-main" *ngIf="platformService.handsetPlatform" tabindex="0">
    <div class="mob-home_body" #home_body>
        <div class="mob-tile" [ngStyle]="getTileSize()" id="category{{category.code_category}}" *ngFor="let category of listCategory; index as i">
            <div class="tile-content">
                <a class="link" (click)="onCategoryClick(category)"
                            [ngClass]="{ 'disabled': category.company_count_all <= 0 }">
<!--
                <a class="link" [routerLink]="['./', category.code_category]"
                            [ngClass]="{ 'disabled': category.company_count_all <= 0 }">
-->                            
                    <div class="image">
                        <img class="img-front" src={{category.image_photo_url}} alt={{category.code_category}}>
                    </div>
                    <div class="fade-away"></div>

                    <div class="mob-caption" [ngClass]="tileSize">
                        <span>{{category.name_category}}</span>

                        <div class="graph">
                            <div class="one-item" id="ptn-index" title="{{translationPtnIndex.data.ptnindex}}" title="{{translationPtnIndex.data.ptnindex}}">
                                <div class="graph-text">{{category.ptn_index}}</div>
                                <div [ngStyle]="getPtnIndexWidth(category)" class="graph-index"></div>
                            </div>              
                            <div class="one-item" id="sub-index" title="{{translationPtnIndex.data.subindex}}">
                                <div class="graph-text">{{category.ptn_subindex}}</div>
                                <div [ngStyle]="getPtnSubindexWidth(category)" class="graph-subindex"></div>
                            </div>                            
                        </div>
                    </div>
                </a>
                <img *ngIf="neadSubscribe(category.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
            </div>  
        </div>
    </div>
</div>
