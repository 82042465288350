import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateObject } from './../services/translation.service';
import { TranslationService } from './../services/translation.service';


@Injectable()
export class RepresentService {

    public tileBigSizeChanged$ = new Subject<boolean>();
    public setTileSize$ = new Subject<boolean>();

    public setSortList$ = new Subject<Array<TranslateObject>>();
    public selectedSort$ = new Subject<string>();

    public viewingCompaniesChanged$ = new Subject<string>();

    private translationCompanyViewing: Array<TranslateObject> = [];


    constructor(private translationService: TranslationService) {
    }


    getCompanyViewType(objectName: string): string {

        let viewCompanyType: string;
        switch (objectName) {
            case 'PAGE_HOME.COMPANY.VIEWING_OPTIONS.COMPANIES': {
 
                viewCompanyType = "PC";
            }
            break;
    
            case 'PAGE_HOME.COMPANY.VIEWING_OPTIONS.VC_FUNDS': {
 
                viewCompanyType = "VC";
            }
            break;

            case 'PAGE_HOME.COMPANY.VIEWING_OPTIONS.PRIVATE_INVESTOR': {
 
                viewCompanyType = "PI";
            }
            break;
        }

        return viewCompanyType;
    }


    takeCompanyViewType(companyView: string): string {

        this.translationService.getCategoryObject('PAGE_HOME.COMPANY.VIEWING_OPTIONS', this.translationCompanyViewing);

        let сompView: TranslateObject = undefined;
        if (companyView) {
            сompView = this.translationCompanyViewing.find(сompView => сompView.translation === companyView);
        }

        if (!сompView) {
            return "PC";
        } else {

            let viewCompanyType: string;
            switch (сompView.object_name) {
                case 'PAGE_HOME.COMPANY.VIEWING_OPTIONS.COMPANIES': {
    
                    viewCompanyType = "PC";
                }
                break;
        
                case 'PAGE_HOME.COMPANY.VIEWING_OPTIONS.VC_FUNDS': {
    
                    viewCompanyType = "VC";
                }
                break;

                case 'PAGE_HOME.COMPANY.VIEWING_OPTIONS.PRIVATE_INVESTOR': {
    
                    viewCompanyType = "PI";
                }
                break;
            }

            return viewCompanyType;
        }
    }

}