import { Component, Output, EventEmitter } from '@angular/core';
import { SessionStorageService } from './../services/session-storage-service';
import { TranslationService } from './../services/translation.service';
import { Industry } from "./../models/home.body";
import { PlatformService } from './../services/platform.service';


@Component({
  selector: 'ptn-splash-m',
  templateUrl: './splash.component-m.html',
  styleUrls: ['./splash.component-m.css']
})

export class SplashComponentM {

    showLogo: boolean = false;
    
    rightBlock1: boolean = false;
    showAddText1: boolean = false;

    rightBlock2: boolean = false;
    showAddText2: boolean = false;

    showRegister: boolean = false;

    counterCountries: number = 93;
    counterCompanies: number = 8837;
    counterUnicorns: number = 1249;
    counterVCFunds: number = 4392;
    counterPrivateInvestors: number = 587;
    counterTotalValuation: number = 5.3;
    counterTotalVCAUM: number = 1.3;
    counterDeals: number = 1783;

    @Output() 
    splashCompleted = new EventEmitter<void>();


    constructor(private sessionStorage: SessionStorageService, private translationService: TranslationService,
            public platformService: PlatformService) {

        setTimeout(() => { 

            this.showLogo = true;
            setTimeout(() => {

                this.rightBlock1 = true;
                setTimeout(() => {

                    this.showAddText1 = true;
                    setTimeout(() => {

                        this.rightBlock2 = true;
                        setTimeout(() => {

                            this.showAddText2 = true;
                            setTimeout(() => {

                                this.showRegister = true;
                            }, 2000);

                        }, 1500);

                    }, 2000);
                }, 1500);
            }, 3000);
        }, 1000);
    }


    onClick() {

        if (!this.translationService.translationDict || this.translationService.translationDict.length <= 0)
            return;

        let listIndustry: Array<Industry> = this.sessionStorage.get("listIndustry");

        if (!listIndustry || listIndustry.length <= 0)
            return;

        this.splashCompleted.emit();
        this.sessionStorage.set('splashCompleted', true);
    }
}
