import { Component } from '@angular/core';
import { PlatformService } from './../../services/platform.service';


@Component({
    selector: 'deals',
    templateUrl: './industry.deals.container.html',
    styleUrls: ['./industry.deals.container.css']
})
  
export class IndustryDealsContainer {


    constructor(public platformService: PlatformService) {}

}
