import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs/tab-group';
import { PtnAppConfig } from 'src/app/shared/ptn-app-config';
import { RegistrationTranslation, SignInTranslation, PasswordRecoveryTranslation, ForgotPasswordTranslation } from '../../shared/translation.const';
import { TranslationService } from '../../services/translation.service';
import { langFORM_SIGNUP, langFORM_SIGNIN } from "../../shared/constant.module";
import { LocalStorageService } from '../../services/local-storage-service';
import { SocialnetAction } from '../../shared/socialnet-action';
import { PlatformService } from './../../services/platform.service';


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

    selectedTabIndex: number = 0;
    facebookLink = PtnAppConfig.url_v2 + '/socialOAuth2?type_identifier=FI';
    googleLink = PtnAppConfig.url_v2 + '/socialOAuth2?type_identifier=GI';
  
    registerTranslation = RegistrationTranslation;
    loginTranslation = SignInTranslation;
    recoveryTranslation = PasswordRecoveryTranslation;
    forgotPassTranslation = ForgotPasswordTranslation;

    displayedTranslation = {
      using_sa: {
        text: null
      },
      signin: {
        button: null
      }
    };

    errorMessage: string = '';
    captionNavigate: string = "";


    constructor(private router: Router, private translationService: TranslationService, private localStorage: LocalStorageService,
          private activatedRoute: ActivatedRoute, public platformService: PlatformService) {

        this.translationService.getTranslate(langFORM_SIGNUP, this.registerTranslation);
        this.translationService.getTranslate(langFORM_SIGNIN, this.loginTranslation);
        this.translationService.getTranslate('PAGE_PASS_RECOVERY', this.recoveryTranslation);
        this.translationService.getTranslate('FORM_FORGOT_PASS', this.forgotPassTranslation);
        this.prepareTranslation();     
    }


    ngOnInit() {

        this.captionNavigate = "";
        if (this.activatedRoute.snapshot.queryParams.id) {

            let queryPar = this.activatedRoute.snapshot.queryParams;

            if (queryPar) {

                switch (this.localStorage.socialnetAction) {
                    case SocialnetAction.Register: {

                    if (this.selectedTabIndex != 0)
                        this.router.navigate(['login/register'],  { queryParams: queryPar });
                    }
                    break;

                    case SocialnetAction.SignIn : {

                    if (this.selectedTabIndex != 1)
                        this.router.navigate(['login/sign-in'], { queryParams: queryPar });
                    }
                    break;
                }
            }
        } else {

            let segmentLen = this.router.parseUrl(this.router.url).root.children.primary.segments.length;

            if (segmentLen == 1 && this.router.parseUrl(this.router.url).root.children.primary.segments[0].path == 'login'){
    
                this.localStorage.socialnetAction = SocialnetAction.Register;
                this.router.navigate(['login/register']);
            } else {
    
                switch (this.router.parseUrl(this.router.url).root.children.primary.segments[1].path) {
                    case 'register': {
    
                        this.localStorage.socialnetAction = SocialnetAction.Register;
                        this.selectedTabIndex = 0;
                    }
                    break;
            
                    case 'sign-in': {
    
                        this.localStorage.socialnetAction = SocialnetAction.SignIn;
                        this.selectedTabIndex = 1;
                    }
                    break;
    
                    case 'forgot-pass': {
                        this.selectedTabIndex = 2;
                        this.captionNavigate = this.loginTranslation.forgot_password.button;
                    }
                    break;
    
                    case 'recovery': {
                        this.selectedTabIndex = 3;
                        this.captionNavigate = this.recoveryTranslation.title.text;
                    }
                    break;
                }
            }
        }

        this.prepareTranslation();

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate(langFORM_SIGNUP, this.registerTranslation);
            this.translationService.getTranslate(langFORM_SIGNIN, this.loginTranslation);
            this.translationService.getTranslate('PAGE_PASS_RECOVERY', this.recoveryTranslation);
            this.translationService.getTranslate('FORM_FORGOT_PASS', this.forgotPassTranslation);

            this.prepareTranslation();
        });

    }


    private prepareTranslation() {

      switch (this.selectedTabIndex) {

        case 0: {

          for (let key in this.registerTranslation) {
            if (this.displayedTranslation[key]) {
              this.displayedTranslation[key] = this.registerTranslation[key];
            }
          }
          this.displayedTranslation.using_sa.text = this.registerTranslation.register_using_sa.text;
        }
        break;
 
        case 1: {

          for (let key in this.loginTranslation) {
            if (this.displayedTranslation[key]) {
              this.displayedTranslation[key] = this.loginTranslation[key];
            }
          }
          this.displayedTranslation.using_sa.text = this.loginTranslation.signin_using_sa.text;
        }
        break;

        case 2: {
          
          for (let key in this.loginTranslation) {
            if (this.recoveryTranslation[key]) {
              this.displayedTranslation[key] = this.recoveryTranslation[key];
            }
          }
        }
        break;

      }

    }


    onSelectedTabChange(tabGroup: MatTabGroup) {

        switch (tabGroup.selectedIndex) {
            case 0: {

                this.localStorage.socialnetAction = SocialnetAction.Register;
                this.router.navigate(['login/register']);
            }
            break;

            case 1: {

                this.localStorage.socialnetAction = SocialnetAction.SignIn;
                this.router.navigate(['login/sign-in']);
            }
            break;
        }
    }


    errorEvent(event: any) {

      this.errorMessage = event;
    }
}