import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { DeviceInfoService } from './services/device-info.service';
import { LoaderService } from './services/loader.service';
import { RouterHistoryService } from './services/router-history.service';
import { TranslationService } from './services/translation.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { SessionStorageService } from './services/session-storage-service';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
import { CompanyService } from './services/company.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IndustryService } from './services/industry.service';
import { VersionService } from './services/version.service';
import { Location } from '@angular/common';
import { environment } from '../environments/environment';
import { langCOPY_OBJECT } from './shared/constant.module';
import { CopyObjectTranslation } from './shared/translation.const';
import { PlatformService } from './services/platform.service';


@Component({
  selector: 'ptn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked {

  public loading = false;
  splashCompleted: boolean = false;
  showRepresent: boolean = true;
  copyObjectTranslation = CopyObjectTranslation;
  
  constructor(private deviceInfoService: DeviceInfoService, private loaderService: LoaderService, private routerHistoryService: RouterHistoryService,
          private translationService: TranslationService, private matIconRegistry: MatIconRegistry,
          private domSanitizer: DomSanitizer, private sessionStorage: SessionStorageService,
          private router: Router, private userService: UserService, private companyService: CompanyService, private breadcrumbService: BreadcrumbService,
          private industryService: IndustryService, private versionService: VersionService,
          private location: Location, public platformService: PlatformService) {

    this.translationService.languageChanged$.subscribe((lang: string) => {
      this.translationService.getTranslate(langCOPY_OBJECT, this.copyObjectTranslation);
      let linkOnCopiedText = this.copyObjectTranslation.link_text;

      document.oncopy = function () {
        var body = document.getElementsByTagName('body')[0];
        var selection = window.getSelection();
        var div = document.createElement('div');

        div.style.position = 'absolute';
        div.style.left = '-99999px';
        body.appendChild(div);
        div.innerHTML = selection + `<br><br>${linkOnCopiedText} <a href=${window.location.href}>${window.location.href}</a>`;
        selection.selectAllChildren(div);

        window.setTimeout(function () {
          body.removeChild(div);
        }, 0);
      }

    });

    this.addGoogleAnalyticsScripts();

    this.loaderService.loading$.subscribe((loading) => {
      //console.log('loading = ' + loading);
      setTimeout(() => {
        this.loading = loading;
        //console.log('this.loading = ' + this.loading);
      });
    });

    this.deviceInfoService.getDeviceInfo().subscribe((devInfo) => {
      //console.log('Device Info');
      //console.log(devInfo);
    });

    this.companyService.listCompanyLoaded$.subscribe((companyListInfo) => {
      //console.log(companyListInfo);

      let industryName = companyListInfo.name_industry;
      if (companyListInfo.code_category == 0) {
        industryName = `${companyListInfo.name_industry} (${companyListInfo.loaded_count} of ${companyListInfo.all_count} ${companyListInfo.name_type_firm})`;
      }
      if (industryName) {
        this.breadcrumbService.set('@industryName', industryName);
      }

      if (companyListInfo.code_category !== 0) {
        let categoryName: string;
        categoryName = `${companyListInfo.name_category} (${companyListInfo.loaded_count} of ${companyListInfo.all_count} ${companyListInfo.name_type_firm})`;
        if (categoryName) {
          this.breadcrumbService.set('@categoryName', categoryName);
        }
      }
    });

    this.matIconRegistry.addSvgIcon(
      "menu_unloged", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/menu_unloged.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "menu_loged", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/menu_loged.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "menu_about", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/about.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "menu_data_collection", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/data_collection.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "menu_term_of_use", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/term_of_use.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "menu_privacy", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/privacy.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "menu_login", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/login.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "menu_register", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/register.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "menu_logout", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/logout.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "menu_subscribe", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/subscribe.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "menu_user", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/user.svg")
    );

    // Сортування для мобільної версії
    this.matIconRegistry.addSvgIcon(
      "sort_by_value", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/ICON_SORT_BY_COMPANY_VALUE.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "sort_by_number", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/ICON_SORT_BY_NUMBER_OF_COMPANIES.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "sort_by_name", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/ICON_SORT_BY_NAME.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "sort_by_valuation", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/ICON_SORT_BY_VALUATION.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "company_viev_company", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/ICONS_TYPE-C.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "company_viev_funds", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/ICONS_TYPE-F.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "company_viev_private", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/ICONS_TYPE-I.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "support", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/imgs/support.svg")
    );

  }


    ngOnInit() {

        let segmentCount: number = 0;
        if (this.location.path()) {
            if (this.router.parseUrl(this.location.path()).root.children.primary)
              segmentCount = this.router.parseUrl(this.location.path()).root.children.primary.segments.length;
        }

        let firstSegment: string = "";

        if (segmentCount > 0)
            firstSegment = this.router.parseUrl(this.location.path()).root.children.primary.segments[0].path.toLowerCase();

        if ((segmentCount == 1 && firstSegment != 'home') || this.sessionStorage.get('splashCompleted') || this.userService.isAuthenticated ||
                segmentCount > 1) {

            this.splashCompleted = true;
            this.translationService.init();
        } else {

            this.translationService.languageChanged$.subscribe((lang: string) => {

                this.industryService.getIndustriesSilent(this.industryService.getSortObjectID());
            });
    
            this.translationService.getDictionarySilent();
        }
    }


    ngAfterContentChecked() {

        let segmentCount: number = 0;
        if (this.location.path()) {
            if (this.router.parseUrl(this.location.path()).root.children.primary)
              segmentCount = this.router.parseUrl(this.location.path()).root.children.primary.segments.length;
        }

        let firstSegment: string = "";
        let secondSegment: string ="";

        if (segmentCount > 0)
            firstSegment = this.router.parseUrl(this.location.path()).root.children.primary.segments[0].path.toLowerCase();
        if (segmentCount > 1)
            secondSegment = this.router.parseUrl(this.location.path()).root.children.primary.segments[1].path.toLowerCase();

        if ((segmentCount == 1 && firstSegment == 'home') || (segmentCount == 3 && secondSegment =='i') || 
                ((segmentCount == 3 || segmentCount == 4) && secondSegment =='ic')) {

            this.showRepresent = true;
        } else {
            this.showRepresent = false;
        }
    }


    private getVersion() {

        this.versionService.getVersion().subscribe(
            response => {
                let json = response;
            },
            error => console.log(error)
        );
    }


    private getPaymentVersion() {

        this.versionService.getPaymentVersion().subscribe(
            response => {
                let json = response;
            },
            error => console.log(error)
        );
    }


    private getUserVersion() {

        this.versionService.getUserVersion().subscribe(
            response => {
                let json = response;
            },
            error => console.log(error)
        );
    }


    onSplashCompleted(event: any) {

        this.splashCompleted = true;
    }

    private addGoogleAnalyticsScripts(): void {
      if (!document.getElementById('gtagScript')) {
        const gtagScript = document.createElement('script');
        gtagScript.setAttribute('id', 'gtagScript');
        gtagScript.async = true;
        gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsMeasurementId}`;
        document.head.appendChild(gtagScript);
      }

      if (!document.getElementById('gtagConfigScript')) {
        const gtagConfigScript = document.createElement('script');
        gtagConfigScript.setAttribute('id', 'gtagConfigScript');
        gtagConfigScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', '${environment.googleAnalyticsMeasurementId}');
      `;
        document.head.appendChild(gtagConfigScript);
      }
    }
}
