<mat-card *ngFor='let firm of filter_firms' [ngClass]="{ sendMessage: showSendMessage }">
    <div class='card-container' id="company{{firm.code_firm}}">
        <div class='card-face'>

<!--            <a [routerLink]="['./', firm.code_firm]"><img src={{firm.image_url}} alt={{firm.code_firm}} title={{firm.code_firm}}></a> -->
            <a [routerLink]="[]" routerLinkActive="active" (click)="onOpenCardClick(firm.code_firm)">
                <img src={{firm.image_url}} alt={{firm.code_firm}} title={{firm.code_firm}}>
            </a>

            <a class='firm-name' [routerLink]="['./', firm.code_firm]">{{firm.name_firm}}</a>
            <span>{{firm.name_country}} {{firm.founded_year}}</span>

            <div class="send-message-block">
                <a class="send-message-button" *ngIf="firm.is_send_message == 1 || firm.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                        (click)="onSendMessageFounderClick(firm)">
                    <img src="/assets/imgs/messagebubble_1.png">
                    <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                </a>

                <a class="send-message-button" *ngIf="firm.is_send_message == 2 || firm.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                        (click)="onSendMessageClick(firm)">
                    <img src="/assets/imgs/messagebubble_2.png">
                    <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                </a>
            </div>

<!--
            <a class="send-message-button" *ngIf="firm.is_send_message > '0'" (click)="onSendMessageClick(firm)" 
                        [routerLink]="[]" routerLinkActive="active">
                <img src="/assets/imgs/messagebubble_{{firm.is_send_message}}.png">
                <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
            </a>
-->
        </div>
        <div *ngIf="typeFirm=='company'" class='card-detail'>
            <mat-grid-list cols='2' rowHeight='2.2em'>
                <mat-grid-tile [colspan]=1 [rowspan]=2 class='detail-title'>{{filterTranslation.data.field.focus}}:</mat-grid-tile><mat-grid-tile [colspan]=1 [rowspan]=2 class='detail-content'>{{firm.focus}}</mat-grid-tile>
                <mat-grid-tile class='detail-title'>{{filterTranslation.data.field.number_of_emploees}}:</mat-grid-tile><mat-grid-tile class='detail-content'>{{firm.name_number_of_emploees}}</mat-grid-tile>
                <mat-grid-tile class='detail-title'>{{filterTranslation.data.field.total_funding_amount}}:</mat-grid-tile><mat-grid-tile class='detail-content'>{{firm.total_funding_amount | bignumber}}</mat-grid-tile>
                <mat-grid-tile class='detail-title'>{{filterTranslation.data.field.valuation}}:</mat-grid-tile><mat-grid-tile class='detail-content'>{{firm.valuation | bignumber}}</mat-grid-tile>
<!--                <mat-grid-tile class='detail-title'>{{filterTranslation.data.field.innovation_index}}:</mat-grid-tile><mat-grid-tile class='detail-content'>{{firm.innovation_index}}</mat-grid-tile> -->
            </mat-grid-list>
        </div>
        <div *ngIf="typeFirm=='fund'" class='card-detail'>
            <mat-grid-list cols='2' rowHeight='2.2em'>
                <mat-grid-tile [colspan]=1 [rowspan]=2 class='detail-title'>{{filterTranslation.data.field.focus}}:</mat-grid-tile><mat-grid-tile [colspan]=1 [rowspan]=2 class='detail-content'>{{firm.focus}}</mat-grid-tile>
                <mat-grid-tile class='detail-title'>{{filterTranslation.data.field.number_of_emploees}}:</mat-grid-tile><mat-grid-tile class='detail-content'>{{firm.name_number_of_emploees}}</mat-grid-tile>
                <mat-grid-tile class='detail-title'>{{filterTranslation.data.field.total_capital}}:</mat-grid-tile><mat-grid-tile class='detail-content'>{{firm.total_capital | bignumber}}</mat-grid-tile>
                <mat-grid-tile class='detail-title'>{{filterTranslation.data.field.deal_size}}:</mat-grid-tile><mat-grid-tile class='detail-content'>{{firm.name_deal_size}}</mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=2 class='detail-title'>{{filterTranslation.data.field.investment_stage}}:</mat-grid-tile><mat-grid-tile [colspan]=1 [rowspan]=2 class='detail-content'>{{firm.name_investment_stage_str}}</mat-grid-tile>
            </mat-grid-list>
        </div>
        <div *ngIf="typeFirm=='private-investor'" class='card-detail'>
            <mat-grid-list cols='2' rowHeight='2.2em'>
                <mat-grid-tile [colspan]=1 [rowspan]=2 class='detail-title'>{{filterTranslation.data.field.focus}}:</mat-grid-tile><mat-grid-tile [colspan]=1 [rowspan]=2 class='detail-content'>{{firm.focus}}</mat-grid-tile>                
                <mat-grid-tile class='detail-title'>{{filterTranslation.data.field.total_capital}}:</mat-grid-tile><mat-grid-tile class='detail-content'>{{firm.total_capital | bignumber}}</mat-grid-tile>
                <mat-grid-tile class='detail-title'>{{filterTranslation.data.field.deal_size}}:</mat-grid-tile><mat-grid-tile class='detail-content'>{{firm.name_deal_size}}</mat-grid-tile>
                <mat-grid-tile [colspan]=1 [rowspan]=2 class='detail-title'>{{filterTranslation.data.field.investment_stage}}:</mat-grid-tile><mat-grid-tile [colspan]=1 [rowspan]=2 class='detail-content'>{{firm.name_investment_stage_str}}</mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
</mat-card>

<div id="send-msg" *ngIf="showSendMessage && !platformService.handsetPlatform" [ngStyle]="getSendMessagePos()">
    <ptn-send-message [personID]="messagePersonID" [companyID]="messageCompanyID" [companyForFounder]="companyForFounder" (onCloseMessage)="onCloseMessage($event)"></ptn-send-message>
</div>
