<div class="main" id="main" *ngIf="!platformService.handsetPlatform && viewAsPhoto" tabindex="0" (window:resize)="onResize($event)">
    <div class="home_body" [ngStyle]="getCanvasWidth()">
        <div class="tile" [ngClass]="{ tileBigSize: tileBigSize }" id="industry{{industry.code_industry}}" *ngFor="let industry of listIndustry; index as i">
            <div class="tile-content">
<!--
                <a class="link" [routerLink]="['./', industry.category_count_all > 0 ? 'ic' : 'i', industry.code_industry]"
                        [ngClass]="{ 'disabled': industry.company_count_all <= 0 && industry.category_count_all <= 0 }">
-->
                <a class="link" (click)="onIndustryClick(industry)"
                        [ngClass]="{ 'disabled': industry.company_count_all <= 0 && industry.category_count_all <= 0 }">

                    <div class="image">
                        <img class="img-front" src={{industry.image_photo_url}} alt={{industry.code_industry}}>
                    </div>

                    <div class="fade-away"></div>

                    <div class="caption" [ngClass]="{ tileBigSize: tileBigSize }">
                        <span>{{industry.name_industry}}</span>

                        <div class="graph">
                            <div class="one-item" id="ptn-index" title="{{translationPtnIndex.data.ptnindex}}">
                                <div class="graph-text">{{industry.ptn_index}}</div>
                                <div [ngStyle]="getPtnIndexWidth(industry)" class="graph-index"></div>
                            </div>              
                            <div class="one-item" id="sub-index" title="{{translationPtnIndex.data.subindex}}">
                                <div class="graph-text">{{industry.ptn_subindex}}</div>
                                <div [ngStyle]="getPtnSubindexWidth(industry)" class="graph-subindex"></div>
                            </div>                            
                        </div>
                    </div>

                </a>
                <img *ngIf="neadSubscribe(industry.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
            </div>
        </div>
    </div>
</div>


<!-- Old -->

<div class="old-main" id="main" *ngIf="!platformService.handsetPlatform && !viewAsPhoto" tabindex="0" (window:resize)="onResize($event)">
    <div class="old-home_body" [ngStyle]="getCanvasWidthOld()">
        <div class="old-tile" [ngClass]="{ tileBigSizeOld: tileBigSize }" *ngFor="let industry of listIndustry; index as i">
            <div class="old-tile-content">
                <a class="old-link" [routerLink]="['./', industry.category_count_all > 0 ? 'ic' : 'i', industry.code_industry]"
                        [ngClass]="{ 'disabled': industry.company_count_all <= 0 && industry.category_count_all <= 0 }"
                        title={{industry.code_industry}}:{{industry.name_industry}}>
                    <div class="old-image">
                        <img class="old-img-front" src={{industry.image_url}} alt={{industry.code_industry}} title={{industry.code_industry}}:{{industry.name_industry}}>
                        <img class="old-img-back" src={{industry.image_over_url}} alt={{industry.code_industry}} title={{industry.code_industry}}:{{industry.name_industry}}>
                    </div>
                    <div class="old-caption" [ngClass]="{ tileBigSizeOld: tileBigSize }">
                        {{industry.name_industry}}
                    </div>
                </a>
                <img *ngIf="neadSubscribe(industry.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
            </div>
        </div>
    </div>
</div>


<!-- Mobile -->

<div class="mob-main" *ngIf="platformService.handsetPlatform" tabindex="0">
    <div class="mob-home_body">
        <div class="mob-tile" [ngStyle]="getTileSize()" id="industry{{industry.code_industry}}" *ngFor="let industry of listIndustry; index as i">
            <div class="tile-content">
                <a class="link" (click)="onIndustryClick(industry)"
                        [ngClass]="{ 'disabled': industry.company_count_all <= 0 && industry.category_count_all <= 0 }">
                        
<!--                
                <a class="link" [routerLink]="['./', industry.category_count_all > 0 ? 'ic' : 'i', industry.code_industry]"
                        [ngClass]="{ 'disabled': industry.company_count_all <= 0 && industry.category_count_all <= 0 }">
-->
                    <div class="image">
                        <img class="mob-img-front" src={{industry.image_photo_url}} alt={{industry.code_industry}}>
                    </div>

                    <div class="fade-away"></div>

                    <div class="mob-caption">
                        <span>{{industry.name_industry}}</span>

                        <div class="graph">
                            <div class="mob-one-item" id="ptn-index" title="{{translationPtnIndex.data.ptnindex}}">
                                <div class="graph-text">{{industry.ptn_index}}</div>
                                <div [ngStyle]="getPtnIndexWidth(industry)" class="graph-index"></div>
                            </div>              
                            <div class="mob-one-item" id="sub-index" title="{{translationPtnIndex.data.subindex}}">
                                <div class="graph-text">{{industry.ptn_subindex}}</div>
                                <div [ngStyle]="getPtnSubindexWidth(industry)" class="graph-subindex"></div>
                            </div>                            
                        </div>
                    </div>
                </a>
                <img *ngIf="neadSubscribe(industry.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
            </div>
        </div>
    </div>
</div>
