<div class="main" *ngIf="!platformService.handsetPlatform">
    <select class="viewing-company" [(ngModel)]="viewingCompanies" #viewing_company (change)="onChangeTypeCompany(viewing_company.selectedIndex)">
        <option *ngFor="let companyViewing of translationCompanyViewing" [ngValue]="companyViewing.translation">{{companyViewing.translation}}</option>
    </select>

    <input type="image" src="/assets/imgs/SmallTile.svg" (click)="onSmallClick()" [ngClass]="{'size-selected': tileSize.bigSize == false}">
    <input type="image" src="/assets/imgs/LargeTile.svg" (click)="onLargeClick()" [ngClass]="{'size-selected': tileSize.bigSize == true}">

    <select class="sort-by" [(ngModel)]="selectedSort" #t (change)="onChangeSort(t.selectedIndex)">
        <option *ngFor="let sort of sortingTile">{{sort.translation}}</option>
    </select>
</div>


<!-- Mobile -->
<div class="main" *ngIf="platformService.handsetPlatform">

    <div class="menu_btn">
        <button mat-icon-button [matMenuTriggerFor]="viewing_company_menu" aria-label="Example icon-button with a menu">
            <mat-icon svgIcon="company_viev_company" *ngIf="viewingCompanies == 'Companies'"></mat-icon>
            <mat-icon svgIcon="company_viev_funds" *ngIf="viewingCompanies == 'VC Funds'"></mat-icon>
            <mat-icon svgIcon="company_viev_private" *ngIf="viewingCompanies == 'Private Investors'"></mat-icon>
        </button>
        <mat-menu #viewing_company_menu="matMenu">
            <button mat-menu-item *ngFor="let companyViewing of translationCompanyViewing; index as i" (click)='onChangeTypeCompany(i)'>
                <span>{{companyViewing.translation}}</span>
            </button>
        </mat-menu>
    </div>

    <input type="image" *ngIf="getLocation() == 'companies'" src="/assets/imgs/SmallTile.svg" (click)="onSmallClick()" [ngClass]="{'size-selected': tileSize.bigSize == false}">
    <input type="image" *ngIf="getLocation() == 'companies'" src="/assets/imgs/LargeTile.svg" (click)="onLargeClick()" [ngClass]="{'size-selected': tileSize.bigSize == true}">

    <div class="menu_btn">
        <button mat-icon-button [matMenuTriggerFor]="sort_menu" aria-label="Example icon-button with a menu">
            <mat-icon svgIcon="sort_by_name" *ngIf="iconVisible('SORT_BY_NAME')"></mat-icon>
            <mat-icon svgIcon="sort_by_number" *ngIf="iconVisible('SORT_BY_NUMBER_OF_COMPANIES')"></mat-icon>
            <mat-icon svgIcon="sort_by_value" *ngIf="iconVisible('SORT_BY_COMPANY_VALUE')"></mat-icon>
            <mat-icon svgIcon="sort_by_valuation" *ngIf="iconVisible('SORT_BY_VALUATION')"></mat-icon>
        </button>
        <mat-menu #sort_menu="matMenu">
            <button mat-menu-item #itm *ngFor="let sort of sortingTile; index as i" (click)='onChangeSort(i)'>
                <span>{{sort.translation}}</span>
            </button>
        </mat-menu>
    </div>

</div>
