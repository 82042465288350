<!-- Desktop version -->
<div class='subscribes-container' *ngIf="!platformService.handsetPlatform">
    <div class='subscribes-description'>
        <h2>{{subscribeTranslation.level_1.title}}</h2>
        <ul class='subscribes-description-list'>
            <li *ngFor='let subDesc of listSubscribeDescription'>
                <div class='listItem'>
                    <mat-icon>done</mat-icon><span>{{subDesc}}</span>
                </div>
            </li>
        </ul>
        <div [innerHtml]='subscribeTranslation.partners'></div>
    </div>
    <div class='subscribes-style'>
        <div class='subscribe-style' *ngFor='let userSubscribe of userSubscribes'>
            <mat-icon svgIcon="menu_subscribe"></mat-icon>
            <div class='subscribe-buy-cancel' *ngIf='!userSubscribe.isActive'>
                <span *ngIf='userSubscribe.trial_period > 0'>{{userSubscribe.trial}}</span>
                <span *ngIf='userSubscribe.trial_period == 0 && userSubscribe.trial_period_inner > 0'>{{userSubscribe.trial_inner}}</span>
                <span class='per-month'>{{userSubscribe.per_month}}</span>
            </div>
            <button mat-stroked-button *ngIf='isAuthenticated && !userSubscribe.isActive' (click)='onBuySubscribe(userSubscribe)'>{{subscribeTranslation.level_1.button.subscribe}}</button>
            <div class='subscribe-buy-cancel' *ngIf='userSubscribe.isActive'>
                <span>{{subscribeTranslation.level_1.paid_date}} {{customer.subscription_end_date | date:'dd.MM.yyyy'}}</span>
            </div>
            <button mat-stroked-button *ngIf='userSubscribe.isActive' (click)='onCancelSubscription()'>{{subscribeTranslation.level_1.button.cancel}}</button>
        </div>
    </div>
    <div class='subscribes-sign-in' *ngIf='!isAuthenticated'>
        <span>{{subscribeTranslation.level_1.need_login}}</span>
        <button mat-stroked-button (click)='onLogIn()'>{{subscribeTranslation.level_1.button.signin}}</button>
    </div>
    <div *ngIf='errorPtn' class='error'>{{errorPtn}}</div>
</div>
<!-- Mobile version -->
<div class='subscribes-container-m' *ngIf="platformService.handsetPlatform">
    <div class='subscribes-description-m'>
        <h2>{{subscribeTranslation.level_1.title}}</h2>
        <ul class='subscribes-description-list'>
            <li *ngFor='let subDesc of listSubscribeDescription'>
                <div class='listItem'>
                    <mat-icon>done</mat-icon><span>{{subDesc}}</span>
                </div>
            </li>
        </ul>
        <div [innerHtml]='subscribeTranslation.partners'></div>
    </div>
    <div class='subscribes-style-m'>
        <div class='subscribe-style-m' *ngFor='let userSubscribe of userSubscribes'>
            <mat-icon svgIcon="menu_subscribe"></mat-icon>
            <div class='subscribe-buy-cancel-m' *ngIf='!userSubscribe.isActive'>
                <span *ngIf='userSubscribe.trial_period > 0'>{{userSubscribe.trial}}</span>
                <span *ngIf='userSubscribe.trial_period == 0 && userSubscribe.trial_period_inner > 0'>{{userSubscribe.trial_inner}}</span>
                <span class='per-month'>{{userSubscribe.per_month}}</span>
            </div>
            <button *ngIf='isAuthenticated && !userSubscribe.isActive' (click)='onBuySubscribe(userSubscribe)'>{{subscribeTranslation.level_1.button.subscribe}}</button>
            <div class='subscribe-buy-cancel-m' *ngIf='userSubscribe.isActive'>
                <span>{{subscribeTranslation.level_1.paid_date}} {{customer.subscription_end_date | date:'dd.MM.yyyy'}}</span>
            </div>
            <button *ngIf='userSubscribe.isActive' (click)='onCancelSubscription()'>{{subscribeTranslation.level_1.button.cancel}}</button>
        </div>
    </div>
    <div class='subscribes-sign-in-m' *ngIf='!isAuthenticated'>
        <span>{{subscribeTranslation.level_1.need_login}}</span>
        <button (click)='onLogIn()'>{{subscribeTranslation.level_1.button.signin}}</button>
    </div>
    <div *ngIf='errorPtn' class='error-m'>{{errorPtn}}</div>
</div>