import { Component, OnInit } from '@angular/core';
import { PrivatePolicyTranslation } from '../shared/translation.const';
import { TranslationService } from './../services/translation.service';
import { PlatformService } from './../services/platform.service';


@Component({
    selector: 'private-policy',
    templateUrl: './private.policy.component.html',
    styleUrls: ['./private.policy.component.css']
})
  
export class PrivatePolicyComponent implements OnInit {

    privatePolicyTranslation = PrivatePolicyTranslation;


    constructor(private translationService: TranslationService, public platformService: PlatformService) {

        this.translationService.getTranslate('PAGE_PRIVACY', this.privatePolicyTranslation);
    }


    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('PAGE_PRIVACY', this.privatePolicyTranslation);
        });
    }
}  