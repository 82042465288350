import { Component, OnInit } from '@angular/core';
import { AnalysisTranslation, HomeTranslation } from "./../shared/translation.const";
import { TranslationService } from "./../services/translation.service";
import { langPAGE_HOME } from "./../shared/constant.module";
import { PlatformService } from './../services/platform.service';


@Component({
    selector: 'analysis',
    templateUrl: './analysis.component.html',
    styleUrls: ['./analysis.component.css']
})
  
export class AnalysisComponent implements OnInit {


    analisysTranslation = AnalysisTranslation;
    homeTranslation = HomeTranslation;

    constructor(private translationService: TranslationService, public platformService: PlatformService) {

        this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);
        this.translationService.getTranslate(langPAGE_HOME, this.homeTranslation);
    }


    ngOnInit() {
        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);
            this.translationService.getTranslate(langPAGE_HOME, this.homeTranslation);
        });
    }
}
