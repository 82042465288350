<section class="criterion-section">
    <p class="criterion-name">
        {{title}}
    </p>
    <ul>
        <li *ngFor='let param of params | callbackbool: filterParam; index as i'>
            <mat-checkbox disableRipple=true *ngIf='showMore || i < numberOfVisibleItems' [(ngModel)]="param.checked" (ngModelChange)="onChange()">
                <span>{{param.name_param}} ({{param.count_lines | bignumber}})</span>
            </mat-checkbox>
        </li>
        <a [routerLink]="[]" routerLinkActive="active" *ngIf="!showMore" (click)="showMore=true">See more
            <img src='/assets/imgs/arrow_down_dim.png'>
        </a>
    </ul>
    <mat-divider *ngIf='showDivider'></mat-divider>
</section>
