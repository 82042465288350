import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { SessionStorageService } from './session-storage-service';
import { DeviceInfoService } from './device-info.service';
import { CommonService } from './common.service';

declare let gtag: Function;

@Injectable()
export class RouterHistoryService {

    excludedRoutes = ['/login/', '/sign-in-social', '/verification', '/recovery', '/recovery_did_not_request'];
    previousUrl$ = new BehaviorSubject<string>(null);
    currentUrl$ = new BehaviorSubject<string>(null);

    constructor(router: Router, private sessionStorageService: SessionStorageService, private deviceInfoService: DeviceInfoService, private commonService: CommonService) {
        this.currentUrl$.next(router.url);
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl$.next(this.sessionStorageService.get('prevUrl'));
                this.currentUrl$.next(event.urlAfterRedirects);                
                if (this.excludedRoutes.every((x) => this.currentUrl$.value.indexOf(x) == -1)) {
                    this.sessionStorageService.set('prevUrl', this.currentUrl$.value);
                }
                //console.log(`prev: ${this.previousUrl$.value}`);
                //console.log(`curr: ${this.currentUrl$.value}`);
                if (this.deviceInfoService.deviceInfo) {
                    //console.log(`curr: ${window.location.href}`);
                    //console.log(this.deviceInfoService.deviceInfo);
                    let analyticData = {
                        page_url: window.location.href,
                        device_data: this.deviceInfoService.deviceInfo
                    }
                    this.commonService.ptnAnalytics(analyticData).subscribe();
                }
                gtag('event', 'ptn-navigation', {
                    'page_url': window.location.href
                });
            }
        });
    }
}