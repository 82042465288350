import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { FilterFirm, FilterParams, FilterPTN } from '../../models/filter';
import { ErrorService } from '../../services/error.service';
import { TranslateObject } from '../../services/translation.service';
import { SessionStorageService } from '../../services/session-storage-service';
import { UserService } from '../../services/user.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { LoaderService } from '../../services/loader.service';
import { PlatformService } from 'src/app/services/platform.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ptn-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges, OnDestroy {

  filter_params: FilterParams = {};  
  filter_firms: Array<FilterFirm> = [];

  filterError: string;
  criterionElements: Array<any>;

  order: string[] = ['sort_index'];
  selectedSortItem: number;
  offset: number = 0;
  limit: number = 20;
  countAllFirms: number = 0;
  initialViewResult: string;

  tableBtnToggle = {
    notVisible: true
  }
  cardBtnToggle = {
    notVisible: false
  }

  private visitorBottom: number = 0;
  private subscriptionParam: Subscription;
  private paramLimit: number = 0;

  @Input()
  typeFirm: string;

  @Input()
  filterTranslation: any;

  @Input()
  sortingList: Array<TranslateObject>;
  
  constructor(private filterService: FilterService, private errorService: ErrorService, public sessionStorageService: SessionStorageService, private userService: UserService,
    private breakpointObserver: BreakpointObserver, private loaderService: LoaderService, public platformService: PlatformService, private router: Router,
    private activatedRoute: ActivatedRoute) {

      this.subscriptionParam = this.activatedRoute.queryParams.subscribe((queryParam: any) => {
              
        if (queryParam['offset'])
            this.paramLimit = +(queryParam['offset']);
      });
  
    let urlParams = new URLSearchParams(window.location.search);
    let filterParam = urlParams.get('filter');
    if (filterParam) {
      filterParam = decodeURIComponent(filterParam);
      console.log(filterParam);
      this.filterService.filterParamsInput = JSON.parse(filterParam);
    }
//    console.log(this.filterService.filterParamsInput);

  }

  private setCriterionElements(): void {
    this.criterionElements = [
      {
        type_criterion: 'check',
        title: this.filterTranslation.filters.filter.title.industry,
        params: this.filter_params.INDUSTRY,
        showCriterion: () => this.filterTranslation.filters.filter.title.industry && this.filter_params.INDUSTRY
      },
      {
        type_criterion: 'check',
        title: this.filterTranslation.filters.filter.title.category,
        params: this.filter_params.CATEGORY,
        showCriterion: () => this.filterTranslation.filters.filter.title.category && this.filter_params.CATEGORY
      },
      {
        type_criterion: 'check',
        title: this.filterTranslation.filters.filter.title.region,
        params: this.filter_params.REGION,
        showCriterion: () => this.filterTranslation.filters.filter.title.region && this.filter_params.REGION
      },
      {
        type_criterion: 'check',
        title: this.filterTranslation.filters.filter.title.country,
        params: this.filter_params.COUNTRY,
        showCriterion: () => this.filterTranslation.filters.filter.title.country && this.filter_params.COUNTRY
      },
      {
        type_criterion: 'interval',
        title: this.filterTranslation.filters.filter.title.founded_year,
        param: this.filter_params.FOUNDED_YEAR,
        showCriterion: () => this.filterTranslation.filters.filter.title.founded_year && this.filter_params.FOUNDED_YEAR?.options
      },
      {
        type_criterion: 'check',
        title: this.filterTranslation.filters.filter.title.number_of_emploees,
        params: this.filter_params.NUMBER_OF_EMPLOEES,
        showCriterion: () => this.filterTranslation.filters.filter.title.number_of_emploees && this.filter_params.NUMBER_OF_EMPLOEES
      },
      {
        type_criterion: 'interval',
        title: this.filterTranslation.filters.filter.title.valuation,
        param: this.filter_params.VALUATION,
        showCriterion: () => this.filterTranslation.filters.filter.title.valuation && this.filter_params.VALUATION?.options
      },
      {
        type_criterion: 'interval',
        title: this.filterTranslation.filters.filter.title.total_funding_amount,
        param: this.filter_params.TOTAL_FUNDING_AMOUNT,
        showCriterion: () => this.filterTranslation.filters.filter.title.total_funding_amount && this.filter_params.TOTAL_FUNDING_AMOUNT?.options
      },
      {
        type_criterion: 'interval',
        title: this.filterTranslation.filters.filter.title.innovation_index,
        param: this.filter_params.INNOVATION_INDEX,
        showCriterion: () => this.filterTranslation.filters.filter.title.innovation_index && this.filter_params.INNOVATION_INDEX?.options
      },
      {
        type_criterion: 'check',
        title: this.filterTranslation.filters.filter.title.exit_status,
        params: this.filter_params.EXIT_STATUS,
        showCriterion: () => this.filterTranslation.filters.filter.title.exit_status && this.filter_params.EXIT_STATUS
      },
      {
        type_criterion: 'check',
        title: this.filterTranslation.filters.filter.title.deal_size,
        params: this.filter_params.DEAL_SIZE,
        showCriterion: () => this.filterTranslation.filters.filter.title.deal_size && this.filter_params.DEAL_SIZE
      },
      {
        type_criterion: 'check',
        title: this.filterTranslation.filters.filter.title.investment_stage,
        params: this.filter_params.INVESTMENT_STAGE,
        showCriterion: () => this.filterTranslation.filters.filter.title.investment_stage && this.filter_params.INVESTMENT_STAGE
      },
      {
        type_criterion: 'interval',
        title: this.filterTranslation.filters.filter.title.total_capital,
        param: this.filter_params.TOTAL_CAPITAL,
        showCriterion: () => this.filterTranslation.filters.filter.title.total_capital && this.filter_params.TOTAL_CAPITAL?.options
      },
      {
        type_criterion: 'check',
        title: this.filterTranslation.filters.filter.title.is_send_message,
        params: this.filter_params.IS_SEND_MESSAGE,
        showCriterion: () => this.filterTranslation.filters.filter.title.is_send_message && this.filter_params.IS_SEND_MESSAGE
      }
    ];
    this.criterionElements = this.criterionElements.filter((item) => item.showCriterion());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['filterTranslation'].firstChange) {
      console.log(changes.filterTranslation.currentValue);
      this.setCriterionElements();      
    }
    if (changes['sortingList']) {
      if (this.sortingList.length > 0) {
        this.selectedSortItem = +this.sessionStorageService.get(`${this.typeFirm}-filter-sortId`) || this.sortingList.sort((a, b) => a.sort_index - b.sort_index)[0].object_id;
//        console.log(this.sortingList);

//        console.log('1. fpi'); console.log(this.filterService.filterParamsInput);
        this.filterService.filterFirms(this.typeFirm, true, this.selectedSortItem, this.offset, this.paramLimit + this.limit).subscribe((fltPtn: FilterPTN) => {
          this.countAllFirms = fltPtn.count_all;
          this.filter_params = fltPtn.filter_params;
//          console.log('2. fp'); console.log(this.filter_params);

          this.setCriterionElements();

          this.filter_firms = JSON.parse(JSON.stringify(fltPtn.filter_firms));
//          console.log('3. ff'); console.log(this.filter_firms);
        },
          (error) => {
            this.filterError = this.errorService.getErrorPTNMessage(error);
//            console.error(this.filterError);
          });

          if (this.paramLimit > 0)
            this.offset = this.paramLimit;
          this.paramLimit = 0;
          if (this.subscriptionParam && !this.subscriptionParam.closed)
              this.subscriptionParam.unsubscribe();
      
      }
    }
  }
  
  ngOnInit(): void {

    this.initialViewResult = this.sessionStorageService.viewFilterResult || 'table';
    this.tableBtnToggle.notVisible = this.initialViewResult == 'table';
    this.cardBtnToggle.notVisible = !this.tableBtnToggle.notVisible;

    this.breakpointObserver.observe(['(max-height: 800px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.visitorBottom = -70;
      } else {
        this.visitorBottom = 0;
      }
    });
  }

  ngOnDestroy(): void {
    this.filterService.filterParamsInput = {};
  }

  setFilter(event?: any): void {
    this.filterService.setFilterParamInput(this.filter_params, this.filterTranslation);
  }

  private loadMore(): void {
//    console.log('Loadmore');

    this.filterService.filterFirms(this.typeFirm, false,  this.selectedSortItem, this.offset = this.offset  + this.limit, this.limit).subscribe((fltPtn: FilterPTN) => {
      this.countAllFirms = fltPtn.count_all;
//      console.log('2. fp'); console.log(this.filter_params);      

      this.filter_firms = this.filter_firms.concat(fltPtn.filter_firms);
//      console.log('3. ff'); console.log(this.filter_firms);
    },
      (error) => {
        this.filterError = this.errorService.getErrorPTNMessage(error);
//        console.error(this.filterError);
      });

    this.paramLimit = 0;
    if (this.subscriptionParam && !this.subscriptionParam.closed)
        this.subscriptionParam.unsubscribe();

    if (this.offset && this.offset > 0)
      this.filterService.setOffsetParam(this.offset);
  }


  onLoadMore(): void {
//    console.log('onLoadMore');
    this.loadMore();
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (!this.loaderService.IsLoading && this.filter_firms.length < this.countAllFirms && Math.ceil(event.target.offsetHeight + event.target.scrollTop) >= event.target.scrollHeight) {
//      console.log('OnScrollEnd');
      this.loadMore();
    }
  }

  onClearAllFilters(): void {
//    console.log('ClearAllFilters');
    this.filterService.clearFilterParamInput();
  }

  onChangeSort(selectedSortItem: number) {
//    console.log(selectedSortItem);
    this.filterService.filterFirms(this.typeFirm, false,  this.selectedSortItem, this.offset = 0, this.limit).subscribe((fltPtn: FilterPTN) => {
      if (this.platformService.handsetPlatform) {
        document.querySelector('.result-container-body-inner-m').scrollTop = 0;
      } else {
        document.querySelector('.result-container-body-inner').scrollTop = 0;
      }
      this.countAllFirms = fltPtn.count_all;
//      console.log('2. fp'); console.log(this.filter_params);      

      this.filter_firms = JSON.parse(JSON.stringify(fltPtn.filter_firms));
//      console.log('3. ff'); console.log(this.filter_firms);

      this.sessionStorageService.set(`${this.typeFirm}-filter-sortId`, this.selectedSortItem);
    },
      (error) => {
        this.filterError = this.errorService.getErrorPTNMessage(error);
        console.error(this.filterError);
      });
  }

  onViewResultChange(val: string) {
    this.initialViewResult = val;
    this.sessionStorageService.viewFilterResult = val;
    this.tableBtnToggle.notVisible = this.initialViewResult == 'table';
    this.cardBtnToggle.notVisible = !this.tableBtnToggle.notVisible;
  }

  isVisorVisible(): boolean {
    return this.filter_firms.find((firm) => firm.subscribe_level > (this.userService.user?.subscribe_level || 0)) ? true : false;
  }

  getResultContainerBodyStyle(): any {
    if (this.isVisorVisible()) {

      let styles = {
        overflow: 'hidden'
      };

      return styles;
    }
  }

  getLoadMoreBtnStyle(): any {
    if (this.isVisorVisible()) {

      let styles = {
        display: 'none'
      };

      return styles;
    }
  }

  getVisitorStyle(): any {
    if (this.isVisorVisible()) {

      let offset = 15 + 30 + 48 * this.filter_firms.findIndex((firm) => firm.subscribe_level > (this.userService.user?.subscribe_level || 0));
      let styles = {
        top: `${offset}px`,
        bottom: `${this.visitorBottom}px`
      };

      return styles;
    }
  }
  
  getVisitorStyleM(): any {
    if (this.isVisorVisible()) {

      let styles = {
        top: '20%'
      };

      return styles;
    }
  }

  onShowFiltersM(): void {
    this.sessionStorageService.isShowFilterMenuM = !this.sessionStorageService.isShowFilterMenuM;
  }
}
