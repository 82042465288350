import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router"
import { CompanyAdvanced } from 'src/app/models/company.data';
import { TranslationService, TranslateObject } from './../services/translation.service';
import { langPAGE_HOME } from "./../shared/constant.module";
import { CompanyService } from "./../services/company.service";
import { UserService } from "./../services/user.service";
import { CompanyPresentation } from "./../models/company.data";
import { CompanyTranslation } from "./../shared/translation.const";
import { DownloadFileService } from "./../services/download.file.service";
import { WebAddressService } from "./../services/web.address.service";
import { SessionStorageService } from "./../services/session-storage-service";
import { Company, Founder } from "./../models/company.data";
import { PlatformService } from './../services/platform.service';


@Component({
    selector: 'company-card',
    templateUrl: './company.card.component.html',
    styleUrls: ['./company.card.component.css']
})
  
export class CompanyCardComponent implements OnInit {

    private founderClickX: number = 0;
    private founderClickY: number = 0;

    companyID: number = null;
    company: CompanyAdvanced = {    
      code_firm: null,
      also_known_as: "",
      code_city: null,
      code_country: null,
      code_number_of_emploees: "",
      code_state: null,
      deals_size: "",
      email: "",
      exit_status: "",
      external_number_firm: null,
      focus: "",
      founded_date: "",
      founded_year: null,
      innovation_index: null,
      legal_name: "",
      name_city: "",
      name_country: "",
      name_deals_size: "",
      name_exit_status: "",
      name_firm: "",
      name_state: "",
      name_type_firm: "",
      number_of_emploees: "",
      phone: "",
      product_list: "",
      top_deals: "",
      total_capital: null,
      total_funding_amount: null,
      type_firm: "",
      valuation: null,
      website: "",
      websiteToShow: "",
      presentation_orig_file: null,
      category: [],
      competitors: [],
      founders: [],
      industry: [],
      investmentStage: [],
      investors: [],
      keyPeople: [],
      nameRegion: [],
      portfolioCompanies: [],
      principalAt: [],
      socialNetworks: [],
      image_url: "",
      is_exists_rounds: null,
      presentation_url: "",
      is_send_message: 0,
      principals: [],
      current_user_company_relations: []
    }

    companyTranslation = CompanyTranslation;
    buttonObjects: Array<TranslateObject> = [];
    sendButtonPerson: Array<TranslateObject> = [];
    sendButtonCompany: Array<TranslateObject> = [];

    listCompany: Array<Company>;

    showPersonCard: boolean = false;
    showSendMessage: boolean = false;
    private personCardBusy: boolean = false;
    private companyPersonCardBusy: boolean = false;
    private timerID = undefined;
    personID: number = null;

    messagePersonID: number = null;
    messageCompanyID: number = null;


    constructor(private activatedRoute: ActivatedRoute, private translationService: TranslationService,
                private router: Router, private companyService: CompanyService, private userService: UserService,
                private downloadFileService: DownloadFileService, private webAddressService: WebAddressService,
                private sessionStorageService: SessionStorageService, public platformService: PlatformService) {

        this.listCompany = this.sessionStorageService.get('listCompany');

        // Переклади
        this.translationService.getTranslate(langPAGE_HOME, this.companyTranslation);
        this.translationService.getCategoryObject('PAGE_HOME.FIRM.CARD.BUTTON', this.buttonObjects);
        this.translationService.getCategoryObject('PAGE_PERSON.BUTTON.MESSAGE', this.sendButtonPerson);
        this.translationService.getCategoryObject('PAGE_HOME.FIRM.CARD.BUTTON.MESSAGE', this.sendButtonCompany);
    }


    ngOnInit() {

        let par: number;
        this.activatedRoute.params.forEach((params: Params) => {

            par = +params["companyId"];

            if (this.companyID != par) {
                this.companyID = par;
                this.checkListCompany();
                this.getCompany(this.companyID);
            }
        });

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate(langPAGE_HOME, this.companyTranslation);
            this.translationService.getCategoryObject('PAGE_HOME.FIRM.CARD.BUTTON', this.buttonObjects);
            this.translationService.getCategoryObject('PAGE_PERSON.BUTTON.MESSAGE', this.sendButtonPerson);
            this.translationService.getCategoryObject('PAGE_HOME.FIRM.CARD.BUTTON.MESSAGE', this.sendButtonCompany);
        });

        this.userService.Authenticated$.subscribe((isAuthenticated: boolean) => {

            this.sessionStorageService.remove('listCompany');
            this.listCompany = [];
        });
    }

    
    private checkListCompany() {

        let existsCompany: boolean = false;

        if (!this.listCompany)
            return;
            
        for (let i = 0; i < this.listCompany.length; i++) {

            if (this.listCompany[i].code_firm == this.companyID) {
                existsCompany = true;
                break;
            }
        }

        if (!existsCompany)
            this.listCompany = [];
    }


    private getCompany(codeCompany: number) {

        this.companyService.getCompany(codeCompany).subscribe(
            response => {

                let json = response;
                if (json) {
                   
                    this.companyService.company = json;
                    this.company = this.companyService.company;
                    this.prepareWeb();
                }
            },
            error => console.log(error)
        );
    }


    neadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel = this.userService.user?.subscribe_level || 0;
        return (subscribeLevel > userSubsribeLevel);
    }


    onPresentationClick(codeCompany: number) {

        this.companyService.getPresentation(codeCompany, this.company.presentation_orig_file).subscribe(
            response => {
                let companyPresentation: CompanyPresentation = response;

                this.downloadFile(companyPresentation.s3PresignedUrl);
//                this.downloadFile(decodeURIComponent(companyPresentation.s3PresignedUrl));
            },
            error => console.log(error)
        );
    }


    downloadFile(url: string) {

        this.downloadFileService.downloadFile(url, this.company.presentation_orig_file).subscribe(response => {
           
            let blob:any = new Blob([response], { type: 'application/pdf' });
            const objectURL = URL.createObjectURL(blob);
    
/*   
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(response)
            a.href = url
            a.click();
            URL.revokeObjectURL(objectUrl);
*/            
            window.open(objectURL);
//            window.location.href = objectURL;
            URL.revokeObjectURL(objectURL);
        }, 
            error => console.log(error),
            () => console.info('File downloaded successfully')
        );
    }


    private prepareWeb() {

        this.company.websiteToShow = this.webAddressService.cutProtocol(this.company.website);
    }


    neadDealsSubscribe() {

        if (!this.buttonObjects || (this.buttonObjects.length <= 0))
            return false;

        let userSubsribeLevel = this.userService.user?.subscribe_level || 0;

        for (let i = 0; i < this.buttonObjects.length; i++) {

            if (this.buttonObjects[i].object_name == 'PAGE_HOME.FIRM.CARD.BUTTON.DEALS')
                return (this.buttonObjects[i].subscribe_level > userSubsribeLevel);
        }
    }


    neadPresentationSubscribe() {

        if (!this.buttonObjects || (this.buttonObjects.length <= 0))
            return false;

        let userSubsribeLevel = this.userService.user?.subscribe_level || 0;

        for (let i = 0; i < this.buttonObjects.length; i++) {

            if (this.buttonObjects[i].object_name == 'PAGE_HOME.FIRM.CARD.BUTTON.VIEW_PRESENTATION')
                return (this.buttonObjects[i].subscribe_level > userSubsribeLevel);
        }
    }


    neadMessagePersonSubscribe(): boolean {

        if (!this.sendButtonPerson || (this.sendButtonPerson.length <= 0))
            return false;

        let userSubsribeLevel = this.userService.user?.subscribe_level || 0;

        return (this.sendButtonPerson[0].subscribe_level > userSubsribeLevel);
    }


    neadMessageCompanySubscribe() {
        
        if (!this.sendButtonCompany || (this.sendButtonCompany.length <= 0))
            return false;

        let userSubsribeLevel = this.userService.user?.subscribe_level || 0;

        return (this.sendButtonCompany[0].subscribe_level > userSubsribeLevel);
    }


    onSendMessagePersonClick(founder: Founder) {

        if (this.neadMessagePersonSubscribe()) {

            this.router.navigate(['/subscribes']);
        } else {
            if (this.platformService.handsetPlatform) {
                this.router.navigate(['/send-message'], { queryParams: { person_id: founder.code_person,
                                                                        company_id: this.companyID } });
            } else {
                this.messagePersonID = founder.code_person;
                this.messageCompanyID = null;
                this.showSendMessage = true;
            }
        }
    }


    onSendMessageCompanyClick() {

        if (this.neadMessageCompanySubscribe()) {

            this.router.navigate(['/subscribes']);
        } else {
            if (this.platformService.handsetPlatform) {
                this.router.navigate(['/send-message'], { queryParams: { person_id: null,
                                                                        company_id: this.company.code_firm } });
            } else {
                this.messagePersonID = null;
                this.messageCompanyID = this.company.code_firm;
                this.showSendMessage = true;
            }
        }
    }

    
    getSendMessagePos() {
        let elementMsg = document.getElementById('send-msg');
        let elementCompany = document.getElementById('company');

        if (elementMsg && elementCompany && elementCompany.parentElement && elementCompany.parentElement.parentElement) {

            let styles = {
                "top": (elementCompany.parentElement.parentElement.clientHeight - elementMsg.clientHeight) / 2 + 'px'
            };
    
            return styles;        
        }
    }


    onCloseMessage(event: any) {

        this.showSendMessage = false;
    }


    onClosePersonCard(event) {

        this.showPersonCard = !event;
        this.personCardBusy = false;
    }


    getSocialNetImage(socialTypeAddress: string): string {

        return this.webAddressService.getSocialImg(socialTypeAddress);
    }


    getPersonCardPosition() {

      if (this.founderClickX <= 0 || this.founderClickY <= 0)
          return undefined;

      let founderClickX = this.founderClickX;
      let founderClickY = this.founderClickY;

      let element = document.getElementById('prsn-crd');

      founderClickY = founderClickY - element.clientHeight - 20;
      founderClickX = founderClickX - element.clientWidth / 2;

      if (founderClickX < 0)
        founderClickX = 0;

      let styles = {
          "top": founderClickY.toString() + 'px',
          "left": founderClickX.toString() + 'px'
      };

      return styles;
  }


    onFounderClick(event: MouseEvent, founder: Founder) {

        if (this.platformService.handsetPlatform) {

            this.router.navigate(['/person-card'], { queryParams: { person_id: founder.code_person } });
        } else {

            if (event) {
                this.founderClickX = event.pageX;
                this.founderClickY = event.pageY;
            }

            this.personID = founder.code_person;
            this.showPersonCard = true;
        }
    }


    onFounderMouseMove(event: MouseEvent, founder: Founder) {

        if (event && !this.showPersonCard) {
            this.founderClickX = event.pageX;
            this.founderClickY = event.pageY;
        }

        this.companyPersonCardBusy = true;

        if (this.timerID) {
            clearTimeout(this.timerID);
            this.timerID = undefined;
        }

        if (this.personID != founder.code_person) {

            this.companyPersonCardBusy = false;
            this.showPersonCard = false;
        }

        this.timerID = setTimeout(() => { 

            this.personID = founder.code_person;
            this.showPersonCard = true;
        }, 500);
    }


    onFounderMouseOut(event: any, founder: Founder) {

        if (this.timerID) {
            clearTimeout(this.timerID);
            this.timerID = undefined;
        }

        this.companyPersonCardBusy = false;

        this.timerID = setTimeout(() => {

            if (!this.companyPersonCardBusy && !this.personCardBusy)
                this.showPersonCard = false;
        }, 2000);
    }


    getRootHref(): string {
        let url: string;
        let slashPos = this.router.url.lastIndexOf('/');
        if (slashPos == -1) {
            url = this.router.url;
        }
        else {
            url = this.router.url.substring(0, slashPos);
        }

        return url;
    }


    onPersonCardBusy(event: boolean) {

        if (this.timerID) {
            clearTimeout(this.timerID);
            this.timerID = undefined;
        }

        this.personCardBusy = event;

        if (!this.personCardBusy) {

            this.timerID = setTimeout(() => {

                if (!this.companyPersonCardBusy && !this.personCardBusy)
                    this.showPersonCard = false;
            }, 2000);
        }
    }    


    visibleGeneral(): boolean {

        let result: any;

        if (!this.companyID)
            result = false
        else
            result = this.company.name_type_firm || this.company.founded_year ||
                this.company.phone || this.company.email ||
                this.company.name_country || this.company.name_state || 
                this.company.name_city || this.company.product_list || 
                (this.company.competitors && this.company.competitors.length > 0);

        return result;
    }


    visibleFinancials(): boolean {

        let result: boolean = true;

        if (!this.companyID)
            result = false
        else
            result = (this.company.valuation > 0) || (this.company.total_funding_amount > 0) || 
                (this.company.total_capital > 0) || ((this.company.top_deals.length > 0) && ((this.company.type_firm == 'VC') || (this.company.type_firm == 'PI')));

        return result;
    }


    visibleInvestments(): boolean {

        let result: any;

        if (!this.companyID)
            result = false
        else
            result = (this.company.investmentStage && (this.company.investmentStage.length > 0)) ||
                this.company.deals_size || 
                (this.company.investors && (this.company.investors.length > 0)) ||
                (this.company.portfolioCompanies && (this.company.portfolioCompanies.length > 0));

        return result;
    }

}