import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage-service';
import { PaymentSystemSession } from '../../models/user';
import { ErrorService } from '../../services/error.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'ptn-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.css']
})
export class SuccessPaymentComponent implements OnInit {

  errorPtn: string;
  successMessage: string;
  paymentSystemSession: PaymentSystemSession;

  constructor(private router: Router, private userService: UserService, private errorService: ErrorService, private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {

    let urlParams = new URLSearchParams(window.location.search);
    let sessionId = urlParams.get('session_id');
    let paymentSystemSession: PaymentSystemSession = {
      sessionId: sessionId
    };

    console.log(paymentSystemSession);
    this.userService.checkoutSession(paymentSystemSession).subscribe(
      result => {
        if (result) {
          console.log(result);
          this.paymentSystemSession = result;
          console.log(this.paymentSystemSession.sessionId);          

          this.userService.signIn(this.userService.user).subscribe((res) => {
            this.successMessage = 'You subscribed successfully!';
          },
            (error) => {
              this.errorPtn = this.errorService.getErrorPTNMessage(error);
              console.error(this.errorPtn);
              this.userService.actionOnSignInError();
            }
          );
        }
      },
      (error) => {
        this.errorPtn = this.errorService.getErrorPTNMessage(error);
        console.error(this.errorPtn);
      }
    );
  }

  onOk(): void {    
      this.router.navigate(['/']);
  }
}
