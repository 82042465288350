<div class="send-message" id="send-message" *ngIf="sendMessageObject.code_instance && !platformService.handsetPlatform">
    <form class="form" novalidate [formGroup]="sendMailForm" (ngSubmit)="onSubmit()">
        <p class="title">{{personTranslation.message_form.title}}</p>

        <div class="form-body">

            <div class="rules">{{personTranslation.message_form.text}}</div>

            <span class="sent-count" *ngIf="sendMessageObject.date_chat_message_last && sendMessageObject.cnt_messages">
                {{personTranslation.message_form.last}} 
                {{sendMessageObject.date_chat_message_last | date: 'dd.MM.yyyy H:mm' }}
                ({{personTranslation.message_form.total}} {{sendMessageObject.cnt_messages}})
            </span>

            <div class="first-col from-company">{{personTranslation.message_form.firms.title}}</div>
        
            <div class="company-list">
                <select #selCompany class="form-control" formControlName="companyID" (change)="onCompanyChange(selCompany.selectedIndex)">
                    <option *ngFor="let company of sendMessageObject.current_user_company_relations">
                        {{company.name_firm}}
                        <span *ngIf="company.is_exist_presentation == 1">
                            &nbsp;&nbsp;&nbsp;{{personTranslation.message_form.firms.presentation}}
                        </span>
                    </option>
                </select>
            </div>
        
            <div class="table">
                <div class="first-col">{{personTranslation.message_form.sender}}</div>
                <div class="second-col">
                    {{currentAccount.first_name}}&nbsp;
                    {{currentAccount.last_name}},
                    {{selectedCompany.name_position}}&nbsp;of
                    {{selectedCompany.name_firm}}&nbsp;
                </div>
        
                <div class="first-col">{{personTranslation.message_form.recipient}}</div>
                <div class="second-col" *ngIf="sendMessageObject.listRecipient.length == 1">
                    {{sendMessageObject.listRecipient[0].full_name}}
                </div>
                <div class="second-col" *ngIf="sendMessageObject.listRecipient.length > 1">
                    <div class="recipient-list" *ngFor="let recipient of sendMessageObject.listRecipient; let firstItem = first; index as i">
                        <div>
                            <input type="checkbox" formControlName="recipient_{{i}}" (change)=onRecipientChange()>
                            <span *ngIf="recipient.full_name">&nbsp;{{recipient.full_name}}</span>
                            <span *ngIf="recipient.position">, {{recipient.position}}</span>
                        </div>
                    </div>
                </div>
            
                <div class="first-col">{{personTranslation.message_form.subject}}</div>
                <div class="second-col">{{currentSubject}}</div>
                        
                <div class="first-col text-msg">{{personTranslation.message_form.message}}</div>
                <textarea id="text-msg" class="second-col form-control" formControlName="textMessage" ></textarea>
            
                <div class="first-col">{{personTranslation.message_form.attach}}</div>
                <input type="checkbox" id="attach" class="second-col" formControlName="attachPresentation">
            </div>

            <div id="result-msg">
                <div id="result-msg_{{i}}" *ngFor="let msg of sendResultMessages; index as i" [ngClass]="{ messageError: msg.sentResult == -1 }">
                    {{msg.message}}&nbsp;{{msg.full_name}}
                </div>
            </div>

            <div id="result-end">&nbsp;</div>
        </div>

        <div class="buttons-msg">
            <div>
                <button class="cancel" *ngIf="!sentResult" (click)="onCloseClick()">{{personTranslation.message_form.button.cansel}}</button>
                <button class="subm" type="submit" *ngIf="!sentResult" [disabled]="!sendMailForm.valid || recipientCount < 1">
                    {{personTranslation.message_form.button.send}}
                </button>
            </div>
            <button class="cls" *ngIf="sentResult" (click)="onCloseClick()">{{personTranslation.message_form.button.close}}</button>
        </div>
    </form>
</div>


<!-- Mobile -->
<div class="mob-send-message" *ngIf="platformService.handsetPlatform">
    <form class="mob-form" novalidate [formGroup]="sendMailForm" (ngSubmit)="onSubmit()">
        <p class="title">{{personTranslation.message_form.title}}</p>

        <div class="mob-form-body">

            <span class="mob-rules">{{personTranslation.message_form.text}}</span>

            <div class="mob-sent-count" *ngIf="sendMessageObject.date_chat_message_last && sendMessageObject.cnt_messages">
                {{personTranslation.message_form.last}} 
                {{sendMessageObject.date_chat_message_last | date: 'dd.MM.yyyy H:mm' }}
                ({{personTranslation.message_form.total}} {{sendMessageObject.cnt_messages}})
            </div>

            <div class="first-col mob-from-company">{{personTranslation.message_form.firms.title}}</div>

            <div class="company-list">
                <select #selCompany class="form-control" formControlName="companyID" (change)="onCompanyChange(selCompany.selectedIndex)">
                    <option *ngFor="let company of sendMessageObject.current_user_company_relations">
                        {{company.name_firm}}
                        <span *ngIf="company.is_exist_presentation == 1">
                            &nbsp;&nbsp;&nbsp;{{personTranslation.message_form.firms.presentation}}
                        </span>
                    </option>
                </select>
            </div>

            <div class="mob-table">
                <div class="first-col">{{personTranslation.message_form.sender}}</div>
                <div class="second-col">
                    {{currentAccount.first_name}}&nbsp;
                    {{currentAccount.last_name}},
                    {{selectedCompany.name_position}}&nbsp;of
                    {{selectedCompany.name_firm}}&nbsp;
                </div>

                <div class="first-col">{{personTranslation.message_form.recipient}}</div>
                <div class="second-col" *ngIf="sendMessageObject.listRecipient.length == 1">
                    {{sendMessageObject.listRecipient[0].full_name}}
                </div>
                <div class="second-col" *ngIf="sendMessageObject.listRecipient.length > 1">
                    <div class="recipient-list" *ngFor="let recipient of sendMessageObject.listRecipient; let firstItem = first; index as i">
                        <div>
                            <input type="checkbox" formControlName="recipient_{{i}}" (change)=onRecipientChange()>
                            <span *ngIf="recipient.full_name">&nbsp;{{recipient.full_name}}</span>
                            <span *ngIf="recipient.position">, {{recipient.position}}</span>
                        </div>
                    </div>
                </div>

                <div class="first-col">{{personTranslation.message_form.subject}}</div>
                <div class="second-col">{{currentSubject}}</div>
                        
                <div class="first-col text-msg">{{personTranslation.message_form.message}}</div>
                <textarea id="text-msg" class="second-col form-control" formControlName="textMessage" ></textarea>
            
                <div class="first-col">{{personTranslation.message_form.attach}}</div>
                <input type="checkbox" id="attach" class="second-col" formControlName="attachPresentation">
            </div>

            <div id="result-msg">
                <div id="result-msg_{{i}}" *ngFor="let msg of sendResultMessages; index as i" [ngClass]="{ messageError: msg.sentResult == -1 }">
                    {{msg.message}}&nbsp;{{msg.full_name}}
                </div>
            </div>

            <div id="result-end">&nbsp;</div>

        </div>

        <div class="mob-buttons-msg">
            <div *ngIf="!sentResult">
                <button class="mob-cancel" (click)="onBackClick()">
                    {{personTranslation.message_form.button.cansel}}
                </button>
                <button class="mob-subm" type="submit" [disabled]="!sendMailForm.valid || recipientCount < 1">
                    {{personTranslation.message_form.button.send}}
                </button>
            </div>

            <button *ngIf="sentResult" (click)="onBackClick()">
                {{personTranslation.message_form.button.back}}
            </button>
        </div>

    </form>
</div>
