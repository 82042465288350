<div class="main">
    <div class="menu_ref" *ngIf="!searchPageLocate()"> 
        <div>
            <button class="toggle-hamburger toggle-hamburger__animx" (click)="onMenuClick()" [ngClass]="{ animateMenu: showMenu }">
                <span>menu toggle</span>
            </button>
        </div>              
    </div>

    <div class="body">
        <div class="logo" *ngIf="!searchPageLocate()">
            <a [routerLink]="[]" routerLinkActive="active" (click)="onLogoClick()">
                <img src="/assets/imgs/Logo.svg">
            </a>
        </div>

        <div class="search" *ngIf="searchPageLocate()">
            <div class="search-box" [ngClass]="{ searchBoxMove: startMoveSearch }">
                <div class="search-form">
                    <input id="searchInput" type="text" placeholder="{{searchTranslation.text}}" #searchInput (input)="onSearchInput(searchInput.value)"
                        autofocus [(value)]="searchValue">
                </div>
            </div>
        </div>
    </div>

    <div class="service">
        <div class="search-btn-container backface" *ngIf="!showMenu && !showServiceMenu" [ngClass]="{ btnSearchRotate: startMoveSearch }">
            <div class="search-btn">
                <a [routerLink]="[]" routerLinkActive="active" (click)="onSearchClick()">
                    <img src="/assets/imgs/SearchWhite.svg">
                </a>
            </div>

            <div class="close-search-btn">
                <a class="close-search" [routerLink]="[]" routerLinkActive="active" (click)="onCloseSearchClick()">
                    <img src="/assets/imgs/close-white.svg">
                </a>
            </div>
        </div>        

        <div class="service-menu" *ngIf="showMenu && !showServiceMenu">
            <a [routerLink]="[]" routerLinkActive="active" (click)="onServiceMenuClick()">
                <img *ngIf="!isAuthenticated" src="/assets/imgs/menu_unloged.svg">
                <img *ngIf="isAuthenticated" src="/assets/imgs/menu_loged.svg">
            </a>
        </div>

    </div>
</div>


<!-- Main menu -->
<div class="main-menu" *ngIf="menuTurn" [ngClass]="{ menuShow: showMenu }">
    <div class="main-container">
        <div class="main-body">
            <div class="menu-item">
                <a [routerLink]="[]" routerLinkActive="active" (click)="onHomeMenuClick()">
                    {{mnuCaptions.home}}
                </a>
            </div>
            <div class="menu-item">
                <a [routerLink]="[]" routerLinkActive="active" (click)="onCompaniesMenuClick()">
                    {{mnuCaptions.companies}}
                </a>
            </div>
            <div class="menu-item">
                <a [routerLink]="[]" routerLinkActive="active" (click)="onFundsMenuClick()">
                    {{mnuCaptions.vc_funds}}
                </a>
            </div>
            <div class="menu-item">
                <a [routerLink]="[]" routerLinkActive="active" (click)="onInvestorsMenuClick()">
                    {{mnuCaptions.privat_investors}}
                </a>
            </div>
            <div class="menu-item">
                <a [routerLink]="[]" routerLinkActive="active" (click)="onDealsMenuClick()">
                    {{mnuCaptions.deals}}
                </a>
            </div>
            <div class="menu-item">
                <a [routerLink]="[]" routerLinkActive="active" (click)="onAnalysisMenuClick()">
                    {{mnuCaptions.analysis}}
                </a>
            </div>
            <div class="menu-item">
                <a [routerLink]="[]" routerLinkActive="active" (click)="onListMenuClick()">
                    {{mnuCaptions.list}}
                </a>
            </div>
            <div class="menu-item">
                <a [routerLink]="[]" routerLinkActive="active" (click)="onPtnIndexMenuClick()">
                    {{mnuCaptions.ptn_index}}
                </a>
            </div>
            <div class="menu-item">
                <a [routerLink]="[]" routerLinkActive="active" (click)="onServiceMenuClick()">
                    <span>{{mnuCaptions.settings}}</span>
                </a>
            </div>
        </div>

        <div class="partner">
            <span>{{homeTranslation.partnership}}</span>
            <a href="https://mvp.vc/" target="_blank">
                <img src="/assets/imgs/MVP header-logo.png">
            </a>
            <span>{{homeTranslation.partner_slogan}}</span>
        </div>

        <div class="social-networks">
            <div *ngFor="let socNet of translateSocialNetworks" >
                <a href="{{socNet.translation}}" target="_blank" *ngIf="socNeadSubscribe(socNet.subscribe_level)" title="{{socNet.translation}}">
                    <img src="{{getSocialNetImage(socNet)}}">
                </a>
            </div>
        </div>

        <div class="footer">{{footerTranslation.copyright.text}}</div>

    </div>    
</div>


<!-- Service menu -->

<div class="main-service" *ngIf="menuTurn" [ngClass]="{ serviceMenuShow: showServiceMenu }">

    <div class="service-body">
        <div class="simple-item">
            <span *ngIf="isAuthenticated">{{userService.user.first_name}} {{userService.user.last_name}}</span>
            <span *ngIf="isAuthenticated" [ngClass]="account">{{userService.user.email}}</span>
        </div>

        <div class="service-menu-item" *ngIf="!isAuthenticated">
            <a [routerLink]="[]" routerLinkActive="active" (click)="onSignInClick()">
                <img class="mnu-img" src="/assets/imgs/login.svg">
                {{mnSrvTranslation.login}}
            </a>
        </div>
        <div class="service-menu-item">
            <a [routerLink]="[]" routerLinkActive="active" (click)="onRegisterClick()">
                <img class="mnu-img" src="/assets/imgs/register.svg">
                {{mnSrvTranslation.register}}
            </a>
        </div>
        <div class="service-menu-item">
            <a [routerLink]="[]" routerLinkActive="active" (click)="onSubscribesClick()">
                <img class="mnu-img" src="/assets/imgs/subscribe.svg">
                {{mnSrvTranslation.subscribe}}
            </a>
        </div>
        <div class="service-menu-item">
            <a [routerLink]="[]" routerLinkActive="active" (click)="onPrivatePolicyClick()">
                <img class="mnu-img" src="/assets/imgs/privacy.svg">
                {{mnSrvTranslation.privacy}}
            </a>
        </div>
        <div class="service-menu-item">
            <a [routerLink]="[]" routerLinkActive="active" (click)="onTermOfUseClick()">
                <img class="mnu-img" src="/assets/imgs/term_of_use.svg">
                {{mnSrvTranslation.terms_of_use}}
            </a>
        </div>
        <div class="service-menu-item">
            <a [routerLink]="[]" routerLinkActive="active" (click)="onDataCollectClick()">
                <img class="mnu-img" src="/assets/imgs/data_collection.svg">
                {{mnSrvTranslation.data_collection}}
            </a>
        </div>
        <div class="service-menu-item">
            <a href="mailto:{{mnSrvTranslation.support.email}}">
                <img class="mnu-img" src="/assets/imgs/support.svg">
                {{mnSrvTranslation.support.caption}}
            </a>
        </div>
        <div class="service-menu-item">
            <a [routerLink]="[]" routerLinkActive="active" (click)="onAboutClick()">
                <img class="mnu-img" src="/assets/imgs/about.svg">
                    {{mnSrvTranslation.about}}
            </a>
        </div>
        <div class="service-menu-item" *ngIf="isAuthenticated">
            <a [routerLink]="[]" routerLinkActive="active" (click)="onSignOut()">
                <img class="mnu-img" src="/assets/imgs/logout.svg">
                {{mnSrvTranslation.sign_out}}
            </a>
        </div>

        <div class="service-menu-item menu-item-no-img">
            <a [routerLink]="[]" routerLinkActive="active" (click)="onSyntheticVC()">
                {{mnSrvTranslation.synthetic_vc}}
            </a>
        </div>
    </div>

    <div class="trial-container" [innerHtml]="trialSubscribe"></div>

</div>
