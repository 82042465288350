<div class="main" *ngIf="!platformService.handsetPlatform" (window:resize)="onResize($event)" [ngClass]="{ sendMessage: showSendMessage }">
    <div class="body" id="body" tabindex="0" [ngStyle]="getCanvasWidthBig()" (scroll)='onScroll($event)'>
        <div class="big" *ngIf="tileSize.bigSize">
            <div class="tile-big" id="company{{company.code_firm}}" *ngFor="let company of listCompany; index as i" [ngClass]="{ tileBlured: neadSubscribe(company.subscribe_level) }">
                <div class="space"></div>

                <div class="company-face">
                    <div class="logo-company">
                        <a [routerLink]="[]" routerLinkActive="active" (click)="onOpenCardClick(company.code_firm)">
                            <img class="img-big" src={{company.image_url}} alt={{company.code_firm}} title={{company.code_firm}}>
                        </a>
                        <div class="about-company">
                            <a class="name-company" [routerLink]="[]" routerLinkActive="active" (click)="onOpenCardClick(company.code_firm)">{{company.name_firm}}</a>
                            <label class="label-company">{{company.name_country}} {{company.founded_year}}</label>
                        </div>
                    </div>

                    <div class="face-right">
                        <div>
                            <div class="fixed-col" *ngIf="company.valuation">{{company.name_object_valuation}}</div>
                            <div class="money" *ngIf="company.valuation">{{company.valuation | currency : '' : 'symbol' : '1.0'}}</div>
                        </div>
                        <div>
                            <div class="fixed-col" *ngIf="company.total_funding_amount">{{company.name_object_invested}}</div>
                            <div class="money" *ngIf="company.total_funding_amount">{{company.total_funding_amount | currency : '' : 'symbol' : '1.0'}}</div>
                        </div>
                    </div>
                </div>

                <div class="face-detail">
                    <div class="focus-block">
                        <img class="focus" src="/assets/imgs/focus.svg">
                        <span>{{company.focus}}</span>
                    </div>
                    <div>
                        <a *ngIf="company.websiteToShow" class="company-website" href="{{company.website}}" target="_blank">
                            <img class="www1" src="/assets/imgs/www.svg">
                            <span>{{company.websiteToShow}}</span>
                            <img class="www2" src="/assets/imgs/www2.svg">
                        </a>
                    </div>
                </div>

                <div class="company-detail">
                    <div class="fixed-col" *ngIf="company.founders && company.founders.length > 0">
<!--                        {{companiesTranslation.company.card.field.founder}} -->
                        {{company.name_object_founder}}
                    </div>

                    <div class="div-list">
                        <div class="subscribe comma-text" *ngFor="let founder of company.founders; let lastItem = last;">
                            <a class="comma-link" (mousemove)="onFounderMouseMove($event, founder)" (mouseout)="onFounderMouseOut($event, founder)"
                                    (click)="onFounderClick($event, founder)" [ngClass]="{ commaLinkMessage: founder.is_send_message == 1 }">
                                {{founder.full_name}}
                            </a>
                            <a class="comma-link-button" *ngIf="founder.is_send_message == 1" (click)="onSendMessageFounderClick(founder)">
                                <img src="/assets/imgs/messagebubble_1.png">
                                <img *ngIf="neadMessagePersonSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <div class="founder-social" *ngFor="let social of founder.social_networks; let lastIten = last;">
                                <a href="{{social.address}}" *ngIf="social.type_address=='LI'" target="_blank">
                                    <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                </a>
                            </div>

                            <span *ngIf="!lastItem && founder.is_send_message != 1">,</span>
                            <span *ngIf="!lastItem">&nbsp;</span>
                        </div>
                    </div>           
                
                    <div class="fixed-col investors" *ngIf="company.investors && company.investors.length > 0">
                        {{company.name_object_investors}}
                    </div>

                    <div class="div-list" *ngIf="company.investors && company.investors.length > 0">
                        <div class="subscribe comma-text" *ngFor="let investor of company.investors; let lastItem = last;">

                            <a class="comma-link-button"  [routerLink]="[]" routerLinkActive="active" (click)="onOpenInvestorCardClick(company.code_firm, investor.code_firm)" [ngClass]="{ 'linkDisabled': investor.type_firm == 'UK' }">
<!--                            <a class="comma-link-button"  [routerLink]="['/company', investor.code_firm]" [ngClass]="{ 'linkDisabled': investor.type_firm == 'UK' }"> -->
                                {{investor.name_firm}}
                            </a>
                            <span *ngIf="!lastItem">&nbsp;</span>
                        </div>
                    </div>           
                </div>

                <div class="footer">
                    <a class="send-message-button" *ngIf="company.is_send_message == 2 || company.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                            (click)="onSendMessageCompanyClick(company)">
                        {{companiesTranslation.company.send_message}}
                        <img src="/assets/imgs/messagebubble_2.png">
                        <img *ngIf="neadMessageCompanySubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                    <div class="send-message-blank" *ngIf="company.is_send_message != 2 && company.is_send_message != 3"></div>

                    <div class="navigate">
                        <a [routerLink]="[]" routerLinkActive="active" (click)="onOpenCardClick(company.code_firm)">
                            {{companiesTranslation.company.card.button.open_card}}
                            <img src="/assets/imgs/open_card.svg">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
<!-- Small -->
        <div class="small" *ngIf="!tileSize.bigSize" [ngStyle]="getCanvasWidthSmall()">
            <div class="tile-small" id="company{{company.code_firm}}" *ngFor="let company of listCompany; index as i" [ngClass]="{ tileBlured: neadSubscribe(company.subscribe_level) }">
                <a class="link-small" [routerLink]="[]" routerLinkActive="active" (click)="onOpenCardClick(company.code_firm)">
                    <div class="img-small">
                        <img src={{company.image_url}} alt="{{company.code_firm}} {{company.name_firm}}" title="{{company.name_firm}}">
                    </div>
                    <div class="caption">
                        <div class="small-comp-name">{{company.name_firm}}</div>
                        <div>{{company.name_country}} {{company.founded_year}}</div>
                    </div>
                </a>

                <div class="send-message-block">
                    <a class="send-message-button" *ngIf="company.is_send_message == 1 || company.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                            (click)="onSendMessageCompanyFounderClick(company)">
                        <img src="/assets/imgs/messagebubble_1.png">
                        <img *ngIf="neadMessageCompanySubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>

                    <a class="send-message-button" *ngIf="company.is_send_message == 2 || company.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                            (click)="onSendMessageCompanyClick(company)">
                        <img src="/assets/imgs/messagebubble_2.png">
                        <img *ngIf="neadMessageCompanySubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                </div>
            </div>
        </div>
    </div>    

    <ptn-visor *ngIf="isVisorVisible" [ngStyle]="getVisorStyle()"></ptn-visor>    
</div>

<div class="send-message" *ngIf="showSendMessage && !platformService.handsetPlatform">
    <ptn-send-message [personID]="personID" [companyID]="companyID" [companyForFounder]="companyForFounder" (onCloseMessage)="onCloseMessage($event)"></ptn-send-message>
</div>

<div class="person-card" id="person-card" *ngIf="showPersonCard && !platformService.handsetPlatform" [ngStyle]="getPersonCardPosition()">
    <ptn-person-card [personID]="personID" (onClosePersonCard)="onClosePersonCard($event)"
            (onPersonCardBusy)="onPersonCardBusy($event)"></ptn-person-card>
</div>


<!-- Mobile -->

<div class="mob-main" id="mob-main" *ngIf="platformService.handsetPlatform">
    <div class="mob-body" tabindex="0" (scroll)='onScroll($event)'>
        <div class="mob-big" *ngIf="tileSize.bigSize">
            <div class="mob-tile-big" *ngFor="let company of listCompany; index as i" [ngClass]="{ tileBlured: neadSubscribe(company.subscribe_level) }">
                <div class="space" id="company{{company.code_firm}}"></div>

                <div class="mob-company-face">
                    <div class="mob-logo-company">
                        <a [routerLink]="[]" routerLinkActive="active" (click)="onOpenCardClick(company.code_firm)">
                            <img class="mob-img-big" src={{company.image_url}} alt={{company.code_firm}} title={{company.code_firm}}>
                            <div class="mob-about-company">
                                <span>{{company.name_firm}}</span>
                                <label class="label-company">{{company.name_country}} {{company.founded_year}}</label>
                            </div>
                        </a>
                    </div>

                    <div class="mob-face-right">
                        <div>
                            <div class="fixed-col" *ngIf="company.valuation">{{company.name_object_valuation}}</div>
                            <div class="money" *ngIf="company.valuation">{{company.valuation | currency : '' : 'symbol' : '1.0'}}</div>
                        </div>
                        <div>
                            <div class="fixed-col" *ngIf="company.total_funding_amount">{{company.name_object_invested}}</div>
                            <div class="money" *ngIf="company.total_funding_amount">{{company.total_funding_amount | currency : '' : 'symbol' : '1.0'}}</div>
                        </div>
                    </div>
                </div>

                <div class="mob-face-detail">
                    <div class="focus-block">
                        <img class="focus" src="/assets/imgs/focus.svg">
                        <span>{{company.focus}}</span>
                    </div>
                    <div>
                        <a class="company-website" *ngIf="company.websiteToShow" href="{{company.website}}" target="_blank">
                            <img class="www1" src="/assets/imgs/www.svg">
                            <span>{{company.websiteToShow}}</span>
                            <img class="www2" src="/assets/imgs/www2.svg">
                        </a>
                    </div>
                </div>

                <div class="mob-company-detail">
                    <div class="fixed-col" *ngIf="company.founders && company.founders.length > 0">
<!--                        {{companiesTranslation.company.card.field.founder}} -->
                        {{company.name_object_founder}}
                    </div>

                    <div class="div-list">
                        <div class="subscribe comma-text" *ngFor="let founder of company.founders; let lastItem = last;">
                            <a class="comma-link" (click)="onMobFounderClick(founder, company)" [ngClass]="{ commaLinkMessage: founder.is_send_message == 1 }">
                                {{founder.full_name}}
                            </a>
                            <a class="comma-link-button" *ngIf="founder.is_send_message == 1" (click)="onSendMessageFounderClick(founder)">
                                <img src="/assets/imgs/messagebubble_1.png">
                                <img *ngIf="neadMessagePersonSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>

                            <div class="founder-social" *ngFor="let social of founder.social_networks; let lastIten = last;">
                                <a href="{{social.address}}" *ngIf="social.type_address=='LI'" target="_blank">
                                    <img src="{{getSocialNetImage(social.type_address)}}" title="{{social.name_address}}">
                                </a>
                            </div>

                            <span *ngIf="!lastItem && founder.is_send_message != 1">,</span>
                            <span *ngIf="!lastItem">&nbsp;</span>
                        </div>
                    </div>           
                
                    <div class="fixed-col investors" *ngIf="company.investors && company.investors.length > 0">
                        {{company.name_object_investors}}
                    </div>

                    <div class="div-list" *ngIf="company.investors && company.investors.length > 0">
                        <div class="subscribe comma-text" *ngFor="let investor of company.investors; let lastItem = last;">
                            <a class="comma-link-button"  [routerLink]="['/company', investor.code_firm]" [ngClass]="{ 'linkDisabled': investor.type_firm == 'UK' }">
                                {{investor.name_firm}}
                            </a>
                            <span *ngIf="!lastItem">&nbsp;</span>
                        </div>
                    </div>           
                </div>

                <div class="mob-footer">
                    <a class="send-message-button" *ngIf="company.is_send_message == 2 || company.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                            (click)="onSendMessageCompanyClick(company)">
                        {{companiesTranslation.company.send_message}}
                        <img src="/assets/imgs/messagebubble_2.png">
                        <img *ngIf="neadMessageCompanySubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                    <div class="send-message-blank" *ngIf="company.is_send_message != 2 && company.is_send_message != 3"></div>

                    <div class="navigate">
                        <a [routerLink]="[]" routerLinkActive="active" (click)="onOpenCardClick(company.code_firm)">
                            {{companiesTranslation.company.card.button.open_card}}
                            <img src="/assets/imgs/open_card.svg">
                        </a>
                    </div>
                </div>
            </div>
        </div>
<!-- Small -->
        <div class="small" *ngIf="!tileSize.bigSize">
            <div class="mob-tile-small" [ngStyle]="getMobTileHeight(company.code_firm)" id="company{{company.code_firm}}" *ngFor="let company of listCompany; index as i" [ngClass]="{ tileBlured: neadSubscribe(company.subscribe_level) }">
                <a class="mob-link-small" [routerLink]="[]" routerLinkActive="active" (click)="onOpenCardClick(company.code_firm)">
                    <div class="mob-img-small">
                        <img src={{company.image_url}} alt="{{company.code_firm}} {{company.name_firm}}" title={{company.name_firm}}>
                    </div>
                    <div class="caption">
                        <div class="small-comp-name">{{company.name_firm}}</div>
                        <div>{{company.name_country}} {{company.founded_year}}</div>
                    </div>
                </a>

                <div class="send-message-block">
                    <a class="send-message-button" *ngIf="company.is_send_message == 1 || company.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                            (click)="onSendMessageCompanyFounderClick(company)">
                        <img src="/assets/imgs/messagebubble_1.png">
                        <img *ngIf="neadMessageCompanySubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>

                    <a class="send-message-button" *ngIf="company.is_send_message == 2 || company.is_send_message == 3" [routerLink]="[]" routerLinkActive="active"
                            (click)="onSendMessageCompanyClick(company)">
                        <img src="/assets/imgs/messagebubble_2.png">
                        <img *ngIf="neadMessageCompanySubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                </div>                

<!--                
                <a class="send-message-button" *ngIf="company.is_send_message > 0" [routerLink]="[]" routerLinkActive="active"
                        (click)="onSendMessageCompanyClick(company)">
                    <img src="/assets/imgs/messagebubble_{{company.is_send_message}}.png">
                    <img *ngIf="neadMessageCompanySubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg"> 
                </a>
-->
            </div>
        </div>
    </div>    

    <ptn-visor *ngIf="isVisorVisible" [ngStyle]="getVisorStyleMob()"></ptn-visor>    

</div>
