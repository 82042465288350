<div class="sector-map" *ngIf="!platformService.handsetPlatform">
    <div class="main">
        <div class="body">
            <div class="body-header">
                <span>{{analisysTranslation.sector_map.title_industry}}</span>
                <span>{{analisysTranslation.sector_map.title_companies}}</span>
                <select class="sort-by" [(ngModel)]="selectedSort" #sortBy (change)="onChangeSort(sortBy.selectedIndex)">
                    <option *ngFor="let sort of sortingObject">{{sort.translation}}</option>
                </select>
            </div>

            <div class="body-body">
                <div class="list-industry">
                    <div class="rows" *ngFor="let industry of listIndustry">
                        <div class="mark" [ngClass]="{ selectedMark: industry.code_industry == currentIndustryID}"></div>
                        <a class="link" id="industry{{industry.code_industry}}" [routerLink]="[]" routerLinkActive="active"
                                title={{industry.name_industry}} (click)="onIndustryClick(industry)"
                                [ngClass]="{ 'selectedIndustry': industry.code_industry == currentIndustryID,
                                            'disabledIndustry': industry.company_count_all <= 0 }">
                            <img class="industry-img image-back" *ngIf="industry.code_industry == currentIndustryID" src="{{industry.image_over_url}}">
                            <img class="industry-img image-front" *ngIf="industry.code_industry != currentIndustryID" src="{{industry.image_url}}">
                            <span>{{industry.name_industry}}</span>
                            <img *ngIf="neadSubscribe(industry.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                        </a>
                    </div>
                </div>

                <div class="polotno">
                    <div class="tile-body">
                        <div class="tiles">
                            <a class="tile" *ngFor="let company of listCompany" [routerLink]="['./', company.code_firm]"
                                        [ngClass]="{ tileBlured: neadSubscribe(company.subscribe_level) }">
                                <div class="div-img">
                                    <img class="company-img" src={{company.image_url}}>
                                </div>            
                                <span>{{company.valuation | currency : '' : 'symbol' : '1.0'}}</span>
                                <span class="company-name">{{company.name_firm}}</span>
                                <span class="country-date">{{company.name_country}} {{company.founded_year}}</span>
                            </a>
                        </div>
                    </div>

                    <ptn-visor *ngIf="isVisorVisible()"></ptn-visor>    

                </div>
            </div>
        </div>
    </div>
</div>


<!-- Mobile -->

<div class="mob-main" *ngIf="platformService.handsetPlatform">

    <div class="mob-header" [ngClass]="{ headerJustify: showIndustries }">
        <a *ngIf="showIndustries" [routerLink]="[]" routerLinkActive="active" (click)="onShowCompaniesClick()" [ngClass]="{ showRoundsDisabled: !currentIndustryID }">
            <span>{{analisysTranslation.sector_map.button.companies}}</span>
            <img src="/assets/imgs/next_white.svg">
        </a>
        <a class="mob-industry-name" *ngIf="!showIndustries" [routerLink]="[]" routerLinkActive="active" (click)="onShowIndustryClick()">
            <img src="/assets/imgs/prev_white.svg">
            <span>{{nameIndustry}}</span>
        </a>

        <select class="mob-sort-by" *ngIf="!showIndustries" [(ngModel)]="selectedSort" #sortBy (change)="onChangeSort(sortBy.selectedIndex)">
            <option *ngFor="let sort of sortingObject">{{sort.translation}}</option>
        </select>
    </div>

    <div class="industries" [ngClass]="{ industriesHide: !showIndustries }">
        <div class="mob-list-industry">
            <div class="mob-one-row" *ngFor="let industry of listIndustry">
                <a class="mob-link" [routerLink]="[]" routerLinkActive="active" title={{industry.name_industry}} (click)="onIndustryClick(industry)"
                        [ngClass]="{ 'selectedIndustry': industry.code_industry == currentIndustryID,
                                    'disabledIndustry': industry.company_count_all <= 0 }">

                    <img class="mob-industry-img image-back" *ngIf="industry.code_industry == currentIndustryID" src="{{industry.image_over_url}}">
                    <img class="mob-industry-img image-front" *ngIf="industry.code_industry != currentIndustryID" src="{{industry.image_url}}">

                    <div class="subscribe">
                        <span>{{industry.name_industry}}</span>
                        <img class="subscribe-img" *ngIf="neadSubscribe(industry.subscribe_level)" src="/assets/imgs/subscribe.svg">
                    </div>

                    <img *ngIf="industry.code_industry != currentIndustryID" src="/assets/imgs/to_rounds.svg">
                    <img *ngIf="industry.code_industry == currentIndustryID" src="/assets/imgs/selected_to_rounds.svg">
                </a>
            </div>
        </div>
    </div>

    <div class="mob-companies">
        <div class="mob-tiles">
            <a class="mob-tile" *ngFor="let company of listCompany" [routerLink]="['./', company.code_firm]"
                        [ngClass]="{ tileBlured: neadSubscribe(company.subscribe_level) }">
                <div class="mob-div-img">
                    <img class="company-img" src={{company.image_url}}>
                </div>            
                <span>{{company.valuation | currency : '' : 'symbol' : '1.0'}}</span>
                <span class="mob-company-name">{{company.name_firm}}</span>
                <span class="country-date">{{company.name_country}} {{company.founded_year}}</span>
            </a>
        </div>
    </div>

    <ptn-visor *ngIf="isVisorVisible() && !showIndustries" [ngClass]="{ mobVisor: true }"></ptn-visor>    

</div>
