import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { DeviceInfoService } from './services/device-info.service';
import { UserService } from './services/user.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AuthGuard } from './services/auth-guard.service';
import { ErrorService } from './services/error.service';
import { TranslationService } from './services/translation.service';
import { HeaderComponent } from './header/header.component';
import { HeaderComponentM } from './header/m-header.component';
import { FooterComponent } from './footer/footer.component';
import { SignInSocialComponent } from './user/sign-in-social/sign-in-social.component';
import { CommonService } from './services/common.service';
import { LocalStorageService } from './services/local-storage-service';
import { SessionStorageService } from './services/session-storage-service';
import { LoaderService } from './services/loader.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SubscribesComponent } from './subscribes/subscribes.component';
import { SuccessPaymentComponent } from './subscribes/success-payment/success-payment.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HomeHeaderService } from './services/home.header.service';
import { CompanyCardComponent } from './companies/company.card.component';
import { IndustryService } from './services/industry.service';
import { CompanyDealsComponent } from './companies/company.deals.component';
import { ChartsModule } from 'ng2-charts';
import { PtnIndexComponent } from './ptn-index/ptn.index.component';
import { CompanyService } from './services/company.service';
import { SearchComponent } from './search/search.component';
import { SearchComponentM } from './search/m-search.component';
import { SearchContainer } from './search/search.container';
import { MaterialModule } from './material.module';
import { ListComponent } from './list/list.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { BytesPipe } from './shared/file-upload/bytes.pipe';
import { ListService } from './services/list.service';
import { AnalysisComponent } from './analysis/analysis.component';
import { SectorMapComponent } from './analysis/sector.map.component';
import { TopIndustryComponent } from './analysis/top.industry.component';
import { BubbleMapComponent } from './analysis/bubble.map.component';
import { IndustryDealsComponent } from './home/deals/industry.deals.component';
import { DownloadFileService } from './services/download.file.service';
import { DialogQuestionComponent } from './shared/dialog-question/dialog-question.component';
import { RouterHistoryService } from './services/router-history.service';
import { FilterCompaniesComponent } from './filter-companies/filter-companies.component';
import { FilterComponent } from './shared/filter/filter.component';
import { Ng5SliderModule } from 'ng5-slider';
import { FilterService } from './services/filter.service';
import { VerifyEmailComponent } from './user/verify.email/verify.email.component';
import { DontRecoveryPasswordComponent } from './user/dont.recovery.password.component/dont.recovery.password.component';
import { PersonCardComponent } from './person/person.card.component';
import { PersonService } from './services/person.service';
import { CallbackboolPipe } from './shared/pipes/callback.pipe';
import { OrderModule } from 'ngx-order-pipe';
import { BigNumberPipe } from './shared/pipes/big-number.pipe';
import { CheckCriterionComponent } from './shared/filter/check-criterion/check-criterion.component';
import { IntervalCriterionComponent } from './shared/filter/interval-criterion/interval-criterion.component';
import { DialogInfoComponent } from './shared/dialog-info/dialog-info.component';
import { TableViewResultComponent } from './shared/filter/table-view-result/table-view-result.component';
import { CardViewResultComponent } from './shared/filter/card-view-result/card-view-result.component';
import { TermsOfUseComponent } from './terms.of.use/terms.of.use.component';
import { PrivatePolicyComponent } from './private.policy/private.policy.component';
import { AboutComponent } from './about/about.component';
import { DataCollectionComponent } from './data.collection/data.collection.component';
import { FilterFundsComponent } from './filter-funds/filter-funds.component';
import { FilterPrivateInvestorsComponent } from './filter-private-investors/filter-private-investors.component';
import { WebAddressService } from './services/web.address.service';
import { MatIconModule } from "@angular/material/icon";
import { AuthInterceptor } from './services/auth-interceptor';
import { PersonCardShortComponent } from './shared/person.card.short/person.card.short.component';
import { SendMessageComponent } from './shared/send.message/send.message';
import { HomeComponent } from "./home/home.component";
import { HomeBodyComponent } from "./home/home_body/home.body.component";
import { CategoryComponent } from "./home/home_body/category.component";
import { CompaniesComponent } from "./home/home_body/companies.component";
import { BreadcrumbModule } from 'xng-breadcrumb';
import { SubscribeLevelGuard } from './services/subscribe-level-guard.service';
import { LoginComponent } from "./user/login/login.component";
import { SignInComponent } from "./user/sign-in/sign-in.component";
import { RegistrationComponent } from "./user/registration/registration.component";
import { ForgotPasswordComponent } from "./user/forgot.password/forgot.password.component";
import { RecoveryPasswordComponent } from "./user/recovery.password/recovery.password.component";
import { BreadCrumbDynamicLabelGuard } from "./services/breadcrumb-dynamic-label-guard.service";
import { VisorComponent } from "./shared/visor.component/visor.component";
import { environment } from '../environments/environment';
import { SplashComponent } from "./splash.component/splash.component";
import { SplashComponentM } from "./splash.component/splash.component-m";
import { BubbleMapService } from './services/bubble.map.service';
import { VersionService } from './services/version.service';
import { RepresentComponent } from "./home/represent.component/represent.component";
import { RepresentService } from './services/represent.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PlatformService } from './services/platform.service';
import { CardViewResultMComponent } from './shared/filter/card-view-result-m/card-view-result-m.component';
import { IndustryDealsContainer } from "./home/deals/industry.deals.container";
import { IndustryDealsComponentM } from "./home/deals/m-industry.deals.component";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderComponentM,
    FooterComponent,
    SignInSocialComponent,
    SubscribesComponent,
    CompanyCardComponent,
    SuccessPaymentComponent,
    CompanyDealsComponent,
    PtnIndexComponent,
    SearchContainer,
    SearchComponent,
    SearchComponentM,
    ListComponent,
    FileUploadComponent,
    BytesPipe,
    AnalysisComponent,
    SectorMapComponent,
    TopIndustryComponent,
    BubbleMapComponent,
    IndustryDealsComponent,
    DialogQuestionComponent,
    FilterCompaniesComponent,
    FilterComponent,
    VerifyEmailComponent,
    DontRecoveryPasswordComponent,
    PersonCardComponent,
    CallbackboolPipe,
    BigNumberPipe,
    CheckCriterionComponent,
    IntervalCriterionComponent,
    DialogInfoComponent,
    TableViewResultComponent,
    CardViewResultComponent,
    TermsOfUseComponent,
    PrivatePolicyComponent,
    AboutComponent,
    DataCollectionComponent,
    FilterFundsComponent,
    FilterPrivateInvestorsComponent,
    PersonCardShortComponent,
    SendMessageComponent,
    HomeComponent,
    HomeBodyComponent,
    CategoryComponent,
    CompaniesComponent,
    LoginComponent,
    SignInComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
    RecoveryPasswordComponent,
    VisorComponent,
    SplashComponent,
    SplashComponentM,
    RepresentComponent,
    PageNotFoundComponent,
    CardViewResultMComponent,
    IndustryDealsContainer,
    IndustryDealsComponentM
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,    
    ReactiveFormsModule,
    HttpClientModule,
    DeviceDetectorModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxWebstorageModule.forRoot(),
    ChartsModule,
    MaterialFileInputModule,
    Ng5SliderModule,
    OrderModule,
    FormsModule,
    MatIconModule,
    BreadcrumbModule
  ],
  providers: [DeviceInfoService, UserService, AuthGuard, ErrorService, TranslationService, CommonService, LocalStorageService, SessionStorageService, LoaderService, HomeHeaderService, IndustryService,
    CompanyService, ListService, RouterHistoryService, DownloadFileService, FilterService, PersonService, WebAddressService, SubscribeLevelGuard, BreadCrumbDynamicLabelGuard,
    BubbleMapService, VersionService, RepresentService, PlatformService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, {provide: APP_BASE_HREF, useValue: environment.baseHref }
  ],
  entryComponents: [
    DialogQuestionComponent, DialogInfoComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
