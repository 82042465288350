<div class="person-card-short" *ngIf="person.code_person && !platformService.handsetPlatform" (mousemove)="onMouseMove($event)" (mouseout)="onMouseOut($event)">
    <a class="close-window" (click)="onCloseClick()">
        <img src="/assets/imgs/close-window.png">
    </a>
    
    <div class="main">
        <span class="person_name">{{person.full_name}}</span>
        <div class="location">
            <span *ngIf="person.name_country">{{person.name_country}},&nbsp;</span>
            <span *ngIf="person.name_state">{{person.name_state}},&nbsp;</span>
            <span *ngIf="person.name_city">{{person.name_city}}</span>
        </div>

        <div class="body">
            <span>{{person.aka_name}}</span>
            <div class="relation">
                <img src="/assets/imgs/focus.svg" *ngIf="person.companyrelations && person.companyrelations.length > 0">

                <div class="div-list">
                    <div class="comma-text" *ngFor="let relations of person.companyrelations; let lastItem = last;">
                        <a class="subscribe" [routerLink]="['/company', relations.code_firm]"
                                    [ngClass]="{ 'companyRelationsDisabled': neadSubscribe(relations.subscribe_level) }" >
                            {{relations.name_firm}}
                            <img *ngIf="neadSubscribe(relations.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                        </a>
                        <span>{{relations.name_position}}</span>
                    </div>            
                </div>           
            </div>

            <div class="internet" *ngIf="person.website || (person.socialnetworks && person.socialnetworks.length > 0)">
                <img src="/assets/imgs/www.svg">
                <a class="email" href={{person.website}}>{{person.websiteToShow}}</a>
                <span *ngIf="person.website">&nbsp;&nbsp;</span>

                <div class="social-networks">
                    <a class="subscribe comma-text" *ngFor="let social of person.socialnetworks; let lastItem = last;" 
                            href={{social.address}} target="_blank">
                        <img src="{{ getSocialImage(social) }}" title="{{social.name_address}}">
                        <img *ngIf="neadSubscribe(social.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                </div>
            </div>

            <div class="call" *ngIf="person.phone">
                <span>{{person.phone}}</span>
                <a href="mailto:{{person.email}}">{{person.email}}</a>
            </div>

            <span>{{person.description_person}}</span>
        </div>
    </div>
</div>


<!-- Mobile -->

<div class="mob-person-card-short" *ngIf="platformService.handsetPlatform">
    <div class="mob-main">
        <span class="person_name">{{person.full_name}}</span>
        <div class="location">
            <span *ngIf="person.name_country">{{person.name_country}},&nbsp;</span>
            <span *ngIf="person.name_state">{{person.name_state}},&nbsp;</span>
            <span *ngIf="person.name_city">{{person.name_city}}</span>
        </div>

        <div class="mob-body">
            <span>{{person.aka_name}}</span>
            <div class="mob-relation">
                <img src="/assets/imgs/focus.svg" *ngIf="person.companyrelations && person.companyrelations.length > 0">

                <div class="div-list">
                    <div class="mob-comma-text" *ngFor="let relations of person.companyrelations; let lastItem = last;">
                        <a class="subscribe" [routerLink]="['/company', relations.code_firm]"
                                    [ngClass]="{ 'companyRelationsDisabled': neadSubscribe(relations.subscribe_level) }" >
                            {{relations.name_firm}}
                            <img *ngIf="neadSubscribe(relations.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                        </a>
                        <span>{{relations.name_position}}</span>
                    </div>            
                </div>           
            </div>

            <div class="mob-internet" *ngIf="person.website || (person.socialnetworks && person.socialnetworks.length > 0)">
                <img src="/assets/imgs/www.svg">
                <a class="email" href={{person.website}}>{{person.websiteToShow}}</a>
                <span *ngIf="person.website">&nbsp;&nbsp;</span>

                <div class="social-networks">
                    <a class="subscribe mob-comma-text" *ngFor="let social of person.socialnetworks; let lastItem = last;" 
                            href={{social.address}} target="_blank">
                        <img src="{{ getSocialImage(social) }}" title="{{social.name_address}}">
                        <img *ngIf="neadSubscribe(social.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                    </a>
                </div>
            </div>

            <div class="mob-call" *ngIf="person.phone">
                <span>{{person.phone}}</span>
                <a href="mailto:{{person.email}}">{{person.email}}</a>
            </div>

            <span class="mob-descriptiob">{{person.description_person}}</span>
        </div>
<!--
        <div class="mob-buttons-msg">
            <button (click)="onBackClick()">
                {{personTranslation.message_form.button.back}}
            </button>
        </div>
-->    
    </div>
</div>
