import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VerifyEmail } from './../../models/user';
import { DeviceInfoService } from './../../services/device-info.service';
import { UserService } from './../../services/user.service';
import { TranslationService } from './../../services/translation.service';
import { ErrorService } from './../../services/error.service';
import { VerifyEmailTranslation } from './../../shared/translation.const';


@Component({
  selector: 'verify-email',
  templateUrl: './verify.email.component.html',
  styleUrls: ['./verify.email.component.css'],
})

export class VerifyEmailComponent implements OnInit {

    translation = VerifyEmailTranslation;
    verifySuccess: boolean = undefined;


    constructor(private activatedRoute: ActivatedRoute, private deviceInfoService: DeviceInfoService,
                private userService: UserService, private translationService: TranslationService,
                private router: Router, private errorService: ErrorService) {

        this.translationService.getTranslate('PAGE_EMAIL_VERIFICATION', this.translation);

        this.activatedRoute.queryParams.subscribe((queryParam: any) => {
             
            let queryUUID = queryParam['uuid'];

            if (queryUUID) {
  
                this.deviceInfoService.getDeviceInfo().subscribe((devInfo) => {

                    let verifyEmail: VerifyEmail = {
                        uuid: queryUUID,
                        device_data: devInfo,
                        language_id: this.translationService.currentLanguage
                    };

                    this.userService.verifyEmail(verifyEmail).subscribe(
                        response => {
                            this.verifySuccess = true;
                        },
                        error => {
                            this.verifySuccess = false;
                            console.log(error);
                        });
                        
                });
            }
        });
    }


    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('PAGE_EMAIL_VERIFICATION', this.translation);
        });
    }


    onLoginClick() {

        if (this.userService.isAuthenticated) {
            this.userService.signOut().subscribe(
                response => {
                    this.router.navigate(['/login/sign-in']);
                },
                error => {
                    console.log(error);
                    let errorStr = this.errorService.getErrorPTNMessage(error);
                    if (errorStr != 'undefined')
                        alert(errorStr);
                    }
            );
        } else {
            this.router.navigate(['/login/sign-in']);
        }
    }
}
