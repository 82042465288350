<!-- Desktop version -->
<div class='filter-container' *ngIf="!platformService.handsetPlatform">
    <div class='search-container'>
        <div class='search-container-top'>
            <div class='search-container-top-inner'>
                <span>{{filterTranslation.text.filters}}</span>                
                <a [routerLink]="[]" routerLinkActive="active" (click)='onClearAllFilters()'>{{filterTranslation.button.clear_all}}
                    <img src='/assets/imgs/filter-clear.png'>
                </a>
            </div>
        </div>
        <div class='search-container-body'>
            <div class='search-container-body-inner'>
                <div *ngFor='let criterionElement of criterionElements; let lastItem = last;'>
                    <ptn-check-criterion *ngIf="criterionElement.type_criterion=='check'" [title]=criterionElement.title
                        [params]=criterionElement.params [showDivider]=!lastItem (change)="setFilter()"></ptn-check-criterion>
                    <ptn-interval-criterion *ngIf="criterionElement.type_criterion=='interval'" [title]=criterionElement.title
                        [param]=criterionElement.param [showDivider]=!lastItem (change)="setFilter($event)">
                    </ptn-interval-criterion>
                </div>
            </div>
        </div>
    </div>
    <div class='result-container'>
        <div class='result-container-top'>
            <div class='result-container-top-inner'>
                <span>{{filter_firms.length}} of {{countAllFirms | bignumber}} Results</span>
                <mat-select [(value)]='selectedSortItem' (selectionChange)='onChangeSort($event.value)'>
                    <mat-option *ngFor='let sortItem of sortingList | orderBy: order : false' [value]='sortItem.object_id'>
                        {{sortItem.translation}}
                    </mat-option>
                </mat-select>
                <mat-button-toggle-group appearance="legacy" #viewResult='matButtonToggleGroup' [value]='initialViewResult'
                    (change)='onViewResultChange(viewResult.value)'>
                    <mat-button-toggle [ngClass]="tableBtnToggle" value='table' aria-label='Table view'>
                        <span>Table view mode</span>
                        <div><img src='/assets/imgs/card-mode-white.png'></div>
                    </mat-button-toggle>
                    <mat-button-toggle [ngClass]="cardBtnToggle" value='card' aria-label='Card view'>                        
                        <span>Card view mode</span>
                        <div><img src='/assets/imgs/card-mode-white.png'></div>                        
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class='result-container-body'>
            <div class='result-container-body-inner' [ngStyle]='getResultContainerBodyStyle()' (scroll)='onScroll($event)'>
                <ptn-table-view-result *ngIf="viewResult.value=='table'" [typeFirm]='typeFirm' [filterTranslation]='filterTranslation' [filter_firms]='filter_firms'></ptn-table-view-result>
                <ptn-card-view-result *ngIf="viewResult.value=='card'" [typeFirm]='typeFirm' [filterTranslation]='filterTranslation' [filter_firms]='filter_firms'></ptn-card-view-result>
            </div>
            <ptn-visor *ngIf="isVisorVisible()" [ngStyle]='getVisitorStyle()'></ptn-visor>
        </div>
        <!-- <div class='btn-loadmore-wrapper' [ngStyle]='getLoadMoreBtnStyle()'>
            <button mat-stroked-button [disabled]='filter_firms.length >= countAllFirms' (click)='onLoadMore()'>Load More</button>    
        </div> -->
    </div>
</div>
<!-- Mobile version -->
<div class='filter-container' *ngIf="platformService.handsetPlatform">    
    <div class='search-container-m' [ngClass]="{ filterShow: sessionStorageService.isShowFilterMenuM }">
        <div class='search-container-top-m'>
            <div class='search-container-top-inner-m'>
                <a [routerLink]="[]" routerLinkActive="active" queryParamsHandling="preserve" (click)='onShowFiltersM()'>
                    <span>{{filterTranslation.button.show}}</span>
                    <img src="/assets/imgs/next_white.svg">
                </a>
                <a [routerLink]="[]" routerLinkActive="active" (click)='onClearAllFilters()'>{{filterTranslation.button.clear_all}}
                    <img src='/assets/imgs/filter-clear.png'>
                </a>
            </div>
        </div>
        <div class='search-container-body-m'>
            <div class='search-container-body-inner-m'>
                <div *ngFor='let criterionElement of criterionElements; let lastItem = last;'>
                    <ptn-check-criterion *ngIf="criterionElement.type_criterion=='check'" [title]=criterionElement.title
                        [params]=criterionElement.params [showDivider]=!lastItem (change)="setFilter()"></ptn-check-criterion>
                    <ptn-interval-criterion *ngIf="criterionElement.type_criterion=='interval'" [title]=criterionElement.title
                        [param]=criterionElement.param [showDivider]=!lastItem (change)="setFilter($event)">
                    </ptn-interval-criterion>
                </div>
            </div>
        </div>
    </div>
    <div class='result-container-m'>
        <div class='result-container-top-m'>
            <div class='result-container-top-inner-m'>
                <a [routerLink]="[]" routerLinkActive="active" queryParamsHandling="preserve" (click)='onShowFiltersM()'>
                    <img src="/assets/imgs/prev_white.svg">
                    <span>{{filterTranslation.button.filters}}</span>
                </a>
                <mat-select [(value)]='selectedSortItem' (selectionChange)='onChangeSort($event.value)'>
                    <mat-option *ngFor='let sortItem of sortingList | orderBy: order : false' [value]='sortItem.object_id'>
                        {{sortItem.translation}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class='result-container-body-m'>
            <div class='result-container-body-inner-m' [ngStyle]='getResultContainerBodyStyle()' (scroll)='onScroll($event)'>
                <ptn-card-view-result-m [typeFirm]='typeFirm' [filterTranslation]='filterTranslation' [filter_firms]='filter_firms'></ptn-card-view-result-m>
            </div>
        </div>        
    </div>
    <ptn-visor *ngIf="isVisorVisible()" [ngStyle]='getVisitorStyleM()'></ptn-visor>
</div>
