import { Component, OnInit } from '@angular/core';
import { UserService } from './../services/user.service';
import { FooterTranslation } from '../shared/translation.const';
import { User, SendEmail, SendMessageResult } from '../models/user';
import { DeviceInfoService } from './../services/device-info.service';
import { TranslationService } from './../services/translation.service';
import { ErrorService } from './../services/error.service';
import { LocalStorageService } from './../services/local-storage-service';
import { PlatformService } from './../services/platform.service';


@Component({
  selector: 'ptn-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})


export class FooterComponent implements OnInit {

  userName: string = "";
  trialSubscribe: string = "";
  translation = FooterTranslation;
  accountNeedVerified: boolean = false;
  verifyMessage: SendMessageResult = {
    msg_after_sending: ''
  };

  constructor(private userService: UserService, private deviceInfoService: DeviceInfoService,
        private translationService: TranslationService, private errorService: ErrorService,
        private localStorage: LocalStorageService, public platformService: PlatformService) {
      
      this.translationService.getTranslate("FOOTER", this.translation);
     
      this.translation.copyright.text = this.translation.copyright.text.replace("<<YEAR>>", (new Date()).getFullYear().toString());

      if (this.userService && this.userService.user) {

        this.userName = this.userService.user.login;
        this.accountNeedVerified = this.userService.user.account_identifier_is_verified && 
                                  this.userService.user.account_identifier_is_verified == '0' &&
                                  this.userService.user.account_verify_send_letter != '1';

        this.trialSubscribe = this.userService.checkSubscribeDate();
      }
  }


  ngOnInit(): void {

    this.userService.loginChanged$.subscribe((user: User) => {

        this.trialSubscribe = "";

        if (user) {
            if (user.login)
                this.userName = user.login
            else
                this.userName = "";

            this.accountNeedVerified = user.account_identifier_is_verified && 
                                      user.account_identifier_is_verified == '0' &&
                                      user.account_verify_send_letter != '1';

            this.trialSubscribe = this.userService.checkSubscribeDate();
        }
    });

    this.userService.Authenticated$.subscribe((isAuthenticated: boolean) => {

      this.accountNeedVerified = isAuthenticated;
      if (!isAuthenticated)
        this.userName = "";
    });

    this.translationService.languageChanged$.subscribe((changeObj) => {

        this.translationService.getTranslate("FOOTER", this.translation);
        this.translation.copyright.text = this.translation.copyright.text.replace("<<YEAR>>", (new Date()).getFullYear().toString());

        this.trialSubscribe = this.userService.checkSubscribeDate();
    });
  }


  onVerifyClick() {

      let verifymEmail: SendEmail = {
        email: this.userService.user.email, 
        type_operation: 'VM', 
        device_data: this.deviceInfoService.deviceInfo,
        language_id: this.translationService.currentLanguage
      }

      this.userService.sendEmail(verifymEmail).subscribe(
        result => {
            this.verifyMessage = result;
            this.userService.user.account_verify_send_letter = '1';
            this.localStorage.user = this.userService.user;
        },
        (error) => {
            console.log(error);
            this.verifyMessage.msg_after_sending = this.errorService.getErrorPTNMessage(error);
        }
      );
  }


  onClearCacheClick() {
/*
    this.userService.clearCache().subscribe(
      result => {},
      (error) => {
        console.log(error);
      }
    )
*/    
  }

}
