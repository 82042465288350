import { Component, OnInit } from '@angular/core';
import { DataCollectTranslation } from '../shared/translation.const';
import { TranslationService } from './../services/translation.service';
import { PlatformService } from './../services/platform.service';


@Component({
    selector: 'data-collection',
    templateUrl: './data.collection.component.html',
    styleUrls: ['./data.collection.component.css']
})
  
export class DataCollectionComponent implements OnInit {

    dataCollectTranslation = DataCollectTranslation;


    constructor(private translationService: TranslationService, public platformService: PlatformService) {

        this.translationService.getTranslate('PAGE_DATA_COLLECT', this.dataCollectTranslation);
    }


    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('PAGE_DATA_COLLECT', this.dataCollectTranslation);
        });
    }
}  