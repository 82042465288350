import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Industry } from "./../models/home.body";
import { IndustryService } from "./../services/industry.service";
import { TranslationService, TranslateObject } from "./../services/translation.service";
import { UserService } from "./../services/user.service";
import { TranslationPtnIndex } from "./../shared/translation.const";
import { PlatformService } from './../services/platform.service';


@Component({
    selector: 'ptn-index',
    templateUrl: './ptn.index.component.html',
    styleUrls: ['./ptn.index.component.css']
})
  
export class PtnIndexComponent implements OnInit {

    listIndustry: Array<Industry>;
    sortingList: Array<TranslateObject> = [];
    translationPtnIndex = TranslationPtnIndex;
    selectedIndex: number = 1;

    @ViewChild('graphbottom') private graphBottom: ElementRef;


    constructor(private industryService: IndustryService, private translationService: TranslationService, private userService: UserService,
            public platformService: PlatformService) {

        // Переклади
        this.translationService.getTranslate('PAGE_PTN_INDEX', this.translationPtnIndex);

        // Отримуємо ID сортування для PTN Index
        this.sortingList = [];
        if (this.translationService.translationDict && this.translationService.translationDict.length > 0) {
            this.translationService.getCategoryObject('PAGE_PTN_INDEX.SORTING_OPTIONS', this.sortingList);
        } else {
            this.translationService.init();
        }

        if (!this.listIndustry || this.listIndustry.length <= 0) {
            // Якщо в Session storage немає збереженого переліку Industries, зчитуємо з серера
            let sortID: number;
            if (this.sortingList.length > 0) {
                for (let i = 0; i < this.sortingList.length; i++) {
                    if (this.sortingList[i].object_name == 'PAGE_PTN_INDEX.SORTING_OPTIONS.SORT_BY_PTNINDEX') {
                        sortID = this.sortingList[i].object_id;
                        this.selectedIndex = i;
                        break;
                    }
                }
            }

            this.getIndustries(sortID);
        }
    }


    ngOnInit(): void {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('PAGE_PTN_INDEX', this.translationPtnIndex);

            this.sortingList = [];
            this.translationService.getCategoryObject('PAGE_PTN_INDEX.SORTING_OPTIONS', this.sortingList);

            this.listIndustry = [];

            let sortID: number;
            if (this.sortingList.length > 0) {
                for (let i = 0; i < this.sortingList.length; i++) {
                    if (this.sortingList[i].object_name == 'PAGE_PTN_INDEX.SORTING_OPTIONS.SORT_BY_PTNINDEX') {
                        sortID = this.sortingList[i].object_id;
                        this.selectedIndex = i;
                        break;
                    }
                }
            }

            this.getIndustries(sortID);
        });
  
    }


    getIndustries(sortIndex: number) {
    
        if (!sortIndex)
            return;

        this.industryService.getIndustries(sortIndex).subscribe(
            response => {
                let json = response;
                if (json) {
                    this.listIndustry = json;
                }
                
            },
            error => console.log(error));
    }


    getPtnIndexWidth(industry: Industry) {

        let styles = {
            "width": '0%'
        };

        if (!industry || !this.graphBottom) {
            styles = {
                "width": '0%'
            };
        } else {
            styles = {
                "width": industry.ptn_index_prc.toString() + '%'
            };
        }

        return styles;
    }

    
    getPtnSubindexWidth(industry: Industry) {

        let styles = {
            "width": '0%'
        };

        if (!industry || !this.graphBottom) {
            styles = {
                "width": '0%'
            };
        } else {
            styles = {
                "width": industry.ptn_subindex_prc.toString() + '%'
            };
        }

        return styles;
    }


    neadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        return (subscribeLevel > userSubsribeLevel);
    }


    onSortClick(colNumber: number) {

        if (this.sortingList && this.sortingList.length > 0) {

            this.selectedIndex = colNumber;
            let sortID = this.sortingList[colNumber].object_id;

            this.getIndustries(sortID);
        }
    }
}
