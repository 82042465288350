import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './../../services/user.service';
import { User, RegisterResponse, SendEmail, SendMessageResult } from './../../models/user';
import { TranslationService } from './../../services/translation.service';
import { DeviceInfoService } from 'src/app/services/device-info.service';
import { ErrorService } from './../../services/error.service';
import { langFORM_SIGNUP } from "./../../shared/constant.module";
import { IdentifierData } from './../../models/identifier-data';
import { passwordStrengthValidator } from './../../shared/password-strength-validator';
import { RegistrationTranslation } from './../../shared/translation.const';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlatformService } from './../../services/platform.service';


@Component({
  selector: 'ptn-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})


export class RegistrationComponent implements OnInit {

    email: FormControl;
    password: FormControl;
    confirm_password: FormControl;
    last_name: FormControl;
    first_name: FormControl;
    company_name: FormControl;
    title: FormControl;

    userSocialID: string;
    typeIdentifier: string;
    fuul_name: string;
    accessToken: string;

    registerForm: FormGroup;

    socialNetRegister: boolean = false;

    controlErrors = {
        email: "",
        password: "",
        confirm_password: "",
        last_name: "",
        first_name: "",
        company_name: "",
        title: ""
    }

    controlValid = {
        email: {
            notValid: false
        },
        password: {
            notValid: false
        },
        confirm_password: {
            notValid: false
        },
        last_name: {
            notValid: false
        },
        first_name: {
            notValid: false
        },
        company_name: {
            notValid: false
        },
        title: {
            notValid: false
        }
    }

    verifyMessage: SendMessageResult = {
        msg_after_sending: ''
    };

    // Переклади для елементів інтерфесу
    allTranslation = RegistrationTranslation;

    @Output() 
    errorEvent = new EventEmitter<string>();

    hide: boolean = true;
  

    constructor(private router: Router, private fBuilder: FormBuilder, private translationService: TranslationService, 
            private deviceInfoService: DeviceInfoService, private userService: UserService, private errorService: ErrorService, 
            private activatedRoute: ActivatedRoute, private matSnackBar: MatSnackBar, public platformService: PlatformService) { 

        this.translationService.getTranslate(langFORM_SIGNUP, this.allTranslation);

        if (this.activatedRoute.snapshot.queryParams.id) {

            let queryParams = this.activatedRoute.snapshot.queryParams;
            if (queryParams) {
                this.email = queryParams.email;
                this.last_name = queryParams.last_name;
                this.first_name = queryParams.first_name;
                this.company_name = queryParams.work ? queryParams.work : null;
                this.userSocialID = queryParams.id;
                this.typeIdentifier = queryParams.type_identifier;
                this.fuul_name = queryParams.name;
                this.accessToken = queryParams.accessToken.substr(0, queryParams.accessToken.length - 1);

                this.password = null;
                this.confirm_password = null;
                this.socialNetRegister = true;
            }
        }
    }


    ngOnInit() {

    //    this.localStorage.socialnetAction = SocialnetAction.Register;

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate(langFORM_SIGNUP, this.allTranslation);
        });
    
        this.buildForm();

        if (this.registerForm && this.socialNetRegister) {
            this.registerForm.get("password").disable();
            this.registerForm.get("confirm_password").disable();
        }
    }


    buildForm() {

        if (this.socialNetRegister) {
        this.registerForm = this.fBuilder.group({
            email: [this.email, [
                Validators.required,
                Validators.pattern("[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}")
            ]],
            password: [this.password, []],
            confirm_password: [this.confirm_password, []],
            last_name: [this.last_name, Validators.required],
            first_name: [this.first_name, Validators.required],
            company_name: [this.company_name, Validators.required],
            title: [this.title, Validators.required]
        });
        } else {
            this.registerForm = this.fBuilder.group({
                email: [this.email, [
                    Validators.required,
                    Validators.pattern("[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}")
                ]],
                password: [this.password, [
                    Validators.required,
                    Validators.minLength(8),
                    passwordStrengthValidator
                ]],
                confirm_password: [this.confirm_password, [
                    Validators.required,
                    passwordStrengthValidator
                ]],
                last_name: [this.last_name, Validators.required],
                first_name: [this.first_name, Validators.required],
                company_name: [this.company_name, Validators.required],
                title: [this.title, Validators.required]
            });
        }
    }


    onSubmit() {

        this.errorEvent.emit('');

        if (!this.registerForm)
            return;

        let identifierData: IdentifierData = {
            id: this.userSocialID,
            email: this.registerForm.get("email").value,
            name: this.fuul_name,
            first_name: this.registerForm.get("first_name").value,
            last_name: this.registerForm.get("last_name").value,
            work: this.registerForm.get("company_name").value
        };

        let registerUser: User = {
            email: this.registerForm.get("email").value,
            first_name: this.registerForm.get("first_name").value,
            last_name: this.registerForm.get("last_name").value,
            company_name: this.registerForm.get("company_name").value,
            password: this.registerForm.get("password").value,
            type_identifier: "E",
            identifier: this.registerForm.get("email").value,
            title: this.registerForm.get("title").value,
            type_account: "U",
            identifier_data: identifierData,
            device_data: this.deviceInfoService.deviceInfo,
            language_id: this.translationService.currentLanguage
        }

        if (this.socialNetRegister) {
            registerUser.identifier = this.userSocialID;
            registerUser.type_identifier = this.typeIdentifier;
            registerUser.access_token = this.accessToken;
        }

        let registerResponse: RegisterResponse = {
            code_count: 0,
            code_identifier: 0,
            code_person: 0
        }
        this.userService.registerResponse = registerResponse;

        this.userService.signUp(registerUser).subscribe(
            result => {
                let json = result;
                if (json) {
        //              console.log(json);
                    this.userService.registerResponse.code_count = json.code_count;
                    this.userService.registerResponse.code_person = json.code_person;
                    this.userService.registerResponse.code_identifier = json.code_identifier;

                    let signUser: User = {
                        identifier: registerUser.identifier,
                        password: registerUser.password,
                        type_identifier: registerUser.type_identifier,
                        identifier_data: registerUser.identifier_data,
                        device_data: registerUser.device_data,
                        language_id: registerUser.language_id,
                        access_token: registerUser.access_token
                    }

                    // Відправка листа
                    let confirmEmail: SendEmail = {
                        email: registerUser.email, 
                        type_operation: 'VM', 
                        device_data: this.deviceInfoService.deviceInfo,
                        language_id: this.translationService.currentLanguage
                    }

                    this.userService.sendEmail(confirmEmail).subscribe(
                        result => {

                            this.verifyMessage = result;

                            let snackBarRef = this.matSnackBar.open(this.verifyMessage.msg_after_sending, 'Ok', {
                                duration: 10000,
                                panelClass: ['snack-trial']
                              });
                                                      
//                            alert(this.verifyMessage.msg_after_sending);

                            this.userService.user = signUser;
                            this.userService.signIn(signUser).subscribe((res) => {
                                this.router.navigate(['/']);
                            },
                            (error) => {
                                console.log(error);
                                this.errorEvent.emit(this.errorService.getErrorPTNMessage(error));
            //                      alert(this.errorService.getErrorPTNMessage(error));
                                this.userService.actionOnSignInError();
                            }
                            );
                        },
                        (error) => {
                            console.log(error);
                            this.errorEvent.emit(this.errorService.getErrorPTNMessage(error));
            //                  alert(this.errorService.getErrorPTNMessage(error));
                            this.userService.actionOnSignInError();
                        }
                    );
                }
            },
            error => {
                console.log(error);
                this.errorEvent.emit(this.errorService.getErrorPTNMessage(error));

                if (this.socialNetRegister) {
                    registerUser.identifier = undefined;
                    registerUser.type_identifier = 'E';
                    this.registerForm.get("password").enable();
                    this.registerForm.get("confirm_password").enable();
                }
            }
        );
    }


    onChange(ctrl: any) {

        if (!this.registerForm) 
        return;

        let form = this.registerForm;

        for (let field in this.controlErrors) {

            this.controlErrors[field] = "";
            this.controlValid[field].notValid = false;

            let control = form.get(field);

            if (control && control.dirty && !control.valid) {
                let controlMessage = this.allTranslation[field];

                for (let key in control.errors) {

                    if (!this.controlErrors[field] && controlMessage.error[key])
                        this.controlErrors[field] += controlMessage.error[key] + " ";
                }

                this.controlValid[field].notValid = true;
            }
            }

        let confirmPassword = form.get("confirm_password");
        let password = form.get("password");

        if (password.value && confirmPassword.value && (password.value != confirmPassword.value)) {

            this.controlErrors.confirm_password = this.allTranslation.confirm_password.error.mustmatch;
            this.controlValid.confirm_password.notValid = true;
        }
    }


    onEyeClick() {

        this.hide = !this.hide;
    }

}
