import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { PersonData } from 'src/app/models/person.data';
import { SocialNetwork } from './../models/social.network';
import { ActivatedRoute, Params, Router } from "@angular/router"
import { TranslationService, TranslateObject } from './../services/translation.service';
import { PersonService } from "./../services/person.service";
import { UserService } from "./../services/user.service";
import { PersonTranslation } from "./../shared/translation.const";
import { WebAddressService } from "./../services/web.address.service";
import { RouterHistoryService } from "./../services/router-history.service";


@Component({
    selector: 'person-card',
    templateUrl: './person.card.component.html',
    styleUrls: ['./person.card.component.css']
})
  
export class PersonCardComponent implements OnInit, AfterContentChecked {

    personID: number = 0;

    personTranslation = PersonTranslation;
    sendButtonObject: Array<TranslateObject> = [];
    person: PersonData;

    showSendMessage = {
        sendMessage: false
    }

    private currentAnchor: string;
    private anchorInited: boolean = false;


    constructor(private activatedRoute: ActivatedRoute, private translationService: TranslationService,
                private personService: PersonService, private userService: UserService,
                private webAddressService: WebAddressService, private routerHistoryService: RouterHistoryService, 
                private router: Router) {

        this.clearPerson();

        this.translationService.getTranslate('page_person', this.personTranslation);
        this.translationService.getCategoryObject('PAGE_PERSON.BUTTON.MESSAGE', this.sendButtonObject);

        let hashTag = this.router.url.indexOf('#');
        if (hashTag > 0) {
            this.currentAnchor = this.router.url.substring(hashTag + 1, this.router.url.length);
        }

        let param: number;
        this.activatedRoute.params.forEach((params: Params) => {
          
            param = +params["personId"];
  
            if (this.personID != param) {

                this.personID = param;
                this.getPerson(this.personID);
            }
        });
    }


    ngOnInit(): void { 

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('page_person', this.personTranslation);
            this.translationService.getCategoryObject('PAGE_PERSON.BUTTON.MESSAGE', this.sendButtonObject);
        });
    }


    ngAfterContentChecked() {

        if (this.currentAnchor && !this.anchorInited ) {
  
            let element = document.getElementById(this.currentAnchor);
            if (element) {
    
                element.scrollIntoView();
                this.anchorInited = true;
            }
        }
    }
  

    private clearPerson() {

        this.person = {
            code_person: null,
            first_name: null,
            last_name: null,
            patronymic: null,
            full_name: null,
            aka_name: null,
            gender: null,
            gender_name: null,
            date_birthday: null,
            description_person: null,
            title: null,
            name_company: null,
            phone: null,
            email: null,
            website: null,
            websiteToShow: null,
            code_country: null,
            name_country: null,
            code_state: null,
            name_state: null,
            code_city: null,
            name_city: null,
            post_address: null,
            subscribe_level: null,
            is_send_message: 0,
            date_chat_message_last: null,
            cnt_messages: 0,
            companyrelations: [],
            socialnetworks: [],
            current_user_company_relations: []
        };
    }


    private getPerson(codePerson: number) {

        this.clearPerson();

        this.personService.getPerson(codePerson).subscribe(
            response => {
                let json = response;
  
                if (json) {
                    this.person = json;

                    this.prepareWeb();
                }
            },
        error => console.log(error));
    }
    

    neadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel: number = 0;
  
        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
 
        return (subscribeLevel > userSubsribeLevel);
    }
  

    private prepareWeb() {

        this.person.websiteToShow = this.webAddressService.cutProtocol(this.person.website);
    }


    onShowMessageClick() {

        if (this.neadMessageSubscribe()) {

            this.router.navigate(['/subscribes']);
        } else {
        
            this.showSendMessage.sendMessage = true;
        }
    }


    visibleGeneral(): boolean {

        let result: any;

        if (!this.personID)
          result = false
        else
          result = this.person.first_name || this.person.last_name ||
              this.person.patronymic || this.person.aka_name ||
              this.person.gender_name || this.person.date_birthday ||
              this.person.name_company || this.person.title;
  
        return result;
    }


    visibleLocations(): boolean {

        let result: any;

        if (!this.personID)
          result = false
        else
          result = this.person.name_country || this.person.name_state ||
              this.person.name_city || this.person.post_address ||
              this.person.phone || this.person.email;
  
        return result;
    }


    getCurrentAnchor(): string {

        let hashTag = this.routerHistoryService.currentUrl$.value.indexOf('#');
  
        if (hashTag > 0) {
  
          let anchorPos = this.routerHistoryService.currentUrl$.value.substring(hashTag + 1, this.routerHistoryService.currentUrl$.value.length);
          if (anchorPos)
            return anchorPos;
        } else {
          return 'general';
        }
    }

    
    neadMessageSubscribe(): boolean {

        if (!this.sendButtonObject || (this.sendButtonObject.length <= 0))
            return false;

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        return (this.sendButtonObject[0].subscribe_level > userSubsribeLevel);
    }


    onCloseMessage(event: number) {

        if (event == 1)
            this.getPerson(this.personID);
        this.showSendMessage.sendMessage = false;
    }


    getSocialImage(social: SocialNetwork): string {

        return this.webAddressService.getSocialImg(social.type_address);
    }

}
