import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslationService } from './../../services/translation.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ForgotPasswordTranslation } from '../../shared/translation.const';
import { SendEmail, SendMessageResult } from '../../models/user';
import { DeviceInfoService } from 'src/app/services/device-info.service';
import { UserService } from '../../services/user.service';
import { ErrorService } from '../../services/error.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector: 'forgot-password',
    templateUrl: './forgot.password.component.html',
    styleUrls: ['./forgot.password.component.css'],
})

export class ForgotPasswordComponent implements OnInit {

    forgotPasswordForm: FormGroup;
    email: FormControl;

    controlErrors = {
      email: ""
    }

    controlValid = {
      email: {
        notValid: false
      }
    }

    sendMessageResult: SendMessageResult = {
      msg_after_sending: ''
    };
  
    forgotPassTranslation = ForgotPasswordTranslation;

    @Output() 
    errorEvent = new EventEmitter<string>();
  

    constructor(private translationService: TranslationService, private fBuilder: FormBuilder,
            private deviceInfoService: DeviceInfoService, private userService: UserService,
            private errorService: ErrorService, private router: Router, private activatedRoute: ActivatedRoute) {

        this.translationService.getTranslate('FORM_FORGOT_PASS', this.forgotPassTranslation);
    }


    ngOnInit() {

      this.translationService.languageChanged$.subscribe((changeObj) => {

        this.translationService.getTranslate('FORM_FORGOT_PASS', this.forgotPassTranslation);
      });

      this.buildForm();

      if (this.activatedRoute.snapshot.queryParams.email) {

        let queryPar = this.activatedRoute.snapshot.queryParams;

        if (queryPar) {

          if (this.forgotPasswordForm) {
    
            let control = this.forgotPasswordForm.get('email');
            if (control)
              control.setValue(queryPar['email']);
          }
    
        }
      }
    }


    private buildForm() {

        this.forgotPasswordForm = this.fBuilder.group({
          email: [this.email, [
                Validators.required,
                Validators.pattern("[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}")
            ]]
        });
    }
  

    onChange(ctrl: any) {

      if (!this.forgotPasswordForm) 
        return;
  
      let form = this.forgotPasswordForm;
    
      for (let field in this.controlErrors) {

        this.controlErrors[field] = "";
  
        let control = form.get(field);
  
        if (control && control.dirty && !control.valid) {
          let controlMessage = this.forgotPassTranslation[field];
  
          for (let key in control.errors) {
  
            if (!this.controlErrors[field])
              this.controlErrors[field] += controlMessage.error[key] + " ";
          }
        }
      }
    }


    onSubmit() {

      this.errorEvent.emit('');

        let sendEmail: SendEmail = {
          email: this.forgotPasswordForm.get("email").value, 
          type_operation: 'RP', 
          device_data: this.deviceInfoService.deviceInfo,
          language_id: this.translationService.currentLanguage
        }

        this.userService.sendEmail(sendEmail).subscribe(
            result => {
                this.sendMessageResult = result;
                alert(this.sendMessageResult.msg_after_sending);
                this.router.navigate(['/']);
            },
            (error) => {
                let errorMessage = this.errorService.getErrorPTNMessage(error);
                console.log(error);
                this.errorEvent.emit(errorMessage);
            }
        );
    }
 
}
