import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './../services/user.service';
import { TranslationService } from './../services/translation.service';
import { LoaderService } from './../services/loader.service';
import { PtnAppConfig } from './../shared/ptn-app-config';
import { finalize } from 'rxjs/operators';
import { PersonData } from "./../models/person.data";


@Injectable()
export class PersonService implements OnInit {

    private url = PtnAppConfig.url_v2;
    person: PersonData;


    constructor(private userService: UserService, private translationService: TranslationService,
                private http: HttpClient, private loaderService: LoaderService) {
    }


    ngOnInit() {
    }


    getPerson(personID: number): Observable<any> {

        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.get(this.url + '/person/' + personID.toString(), { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading));
    }

}
