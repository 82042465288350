import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Industry } from "./../models/home.body";
import { IndustryService } from "./../services/industry.service";
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { AnalysisTranslation } from "./../shared/translation.const";
import { TranslationService, TranslateObject } from "./../services/translation.service";
import { UserService } from "./../services/user.service";
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from "./../services/company.service";
import { Company } from "./../models/company.data";
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as Chart from 'chart.js';
import { PlatformService } from './../services/platform.service';


@Component({
    selector: 'top-industry',
    templateUrl: './top.industry.component.html',
    styleUrls: ['./top.industry.component.css']
})
  
export class TopIndustryComponent implements OnInit, AfterViewChecked {

    listIndustry: Array<Industry> = [];
    listCompany: Array<Company> = [];

    analisysTranslation = AnalysisTranslation;

    sortingObject: Array<TranslateObject> = [];
    private industrySort: Array<TranslateObject> = [];

    listLimit: Array<TranslateObject> = [];
    selectedLimit: string = "";
    limitID: number = null;

    currentIndustryID: number = undefined;
    industryInited: boolean = false;

    public barChartOptions: ChartOptions = {
        responsive: true,
/*
// Можна змінити текст в Hint
        tooltips: {
            enabled: true,
            callbacks: {
                label: function (tooltipItem, data) {
                    let label = data.labels[tooltipItem.index];
                    let count = data
                          .datasets[tooltipItem.datasetIndex]
                          .data[tooltipItem.index];
                    return label + " Reads Count : " + count;
                },
            },
        },
*/
        scales: {
            yAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: ''
                    }
                },
            ],
            xAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: '',
                    },
                    ticks: {
                        stepSize: 1,
                        min: 0,
                        maxRotation: 90,
                        minRotation: 90,
                        autoSkip: false,
                        fontStyle: 'bold'
                    }
                }
            ]
        },
        plugins: {
            datalabels: {
                rotation: 270,
                color: '#364d7b',
                align: "top",
                font: {
                    size: 13
                },
/*
                formatter: (value, ctx) => {
                    const label = value;
                    return label;
                },
*/
                listeners: {
                    click: function(context) {
                      // Receives `click` events only for labels of the first dataset.
                      // The clicked label index is available in `context.dataIndex`.
                      console.log('label ' + context.dataIndex + ' has been clicked!');
                    }
                }
            }
        }
    };
  
    public barChartLabels: Label[] = [];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];
  
    public barChartData: ChartDataSets[] = [
        { data: [], label: '', barThickness: 12},
        { data: [], label: '', barThickness: 12}
    ];

    public barChartColors: Color[] = [
        { backgroundColor: '#00C2C7' },
        { backgroundColor: '#189AD3' }
    ];

    // Mobile
    showIndustries: boolean = true;
    nameIndustry: string = undefined;

    
    constructor(private industryService: IndustryService, private translationService: TranslationService, 
                private userService: UserService, private router: Router, private companyService: CompanyService, 
                private activatedRoute: ActivatedRoute, public platformService: PlatformService) {

        this.activatedRoute.fragment.subscribe(
            (fragment: string) => {
                this.currentIndustryID = +fragment;
            }
        );
        
        this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);
//        this.translationService.getCategoryObject('PAGE_HOME.COMPANY.SORTING_OPTIONS.SORT_BY_VALUATION', this.sortingObject);
        this.translationService.getCategoryObject('PAGE_ANALYSIS.TOP_IN_INDUSTRY.SORTING_OPTIONS.SORT_BY_VALUATION', this.sortingObject);

        this.takeLimitList();

        this.industrySort = [];
        this.translationService.getCategoryObject('PAGE_HOME.INDUSTRY.SORTING_OPTIONS.SORT_BY_NAME', this.industrySort);

        this.getIndustries();
    }


    ngOnInit() {

        this.barChartData[0].label = this.analisysTranslation.top_in_industry.funding.title;
        this.barChartData[1].label = this.analisysTranslation.top_in_industry.valuation.title;

        this.barChartOptions.scales.yAxes[0].scaleLabel.labelString = this.analisysTranslation.top_in_industry.y_axis;
        this.barChartOptions.scales.xAxes[0].scaleLabel.labelString = this.analisysTranslation.top_in_industry.x_axis;

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);
            this.translationService.getCategoryObject('PAGE_ANALYSIS.TOP_IN_INDUSTRY.SORTING_OPTIONS.SORT_BY_VALUATION', this.sortingObject);

            this.takeLimitList();

            this.industrySort = [];
            this.translationService.getCategoryObject('PAGE_HOME.INDUSTRY.SORTING_OPTIONS.SORT_BY_NAME', this.industrySort);

            this.getIndustries();
        });

//        this.barChartOptions.plugins.datalabels.font = {size: 8};
    }


    ngAfterViewChecked() {

        if (this.currentIndustryID > 0 && !this.industryInited) {

            let element = document.getElementById('industry' + this.currentIndustryID.toString());
            if (element) {

                element.scrollIntoView();
                this.industryInited = true;
            }
        }
    }


    private takeLimitList() {

        this.listLimit = [];
        this.selectedLimit = "";

        this.translationService.getObjectBySubscribe('PAGE_ANALYSIS.TOP_IN_INDUSTRY.GRAPH_OPTIONS.TOP_SELECTOR', this.listLimit, this.userService.getUserSubscribeLevel());
        if (this.listLimit && this.listLimit.length > 0) {

            let limit = this.listLimit.find(limit => limit.object_name === 'PAGE_ANALYSIS.TOP_IN_INDUSTRY.GRAPH_OPTIONS.TOP_SELECTOR.TOP_10');
            if (limit) {

                this.selectedLimit = limit.translation;
                this.limitID = limit.sort_index;
            } else {

                this.selectedLimit = this.listLimit[0].translation;
                this.limitID = this.listLimit[0].sort_index;
            }
        }
    }


    private getIndustries() {

        if (!this.industrySort || this.industrySort.length <= 0)
            return;
            
        this.industryService.getIndustries(this.industrySort[0].object_id).subscribe(
            response => {
                let json = response;
                if (json) {
                    this.listIndustry = json;

                    if (this.listIndustry && this.listIndustry.length > 0 && !this.currentIndustryID) {
                        this.currentIndustryID = this.listIndustry[0].code_industry;
                        this.nameIndustry = this.listIndustry[0].name_industry;
                    }

                    this.showChart();
                }
            },
            error => console.log(error));
    }


    private showChart() {

        let userSubsribeLevel: number = 0;
        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;

        if (this.currentIndustryID > 0) {
            this.getTopCompanies(this.currentIndustryID);
        } else {
            for (let i = 0; i < this.listIndustry.length; i++)
                if (this.listIndustry[i].company_count_all > 0 && userSubsribeLevel >= this.listIndustry[i].subscribe_level) {

                    this.getTopCompanies(this.listIndustry[i].code_industry);
                    break;
                }
        }
    }


    private getTopCompanies(codeIndustry: number) {

        if (!this.limitID)
            return;

        let sortObjectID = undefined;
        if (this.sortingObject && (this.sortingObject.length > 0) && this.sortingObject[0])
            sortObjectID = this.sortingObject[0].object_id;

        this.companyService.getCompanyList(codeIndustry, 0, this.limitID, 0, sortObjectID).subscribe(
            response => {
                let json = response;
                if (json) {
                    this.listCompany = json;

                    for (let i = 0; i < this.barChartData.length; i++) {
                        this.barChartData[i].data = [];
                    }
                    this.barChartLabels = [];

                    this.barChartOptions.scales.yAxes[0].scaleLabel.labelString = this.analisysTranslation.top_in_industry.y_axis;
                    this.barChartOptions.scales.xAxes[0].scaleLabel.labelString = this.analisysTranslation.top_in_industry.x_axis;
        
                    for (let i = this.listCompany.length - 1; i >= 0; i--) {

                        this.barChartLabels.push(this.listCompany[i].name_firm);

                        this.barChartData[0].data.push(Math.floor(Number((this.listCompany[i].total_funding_amount / 1000000))));
                        this.barChartData[1].data.push(Math.floor(Number((this.listCompany[i].valuation / 1000000))));
                    }
                }
            },
            error => console.log(error));
    }


    onIndustryClick(industry: Industry) {
        
        this.router.navigate(['analysis/top-industry'], { fragment: industry.code_industry.toString() });

        if (this.neadSubscribe(industry.subscribe_level))
            this.router.navigate(['/subscribes'])
        else {
            this.currentIndustryID = industry.code_industry;
            this.nameIndustry = industry.name_industry;
            this.industryInited = true;
            this.getTopCompanies(industry.code_industry);
            this.showIndustries = false;
        }
    }


    neadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        return (subscribeLevel > userSubsribeLevel);
    }


//    public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    public chartClicked({event, active}: any): void {

        if (!active || active.length <= 0)
            return;

        if (active[0]._index >=0 && active[0]._index < this.listCompany.length) {
            this.router.navigate(['./', this.listCompany[this.listCompany.length - (active[0]._index + 1)].code_firm], {relativeTo: this.activatedRoute});
        }
    }


    isVisorVisible(): boolean {

        let userSubsribeLevel = this.userService.user?.subscribe_level || 0;
        let res: boolean = false;

        if (this.listCompany) {
            for (let i = 0; i < this.listCompany.length; i++) {
                if (userSubsribeLevel < this.listCompany[i].subscribe_level) {
                    res = true;
                    break;
                }
            }
        }
        return res;

/*
        let res = this.userService != undefined && this.userService.user != undefined;

        if (res) {
            if (this.listCompany) {
                for (let i = 0; i < this.listCompany.length; i++) {
                    if (this.userService.user.subscribe_level < this.listCompany[i].subscribe_level) {
                        return true;
                    }
                }
            } else
                return false;
        } else
            return true;
*/            
    }


    onChangeLimit(selectedIndex: number) {

        this.limitID = this.listLimit[selectedIndex].sort_index;
        this.getTopCompanies(this.currentIndustryID);
    }


    onShowDiagramClick() {

        if (!this.currentIndustryID || this.currentIndustryID <= 0)
            return;

        let industry = this.listIndustry.find(industry => industry.code_industry === this.currentIndustryID);

        if (!industry)
            return;

        this.router.navigate(['analysis/top-industry'], { fragment: industry.code_industry.toString() });

        this.showIndustries = false;

        if (this.neadSubscribe(industry.subscribe_level))
            this.router.navigate(['/subscribes'])
        else {
            this.nameIndustry = industry.name_industry
            this.getTopCompanies(industry.code_industry);
        }
    }


    onShowIndustryClick() {

        this.router.navigate(['analysis/top-industry'], { fragment: this.currentIndustryID.toString() });

        this.showIndustries = true;
    }


}
