import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './../services/user.service';
import { TranslationService } from './../services/translation.service';
import { LoaderService } from './../services/loader.service';
import { PtnAppConfig } from './../shared/ptn-app-config';
import { finalize } from 'rxjs/operators';
import { CompanyAdvanced, CompanyListInfo } from "./../models/company.data";


@Injectable()
export class CompanyService {

    private url = PtnAppConfig.url_v2;

    companyCount: number = 0;
    company: CompanyAdvanced;
    listCompanyLoaded$ = new Subject<CompanyListInfo>();

    constructor(private userService: UserService, private translationService: TranslationService,
                private http: HttpClient, private loaderService: LoaderService) {

    }


    getCompany(companyID: number): Observable<any> {

        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.get(this.url + '/firm/' + companyID.toString(), { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading));
    }


    getCompanyList(industryID: number, categoryID: number, limit: number, offset: number, sortObjectID: number, typeFirm?: string): Observable<any> {

        let addParams: string = '';

        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        addParams = '&limit=' + limit.toString() + '&offset=' + offset.toString();
        if (typeFirm)
            addParams = addParams + '&type_firm=' + typeFirm;

        this.loaderService.startLoading();

        if (industryID && categoryID) {
            return this.http.get(this.url + '/firm?sort_object_id=' + sortObjectID + '&code_category=' + categoryID + '&code_industry=' + industryID + addParams, 
            { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading));
        } else {
            if (industryID) {
                return this.http.get(this.url + '/firm?sort_object_id=' + sortObjectID + '&code_industry=' + industryID + addParams,
                        { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading));
            };
        };
    }


    getRounds(companyID: number): Observable<any> {

        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        this.loaderService.startLoading();
        return this.http.get(this.url + '/firm/rounds/' + companyID.toString(), { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading));
    }


    getPresentation(companyID: number, fileName: string): Observable<any> {

        let url = PtnAppConfig.url_v2;
        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/pdf",
            "x-vms-lang": this.translationService.currentLanguage,
            "Content-Disposition": `attachment; filename="${fileName}"`
        });        

        this.loaderService.startLoading();
        return this.http.get(url + '/user/firm/presentation-presigned-get/' + companyID.toString(), { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading));
    }
}
