import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from './../../services/user.service';
import { User } from './../../models/user';
import { ErrorService } from './../../services/error.service';
import { DeviceInfoService } from 'src/app/services/device-info.service';
import { TranslationService } from './../../services/translation.service';
import { langFORM_SIGNIN } from "./../../shared/constant.module";
import { RouterHistoryService } from './../../services/router-history.service';
import { SignInTranslation } from './../../shared/translation.const';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlatformService } from './../../services/platform.service';

@Component({
  selector: 'ptn-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  signInForm: FormGroup;
  email: FormControl;
  password: FormControl;
  passwordColor: string;
  formError: string;

  trialBegined: boolean = false;

  controlErrors = {
    email: "",
    password: ""
  }

  controlValid = {
    email: {
      notValid: false
    },
    password: {
      notValid: false
    }
  }

  hide: boolean = true;

  translatedCaptions = SignInTranslation;

  private previousUrl: string;

  @Output() 
  errorEvent = new EventEmitter<string>();


  constructor(private router: Router, private userService: UserService, private errorService: ErrorService, private deviceInfoService: DeviceInfoService,
    private translationService: TranslationService, private matSnackBar: MatSnackBar,
    private routerHistoryService: RouterHistoryService, public platformService: PlatformService) {

    this.translationService.getTranslate(langFORM_SIGNIN, this.translatedCaptions);
  }

  ngOnInit(): void {
    this.previousUrl = this.routerHistoryService.previousUrl$.value || '/';
    console.log(`previousUrl: ${this.previousUrl}`);

//    this.localStorageService.socialnetAction = SocialnetAction.SignIn;

    this.createFormControls();
    this.createForm();
    // this.signInForm.statusChanges.subscribe((status) => {
    //   console.log(status); //status will be "VALID", "INVALID", "PENDING" or "DISABLED"
    //   //if (status === 'VALID') {
    //   //console.log('Ok');
    //   //}
    // });

    this.translationService.languageChanged$.subscribe((changeObj) => {
      this.translationService.getTranslate(langFORM_SIGNIN, this.translatedCaptions);
    });

    // if (!this.localStorageService.user) {
    //   this.userService.x_vms_session = 'notauthenticate';
    //   this.userService.user = null;
    //   this.userService.isAuthenticated = false;
    //   this.userService.Authenticated$.next(false);
    //   this.userService.loginChanged$.next(null);
    // }
  }

  onPasswordChange(searchValue: string): void {
    //console.log(searchValue);
    //console.log(getPasswordStrength(searchValue));
    //this.passwordColor = getPasswordColor(searchValue);
  }

  createFormControls(): void {
    this.email = new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}")]);
    this.password = new FormControl('', [Validators.required/*, passwordStrengthValidator*/]);
  }

  createForm(): void {
    this.signInForm = new FormGroup({
      email: this.email,
      password: this.password
    });
  }

  onSubmit(): void {

    this.errorEvent.emit('');

    if (this.signInForm.valid) {
      this.formError = null;
      console.log('Send sign in info to server');
      //console.log(this.signInForm.value);

      let user: User = {
        identifier: this.email.value,
        password: this.password.value,
        type_identifier: 'E',
        identifier_data: null,
        device_data: this.deviceInfoService.deviceInfo,
        language_id: this.translationService.currentLanguage
      };

      this.userService.user = user;
      this.userService.signIn(user).subscribe((res) => {
        if (!this.userService.user.id_subscription_payment_system && this.userService.user.subscription_end_date) {
          this.checkSubscribeDate();
        } else {
          window.location.href = window.location.origin + this.previousUrl;
        }
      },
        (error) => {
          this.formError = this.errorService.getErrorPTNMessage(error);
          this.errorEvent.emit(this.formError);
          console.log(this.formError);
          this.userService.actionOnSignInError();
        }
      );
    }
  }


  private checkSubscribeDate() {

    let today = new Date();
    let subscribeDate = new Date(this.userService.user.subscription_end_date * 1000);

    if (subscribeDate < today) {

      this.trialBegined = true;

      let snackBarRef = this.matSnackBar.open(this.translatedCaptions.trial_ended.message, 'Ok', {
        duration: 10000,
        panelClass: ['snack-bar']
      });
      snackBarRef.afterDismissed().subscribe(() => {
        this.trialBegined = false;
        window.location.href = window.location.origin + this.previousUrl;
      });
    } else {
      window.location.href = window.location.origin + this.previousUrl;
    }
  }


  onChange(ctrl: any) {

    if (!this.signInForm) 
      return;

    let form = this.signInForm;

    for (let field in this.controlErrors) {

      this.controlErrors[field] = "";
      this.controlValid[field].notValid = false;

      let control = form.get(field);

      if (control && control.dirty && !control.valid) {
        let controlMessage = this.translatedCaptions[field];

        for (let key in control.errors) {

          if (!this.controlErrors[field])
            this.controlErrors[field] += controlMessage.error[key] + " ";
        }

        this.controlValid[field].notValid = true;
      }
    }
  }


  onForgotPassClick() {

    if (!this.signInForm) 
      return;

    let form = this.signInForm;
    let control = form.get('email');
    if (control.value) {

      let queryPar = {
        email: control.value
      }
      this.router.navigate(['/login/forgot-pass'], { queryParams: queryPar });
    } else {
      this.router.navigate(['/login/forgot-pass']);
    }
  }


  onEyeClick() {

    this.hide = !this.hide;
  }

}
