<div class="person">
    <div class="main" [ngClass]="showSendMessage">
        <div class="header">
            <div class="header-left">
                <div class="name-person">{{person.full_name}}</div>
                <span>{{person.name_country}}</span>
                <span>{{person.name_city}}</span>
            </div>
            <div class="header-right">
                <div id="header-right">
                    <img src="/assets/imgs/focus.svg">

                    <div class="div-list">
                        <div class="comma-text" *ngFor="let relations of person.companyrelations; let lastItem = last;">
                            <a class="subscribe" [routerLink]="['/company', relations.code_firm]"
                                        [ngClass]="{ 'companyRelationsDisabled': neadSubscribe(relations.subscribe_level) }" >
                                {{relations.name_firm}}
                                <img *ngIf="neadSubscribe(relations.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>
                            <span>&nbsp;{{relations.name_position}}</span>
                            <div *ngIf="!lastItem">&nbsp;</div>
                        </div>            
                    </div>           
                </div>

                <div id="header-right">
                    <img class="www" src="/assets/imgs/www.svg">
                    <div class="top-internet">
                        <div class="div-list">
                            <a href={{person.website}}>{{person.websiteToShow}}</a>
                        </div>
                        <div class="div-list">
                            <a class="subscribe comma-text" *ngFor="let social of person.socialnetworks; let lastItem = last;" 
                                    href={{social.address}}>
                                <img class="social-img" src="{{ getSocialImage(social) }}" title="{{social.name_address}}">
                                <img *ngIf="neadSubscribe(social.subscribe_level)" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                            </a>
                        </div>           
                    </div>
                </div>
            </div>
        </div>

        <div class="person-card">
            <div class="anchors">
                <div class="menu-item" *ngIf="visibleGeneral()">
                    <div [ngClass]="{ currentAnchor: getCurrentAnchor() == 'general' }"></div>
                    <a [ngClass]="{ selectedAnchor: getCurrentAnchor() == 'general' }" href="/person/{{personID}}#general">
                        {{personTranslation.card.anchor.general}}
                    </a>
                </div>
                <div class="menu-item" *ngIf="visibleLocations()">
                    <div [ngClass]="{ currentAnchor: getCurrentAnchor() == 'locations' }"></div>
                    <a [ngClass]="{ selectedAnchor: getCurrentAnchor() == 'locations' }" href="/person/{{personID}}#locations">
                    {{personTranslation.card.anchor.locations}}
                    </a>
                </div>
            </div>

            <div class="body" #body>
<!-- general -->
                <div class="section" id="general" #general *ngIf="visibleGeneral()">
                    <div class="info-item" *ngIf="person.first_name">
                        <div class="fixed-col">{{personTranslation.card.field.first_name}}</div>
                        {{person.first_name}}
                    </div>
                    <div class="info-item" *ngIf="person.last_name">
                        <div class="fixed-col">{{personTranslation.card.field.last_name}}</div>
                        {{person.last_name}}
                    </div>
                    <div class="info-item" *ngIf="person.patronymic">
                        <div class="fixed-col">{{personTranslation.card.field.patronymic}}</div>
                        {{person.patronymic}}
                    </div>
                    <div class="info-item" *ngIf="person.aka_name">
                        <div class="fixed-col">{{personTranslation.card.field.aka_name}}</div>
                        {{person.aka_name}}
                    </div>
                    <div class="info-item" *ngIf="person.gender_name">
                        <div class="fixed-col">{{personTranslation.card.field.gender}}</div>
                        {{person.gender_name}}
                    </div>
                    <div class="info-item" *ngIf="person.date_birthday">
                        <div class="fixed-col">{{personTranslation.card.field.date_birthday}}</div>
                        {{person.date_birthday}}
                    </div>
                    <div class="info-item" *ngIf="person.name_company">
                        <div class="fixed-col">{{personTranslation.card.field.name_company}}</div>
                        {{person.name_company}}
                    </div>
                    <div class="info-item" *ngIf="person.title">
                        <div class="fixed-col">{{personTranslation.card.field.title}}</div>
                        {{person.title}}
                    </div>
                </div>
<!-- locations -->
                <div class="section" id="locations" #locations *ngIf="visibleLocations()">
                    <div class="info-item" *ngIf="person.name_country">
                        <div class="fixed-col">{{personTranslation.card.field.name_country}}</div>
                        {{person.name_country}}
                    </div>
                    <div class="info-item" *ngIf="person.name_state">
                        <div class="fixed-col">{{personTranslation.card.field.name_state}}</div>
                        {{person.name_state}}
                    </div>
                    <div class="info-item" *ngIf="person.name_city">
                        <div class="fixed-col">{{personTranslation.card.field.name_city}}</div>
                        {{person.name_city}}
                    </div>
                    <div class="info-item" *ngIf="person.post_address">
                        <div class="fixed-col">{{personTranslation.card.field.post_address}}</div>
                        {{person.post_address}}
                    </div>
                    <div class="info-item" *ngIf="person.phone">
                        <div class="fixed-col">{{personTranslation.card.field.phone}}</div>
                        {{person.phone}}
                    </div>
                    <div class="info-item" *ngIf="person.email">
                        <div class="fixed-col">{{personTranslation.card.field.email}}</div>
                        <a href="mailto:{{person.email}}">{{person.email}}</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer">
            <div class="ryska"></div>
            <div class="buttons">
                <a *ngIf="person.is_send_message == 1" [routerLink]="[]" routerLinkActive="active"
                        (click)="onShowMessageClick()">
                    {{personTranslation.button.message}}
                    <img class="www2" src="/assets/imgs/www2.svg">
                    <img *ngIf="neadMessageSubscribe()" class="subscribe-img" src="/assets/imgs/subscribe.svg">
                </a>
            </div>    
        </div>
    </div>    

    <div class="send-message" *ngIf="showSendMessage.sendMessage">
        <ptn-send-message [person]="person" (onCloseMessage)="onCloseMessage($event)"></ptn-send-message>
    </div>
</div>