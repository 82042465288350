import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { FilterFirm } from '../../../models/filter';
import { Router, ActivatedRoute } from '@angular/router';
import { FilterService } from '../../../services/filter.service';
import { PlatformService } from '../../../services/platform.service';
import { Subscription } from 'rxjs';
import { TranslationService } from '../../../services/translation.service';


@Component({
  selector: 'ptn-table-view-result',
  templateUrl: './table-view-result.component.html',
  styleUrls: ['./table-view-result.component.scss']
})
export class TableViewResultComponent implements OnInit, AfterViewChecked {

  displayedColumns: string[];

  @Input()
  typeFirm: string;

  @Input()
  filterTranslation: any;
  
  @Input()
  filter_firms: Array<FilterFirm>;

  private messageSubscribeLevel: number = 0;
  showSendMessage: boolean = false;
  messagePersonID: number = null;
  messageCompanyID: number = null;
  companyForFounder: boolean = false;

  private subscriptionFragment: Subscription;
  private currentCompanyID: number = undefined;


  constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute, 
        private filterService: FilterService, public platformService: PlatformService, private translationService: TranslationService) { 

      this.subscriptionFragment = this.activatedRoute.fragment.subscribe(
        (fragment: string) => {
      
            this.currentCompanyID = +fragment;
        }
      );
  }

  ngOnInit(): void {

    switch (this.typeFirm) {
      case 'company':
        this.displayedColumns = ['name_firm', 'name_country', 'name_number_of_emploees', 'total_funding_amount', 'valuation', 'founded_year', 'is_send_message'];
        break;
      case 'fund':
        this.displayedColumns = ['name_firm', 'name_country', 'name_number_of_emploees', 'total_capital', 'name_investment_stage_str', 'founded_year', 'is_send_message'];
        break;
      case 'private-investor':
        this.displayedColumns = ['name_firm', 'name_country', 'name_deal_size', 'total_capital', 'name_investment_stage_str', 'founded_year', 'is_send_message'];
        break;
      default:
        break;
    }

    this.translationService.languageChanged$.subscribe((changeObj) => {
      this.messageSubscribeLevel = this.filterService.getMessageSubscribeLavel(this.typeFirm);
    });

    this.messageSubscribeLevel = this.filterService.getMessageSubscribeLavel(this.typeFirm);
  }


  ngAfterViewChecked() {

    if (this.currentCompanyID) {
      let element = document.getElementById("element" + this.currentCompanyID.toString());
      if (element) {
          element.scrollIntoView({block: "center"});
          setTimeout(() => { 
            this.currentCompanyID = undefined;
          }, 1000);
      }
    }
  }


  neadSubscribe(subscribeLevel: number): boolean {
    let userSubsribeLevel = this.userService.user?.subscribe_level || 0;
    return subscribeLevel > userSubsribeLevel;
  }

  onSendMessageClick(element) {

    if (this.neadMessageSubscribe()) {

      this.router.navigate(['/subscribes']);
    } else {
      if (this.platformService.handsetPlatform) {
        this.router.navigate(['/send-message'], { queryParams: { person_id: null,
                                                                company_id: element.code_firm } });
      } else {
        this.messagePersonID = null;
        this.messageCompanyID = element.code_firm;
        this.companyForFounder = false;
        this.showSendMessage = true;
      }
    }
  }

  onSendMessageFounderClick(firm) {

    if (this.neadMessageSubscribe()) {

      this.router.navigate(['/subscribes']);
    } else {

      if (this.platformService.handsetPlatform) {
        this.router.navigate(['/send-message'], { queryParams: { person_id: null,
                                                                company_id: firm.code_firm } });
        } else {
          this.messagePersonID = null;
          this.messageCompanyID = firm.code_firm;
          this.companyForFounder = true;
          this.showSendMessage = true;
        }
    }
  }

  neadMessageSubscribe() {

    let userSubsribeLevel = this.userService.user?.subscribe_level || 0;

    return (this.messageSubscribeLevel > userSubsribeLevel);
  }

  onCloseMessage(event: any) {

    this.showSendMessage = false;
  }


  getSendMessagePos() {
    let elementMsg = document.getElementById('send-msg');

    if (elementMsg) {

      let styles = {
            "top": '-100px',
      };

      return styles;        
    }
  }


  onOpenCardClick(codeCompany: number) {

    let par = this.activatedRoute.snapshot.queryParams;
    
    this.router.navigate([], { queryParams: par, fragment: codeCompany.toString() });

    setTimeout(() => { 
      this.router.navigate(['./', codeCompany], {relativeTo: this.activatedRoute});
    }, 10);
  }

}
