import { FormGroup } from '@angular/forms';

export function getFormError(form: FormGroup, controlErrors: any, translation: any) {
    let errorMessage: string;

    for (let field in controlErrors) {
        let control = form.get(field);

        if (control && (control.dirty || control.touched) && !control.valid) {
            let controlMessage = translation[field];

            for (let key in control.errors) {
                if (controlMessage.error) {
                    errorMessage = controlMessage.error[key];
                    if (errorMessage)
                        break;
                }                
            }
        }
    }

    return errorMessage;
}