import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, Router, CanActivateChild } from '@angular/router';
import { langPAGE_LOGIN_REQUIREMENT } from '../shared/constant.module';
import { LoginRequirementTranslation } from '../shared/translation.const';
import { TranslationService } from './translation.service';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    
    private loginRequirementTranslation = LoginRequirementTranslation;
    
    constructor(private router: Router, private userService: UserService, private translationService: TranslationService, private _snackBar: MatSnackBar) {
        this.translationService.getTranslate(langPAGE_LOGIN_REQUIREMENT, this.loginRequirementTranslation);
    }

    canActivate(): boolean {
        if (!this.userService.isAuthenticated) {
            let snackBarRef = this._snackBar.open(this.loginRequirementTranslation.massege_form.text, 'Ok', {
                duration: 10000,
                panelClass: ['snack-bar']
            });
            snackBarRef.afterDismissed().subscribe(() => {
                this.router.navigate(['/login/sign-in']);
            });
        }
        return this.userService.isAuthenticated;
    }
    canActivateChild(): boolean {
        return this.canActivate();
    }
}