<div class="ptn-recovery">

    <form novalidate [formGroup]="recoveryForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="password">{{recoveryTranslation.password.field}}</label>
            <div class="password-group">
                <input type="password" class="password form-control" id="password" formControlName="password" #password
                        [type]="hide ? 'password' : 'text'"
                        (change)="onChange('password')" [ngClass]="controlValid.password" placeholder={{recoveryTranslation.password.field}}/>
                <img class="eye" *ngIf="hide && password.value" src="/assets/imgs/eye-open.svg" (click)="onEyeClick()">
                <img class="eye" *ngIf="!hide && password.value" src="/assets/imgs/eye-close.svg" (click)="onEyeClick()">
            </div>

            <div *ngIf="controlErrors.password" class="error message">
                {{ controlErrors.password }}
            </div>
            <div *ngIf="!controlErrors.password">
                &nbsp;
            </div>
        </div>

        <div class="form-group">
            <label for="confirm_password">{{recoveryTranslation.confirm_password.field}}</label>
            <input type="password" class="form-control" id="confirm_password" required formControlName="confirm_password" #confirm_password
                                (change)="onChange('confirm_password')" [ngClass]="controlValid.confirmPassword" placeholder={{recoveryTranslation.confirm_password.field}} />

            <div *ngIf="controlErrors.confirmPassword" class="error message">
                {{ controlErrors.confirmPassword }}
            </div>
            <div *ngIf="!controlErrors.confirmPassword">
                &nbsp;
            </div>
        </div>

        <div class="message-container">
        <div class="success message" *ngIf="resultAction == 1">
            {{recoveryTranslation.done.text}}
        </div>
        <div class="error message" *ngIf="resultAction == 2">
            {{recoveryTranslation.error.text}}
        </div>
    </div>

        <div class="div-button">
            <button type="submit" [disabled]="!recoveryForm.valid || controlValid.password.notValid || controlValid.confirmPassword.notValid || resultAction > 0">
                {{recoveryTranslation.save.button}}
            </button>

            <div class="recovery">
                <p>{{recoveryTranslation.next.text}}</p>
            </div>
            <button class="sign-in" [disabled]="resultAction == 0" (click)="onLoginClick()">
                {{recoveryTranslation.signin.button}}
            </button>
        </div>

    </form>

</div>