import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { IFilterCheckParam } from '../../../models/filter';

@Component({
  selector: 'ptn-check-criterion',
  templateUrl: './check-criterion.component.html',
  styleUrls: ['./check-criterion.component.scss']
})
export class CheckCriterionComponent implements OnInit {

  showMore = false;
  numberOfVisibleItems = 4;
  //order: string[] = ['checked', 'name_param']; //pipe ngx-order-pipe -> orderBy: order: true -> do not work in production mode

  @Input()
  title: string;

  @Input()
  params: IFilterCheckParam[];

  @Input()
  showDivider: boolean = true;

  @Output() change = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
    this.params = this.params.sort(function (a, b) {
      return (+b.checked - +a.checked) || (a.name_param.toUpperCase() > b.name_param.toUpperCase() ? 1 : -1);
    });

    this.showMore = this.params.length <= this.numberOfVisibleItems;
  }

  filterParam(filterCheckParam: IFilterCheckParam) {
    return filterCheckParam.count_lines > 0
  }

  onChange(): void {
    this.change.emit();
  }
}
