<div class="main">

    <div class="menu">
        <a class="logo" [routerLink]="['/']">
            <img src="/assets/imgs/Logo.svg">
        </a>

        <div class="menu_ref">
            <div class="div-ref">
                <a [routerLink]="['/home']">{{mnuCaptions.home}}</a>
                <div class="underlined" [ngClass]="{ 'refSelected': getLocation()=='/home' }"></div>
            </div>
            <div class="div-ref main-companies">
                <a [routerLink]="['/filter-companies']">{{mnuCaptions.companies}}</a>
                <div class="underlined" [ngClass]="{ 'refSelected': getLocation()=='/filter-companies' }"></div>
            </div>
            <div class="div-ref main-funds">
                <a [routerLink]="['/filter-funds']">{{mnuCaptions.vc_funds}}</a>
                <div class="underlined" [ngClass]="{ 'refSelected': getLocation()=='/filter-funds' }"></div>
            </div>
            <div class="div-ref main-pi">
                <a [routerLink]="['/filter-private-investors']">{{mnuCaptions.privat_investors}}</a>
                <div class="underlined" [ngClass]="{ 'refSelected': getLocation()=='/filter-private-investors' }"></div>
            </div>
            <div class="div-ref main-deals" >
                <a [routerLink]="['/deals']">{{mnuCaptions.deals}}</a>
                <div class="underlined" [ngClass]="{ 'refSelected': getLocation()=='/deals' }"></div>
            </div>
            <div class="div-ref main-analysis">
                <a [routerLink]="['/analysis']">{{mnuCaptions.analysis}}</a>
                <div class="underlined" [ngClass]="{ 'refSelected': getLocation()=='/analysis' }"></div>
            </div>
<!--
            <div class="div-ref main-trends">
                <a [routerLink]="['/']">{{mnuCaptions.trends}}</a>
                <div class="underlined"></div>
            </div>
-->            
            <div class="div-ref main-list">
                <a [routerLink]="['/list']">{{mnuCaptions.list}}</a>
                <div class="underlined" [ngClass]="{ 'refSelected': getLocation()=='/list' }"></div>
            </div>
            <div class="div-ref main-ptn-index">
                <a [routerLink]="['/ptn-index']">{{mnuCaptions.ptn_index}}</a>
                <div class="underlined" [ngClass]="{ 'refSelected': getLocation()=='/ptn-index' }"></div>
            </div>
        </div>

        <div class="hidden-menu">
            <button mat-icon-button [matMenuTriggerFor]="hiddenmenu" aria-label="Example icon-button with a menu">
                <mat-icon>filter_list</mat-icon>
            </button>
            <mat-menu #hiddenmenu="matMenu">
                <button class="hidden-button hidden-companies" mat-menu-item (click)='onCompaniesClick()'>
                    <span>{{mnuCaptions.companies}}</span>
                </button>
                <button class="hidden-button hidden-funds" mat-menu-item (click)='onFundsClick()'>
                    <span>{{mnuCaptions.vc_funds}}</span>
                </button>
                <button class="hidden-button hidden-pi" mat-menu-item (click)='onPrivateInvClick()'>
                    <span>{{mnuCaptions.privat_investors}}</span>
                </button>
                <button class="hidden-button hidden-deals" mat-menu-item (click)='onDealsClick()'>
                    <span>{{mnuCaptions.deals}}</span>
                </button>
                <button class="hidden-button hidden-analysis" mat-menu-item (click)='onAnalysisClick()'>
                    <span>{{mnuCaptions.analysis}}</span>
                </button>
<!--
                <button class="hidden-button hidden-trends" mat-menu-item (click)='onTrendsClick()'>
                    <span>{{mnuCaptions.trends}}</span>
                </button>
-->                
                <button class="hidden-button hidden-list" mat-menu-item (click)='onListClick()'>
                    <span>{{mnuCaptions.list}}</span>
                </button>
                <button class="hidden-button hidden-ptn-index" mat-menu-item (click)='onPtnIndexClick()'>
                    <span>{{mnuCaptions.ptn_index}}</span>
                </button>
            </mat-menu>
        </div>
    
        <div class="menu_btn">
            <a class="search" [routerLink]="['/search']">
                <img class="search-img" src="/assets/imgs/search_desctop.svg">
            </a>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" (click)="onMenuButtonClick()">
                <mat-icon *ngIf="!isAuthenticated">menu</mat-icon>
                <mat-icon *ngIf="isAuthenticated" svgIcon="menu_loged"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button *ngIf="!isAuthenticated" mat-menu-item (click)='onSignIn()'>
                    <mat-icon svgIcon="menu_login"></mat-icon>
                    <span>{{mnSrvTranslation.login}}</span>
                </button>
                <button *ngIf="isAuthenticated" mat-menu-item>
                    <mat-icon svgIcon="menu_user"></mat-icon>
                    <span>{{userService.user.first_name}} {{userService.user.last_name}}</span>
                </button>
                <button class="mat-email" *ngIf="isAuthenticated" mat-menu-item>
                    <mat-icon></mat-icon>
                    <span [ngClass]="account">{{userService.user.email}}</span>
                </button>
<!--
                <mat-list *ngIf="isAuthenticated">
                    <mat-list-item>{{userService.user.email}}</mat-list-item>
                </mat-list>
-->
                <mat-divider></mat-divider>

                <button mat-menu-item (click)='onRegister()'>
                    <mat-icon svgIcon="menu_register"></mat-icon>
                    <span>{{mnSrvTranslation.register}}</span>
                </button>
                <button mat-menu-item (click)='onSubscribe()'>
                    <mat-icon svgIcon="menu_subscribe"></mat-icon>
                    <span>{{mnSrvTranslation.subscribe}}</span>
                </button>

                <mat-divider></mat-divider>

                <button mat-menu-item (click)='onPrivacy()'>
                    <mat-icon svgIcon="menu_privacy"></mat-icon>
                    <span>{{mnSrvTranslation.privacy}}</span>
                </button>
                <button mat-menu-item (click)='onTermOfUse()'>
                    <mat-icon svgIcon="menu_term_of_use"></mat-icon>
                    <span>{{mnSrvTranslation.terms_of_use}}</span>
                </button>
                <button mat-menu-item (click)='onDataCollection()'>
                    <mat-icon svgIcon="menu_data_collection"></mat-icon>
                    <span>{{mnSrvTranslation.data_collection}}</span>
                </button>
                <button mat-menu-item (click)='onSupport()'>
                    <mat-icon svgIcon="support"></mat-icon>
                    <span>{{mnSrvTranslation.support.caption}}</span>
                </button>
                <button mat-menu-item (click)='onAbout()'>
                    <mat-icon svgIcon="menu_about"></mat-icon>
                    <span>{{mnSrvTranslation.about}}</span>
                </button>

                <mat-divider *ngIf="this.isAuthenticated"></mat-divider>

                <button mat-menu-item *ngIf="isAuthenticated" (click)='onSignOut()'>
                    <mat-icon svgIcon="menu_logout"></mat-icon>
                    <span>{{mnSrvTranslation.sign_out}}</span>
                </button>

                <mat-divider></mat-divider>
                <mat-checkbox color="primary" [(checked)]="viewAsPhoto" class="mat-menu-item" (change)="onViewAsPhotoChange($event)">
                    &nbsp;&nbsp;&nbsp;&nbsp;{{mnSrvTranslation.view_as_photo}}
                </mat-checkbox>

                <button mat-menu-item (click)='onSyntheticVC()'>
                    <mat-icon></mat-icon>
                    <span>{{mnSrvTranslation.synthetic_vc}}</span>
                </button>

<!--
                <button mat-menu-item (click)='onChangeLanguage("ENG")' [ngClass]="{ 'langSelected': languageID=='ENG' }">
                    <span>ENG</span>
                </button>
                <button mat-menu-item (click)='onChangeLanguage("RUS")' [ngClass]="{ 'langSelected': languageID=='RUS' }">
                    <span>RUS</span>
                </button>
-->
            </mat-menu>
        </div>
    </div>
</div>