import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { Industry } from "./../models/home.body";
import { SessionStorageService } from '../services/session-storage-service';
import { IndustryService } from "./../services/industry.service";
import { Company } from "./../models/company.data";
import { CompanyService } from "./../services/company.service";
import { UserService } from "./../services/user.service";
import { Router, ActivatedRoute } from '@angular/router';
import { AnalysisTranslation } from "./../shared/translation.const";
import { TranslationService } from "./../services/translation.service";
import { TranslateObject } from './../services/translation.service';
import { LocalStorageService } from '../services/local-storage-service';
import { PlatformService } from './../services/platform.service';


@Component({
    selector: 'sector-map',
    templateUrl: './sector.map.component.html',
    styleUrls: ['./sector.map.component.css']
})
  
export class SectorMapComponent implements OnInit, AfterContentChecked {

    listIndustry: Array<Industry> = [];
    listCompany: Array<Company> = [];
    analisysTranslation = AnalysisTranslation;

    selectedSort: string = '';
    sortIndex: number = 0;
    sortingObject: Array<TranslateObject> = [];
    currentIndustryID: number = undefined;
    industryInited: boolean = false;
    private industrySort: Array<TranslateObject> = [];

    // Mobile
    showIndustries: boolean = true;
    nameIndustry: string = undefined;


    constructor(private sessionStorage: SessionStorageService, private industryService: IndustryService, 
            private companyService: CompanyService, private userService: UserService, private router: Router, 
            private translationService: TranslationService, private localStorage: LocalStorageService, 
            private activatedRoute: ActivatedRoute, public platformService: PlatformService) {

        this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);

        this.takeSortList();

        let storedSort = this.localStorage.get('sectorMapSort');
        if (storedSort)
            this.sortIndex = storedSort;

        this.activatedRoute.fragment.subscribe(

            (fragment: string) => {

                this.currentIndustryID = +fragment;

                if (this.currentIndustryID > 0 && !this.industryInited) {

                    let element = document.getElementById('industry' + this.currentIndustryID.toString());
                    if (element) {

                        element.scrollIntoView();
                        this.industryInited = true;
                    }
                }
        
                this.showCompanies();
            }
        );

        this.industrySort = [];
        this.translationService.getCategoryObject('PAGE_HOME.INDUSTRY.SORTING_OPTIONS.SORT_BY_NAME', this.industrySort);

        this.getIndustries();
    }


    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);
            this.takeSortList();

            this.industrySort = [];
            this.translationService.getCategoryObject('PAGE_HOME.INDUSTRY.SORTING_OPTIONS.SORT_BY_NAME', this.industrySort);

            this.getIndustries();
        });
    }

    
    ngAfterContentChecked() {

        if (this.currentIndustryID > 0 && !this.industryInited) {

            let element = document.getElementById('industry' + this.currentIndustryID.toString());
            if (element) {

                element.scrollIntoView();
                this.industryInited = true;
            }
        }
    }


    private takeSortList() {

        this.sortingObject = [];
        this.translationService.getCategoryObject('PAGE_ANALYSIS.SECTOR_MAP.SORTING_OPTIONS', this.sortingObject);
        if (this.sortingObject && this.sortingObject.length > 0) {

            this.selectedSort = this.sortingObject[this.sortIndex].translation;
            this.showCompanies();
        }
    }


    private showCompanies() {

        if (!this.listIndustry)
            return;

        let userSubscribeLevel: number = 0;
        if (this.userService.user && this.userService.user.subscribe_level)
            userSubscribeLevel = this.userService.user.subscribe_level;

        if (this.currentIndustryID > 0) {

            for (let i = 0; i < this.listIndustry.length; i++) {
                if (this.listIndustry[i].code_industry == this.currentIndustryID) {
                    if (userSubscribeLevel >= this.listIndustry[i].subscribe_level) {
                        this.getCompanies(this.currentIndustryID);
                    } else {
                        this.router.navigate(['/subscribes']);
                    }
                }
            }
        } else {

            for (let i = 0; i < this.listIndustry.length; i++)
                if (this.listIndustry[i].company_count_all > 0) {

                    if (userSubscribeLevel >= this.listIndustry[i].subscribe_level) {

                        this.getCompanies(this.listIndustry[i].code_industry);
                        break;
                    }
                }                
        }
    }


    private getIndustries() {
    
        if (!this.industrySort || this.industrySort.length <= 0)
            return;

        this.industryService.getIndustries(this.industrySort[0].object_id).subscribe(
            response => {
                let json = response;
                if (json) {
                    this.listIndustry = json;
                    if (this.listIndustry && this.listIndustry.length > 0 && !this.currentIndustryID)
                        this.currentIndustryID = this.listIndustry[0].code_industry;
  
                    this.showCompanies();
                }
            },
            error => console.log(error));
    }


    private getCompanies(codeIndustry: number) {

        this.listCompany = [];

        if (!this.sortingObject || this.sortingObject.length <= 0)
            return;

        this.companyService.getCompanyList(codeIndustry, 0, 0, 0, this.sortingObject[this.sortIndex].object_id).subscribe(
            response => {
                let json = response;
                if (json) {
                    this.listCompany = json;

                    this.sessionStorage.set('listCompany', this.listCompany);
                }
            },
            error => console.log(error));
    }


    isVisorVisible(): boolean {

        let userSubsribeLevel = this.userService.user?.subscribe_level || 0;
        let res: boolean = false;

        if (this.listCompany) {
            for (let i = 0; i < this.listCompany.length; i++) {
                if (userSubsribeLevel < this.listCompany[i].subscribe_level) {
                    res = true;
                    break;
                }
            }
        }

        return res;

/*
        let res = this.userService != undefined && this.userService.user != undefined;

        if (res) {
            if (this.listCompany) {
                for (let i = 0; i < this.listCompany.length; i++) {
                    if (this.userService.user.subscribe_level < this.listCompany[i].subscribe_level) {
                        return true;
                    }
                }
            } else
                return false;
        } else
            return true;
*/            
    }


    onIndustryClick(industry: Industry) {

        this.currentIndustryID = industry.code_industry;
        this.nameIndustry = industry.name_industry;
    
        this.router.navigate(['analysis/sector-map'], { fragment: industry.code_industry.toString() });
    
        this.showIndustries = false;
    
        if (this.neadSubscribe(industry.subscribe_level))
            this.router.navigate(['/subscribes'])
        else {
            this.getCompanies(industry.code_industry);
        }
    }


    neadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        return (subscribeLevel > userSubsribeLevel);
    }

    
    onChangeSort(selectedIndex: number) {

        this.sortIndex = selectedIndex;
        this.localStorage.set('sectorMapSort', this.sortIndex);
        this.getCompanies(this.currentIndustryID);
    }

    
    onShowCompaniesClick() {

        if (!this.currentIndustryID || this.currentIndustryID <= 0)
            return;

        let industry = this.listIndustry.find(industry => industry.code_industry === this.currentIndustryID);

        if (!industry)
            return;

        this.router.navigate(['analysis/sector-map'], { fragment: industry.code_industry.toString() });

        this.showIndustries = false;

        if (this.neadSubscribe(industry.subscribe_level))
            this.router.navigate(['/subscribes'])
        else {
            this.currentIndustryID = industry.code_industry;
            this.nameIndustry = industry.name_industry
            this.getCompanies(this.currentIndustryID);
        }
    }


    onShowIndustryClick() {

        this.router.navigate(['analysis/sector-map'], { fragment: this.currentIndustryID.toString() });

        this.showIndustries = true;
    }

}
