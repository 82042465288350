import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, Tooltip } from 'chart.js';
import { Color } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BubbleMapService } from './../services/bubble.map.service';
import { BubbleMap, BubbleData, ChartData, Regions, Firms, Years } from './../models/bubble.map';
import { R3TargetBinder } from '@angular/compiler';
import { UserService } from "./../services/user.service";
import { Router, ActivatedRoute } from '@angular/router';
import { AnalysisTranslation } from "./../shared/translation.const";
import { TranslationService, TranslateObject } from "./../services/translation.service";
import { IndustryService } from "./../services/industry.service";
import { Industry } from "./../models/home.body";
import { PlatformService } from './../services/platform.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'bubble-map',
    templateUrl: './bubble.map.component.html',
    styleUrls: ['./bubble.map.component.css']
})
  
export class BubbleMapComponent implements OnInit, AfterViewChecked {

    private subscriptionParams: Subscription;
    private startdeFirst: boolean = false;
    private subscriptionFragment: Subscription;
    private currentCompanyID: number = undefined;

    selectedFirm: number = 0;
    listBubbleMap: BubbleMap;

    analisysTranslation = AnalysisTranslation;
   
    listCompanyType: Array<TranslateObject> = [];
    selectedCompanyType: string = "";
    companyType: string = null;

    listIndustry: Array<Industry> = [];
    selectedIndustry: string = "";
    IndustryID: number = null;

    listLimit: Array<TranslateObject> = [];
    selectedLimit: string = "";
    limitID: number = null;
    
    public bubbleChartOptions: ChartOptions = {
        responsive: true,
        tooltips: {
            enabled: false,
/*            
            caretSize: 30,
            backgroundColor: 'lime',
*/
            callbacks: {
/*
                label: function(tooltipItem, data) {
                    console.log("-0 ", tooltipItem);
                    console.log("-1 ", data);
                    return "Можем написать";
                }
*/
            },
        },

        scales: {
            xAxes: [{
                ticks: {
                    display: false,
                    min: 50,
                    max: 1000,
                    maxTicksLimit: 11,
/*                    
                    callback: function(value, index, values) {

                        console.log(value);
                        
                        return 'value is ' + value;
                    }
*/                    
                },
                afterUpdate(scale) {
                }
            }],
            yAxes: [{
                display: false,
                ticks: {
//                    min: -10,
                    max: 110,
                    fontColor: "#364D7B"
                }
            }]
        },

        plugins: {
            datalabels: {
                color: "white",
//                align: "right",
//                offset: 10,
                font: {
                    size: 12
                },
//                textStrokeWidth: 1,
//                textStrokeColor: 'white',

/*
                rotation: 315,
                backgroundColor: "rgba(255, 255, 255, 1)",
                                borderWidth: 4,
                                borderColor: 'yellowgreen',
                                borderRadius: 8,
                */
       
                formatter: (value, ctx) => {
                                    
                    const label = this.listBubbleMap.regions[ctx.datasetIndex].firms[ctx.dataIndex].firm_number;
                    return label;
                },
                                
                /*
                                listeners: {
                                    click: function(context) {
                                      // Receives `click` events only for labels of the first dataset.
                                      // The clicked label index is available in `context.dataIndex`.
                                      console.log('label ' + context.dataIndex + ' has been clicked!');
                                    }
                                }
                */                
            }
        }
    };

    public bubbleChartType: ChartType = 'bubble';
    public bubbleChartLegend = false;
    public bubbleChartPlugins = [pluginDataLabels];
   
    public bubbleChartData: ChartDataSets[] = [
        {
            data: [
            ],
            label: '111',
            borderWidth: 1,
            borderColor: "lime"
        }
    ];

    public bubbleChartColors: Color[] = [];
      

    constructor(private bubbleMapService: BubbleMapService, private userService: UserService, private router: Router, 
                private activatedRoute: ActivatedRoute, private translationService: TranslationService,
                private industryService: IndustryService, public platformService: PlatformService) {

        this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);

        this.subscriptionParams = this.activatedRoute.queryParams.subscribe((queryParam: any) => {
              
            if (queryParam['type']) {
                this.companyType = queryParam['type'];
                this.startdeFirst = true;
            }

            if (queryParam['industry_id']) {
                this.IndustryID = queryParam['industry_id'];
                this.startdeFirst = true;
            }

            if (queryParam['limit']) {
                this.limitID = queryParam['limit'];
                this.startdeFirst = true;
            }

            if (this.startdeFirst)
                this.getBubbleMap();

            setTimeout(() => { 

                if (this.subscriptionParams && !this.subscriptionParams.closed)
                    this.subscriptionParams.unsubscribe();
            }, 0);
   
        });

        this.subscriptionFragment = this.activatedRoute.fragment.subscribe(
            (fragment: string) => {
                this.currentCompanyID = +fragment;
            }
        );

        this.takeCompanyTypeList();
        this.takeIndustryList();
        this.takeLimitList();
        if (!this.startdeFirst)
            this.getBubbleMap();
    }


    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('PAGE_ANALYSIS', this.analisysTranslation);

            this.takeCompanyTypeList();
            this.takeIndustryList();
            this.takeLimitList();
            this.getBubbleMap();
        });
    }


    ngAfterViewChecked() {

        if (this.currentCompanyID) {
            let element = document.getElementById("firm" + this.currentCompanyID.toString());
            if (element) {
                element.scrollIntoView();
                this.currentCompanyID = undefined;
            }

            if (this.subscriptionFragment && !this.subscriptionFragment.closed)
                this.subscriptionFragment.unsubscribe();
        }
    }


    private takeCompanyTypeList() {

        this.listCompanyType = [];
        this.selectedCompanyType = "";

        let obj: TranslateObject = null;

        obj = this.translationService.getTranslateObject('PAGE_ANALYSIS.BUBBLE_MAP.VC_FUNDS.TITLE');
        if (obj)
            this.listCompanyType.push(obj);
        
        obj = this.translationService.getTranslateObject('PAGE_ANALYSIS.BUBBLE_MAP.COMPANIES.TITLE');
        if (obj)
            this.listCompanyType.push(obj);
    
        if (this.listCompanyType.length > 0) {

            if (!this.companyType)
                this.companyType = 'VC';

            switch (this.companyType) {
                case 'VC': {
     
                    this.selectedCompanyType = this.listCompanyType[0].translation;
                }
                break;
        
                case 'PC': {
        
                    this.selectedCompanyType = this.listCompanyType[1].translation;
                }
                break;
            }
        }
    }


    private takeIndustryList() {

        this.listIndustry = [];
        this.selectedIndustry = "";

        let sortingTile: Array<TranslateObject> = [];

        this.translationService.getCategoryObject('PAGE_HOME.INDUSTRY.SORTING_OPTIONS.SORT_BY_NAME', sortingTile);

        if (!sortingTile || sortingTile.length <= 0)
            return;
            
        this.industryService.getIndustries(sortingTile[0].object_id).subscribe(
            response => {
                let json = response;
                if (json) {

                    this.listIndustry = json;

                    let industry: Industry = {
                        code_industry: null, 
                        name_industry: this.analisysTranslation.bubble_map.graph_options.industries.all_industries,
                        category_count_by_subscribe: null,
                        category_count_all: null,
                        code_image_photo: null,
                        code_image: null,
                        company_count_by_subscribe: null,
                        company_count_all: null,
                        is_exists_rounds: null,
                        image_photo_url: null,
                        image_over_url: null,
                        image_url: null,
                        pos_number: null,
                        ptn_index: null,
                        ptn_index_prc: null,
                        ptn_subindex: null,
                        ptn_subindex_prc: null,
                        subscribe_level: null,
                    }
                    this.listIndustry.splice(0, 0, industry);

                    if (!this.IndustryID) {
                        this.IndustryID = null;
                        this.selectedIndustry = this.analisysTranslation.bubble_map.graph_options.industries.all_industries;
                    } else {

                        let indstr: Industry = undefined;
                        indstr = this.listIndustry.find(indstr => indstr.code_industry === Number(this.IndustryID));
                        if (indstr)
                            this.selectedIndustry = indstr.name_industry;
                    }
                }
            },
            error => console.log(error));
    }


    private takeLimitList() {

        this.listLimit = [];
        this.selectedLimit = "";

        this.translationService.getObjectBySubscribe('PAGE_ANALYSIS.BUBBLE_MAP.GRAPH_OPTIONS.IN_REGION_SELECTOR', this.listLimit, this.userService.getUserSubscribeLevel());
        if (this.listLimit && this.listLimit.length > 0) {

            let limit: any = undefined;
            if (this.platformService.handsetPlatform)
                limit = this.listLimit.find(limit => limit.object_name === 'PAGE_ANALYSIS.BUBBLE_MAP.GRAPH_OPTIONS.IN_REGION_SELECTOR.5')
            else
                limit = this.listLimit.find(limit => limit.object_name === 'PAGE_ANALYSIS.BUBBLE_MAP.GRAPH_OPTIONS.IN_REGION_SELECTOR.10');

            if (!this.limitID) {

                if (limit) {
                    this.limitID = limit.sort_index;
                    this.selectedLimit = limit.translation;
                } else {
                    this.limitID = this.listLimit[0].sort_index;
                    this.selectedLimit = this.listLimit[0].translation;
                }
            } else {

                limit = undefined;
                limit = this.listLimit.find(limit => limit.sort_index === Number(this.limitID));
                if (limit)
                    this.selectedLimit = limit.translation;
            }
        }
    }


    private getBubbleMap() {

        if (!this.companyType || !this.limitID)
            return;

        if (this.subscriptionParams && !this.subscriptionParams.closed)
            this.subscriptionParams.unsubscribe();

        this.bubbleMapService.getBubbleMap(this.companyType, this.IndustryID, this.limitID, 1980).subscribe(
            response => {
                let json = response;
                if (json) {

                    this.execNavigate();

                    this.listBubbleMap = json;

                    this.listBubbleMap.regions.sort((a, b) => {
                        if (a.number_region > b.number_region) {
                            return 1;
                        }
                    
                        if (a.number_region < b.number_region) {
                            return -1;
                        }
                    
                        return 0;
                    });
            
                    this.listBubbleMap.years.sort((a, b) => {
                        if (a.sort_index > b.sort_index) {
                            return 1;
                        }
                    
                        if (a.sort_index < b.sort_index) {
                            return -1;
                        }
                    
                        return 0;
                    });
            
                    this.buildMap();
                }
            },
            error => console.log(error));
    }


    private execNavigate() {

        if (this.companyType != "VC" || this.IndustryID || this.limitID) {

            if (this.IndustryID) {

                this.router.navigate([], { queryParams: { "type": this.companyType, "industry_id": this.IndustryID, "limit": this.limitID } });
            } else {
                
                this.router.navigate([], { queryParams: { "type": this.companyType, "limit": this.limitID } });
            }
        } else {
            this.router.navigate([]);
        }
    }


    private buildMap() {

        let firmNumber: number = 0;
        this.bubbleChartData = [];
        this.bubbleChartColors = [];

        let chartData: ChartData;
        let bubbleData: Array<BubbleData> = [];
        let backColor = [];

        for (let regionIndex = 0; regionIndex < this.listBubbleMap.regions.length; regionIndex++) {
            
            bubbleData = [];
            backColor = [];

            for (let companyIndex = 0; companyIndex < this.listBubbleMap.regions[regionIndex].firms.length; companyIndex++) {

                let firm: Firms = this.listBubbleMap.regions[regionIndex].firms[companyIndex];
                let sizBubble: number = 2;

                bubbleData.push( {x: firm.axis_x,
                                y: firm.axis_y,
                                r: firm.bubble_size / sizBubble});

                firmNumber++;
                firm.firm_number = firmNumber;
                firm.size_total_capital = firm.size_total_capital / 1000000;

                backColor.push(firm.bubble_color);
            }

            this.bubbleChartColors.push({backgroundColor: backColor});
            chartData = {
                            data: bubbleData, 
                            label: this.listBubbleMap.regions[regionIndex].region_name
                        };
            this.bubbleChartData.push(chartData);
        }
    }


//    public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    public chartClicked({event, active}: any): void {

        if (!active || active.length <= 0)
            return;

        if (active[0]._index >= 0 && active[0]._datasetIndex >= 0 &&
                this.listBubbleMap.regions.length > active[0]._datasetIndex &&
                this.listBubbleMap.regions[active[0]._datasetIndex].firms.length > active[0]._index) {

            this.router.navigate(['./', this.listBubbleMap.regions[active[0]._datasetIndex].firms[active[0]._index].code_firm], {relativeTo: this.activatedRoute});
        }
    }

    
    public chartHovered({event, active}: any): void {
        
//        console.log("0-", event);
//        console.log(active[0]._index, active[0]._datasetIndex);

        let region: Regions = this.listBubbleMap.regions[active[0]._datasetIndex];
        if (region) {
            let firm: Firms = region.firms[active[0]._index];
            if (firm) {
                this.selectedFirm = firm.firm_number;

                let element = document.getElementById('firm' + firm.firm_number.toString());
                if (element) {
                    element.scrollIntoView();
                }
            }
        }
    }


    getLegendColor(year: Years) {

        if (year) {

            let styles = {
                "background-color": year.bubble_color
            };

            return styles;
        }
    }


    isVisorVisible(): boolean {

        let res = this.userService != undefined && this.userService.user != undefined;

        if (res) {

            if (this.listBubbleMap && this.listBubbleMap.regions && this.listBubbleMap.regions.length > 0) {
                for (let region = 0; region < this.listBubbleMap.regions.length ; region++) {

                    let regions = this.listBubbleMap.regions[region];
                    for (let firm = 0; firm < regions.firms.length; firm++) {

                        if (this.userService.user.subscribe_level < regions.firms[firm].subscribe_level) {
                            return true;
                        }
                    }
                }
            } else
                return false;
        } else
            return true;
    }


    neadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        return (subscribeLevel > userSubsribeLevel);
    }


    onChangeCompanyType(selectedIndex: number) {

        switch (selectedIndex) {
            case 0: {

                this.companyType = 'VC';
                this.IndustryID = null;
            }
            break;

            case 1: {

                this.companyType = 'PC';
            }
            break;
        }

        this.getBubbleMap();
    }


    onChangeIndustries(selectedIndex: number) {

        this.IndustryID = this.listIndustry[selectedIndex].code_industry;
        this.getBubbleMap();
    }


    onChangeLimit(selectedIndex: number) {

        this.limitID = this.listLimit[selectedIndex].sort_index;
        this.getBubbleMap();
    }


    drawBubble(firm: Firms) {

        let styles = {
            "width": firm.bubble_size.toString() + "px",
            "height": firm.bubble_size.toString() + "px",
            "border-radius": (firm.bubble_size / 2).toString() + "px",
            "background-color": firm.bubble_color
        };

        return styles;
    }


    onCompanyClick(codeCompany: number) {

        if (this.companyType != "VC" || this.IndustryID || this.limitID) {

            if (this.IndustryID) {

                this.router.navigate([], { queryParams: { "type": this.companyType, "industry_id": this.IndustryID, "limit": this.limitID }, 
                                                            fragment: codeCompany.toString() });
            } else {
                
                this.router.navigate([], { queryParams: { "type": this.companyType, "limit": this.limitID },
                                                        fragment: codeCompany.toString() });
            }
        } else {
            this.router.navigate([], { fragment: codeCompany.toString() });
        }

        setTimeout(() => { 
            this.router.navigate(['./', codeCompany], {relativeTo: this.activatedRoute});
        }, 1000);

    }
}
