<div class="ptn-registration" *ngIf="!platformService.handsetPlatform">

    <form novalidate [formGroup]="registerForm" (ngSubmit)="onSubmit()">
  
        <div class="form-group">
            <label for="email">{{allTranslation.email.field}}</label>

            <input type="text" #email class="form-control" (change)="onChange('email')" id="email" formControlName="email" required 
                        [ngClass]="controlValid.email" placeholder={{allTranslation.email.field}} />

            <div *ngIf="controlErrors.email" class="error-message">
                {{ controlErrors.email }}
            </div>
            <div *ngIf="!controlErrors.email">
                &nbsp;
            </div>
        </div>

        <div class="double-group">
            <div class="group_div">
                <div class="double">
                    <div class="form-group">
                        <label for="password">{{allTranslation.password.field}}</label>
                        <div class="password-group">
                            <input type="password" [type]="hide ? 'password' : 'text'" class="password form-control" id="password" formControlName="password" #password
                                        (change)="onChange('password')" placeholder={{allTranslation.password.placeholder}}
                                        [ngClass]="controlValid.password" #password/>
                            <img class="eye" *ngIf="hide && password.value" src="/assets/imgs/eye-open.svg" (click)="onEyeClick()">
                            <img class="eye" *ngIf="!hide && password.value" src="/assets/imgs/eye-close.svg" (click)="onEyeClick()">
                            <a title="{{allTranslation.password_policy.hint}}">?</a>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="confirm_password">{{allTranslation.confirm_password.field}}</label>
                        <input type="password" class="password form-control" id="confir_mpassword" required formControlName="confirm_password" #confirm_password
                                    (change)="onChange('confirm_password')" placeholder={{allTranslation.confirm_password.placeholder}}
                                    [ngClass]="controlValid.confirm_password" />
                    </div>
                </div>

                <div *ngIf="controlErrors.password || controlErrors.confirm_password" class="error-message">
                    {{ controlErrors.password }} {{ controlErrors.confirm_password }}
                </div>
                <div class="error-message" *ngIf="!controlErrors.password && !controlErrors.confirm_password">
                    &nbsp;
                </div>
            </div>
            
            <div class="group_div">
                <div class="double">
                    <div class="form-group">
                        <label for="first_name" >{{allTranslation.first_name.field}}</label>
                        <input type="text" class="form-control" id="first_name" required formControlName="first_name"
                                    (change)="onChange('first_name')" placeholder={{allTranslation.first_name.placeholder}} 
                                    [ngClass]="controlValid.first_name"/>
                    </div>

                    <div class="form-group">
                        <label for="last_name" >{{allTranslation.last_name.field}}</label>
                        <input type="text" class="form-control" id="last_name" required formControlName="last_name"
                                    (change)="onChange('last_name')" placeholder={{allTranslation.last_name.placeholder}}
                                    [ngClass]="controlValid.last_name">
                    </div>
                </div>

                <div *ngIf="controlErrors.first_name || controlErrors.last_name" class="error-message">
                    {{ controlErrors.first_name }} {{ controlErrors.last_name }}
                </div>
                <div *ngIf="!controlErrors.first_name && !controlErrors.last_name">
                    &nbsp;
                </div>
            </div>

            <div class="group_div">
                <div class="double">
                    <div class="form-group">
                        <label for="company_name" >{{allTranslation.company.field}}</label>
                        <input type="text" class="form-control" id="company_name" required formControlName="company_name"
                                    (change)="onChange('company')" placeholder={{allTranslation.company.placeholder}}
                                    [ngClass]="controlValid.company_name">
                    </div>

                    <div class="form-group">
                        <label for="title" >{{allTranslation.title.field}}</label>
                        <input type="text" class="form-control" id="title" formControlName="title" 
                                    (change)="onChange('title')" placeholder={{allTranslation.title.placeholder}}
                                    [ngClass]="controlValid.title">
                    </div>
                </div>

                <div *ngIf="controlErrors.company_name || controlErrors.title" class="error-message">
                    {{ controlErrors.company_name }} {{ controlErrors.title }}
                </div>
                <div *ngIf="!controlErrors.company_name && !controlErrors.title">
                    &nbsp;
                </div>
            </div>
        </div>

        <div class="div-button">
            <button type="submit" [disabled]="!registerForm.valid || controlValid.password.notValid || controlValid.confirm_password.notValid">{{allTranslation.signup.button}}</button>
        </div>

    </form>
</div>


<!-- Mobile -->

<div class="mob-ptn-registration" *ngIf="platformService.handsetPlatform">
    <form novalidate [formGroup]="registerForm" (ngSubmit)="onSubmit()">
  
        <div class="mob-form-group">
            <label for="email">{{allTranslation.email.field}}</label>

            <input type="text" #email class="form-control" (change)="onChange('email')" id="email" formControlName="email" required 
                        [ngClass]="controlValid.email" placeholder={{allTranslation.email.field}} />

            <div class="mob-error-message" *ngIf="controlErrors.email">
                {{ controlErrors.email }}
            </div>
            <div *ngIf="!controlErrors.email">
                &nbsp;
            </div>
        </div>

        <div class="mob-form-group">
            <label for="password">{{allTranslation.password.field}}</label>

            <div class="password-group">
                <input type="password" [type]="hide ? 'password' : 'text'" class="password form-control" id="password" formControlName="password" #password
                            (change)="onChange('password')" placeholder={{allTranslation.password.placeholder}}
                            [ngClass]="controlValid.password" #password/>
                <img class="eye" *ngIf="hide && password.value" src="/assets/imgs/eye-open.svg" (click)="onEyeClick()">
                <img class="eye" *ngIf="!hide && password.value" src="/assets/imgs/eye-close.svg" (click)="onEyeClick()">
            </div>

            <div  class="mob-error-message" *ngIf="controlErrors.password">
                {{ controlErrors.password }}
            </div>
            <div class="mob-error-message" *ngIf="!controlErrors.password">
                &nbsp;
            </div>
        </div>

        <div class="mob-form-group">
            <label for="confirm_password">{{allTranslation.confirm_password.field}}</label>
            <input type="password" class="password form-control" id="confir_mpassword" required formControlName="confirm_password" #confirm_password
                        (change)="onChange('confirm_password')" placeholder={{allTranslation.confirm_password.placeholder}}
                        [ngClass]="controlValid.confirm_password" />

            <div  class="mob-error-message" *ngIf="controlErrors.confirm_password">
                {{ controlErrors.confirm_password }}
            </div>
            <div class="mob-error-message" *ngIf="!controlErrors.confirm_password">
                &nbsp;
            </div>
           
        </div>

        <div class="mob-form-group">
            <label for="first_name" >{{allTranslation.first_name.field}}</label>
            <input type="text" class="form-control" id="first_name" required formControlName="first_name"
                        (change)="onChange('first_name')" placeholder={{allTranslation.first_name.placeholder}} 
                        [ngClass]="controlValid.first_name"/>

            <div class="mob-error-message" *ngIf="controlErrors.first_name">
                {{ controlErrors.first_name }}
            </div>
            <div *ngIf="!controlErrors.first_name">
                &nbsp;
            </div>
        </div>

        <div class="mob-form-group">
            <label for="last_name" >{{allTranslation.last_name.field}}</label>
            <input type="text" class="form-control" id="last_name" required formControlName="last_name"
                        (change)="onChange('last_name')" placeholder={{allTranslation.last_name.placeholder}}
                        [ngClass]="controlValid.last_name">

            <div class="mob-error-message" *ngIf="controlErrors.last_name">
                {{ controlErrors.last_name }}
            </div>
            <div *ngIf="!controlErrors.last_name">
                &nbsp;
            </div>
        </div>

        <div class="mob-form-group">
            <label for="company_name" >{{allTranslation.company.field}}</label>
            <input type="text" class="form-control" id="company_name" required formControlName="company_name"
                        (change)="onChange('company')" placeholder={{allTranslation.company.placeholder}}
                        [ngClass]="controlValid.company_name">
        </div>

        <div class="mob-form-group">
            <label for="title" >{{allTranslation.title.field}}</label>
            <input type="text" class="form-control" id="title" formControlName="title" 
                        (change)="onChange('title')" placeholder={{allTranslation.title.placeholder}}
                        [ngClass]="controlValid.title">
        </div>

        <div class="div-button">
            <button type="submit" [disabled]="!registerForm.valid || controlValid.password.notValid || controlValid.confirm_password.notValid">{{allTranslation.signup.button}}</button>
        </div>

    </form>
</div>
