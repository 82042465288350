import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslationService } from '../services/translation.service';
import { HeaderTranslation, ListTranslation } from "../shared/translation.const";
import { langPAGE_LIST } from "../shared/constant.module";
import { getFormError } from "../shared/helper.function";
import { ListService } from '../services/list.service';
import { FileInputComponent } from 'ngx-material-file-input';
import { ErrorService } from '../services/error.service';
import { ListData } from '../models/user';
import { UserService } from '../services/user.service';
import { PresentationPresignInfo } from '../models/file';
import { MatDialog } from '@angular/material/dialog';
import { DialogInfoComponent } from '../shared/dialog-info/dialog-info.component';
import { PlatformService } from '../services/platform.service';

@Component({
  selector: 'ptn-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @ViewChild(FileInputComponent)
  private presentationInputComponent: FileInputComponent;

  listTranslation = ListTranslation;

  listForm: FormGroup;
  first_name: FormControl;
  last_name: FormControl;
  email: FormControl;
  title: FormControl;
  company: FormControl;
  website: FormControl;
  presentation: FormControl;

  errorMessage: string;
  
  get isListFormInvalid(): boolean {    
    return this.listForm.invalid || (!this.presentationInputComponent?.empty && !this.isUploadSuccess);
  }

  private isUploadSuccess: boolean;

  filePresentation: File;
  presentationPresignInfo: PresentationPresignInfo;

  controlErrors = {
    last_name: '',
    first_name: '',
    email: '',
    title: '',
    company: '',
    website: '',
    presentation: ''
  }

  showDescription: boolean = false;

  constructor(private translationService: TranslationService, private listServise: ListService, private errorService: ErrorService, private userService: UserService,
    public dialog: MatDialog, public platformService: PlatformService) { 
    this.translationService.getTranslate(langPAGE_LIST, this.listTranslation);
  }

  ngOnInit(): void {
    this.translationService.languageChanged$.subscribe((changeObj) => {
      this.translationService.getTranslate(langPAGE_LIST, this.listTranslation);
    });

    this.createFormControls();
    this.createForm();

    this.first_name.setValue(this.userService.user.first_name);
    this.last_name.setValue(this.userService.user.last_name);
    this.email.setValue(this.userService.user.email);
  }

  createFormControls(): void {
    this.first_name = new FormControl('', Validators.required);
    this.last_name = new FormControl('', Validators.required);
    this.email = new FormControl('', Validators.required);
    this.title = new FormControl('', Validators.required);
    this.company = new FormControl('', Validators.required);
    this.website = new FormControl('', Validators.required);
    this.presentation = new FormControl('');
  }

  createForm(): void {
    this.listForm = new FormGroup({
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      title: this.title,
      company: this.company,
      website: this.website,
      presentation: this.presentation
    });
  }

  onSubmit(): void {
    if (!this.isListFormInvalid) {
      console.log('Submit');
      this.errorMessage = null;

      let listData: ListData = {
        first_name: this.first_name.value,
        last_name: this.last_name.value,
        email: this.email.value,
        title: this.title.value,
        company: this.company.value,
        website: this.website.value,
        presentation_url: this.presentationPresignInfo ? this.presentationPresignInfo.s3PresignedUrl : null,
        presentation_file_orig: this.presentationPresignInfo ? this.presentationPresignInfo.generatedFileName : null
      };

      console.log(listData);
      this.listServise.saveListData(listData).subscribe(() => {
        console.log('saveListData is Ok');
        this.clearForm();
      }, 
      (error) => {
        this.errorMessage = this.errorService.getErrorPTNMessage(error);
        console.log(this.errorMessage);
      });
    }
  }

  onBlur() {
    if (this.listForm){
      this.errorMessage = this.getErrorMessage();
    }
  }

  private getErrorMessage(): string {
    let errMsg = getFormError(this.listForm, this.controlErrors, this.listTranslation);
    if (this.filePresentation && this.filePresentation.name.split('.').pop().toLowerCase() != 'pdf') {
      this.presentationInputComponent?.clear();
      this.clearPresentationFile();
      if (!errMsg) {
        errMsg = this.listTranslation.presentation.error.mustmatch;
      }
    }
    return errMsg;
  }

  private clearPresentationFile(): void {
    this.isUploadSuccess = false;
    this.filePresentation = null;
    this.presentationPresignInfo = null;
  }

  onFileChange(files: FileList) {
    this.isUploadSuccess = false;
    if (files.length == 0) {
      this.clearPresentationFile();
    }
    else {
      console.log(`${files[0].name} (${files[0].size}) type: ${files[0].type}`);
      this.filePresentation = files[0];
      this.listServise.getS3Url(files[0].name).subscribe((presentPresignInfo: PresentationPresignInfo) => {
        this.presentationPresignInfo = presentPresignInfo;
        console.log(this.presentationPresignInfo);
      });
    }
    this.errorMessage = this.getErrorMessage();
  }

  onClearPresentationInput(event?: any) {
    this.presentationInputComponent.clear(event);
    this.clearPresentationFile();
    this.errorMessage = this.getErrorMessage();
  }

  onUploadFilePresentation(event: any) {
    console.log('onUploadFilePresentation');
    console.log(event.file);
    console.log(event.event ? event.event : '');
  }

  onUploadFilePresentationComplete(isSuccess: boolean) {
    console.log('onUploadFilePresentationComplete');
    this.isUploadSuccess = isSuccess;
    console.log(`isSuccess: ${this.isUploadSuccess}`);
  }

  private clearForm(): void {
    for (const fieldName in this.listForm.controls) {
      this.listForm.controls[fieldName].setValue(null);
      this.listForm.controls[fieldName].markAsUntouched();
    } 
    this.presentationInputComponent?.clear();
    this.clearPresentationFile();
    this.dialog.open(DialogInfoComponent, {
      width: '350px',
      data: { title: this.listTranslation.list, info: this.listTranslation.add.message, ok_label: 'Ok' }
    });
  }
}
