import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PtnAppConfig } from './../shared/ptn-app-config';
import { map, finalize, catchError } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { TranslationService, TranslateObject } from './translation.service';
import { FilterParams, FilterPTN } from '../models/filter';
import { SessionStorageService } from './session-storage-service';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable()
export class FilterService {

    private url = PtnAppConfig.url_v2;
    private offsetList: number = undefined;

    filterParamsInput: FilterParams = {};

    constructor(private http: HttpClient, private loaderService: LoaderService, private translationService: TranslationService, private sessionStorageService: SessionStorageService, private userService: UserService,
            private router: Router) {}

    filterFirms(typeFirm: string, isInit: boolean, sortObjectId: number, offset: number, limit: number): Observable<FilterPTN> {
        let myHeaders = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-session": this.userService.x_vms_session,
            "x-vms-lang": this.translationService.currentLanguage
        });

        this.loaderService.startLoading();
        return this.http.post<FilterPTN>(`${this.url}/firm/search/${typeFirm}?sort_object_id=${sortObjectId}&offset=${offset}&limit=${limit}`, this.filterParamsInput, { headers: myHeaders }).pipe(finalize(this.loaderService.finishLoading)).pipe(map((fltPtn: FilterPTN) => {
            console.log('Filter is succeded');
            console.log(fltPtn);
            let filterPTN: FilterPTN = fltPtn;            
            if (offset == 0) {
                if (isInit) {
                    if (Object.keys(this.filterParamsInput).length === 0) {
                        filterPTN.filter_params.EXIT_STATUS.forEach((item) => item.checked = false);
                        filterPTN.filter_params.REGION.forEach((item) => item.checked = false);
                        filterPTN.filter_params.COUNTRY.forEach((item) => item.checked = false);
                        filterPTN.filter_params.NUMBER_OF_EMPLOEES.forEach((item) => item.checked = false);
                        filterPTN.filter_params.INDUSTRY.forEach((item) => item.checked = false);
                        filterPTN.filter_params.CATEGORY.forEach((item) => item.checked = false);
                        filterPTN.filter_params.INVESTMENT_STAGE.forEach((item) => item.checked = false);
                        filterPTN.filter_params.DEAL_SIZE.forEach((item) => item.checked = false);
                        filterPTN.filter_params.IS_SEND_MESSAGE.forEach((item) => item.checked = false);
                    }
                    else {
                        filterPTN.filter_params.EXIT_STATUS.forEach((item) => item.checked = this.filterParamsInput.EXIT_STATUS?.find((itemInput) => itemInput.code_param == item.code_param) ? true : false);
                        filterPTN.filter_params.REGION.forEach((item) => item.checked = this.filterParamsInput.REGION?.find((itemInput) => itemInput.code_param == item.code_param) ? true : false);
                        filterPTN.filter_params.COUNTRY.forEach((item) => item.checked = this.filterParamsInput.COUNTRY?.find((itemInput) => itemInput.code_param == item.code_param) ? true : false);
                        filterPTN.filter_params.NUMBER_OF_EMPLOEES.forEach((item) => item.checked = this.filterParamsInput.NUMBER_OF_EMPLOEES?.find((itemInput) => itemInput.code_param == item.code_param) ? true : false);
                        filterPTN.filter_params.INDUSTRY.forEach((item) => item.checked = this.filterParamsInput.INDUSTRY?.find((itemInput) => itemInput.code_param == item.code_param) ? true : false);
                        filterPTN.filter_params.CATEGORY.forEach((item) => item.checked = this.filterParamsInput.CATEGORY?.find((itemInput) => itemInput.code_param == item.code_param) ? true : false);
                        filterPTN.filter_params.INVESTMENT_STAGE.forEach((item) => item.checked = this.filterParamsInput.INVESTMENT_STAGE?.find((itemInput) => itemInput.code_param == item.code_param) ? true : false);
                        filterPTN.filter_params.DEAL_SIZE.forEach((item) => item.checked = this.filterParamsInput.DEAL_SIZE?.find((itemInput) => itemInput.code_param == item.code_param) ? true : false);
                        filterPTN.filter_params.IS_SEND_MESSAGE.forEach((item) => item.checked = this.filterParamsInput.IS_SEND_MESSAGE?.find((itemInput) => itemInput.code_param == item.code_param) ? true : false);
                    }

                    if (fltPtn.filter_params.FOUNDED_YEAR.value_min != null) {
                        filterPTN.filter_params.FOUNDED_YEAR.options = { stepsArray: fltPtn.filter_params.FOUNDED_YEAR.stepsArray.map(item => { return {value: item} }) };
                        // if (this.filterParamsInput?.FOUNDED_YEAR?.value_min != null) {
                        //     filterPTN.filter_params.FOUNDED_YEAR.value_min = this.filterParamsInput.FOUNDED_YEAR.value_min;
                        //     filterPTN.filter_params.FOUNDED_YEAR.value_max = this.filterParamsInput.FOUNDED_YEAR.value_max;
                        // }
                    }
                    if (fltPtn.filter_params.TOTAL_FUNDING_AMOUNT.value_min != null) {
                        filterPTN.filter_params.TOTAL_FUNDING_AMOUNT.options = { stepsArray: fltPtn.filter_params.TOTAL_FUNDING_AMOUNT.stepsArray.map(item => { return {value: item} }), translate: this.translateValue};
                        // if (this.filterParamsInput?.TOTAL_FUNDING_AMOUNT?.value_min != null) {
                        //     filterPTN.filter_params.TOTAL_FUNDING_AMOUNT.value_min = this.filterParamsInput.TOTAL_FUNDING_AMOUNT.value_min;
                        //     filterPTN.filter_params.TOTAL_FUNDING_AMOUNT.value_max = this.filterParamsInput.TOTAL_FUNDING_AMOUNT.value_max;
                        // }
                    }
                    if (fltPtn.filter_params.VALUATION.value_min != null) {
                        filterPTN.filter_params.VALUATION.options = { stepsArray: fltPtn.filter_params.VALUATION.stepsArray.map(item => { return {value: item} }), translate: this.translateValue};
                        // if (this.filterParamsInput?.VALUATION?.value_min != null) {
                        //     filterPTN.filter_params.VALUATION.value_min = this.filterParamsInput.VALUATION.value_min;
                        //     filterPTN.filter_params.VALUATION.value_max = this.filterParamsInput.VALUATION.value_max;
                        // }
                    }
                    if (fltPtn.filter_params.TOTAL_CAPITAL.value_min != null) {
                        filterPTN.filter_params.TOTAL_CAPITAL.options = { stepsArray: fltPtn.filter_params.TOTAL_CAPITAL.stepsArray.map(item => { return {value: item} }), translate: this.translateValue};                        
                        // if (this.filterParamsInput?.TOTAL_CAPITAL?.value_min != null) {
                        //     filterPTN.filter_params.TOTAL_CAPITAL.value_min = this.filterParamsInput.TOTAL_CAPITAL.value_min;
                        //     filterPTN.filter_params.TOTAL_CAPITAL.value_max = this.filterParamsInput.TOTAL_CAPITAL.value_max;
                        // }
                    }
                    if (fltPtn.filter_params.INNOVATION_INDEX.value_min != null) {
                        filterPTN.filter_params.INNOVATION_INDEX.options = { stepsArray: fltPtn.filter_params.INNOVATION_INDEX.stepsArray.map(item => { return {value: item} }) };
                        // if (this.filterParamsInput?.INNOVATION_INDEX?.value_min != null) {
                        //     filterPTN.filter_params.INNOVATION_INDEX.value_min = this.filterParamsInput.INNOVATION_INDEX.value_min;
                        //     filterPTN.filter_params.INNOVATION_INDEX.value_max = this.filterParamsInput.INNOVATION_INDEX.value_max;
                        // }
                    }
                }
                switch (typeFirm) {
                    case 'company':
                        this.sessionStorageService.filterCompaniesParamsInput = this.filterParamsInput;
                        break;
                    case 'fund':
                        this.sessionStorageService.filterFundsParamsInput = this.filterParamsInput;
                        break;
                    case 'private-investor':
                        this.sessionStorageService.filterInvestorsParamsInput = this.filterParamsInput;
                        break;
                    default:
                        break;
                }
            }
            filterPTN.filter_firms.forEach((item) => {
                item.name_investment_stage_str = item.name_investment_stage.map((item => item.name_investment_stage)).join(', ');
                if (item.total_funding_amount === 0) {
                    item.total_funding_amount = null;
                }
                if (item.total_capital === 0) {
                    item.total_capital = null;
                }
                if (item.name_country == null) {
                    item.name_country = '-';
                }
            });
            console.log(filterPTN);
            return filterPTN;
        }));
    }

    setFilterParamInput(filterParams: FilterParams, filterTranslation: any): void {        
        let fltPrmInput: FilterParams = {
            INDUSTRY: filterParams.INDUSTRY.filter(item => item.checked),
            CATEGORY: filterParams.CATEGORY.filter(item => item.checked),
            REGION: filterParams.REGION.filter(item => item.checked),
            COUNTRY: filterParams.COUNTRY.filter(item => item.checked),
            NUMBER_OF_EMPLOEES: filterParams.NUMBER_OF_EMPLOEES.filter(item => item.checked),
            EXIT_STATUS: filterParams.EXIT_STATUS.filter(item => item.checked),
            INVESTMENT_STAGE: filterParams.INVESTMENT_STAGE.filter(item => item.checked),
            DEAL_SIZE: filterParams.DEAL_SIZE.filter(item => item.checked),
            IS_SEND_MESSAGE: filterParams.IS_SEND_MESSAGE.filter(item => item.checked),
            FOUNDED_YEAR: filterTranslation.filters.filter.title.founded_year && filterParams.FOUNDED_YEAR.value_min != null ? filterParams.FOUNDED_YEAR : null,
            TOTAL_FUNDING_AMOUNT: filterTranslation.filters.filter.title.total_funding_amount && filterParams.TOTAL_FUNDING_AMOUNT.value_min != null ? filterParams.TOTAL_FUNDING_AMOUNT : null,
            VALUATION: filterTranslation.filters.filter.title.valuation && filterParams.VALUATION.value_min != null ? filterParams.VALUATION : null,
            TOTAL_CAPITAL: filterTranslation.filters.filter.title.total_capital && filterParams.TOTAL_CAPITAL.value_min != null ? filterParams.TOTAL_CAPITAL : null,
            INNOVATION_INDEX: filterTranslation.filters.filter.title.innovation_index && filterParams.INNOVATION_INDEX.value_min != null ? filterParams.INNOVATION_INDEX : null
        };

        this.filterParamsInput = {};
        fltPrmInput = JSON.parse(JSON.stringify(fltPrmInput));
        for (let field of Object.keys(fltPrmInput)) {
            if (Array.isArray(fltPrmInput[field])) {
                if (fltPrmInput[field].length > 0) {
                    this.filterParamsInput[field] = fltPrmInput[field];
                    this.filterParamsInput[field].forEach((item) => {
                        delete item.checked;
                        delete item.count_lines;
                        delete item.name_param;
                        delete item.sort_index;
                    });
                }
            }
            else if (fltPrmInput[field]) {
                this.filterParamsInput[field] = fltPrmInput[field];
                if (this.filterParamsInput[field].options) {
                    delete this.filterParamsInput[field].options;
                }
                if (this.filterParamsInput[field].stepsArray) {
                    delete this.filterParamsInput[field].stepsArray;
                }
            }            
        }
        
        setTimeout(() => {

            if (this.offsetList && this.offsetList > 0) 
                window.location.search = `?filter=${encodeURIComponent(JSON.stringify(this.filterParamsInput))}&offset=` + this.offsetList.toString()
            else
                window.location.search = `?filter=${encodeURIComponent(JSON.stringify(this.filterParamsInput))}`;
        }, 200);
    }

    setOffsetParam(offset: number) {
        this.offsetList = offset;

        this.router.navigate([], { queryParams: {"offset": this.offsetList, "filter": encodeURIComponent(JSON.stringify(this.filterParamsInput))} });
    }


    clearFilterParamInput(): void {
        this.filterParamsInput = {};
        window.location.search = '';
    }

    private translateValue(value: number): string {
        if (isNaN(parseFloat('' + value)) || !isFinite(value)) return '-';
        if (value <= 0) return '0';
        let units = ['', 'k', 'm', 'b'],
            number = Math.floor(Math.log(value) / Math.log(1000));
        return (value / Math.pow(1000, Math.floor(number))).toFixed(1) + ' ' + units[number];
    }


    getMessageSubscribeLavel(typeFirm: string): number {

        let sendMessageCompany: Array<TranslateObject> = [];
        let sendMessageFunds: Array<TranslateObject> = [];
        let sendMessagePrivat: Array<TranslateObject> = [];
    
        this.translationService.getCategoryObject('PAGE_COMPANIES.BUTTON.MESSAGE', sendMessageCompany);
        this.translationService.getCategoryObject('PAGE_VC_FUNDS.BUTTON.MESSAGE', sendMessageFunds);
        this.translationService.getCategoryObject('PAGE_PRIV_INVESTORS.BUTTON.MESSAGE', sendMessagePrivat);

        switch (typeFirm) {
    
          case 'company': {
    
            if (sendMessageCompany.length > 0)
              return sendMessageCompany[0].subscribe_level;
          }
          break;
    
          case 'fund': {
           
            if (sendMessageFunds.length > 0)
                return sendMessageFunds[0].subscribe_level;
          }
          break;
    
          case 'private-investor': {
    
            if (sendMessagePrivat.length > 0)
                return sendMessagePrivat[0].subscribe_level;
          }
          break;
    
        }
      }
    
}