<div class="bubble-map" *ngIf="!platformService.handsetPlatform">
    <div class="main">

        <div class="body-header">
            <select class="company-type" [(ngModel)]="selectedCompanyType" #compType (change)="onChangeCompanyType(compType.selectedIndex)" title="Company type">
                <option *ngFor="let compType of listCompanyType">{{compType.translation}}</option>
            </select>

            <select class="industries" *ngIf="companyType == 'PC'" [(ngModel)]="selectedIndustry" #industries (change)="onChangeIndustries(industries.selectedIndex)" title="Industries">
                <option *ngFor="let industry of listIndustry">{{industry.name_industry}}</option>
            </select>
            <div *ngIf="companyType != 'PC'"></div>

            <span>{{analisysTranslation.bubble_map.diagram.title}}</span>

            <select class="company-limit" [(ngModel)]="selectedLimit" #companyLimit (change)="onChangeLimit(companyLimit.selectedIndex)" title="Limit">
                <option *ngFor="let limit of listLimit">{{limit.translation}}</option>
            </select>
        </div>

        <div class="body-body">
            <div class="companies">
                <div *ngIf="listBubbleMap">
                    <div class="firm-region" *ngFor="let region of listBubbleMap.regions">
                        <div class="firm" id=firm{{firm.code_firm}} *ngFor="let firm of region.firms" [ngClass]="{ selectedFirm: selectedFirm == firm.firm_number }">
                            <a [routerLink]="" (click)="onCompanyClick(firm.code_firm)">
<!--                            
                            <a [routerLink]="['./', firm.code_firm]">
-->                                
                                <span class="firm-number">{{firm.firm_number}}</span>
                                <div class="logo" [ngClass]="{ firmBlured: neadSubscribe(firm.subscribe_level) }">
                                    <img src="{{firm.image_url}}">
                                    <div class="logo-detail">
                                        <span class="firm-name">{{firm.name_firm}}</span>
                                        <span>{{region.region_name}}, {{firm.founded_year}}, {{firm.size_total_capital | currency : '' : 'symbol' : '1.0'}} M</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-body">
                <div class="bubble-chart">
                    <div class="legend" *ngIf="listBubbleMap">
                        <div class="legend-year" *ngFor="let year of listBubbleMap.years">
                            <div class="year" [ngStyle]="getLegendColor(year)"></div>
                            <span>{{year.name_year}}</span>
                        </div>
                    </div>

                    <div class="chart">
                        <canvas baseChart
                        [datasets]="bubbleChartData"
                        [options]="bubbleChartOptions"
                        [colors]="bubbleChartColors"
                        [legend]="bubbleChartLegend"
                        [chartType]="bubbleChartType"
                        [plugins]="bubbleChartPlugins"
                        (chartClick)="chartClicked($event)"
                        (chartHover)="chartHovered($event)">
                        </canvas>
                    </div>
                   
                    <div class="regions" *ngIf="listBubbleMap">
                        <span *ngFor="let region of listBubbleMap.regions">{{region.region_name}}</span>
                    </div>
                </div>
            </div>
            <ptn-visor *ngIf="isVisorVisible()"></ptn-visor>
        </div>        
    </div>
</div>


<!-- Mobile -->

<div class="mob-bubble-map" *ngIf="platformService.handsetPlatform">

    <div class="mob-header">

        <select class="mob-company-type" [(ngModel)]="selectedCompanyType" #compType (change)="onChangeCompanyType(compType.selectedIndex)" title="Company type">
            <option *ngFor="let compType of listCompanyType">{{compType.translation}}</option>
        </select>

        <select class="mob-company-limit" [(ngModel)]="selectedLimit" #companyLimit (change)="onChangeLimit(companyLimit.selectedIndex)" title="Limit">
            <option *ngFor="let limit of listLimit">{{limit.translation}}</option>
        </select>

    </div>

    <div class="mob-header" *ngIf="companyType == 'PC'">
        <select class="industries" [(ngModel)]="selectedIndustry" #industries (change)="onChangeIndustries(industries.selectedIndex)" title="Industries">
            <option *ngFor="let industry of listIndustry">{{industry.name_industry}}</option>
        </select>
    </div>

    <div class="mob-companies">
        <div *ngIf="listBubbleMap">
            <div class="mob-firm-region" *ngFor="let region of listBubbleMap.regions">
                <div class="mob-firm" id=firm{{firm.code_firm}} *ngFor="let firm of region.firms" [ngClass]="{ selectedFirm: selectedFirm == firm.firm_number }">
                    <a [routerLink]="" (click)="onCompanyClick(firm.code_firm)">
                        <div class="firm-bubble">
                            <div class="mob-bubble" [ngStyle]="drawBubble(firm)">
                                {{firm.firm_number}}
                            </div>
                        </div>

                        <div class="mob-logo" [ngClass]="{ firmBlured: neadSubscribe(firm.subscribe_level) }">
                            <div class="mob-logo-detail">
                                <span class="firm-name">{{firm.name_firm}}</span>
                                <span>{{region.region_name}}, {{firm.founded_year}}, {{firm.size_total_capital | currency : '' : 'symbol' : '1.0'}} M</span>
                            </div>
                            <img src="{{firm.image_url}}">
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <ptn-visor *ngIf="isVisorVisible()" [ngClass]="{ mobVisor: true }"></ptn-visor>

</div>
