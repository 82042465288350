import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './../../services/user.service';
import { DontRecoveryPassword } from './../../models/user';
import { DeviceInfoService } from './../../services/device-info.service';
import { TranslationService } from './../../services/translation.service';
import { PasswordNotRecoveryTranslation } from './../../shared/translation.const';
import { ErrorService } from './../../services/error.service';


@Component({
  selector: 'dont-recovery',
  templateUrl: './dont.recovery.password.component.html',
  styleUrls: ['./dont.recovery.password.component.css'],
})

export class DontRecoveryPasswordComponent implements OnInit {


    translation = PasswordNotRecoveryTranslation;

    constructor(private activatedRoute: ActivatedRoute, private userService: UserService,
                private deviceInfoService: DeviceInfoService, private translationService: TranslationService,
                private router: Router, private errorService: ErrorService) {

        this.translationService.getTranslate('PAGE_PASS_RECOVERY_NOT_REQUEST', this.translation);
    }


    ngOnInit() {

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('PAGE_PASS_RECOVERY_NOT_REQUEST', this.translation);
        });

        this.activatedRoute.queryParams.subscribe((queryParam: any) => {
             
            let queryUUID = queryParam['uuid'];
            if (queryUUID) {
            
                this.deviceInfoService.getDeviceInfo().subscribe((devInfo) => {
            
                    let dontRecoveryPassword: DontRecoveryPassword = {
                        uuid: queryUUID,
                        device_data: devInfo,
                        language_id: this.translationService.currentLanguage
                    };

                    this.userService.dontRecoveryPassword(dontRecoveryPassword).subscribe(
                        response => { 
                            console.log(response);
//                            this.router.navigate(['/']);
                        },
                        error => {
                            console.log(error);
                            let errorStr = this.errorService.getErrorPTNMessage(error);
                            if (errorStr != 'undefined')
                                alert(errorStr);
                        }
                    );
                });                
            }
        });
        
    }

}
