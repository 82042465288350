import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PersonData } from 'src/app/models/person.data';
import { SocialNetwork } from "./../../models/social.network";
import { CompanyTranslation } from "./../translation.const";
import { TranslationService } from "./../../services/translation.service";
import { langPAGE_HOME } from "./../constant.module";
import { UserService } from "./../../services/user.service";
import { WebAddressService } from "./../../services/web.address.service";
import { PersonService } from "./../../services/person.service";
import { ErrorService } from "./../../services/error.service";
import { PlatformService } from './../../services/platform.service';
import { ActivatedRoute } from '@angular/router';
import { RouterHistoryService } from './../../services/router-history.service';


@Component({
    selector: 'ptn-person-card',
    templateUrl: './person.card.short.component.html',
    styleUrls: ['./person.card.short.component.css']
})
  
export class PersonCardShortComponent implements OnInit {

    companyTranslation = CompanyTranslation;
    person: PersonData;
    
    @Input() personID: number = 0;
    @Output() onClosePersonCard = new EventEmitter<boolean>();
    @Output() onPersonCardBusy = new EventEmitter<boolean>();

    private previousUrl: string;


    constructor(private translationService: TranslationService, private userService: UserService,
            private webAddressService: WebAddressService, private personService: PersonService,
            private errorService: ErrorService, public platformService: PlatformService,
            private activatedRoute: ActivatedRoute, private routerHistoryService: RouterHistoryService) {

        this.translationService.getTranslate(langPAGE_HOME, this.companyTranslation);
        this.clearPerson();
    }


    ngOnInit() {

        this.activatedRoute.queryParams.subscribe((queryParam: any) => {
              
            if (queryParam['person_id'])
                this.personID = queryParam['person_id'];
        });

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate(langPAGE_HOME, this.companyTranslation);
        });

        this.getPerson();

        this.onPersonCardBusy.emit(true);

        this.previousUrl = this.routerHistoryService.previousUrl$.value || '/';
        if (this.previousUrl.indexOf("person-card") >= 1) {
            this.previousUrl = "";
        }

    }


    private getPerson() {

        if (this.personService.person && this.personService.person.code_person == this.personID) {

            this.person = this.personService.person;
            return;
        }

        if (this.personID <= 0)
            return;

        if (this.person)
            this.clearPerson();

        this.personService.getPerson(this.personID).subscribe(
            response => {
                let json = response;

                if (json) {
                    this.person = json;
                    this.prepareWeb();
                    this.personService.person = this.person;
                }
            },
            error => {
                console.log(error);
                let errorStr = this.errorService.getErrorPTNMessage(error);
                if (errorStr != 'undefined')
                    alert(errorStr);
            }
        );
    }


    private clearPerson() {

        this.person = {
            code_person: null,
            first_name: null,
            last_name: null,
            patronymic: null,
            full_name: null,
            aka_name: null,
            gender: null,
            gender_name: null,
            date_birthday: null,
            description_person: null,
            title: null,
            name_company: null,
            phone: null,
            email: null,
            website: null,
            websiteToShow: null,
            code_country: null,
            name_country: null,
            code_state: null,
            name_state: null,
            code_city: null,
            name_city: null,
            post_address: null,
            subscribe_level: null,
            is_send_message: null,
            date_chat_message_last: null,
            cnt_messages: null,
            companyrelations: [],
            socialnetworks: [],
            current_user_company_relations: []
        }
    }


    onCloseClick() {

        this.onClosePersonCard.emit(true);
    }


    neadSubscribe(subscribeLevel: number): boolean {

        let userSubsribeLevel: number = 0;
  
        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
 
        return (subscribeLevel > userSubsribeLevel);
    }


    getSocialImage(social: SocialNetwork): string {

        return this.webAddressService.getSocialImg(social.type_address);
    }


    onMouseMove(event: MouseEvent) {

        this.onPersonCardBusy.emit(true);
    }


    onMouseOut(event: MouseEvent) {

        this.onPersonCardBusy.emit(false);
    }


    private prepareWeb() {

        if (!this.person)
            return;

        this.person.websiteToShow = this.webAddressService.cutProtocol(this.person.website);
    }


    onBackClick() {

        window.location.href = window.location.origin + this.previousUrl;
    }

}  