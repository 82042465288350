import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './../services/user.service';
import { TranslationService } from './../services/translation.service';
import { PtnAppConfig } from '../shared/ptn-app-config';
import { SearchTranslation } from '../shared/translation.const';
import { langPAGE_HOME } from "./../shared/constant.module";
import { SearchObgect } from "./../models/search.data";
import { WebAddressService } from './../services/web.address.service';
import { TranslateObject } from './../services/translation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorService } from './../services/error.service';
import { searchTextInput$, showSearch$, searchTextParams$ } from "./../header/m-header.component";
import { Subscription } from 'rxjs';


const searchLimit: number = 20;

@Component({
    selector: 'mobile-search',
    templateUrl: './m-search.component.html',
    styleUrls: ['./m-search.component.css']
})


export class SearchComponentM implements OnInit, OnDestroy {

    searchResultPC: Array<SearchObgect> = [];
    searchAllResultPC: Array<SearchObgect> = [];

    searchResultFI: Array<SearchObgect> = [];
    searchAllResultFI: Array<SearchObgect> = [];

    searchResultPI: Array<SearchObgect> = [];
    searchAllResultPI: Array<SearchObgect> = [];

    searchResultPE: Array<SearchObgect> = [];
    searchAllResultPE: Array<SearchObgect> = [];

    foundCountPC = '';
    foundCountFI = '';
    foundCountPI = '';
    foundCountPE = '';
    
    searchValue = '';
    searchOffset: number = 0;
    paramLimit: number = undefined;
    paramFragment: string = undefined;
    private subscriptionFragment: Subscription;
    private subscriptionParam: Subscription;

    slogan = {
        slogan: "()"
    }

    sloganVisible: boolean = true;

    allTranslation = SearchTranslation;
    showMoreObject: Array<TranslateObject> = [];
    sendMessageObject: Array<TranslateObject> = [];

    wasSearch: boolean = false;
    private firstSearch: boolean = false;

    private url = PtnAppConfig.url_v2;
    userSubscribeLevel: number = 0;

    private searchPhase: number = 0;
    private showMoreTypeEntity: string = 'PC';


    constructor(private userService: UserService, private http: HttpClient, private router: Router,
            private translationService: TranslationService, private webAddressService: WebAddressService, 
            private errorService: ErrorService, private activatedRoute: ActivatedRoute) {

        if (this.userService.user && this.userService.user.subscribe_level)
            this.userSubscribeLevel = this.userService.user.subscribe_level;

        this.initSearchResult();

        if (this.translationService.translationDict && this.translationService.translationDict.length > 0) {

            this.translationService.getTranslate('search_object', this.allTranslation);
            this.translationService.getTranslate(langPAGE_HOME, this.slogan);
            this.translationService.getCategoryObject('SEARCH_OBJECT.SHOW_MORE', this.showMoreObject);
            this.translationService.getCategoryObject('SEARCH_OBJECT.TEXT.BUTTON.MESSAGE', this.sendMessageObject);
        } else {
            this.translationService.init();
        }
    }


    ngOnInit() {

        showSearch$.next(true);

        this.subscriptionParam = this.activatedRoute.queryParams.subscribe((queryParam: any) => {
              
            if (queryParam['search']) {
                this.searchValue = queryParam['search'];
                searchTextParams$.next(this.searchValue);
            }

            if (queryParam['limit'])
                this.paramLimit = queryParam['limit'];
        });

        this.subscriptionFragment = this.activatedRoute.fragment.subscribe(
            (fragment: string) => {
                this.paramFragment = fragment;
            }
        );

        searchTextInput$.subscribe((searchStr: string) => {

            this.initSearchResult();
            this.searchValue = searchStr;
            this.searchOffset = 0;
            this.startSearch();
        });

        this.translationService.languageChanged$.subscribe((changeObj) => {

            this.translationService.getTranslate('search_object', this.allTranslation);

            if (!this.firstSearch) {

                this.initSearchResult();
                this.firstSearch = true;
                this.startSearch();
            }
    
            this.translationService.getTranslate(langPAGE_HOME, this.slogan);
            this.translationService.getCategoryObject('SEARCH_OBJECT.SHOW_MORE', this.showMoreObject);
            this.translationService.getCategoryObject('SEARCH_OBJECT.TEXT.BUTTON.MESSAGE', this.sendMessageObject);
        });

        this.userService.Authenticated$.subscribe((isAuthenticated: boolean) => {
            if (isAuthenticated)
                this.userSubscribeLevel = this.userService.user.subscribe_level
            else {
                this.userSubscribeLevel = 0;
            }
        });

        this.initSearchResult();
        this.startSearch();

    }


    ngOnDestroy() {

        showSearch$.next(false);
    }


    private initSearchResult() {

        this.searchResultPC = [];
        this.searchAllResultPC = [];

        this.searchResultFI = [];
        this.searchAllResultFI = [];
    
        this.searchResultPI = [];
        this.searchAllResultPI = [];
    
        this.searchResultPE = [];
        this.searchAllResultPE = [];
    
        this.searchPhase = 0;
        this.wasSearch = false;
    }


    private startSearch() {        

        let myHeaders = new HttpHeaders({
            "x-vms-session": this.userService.x_vms_session,
            "Content-Type": "application/json; charset=utf-8",
            "x-vms-lang": this.translationService.currentLanguage
        });        

        let searchParam: string = '';
        if (this.searchValue && this.searchValue.length > 0)
            searchParam = '&search_string=' + this.searchValue;

        let commandLimit: number = searchLimit;
        if (this.paramLimit && this.paramLimit > 0)
            commandLimit = this.paramLimit;
    
        this.makeParamString();

        this.http.get<Array<SearchObgect>>(this.url + '/object/search?limit=' + commandLimit.toString() + '&offset=' + this.searchOffset + searchParam, 
                { headers: myHeaders }).subscribe(

                response => {

                    let json = response;

                    if (json) {
                        this.parseSearchResult(json);
                    }

                    if (this.paramLimit && this.paramLimit > 0)
                        this.searchOffset = this.paramLimit - searchLimit;
        
                    this.paramLimit = undefined;
                    if (this.subscriptionParam && !this.subscriptionParam.closed)
                        this.subscriptionParam.unsubscribe();

                    setTimeout(() => {

                        let element = document.getElementById('searchInput');
                        if (element)
                            element.focus();

                        element = document.getElementById(this.paramFragment);
                        if (element) {
                            element.scrollIntoView();
                            this.paramFragment = undefined;
                        }
                    
                        if (this.subscriptionFragment && !this.subscriptionFragment.closed)
                            this.subscriptionFragment.unsubscribe();
                        }, 100);

                    this.wasSearch = true;
                },
                error => {
                    this.wasSearch = true;
                    console.log(this.errorService.getErrorPTNMessage(error));
                    console.log(error);
                });
    }


    parseSearchResult(searchResult: Array<SearchObgect>) {

        this.foundCountPC = '';
        this.foundCountFI = '';
        this.foundCountPI = '';
        this.foundCountPE = '';

        let userSubsribeLevel: number = 0;
        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;

        for (let i = 0; i < searchResult.length; i++) {

            switch (searchResult[i].type_entity) {

                case "PC": {
                    let srchRes = this.searchAllResultPC.find(srchRes => srchRes.code_entity === searchResult[i].code_entity);
                    if (!srchRes)
                        this.searchAllResultPC.push(searchResult[i]);

                    this.foundCountPC = searchResult[i].type_entity_count.toString() + '/' + searchResult[i].count_all.toString();
                }
                break;

                case "FI": {
                    let srchRes = this.searchAllResultFI.find(srchRes => srchRes.code_entity === searchResult[i].code_entity);
                    if (!srchRes)
                        this.searchAllResultFI.push(searchResult[i]);
                    this.foundCountFI = searchResult[i].type_entity_count.toString() + '/' + searchResult[i].count_all.toString();
                }
                break;

                case "PI": {
                    let srchRes = this.searchAllResultPI.find(srchRes => srchRes.code_entity === searchResult[i].code_entity);
                    if (!srchRes)
                        this.searchAllResultPI.push(searchResult[i]);
                    this.foundCountPI = searchResult[i].type_entity_count.toString() + '/' + searchResult[i].count_all.toString();
                }
                break;

                case "PE": {
                    let srchRes = this.searchAllResultPE.find(srchRes => srchRes.code_entity === searchResult[i].code_entity);
                    if (!srchRes)
                        this.searchAllResultPE.push(searchResult[i]);
                    this.foundCountPE = searchResult[i].type_entity_count.toString() + '/' + searchResult[i].count_all.toString();
                }
                break;
            }
        }

        switch (this.searchPhase) {

            case 0: {
                this.showAll();
            }
            break;

            case 1: {
                this.showMore(this.showMoreTypeEntity);
            }
            break;
        }
        this.searchPhase = 1;
    }


    private showAll() {

        for (let i = this.searchResultPC.length; i < this.searchAllResultPC.length; i++) {

            let srchRes = this.searchResultPC.find(srchRes => srchRes.code_entity === this.searchAllResultPC[i].code_entity);
            if (!srchRes)
                this.searchResultPC.push(this.searchAllResultPC[i]);

            if (this.searchOffset == 0 && i >= 4 && !this.paramFragment)
                break;
        }

        for (let i = this.searchResultFI.length; i < this.searchAllResultFI.length; i++) {

            let srchRes = this.searchResultFI.find(srchRes => srchRes.code_entity === this.searchAllResultFI[i].code_entity);
            if (!srchRes)
                this.searchResultFI.push(this.searchAllResultFI[i]);

            if (this.searchOffset == 0 && i >= 4 && !this.paramFragment)
                break;
        }

        for (let i = this.searchResultPI.length; i < this.searchAllResultPI.length; i++) {

            let srchRes = this.searchResultPI.find(srchRes => srchRes.code_entity === this.searchAllResultPI[i].code_entity);
            if (!srchRes)
                this.searchResultPI.push(this.searchAllResultPI[i]);

            if (this.searchOffset == 0 && i >= 4 && !this.paramFragment)
                break;
        }

        for (let i = this.searchResultPE.length; i < this.searchAllResultPE.length; i++) {

            let srchRes = this.searchResultPE.find(srchRes => srchRes.code_entity === this.searchAllResultPE[i].code_entity);
            if (!srchRes)
                this.searchResultPE.push(this.searchAllResultPE[i]);

            if (this.searchOffset == 0 && i >= 4 && !this.paramFragment)
                break;
        }
    }


    private showMore(typeEntity: string) {

        let currentCount: number = 0;

        switch (typeEntity) {

            case "PC": {
                currentCount = this.searchResultPC.length;
                for (let i = this.searchResultPC.length; i < this.searchAllResultPC.length; i++) {
        
                    this.searchResultPC.push(this.searchAllResultPC[i]);

                    if (i >= currentCount + searchLimit)
                        break;
                }
            }
            break;

            case "FI": {

                currentCount = this.searchResultFI.length;
                for (let i = this.searchResultFI.length; i < this.searchAllResultFI.length; i++) {
        
                    this.searchResultFI.push(this.searchAllResultFI[i]);
                    if (i >= currentCount + searchLimit)
                        break;
                }
            }
            break;

            case "PI": {
                currentCount = this.searchResultPI.length;
                for (let i = this.searchResultPI.length; i < this.searchAllResultPI.length; i++) {
        
                    this.searchResultPI.push(this.searchAllResultPI[i]);
                    if (i >= currentCount + searchLimit)
                        break;
                }
            }
            break;

            case "PE": {
                currentCount = this.searchResultPE.length;
                for (let i = this.searchResultPE.length; i < this.searchAllResultPE.length; i++) {
        
                    this.searchResultPE.push(this.searchAllResultPE[i]);
                    if (i >= currentCount + searchLimit)
                        break;
                }
            }
            break;
        }
    }

    
    onShowMoreClick(typeEntity: string) {
            
        if (this.neadSubscribeShowMore(this.showMoreObject[0])) {
            this.router.navigate(['/subscribes']);
            return;
        }

        this.showMoreTypeEntity = typeEntity;

        switch (this.showMoreTypeEntity) {

            case "PC": {

                if (this.searchResultPC.length < this.searchAllResultPC.length) {
                    this.showMore(this.showMoreTypeEntity);
                } else {
                    this.searchOffset = this.searchResultPC.length;
                    this.startSearch();
                }
            }
            break;

            case "FI": {
                if (this.searchResultFI.length < this.searchAllResultFI.length) {
                    this.showMore(this.showMoreTypeEntity);
                } else {
                    this.searchOffset = this.searchResultFI.length;
                    this.startSearch();
                }
            }
            break;

            case "PI": {
                if (this.searchResultPI.length < this.searchAllResultPI.length) {
                    this.showMore(this.showMoreTypeEntity);
                } else {
                    this.searchOffset = this.searchResultPI.length;
                    this.startSearch();
                }
            }
            break;

            case "PE": {
                if (this.searchResultPE.length < this.searchAllResultPE.length) {
                    this.showMore(this.showMoreTypeEntity);
                } else {
                    this.searchOffset = this.searchResultPE.length;
                    this.startSearch();
                }
            }
            break;
        }

        this.makeParamString();
    }


    getSocialNetImage(socialTypeAddress: string): string {

        return this.webAddressService.getSocialImg(socialTypeAddress);
    }


    neadSubscribeShowMore(showMoreObject: TranslateObject): boolean {

        let userSubsribeLevel: number = 0;
  
        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;
  
        if (showMoreObject)
            return (showMoreObject.subscribe_level > userSubsribeLevel)
        else
            return false;
    }


    neadMessageSubscribe() {

        if (!this.sendMessageObject || (this.sendMessageObject.length <= 0))
            return false;

        let userSubsribeLevel: number = 0;

        if (this.userService.user && this.userService.user.subscribe_level)
            userSubsribeLevel = this.userService.user.subscribe_level;

         return (this.sendMessageObject[0].subscribe_level > userSubsribeLevel);
    }


    onSendMessageCompanyClick(entity: SearchObgect) {

        if (this.neadMessageSubscribe()) {

            this.router.navigate(['/subscribes']);
        } else {

            this.router.navigate(['/send-message'], { queryParams: { person_id: null,
                                                                     company_id: entity.code_entity,
                                                                     for_person: 0 } });
        }
    }


    onSendMessageCompanyFounderClick(entity: SearchObgect) {

        if (this.neadMessageSubscribe()) {

            this.router.navigate(['/subscribes']);
        } else {

            this.router.navigate(['/send-message'], { queryParams: { person_id: null,
                                                                    company_id: entity.code_entity,
                                                                    for_person: 1 } });
        }
    }


    onSendMessagePersonClick(entity: SearchObgect) {

        if (this.neadMessageSubscribe()) {

            this.router.navigate(['/subscribes']);
        } else {

            this.router.navigate(['/send-message'], 
                { queryParams: { person_id: entity.code_entity,
                    company_id: null } 
                });
        }
    }


    private makeParamString() {

        let parLim: number = 0;
        if (this.paramLimit && this.paramLimit > 0)
            parLim = this.paramLimit
        else
            parLim = this.searchOffset + searchLimit;

        if (this.searchValue && this.searchValue.length > 0) {
            this.router.navigate([], { queryParams: {"search": this.searchValue,
                                    "limit": parLim}, 
                                    fragment: this.paramFragment });
        } else {

            if (this.paramFragment && this.paramFragment.length > 0)
                this.router.navigate([], { fragment: this.paramFragment })
            else
                this.router.navigate([]);
        }
    }


    onEntityClick(obj: SearchObgect, codeFirm?: number) {

        this.paramFragment = obj.type_entity + obj.code_entity.toString();

        this.makeParamString();

        setTimeout(() => { 
            if (obj.type_entity == 'PE')
                this.router.navigate(['./', codeFirm], {relativeTo: this.activatedRoute})
            else
                this.router.navigate(['./', obj.code_entity], {relativeTo: this.activatedRoute});
        }, 10);
    }
 
    
    onPersonClick(event: MouseEvent, personSearch: SearchObgect) {

        this.paramFragment = personSearch.type_entity + personSearch.code_entity.toString();

        this.makeParamString();

        setTimeout(() => { 
            this.router.navigate(['/person-card'], { queryParams: { person_id: personSearch.code_entity } });
        }, 10);
    }

}